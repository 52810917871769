/* Created by obbiettivo on 29/06/18.
 */
import React, { Component } from 'react';
import {List,TextArea} from 'semantic-ui-react';
import { setfocusobj} from '../../../../redux/actions/ObjectAction'



class AudioInfo extends Component {
  constructor (props) {
    super(props);
    var updated = this.props.store.getState();
    this.state={
      previousState:updated,
      description:updated.reducer.focusobj.obj.description,
      name:updated.reducer.focusobj.obj.name };
    this.updateState = this.updateState.bind(this);
    this.neOnChange=this.neOnChange.bind(this);
  }



  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('audio', focusobj,{}));
    unsubscribe();
  }

  neOnChange(event, data){
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    switch(data.name){
      case 'description':
        focusobj.description = data.value;
        this.props.dispatch(setfocusobj('audio', focusobj,{'type':'audio','action':'description'}));
		this.props.parent.waitingAnswer();
        break;
      case 'name':
        focusobj.name = data.value;
        this.props.dispatch(setfocusobj('audio', focusobj,{'type':'audio','action':'description'}));
		this.props.parent.waitingAnswer();
        break;
      default:
        break;
    }
    unsubscribe();
  }

  updateState(){
    var updated = this.props.store.getState() //NEW
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'audio') {
      this.setState({
        previousState:updated,
        description:updated.reducer.focusobj.obj.description,
        name:updated.reducer.focusobj.obj.name});
		this.props.parent.waitingAnswer();
    }
  }

  render () {
    return (
      <List className="componentOnIframeClass">
        <List.Item>
          <List.Content>
            <List.Header className="listitemheaderobj" ></List.Header>
            <List.Description className="listitemobj" >
              <TextArea name="name" placeholder='Name...' onChange={this.neOnChange} value={this.state.name} autoHeight style={{ minHeight: 30,maxHeight: 30, width:'100%',}} maxLength="30" />
              <div>
              <TextArea name="description" placeholder='Description...' onChange={this.neOnChange} value={this.state.description} autoHeight style={{ minHeight: 50, width:'100%',height:'auto'}} maxLength="100" />
              </div>
            </List.Description>
          </List.Content>
        </List.Item>
      </List>
    )
  }
}

export default AudioInfo;
