/**
 * Created by michelefuschillo on 29/01/18.
 */
import React, { Component } from 'react';
import {Table,Input} from 'semantic-ui-react';
import { setfocusobj} from '../../../../redux/actions/ObjectAction'

class Video2dScale extends Component {

  constructor (props) {

    super(props);



    this.updateState = this.updateState.bind(this);
    var updated = this.props.store.getState();
    this.state =
    {
      previousState:updated,
      sizew:parseInt(updated.reducer.focusobj.obj.sizew, 10),
      sizeh:parseInt(updated.reducer.focusobj.obj.sizeh, 10)
    };

    this.shapeScaleHandler = this.shapeScaleHandler.bind(this);
    this.isNumeric = this.isNumeric.bind (this);
  }



  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('video2d', focusobj,{'type':'video2d','action':'scale'}));
    unsubscribe();
  }

  updateState(){
    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'video2d') {
      this.setState({
        previousState:updated,
        sizew: parseInt(updated.reducer.focusobj.obj.sizew, 10),
        sizeh: parseInt(updated.reducer.focusobj.obj.sizeh, 10) });
		this.props.parent.waitingAnswer();
    }
  }

  isNumeric (n) {
    return !isNaN (parseFloat(n)) && isFinite(n);
  }

  shapeScaleHandler (event, data)
  {
      var w;
      var h;
      var unsubscribe = this.props.store.subscribe(this.updateState);
      var updated = this.props.store.getState();
      var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
      var ratio=(1.77);//Set to 16:9

      var esito = this.isNumeric (data.value);
      console.log ('shapeScaleHandler: isNumeric: ', esito);
      if (esito == true) {

        w = Number (data.value);
        w = Math.round (data.value);
        if ((w >= 0) && (w <= 584)) {
          h = parseInt (w/ratio, 10);

          focusobj.sizew = w;
          focusobj.properties.width = w;

          focusobj.sizeh = h;
          focusobj.properties.height = h;

          this.props.dispatch(setfocusobj('video2d', focusobj,{'type':'video2d','action':'scale'}));

        }

      }

    unsubscribe();

  }

  render () {
    return (
      <Table collapsing id='section-config-tableTextPosition' basic='very' >
        <Table.Body>

        {/* FULARR: modifiche su width */}
          <Table.Row>
            <Table.Cell>
            <Input name='sizew' type='range' min={0} max={584} step={1} value={this.state.sizew}
                                onChange={this.shapeScaleHandler}
                                style={{height: 'auto', width: '10vw'}}/>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>

            <Input name="sizew" min={0} max={584} step={1} value={this.state.sizew} onChange={this.shapeScaleHandler} size='small' style={{height:'auto', width:'4vw'}}/><span style={{marginLeft:'10px'}}>Width</span>

            </Table.Cell>

          </Table.Row>

        </Table.Body>

      </Table>
    )
  }
}

export default Video2dScale;
