/**
 * Created by michelefuschillo on 01/02/18.
 */
import React, { Component } from 'react';
import { List, Divider, Input, Icon, Popup, Button } from 'semantic-ui-react';
import { setfocusobj } from '../../../../redux/actions/ObjectAction'
import { launchModal } from '../../../../redux/actions/ModalAction'
import * as services from '../../../../config/services.json';
import { getResourceDataForLanguage } from './../utils';

let unsubscribe;
class AudioAppearance extends Component {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    var updated = this.props.store.getState();
    this.state = {
      previousState: updated,
      width: updated.reducer.focusobj.obj.properties.width,
      height: updated.reducer.focusobj.obj.properties.height,
      name: updated.reducer.focusobj.obj.properties.name,
      audioPath: updated.reducer.focusobj.obj.properties.audioPath,
      coveraction: updated.reducer.focusobj.obj.properties.coveraction,
      resourceid: updated.reducer.focusobj.obj.properties.resourceid
    };
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('audio', focusobj, {}));
    unsubscribe();
  }

  updateState() {
    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'audio') {
      this.setState({
        previousState: updated,
        width: updated.reducer.focusobj.obj.properties.width,
        height: updated.reducer.focusobj.obj.properties.height,
        name: updated.reducer.focusobj.obj.properties.name,
        audioPath: updated.reducer.focusobj.obj.properties.audioPath,
        coveraction: updated.reducer.focusobj.obj.properties.coveraction,
        resourceid: updated.reducer.focusobj.obj.properties.resourceid
      });
      this.props.parent.waitingAnswer();
    } else {
      unsubscribe(); //the state update will be made into another component (asynchronous mode)
    }
  }

  inputHandler = (event, data) => {
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    switch (data.name) {
      case 'width':
        focusobj.properties.width = data.value;
        this.props.dispatch(setfocusobj('audio', focusobj, {}));
        break;
      case 'height':
        focusobj.properties.height = data.value;
        this.props.dispatch(setfocusobj('audio', focusobj, {}));
        break;
      default:
        break;
    }
    unsubscribe();
  }
  openUploadModal = (event, data) => {
    //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'image' });

    /**NUOVA MOADLITà ASSET 07-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "upload",
        typeres: "audio"
      }
    }, "*");

    window.addEventListener('message', this.postMessageHandler);
  }

  openListModal = (event, data) => {
    //unsubscribe = this.props.store.subscribe(this.updateState);
    //this.props.dispatch(launchModal('SETTING_MODAL', 'AUDIO'));
    //this.setState({ macrocategory: 'audio' });
    //unsubscribe = this.props.store.subscribe(this.updateState);
    //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'audio' });
    /**NUOVA MOADLITà ASSET 15-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "audiolist"
      }
    }, "*");
    window.addEventListener('message', this.postMessageHandler);
  }

  /**NUOVA MOADLITà ASSET 15-12-2021 */
  postMessageHandler = (event) => {
    if (event.origin === services.ASSETDOMAIN) { //FROM ASSETLOGIN
      switch (event.data.type) {
        case "NEWRESOURCE":
          //console.log(event.data.payload);
          unsubscribe = this.props.store.subscribe(this.updateState);
          var focusobj = Object.assign({}, this.props.reducer.focusobj.obj);
          if (event.data.payload.type === "audiocloud") {
            let data = {};
            data.medialan = event.data.payload.url;
            focusobj.properties.audioPath = event.data.payload.url[getResourceDataForLanguage(data)];
          } else {
            focusobj.properties.audioPath = this.coverUrl(event.data.payload);
          }
          focusobj.properties.name = event.data.payload.name;
          focusobj.properties.resourceid = event.data.payload._id;
          this.props.dispatch(setfocusobj("audio", focusobj, { 'type': 'audio', 'action': 'audio' }));
          window.removeEventListener('message', this.postMessageHandler);
          //this.setState({ childmessage: event.data.payload.msg });
          break;
        default: break;
      }
    }
  }


  cancelCoverSelected = () => {
    unsubscribe = this.props.store.subscribe(this.updateState)
    var updated = this.props.store.getState()
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj)
    focusobj.properties.audioPath = "";
    focusobj.properties.name = "";
    focusobj.properties.resourceid = "";
    this.props.dispatch(setfocusobj('audio', focusobj, {}));
    this.setState({ name: "" });
  }

  coverUrl = (resource) => {
    let ser =
      services.ASSETBACKEND + services.singleresourcemanager_GET_AUTH.replace(
        "{APPNAME}",
        this.props.reducer.appname
      );
    ser = ser.replace("{DOMAIN}", this.props.reducer.domain);
    ser = ser.replace("{RESOURCEID}", resource._id);
    ser = ser.replace("{USERID}", this.props.reducer.userid);
    ser = ser.replace("{COVER}", false);
    ser = ser.replace("{MEDIARES}", "first");
    ser = ser.replace("{MEDIALAN}", getResourceDataForLanguage(resource));
    ser = ser + "&t=" + new Date().getTime();
    return ser;
  };
  openListModalLink = (event, data) => {
    //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'audio' });
    /**NUOVA MOADLITà ASSET 07-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "audiocloudlist"
      }
    }, "*");
    window.addEventListener('message', this.postMessageHandler);
  }
  render() {
    return (
      <List className="componentOnIframeClass">
        {/*<List.Item>
          <Divider horizontal>Size</Divider>
          <List.Content>
            <List.Description>
              <List horizontal>
                <List.Item>
                  <List.Header> width </List.Header>
                  <Input name='width' type='range' min={1} max={500} step={1} value={this.state.width} onChange={this.inputHandler}
                         style={{ height:'auto', width:'65px'}}/>
                </List.Item>
                <List.Item>
                  <List.Header> height </List.Header>
                  <Input name='height' type='range' min={1} max={500} step={1} value={this.state.height} onChange={this.inputHandler}
                         style={{ height:'auto', width:'65px'}}/>
                </List.Item>
              </List>
            </List.Description>
          </List.Content>
        </List.Item>*/}

        <List.Item>
          <List.Content>
            <List.Description>
              <List>
                <List.Item>
                  <Input size='mini' disabled
                    value={this.state.name} placeholder='Add Audio...'
                    action={!this.state.name ?
                      { icon: 'upload', circular: true, onClick: this.openUploadModal, name: "AUDIO" }
                      :
                      { icon: 'delete', circular: true, onClick: this.cancelCoverSelected, name: "AUDIO" }} />

                  {/* <Popup flowing hoverable position='bottom right' size='small' trigger={
                    <Icon name='list' size="small" onClick={this.openListModal} />
                  }>
                    <p id='section-edit-card-link'>{"Load resource stored"}</p>
                  </Popup> */}


                  <Button icon   onClick={this.openListModal}>
                    <Icon style={{padding:0}}name='list' />
                  </Button>

                  {/* commentato pulsante che apre le risorse create come link */}
                  {/* <Popup flowing hoverable position='bottom right' size='small' trigger={
                    <Icon name='linkify' size="small" onClick={this.openListModalLink} />
                  }>
                    <p id='section-edit-card-link'>{"Load resource stored as a link "}</p>
                  </Popup> */}
                </List.Item>
              </List>
            </List.Description>
          </List.Content>
        </List.Item>
      </List>
    )
  }
}

export default AudioAppearance;
