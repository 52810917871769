/**
* Created by michelefuschillo on 06/11/18.
*/
import React, { Component } from 'react';
import { Modal, Header, Grid, Button, Image, Input, Label, Popup, Icon } from 'semantic-ui-react';
import Axios from 'axios';
import * as services from '../../../../config/services.json';
import { setfocusobj } from '../../../../redux/actions/ObjectAction';
import JumpModal from "../actionModal/jumpmodal.jsx";
import SwitchBackgroundResource from "../actionModal/switchBackgroundResource.jsx";

import '../../../../css/config.css'
var unsubscribe;
class VideoEvent extends Component {
	constructor(props) {
		super(props)
		var videoundertakeoptionstemp = this.props.eventoptions;		
		
		if (videoundertakeoptionstemp) {
			if (videoundertakeoptionstemp.videoundertake === true) {
				this.state = ({
					jumpModal: false,
					switchModal: false,
					vrmodeModal:false,
					videoundertakejump: videoundertakeoptionstemp.type === 'jump' ? true : false,
					videoundertakestop: videoundertakeoptionstemp.type === 'stop' ? true : false,
					videoundertakeloop: videoundertakeoptionstemp.type === 'loop' ? true : false,
					videoundertakeswitch:this.props.switchoptions ? Object.keys(this.props.switchoptions).length !== 0  ? true : false : false,
					videoundertakevrmode:this.props.vrmodeoptions ? Object.keys(this.props.vrmodeoptions).length !== 0  ? true : false : false,
					videoundertakeoptions: this.props.eventoptions,
					switchoptions: this.props.switchoptions,
					vrmodeoptions: this.props.vrmodeoptions

				})
			} else {
				this.state = ({
					jumpModal: false,
					switchModal: false,
					vrmodeModal:false,
					videoundertakejump: false,
					videoundertakestop: false,
					videoundertakeloop:  Object.keys(videoundertakeoptionstemp).length !== 0? videoundertakeoptionstemp.videoundertake : true,
					videoundertakeswitch: this.props.switchoptions ? Object.keys(this.props.switchoptions).length !== 0  ? true : false : false,
					videoundertakevrmode:this.props.vrmodeoptions ? Object.keys(this.props.vrmodeoptions).length !== 0  ? true : false : false,
					videoundertakeoptions: {},
					switchoptions: this.props.switchoptions,
					vrmodeoptions: this.props.vrmodeoptions


				})
			}
		} else {
			this.state = ({
				jumpModal: false,
				switchModal: false,
				vrmodeModal:false,
				videoundertakejump: false,
				videoundertakestop: false,
				videoundertakeloop: true,
				videoundertakeswitch:this.props.switchoptions ? Object.keys(this.props.switchoptions).length !== 0  ? true : false : false,
				videoundertakevrmode:this.props.vrmodeoptions ? Object.keys(this.props.vrmodeoptions).length !== 0  ? true : false : false,
				videoundertakeoptions: {},
				switchoptions: this.props.switchoptions,
				vrmodeoptions: this.props.vrmodeoptions


			})
		}
		this.setEventOptions = this.setEventOptions.bind(this);
	}

	componentWillMount() {

	}

	setEventOptions(eventoptions, switchoptions,vrmodeoptions) {
		console.log("vrmodeoptions")
		console.log(vrmodeoptions)
		var videoundertakeoptionstemp = eventoptions;
		if (videoundertakeoptionstemp) {
			if (videoundertakeoptionstemp.videoundertake === true) {
				this.state = ({
					jumpModal: false,
					switchModal: false,
					vrmodeModal:false,
					videoundertakejump: videoundertakeoptionstemp.type === 'jump' ? true : false,
					videoundertakestop: videoundertakeoptionstemp.type === 'stop' ? true : false,
					videoundertakeloop: videoundertakeoptionstemp.type === 'loop' ? true : false,
					videoundertakeswitch: this.props.switchoptions ? Object.keys(this.props.switchoptions).length !== 0  ? true : false : false,
					videoundertakevrmode:this.props.vrmodeoptions ? Object.keys(this.props.vrmodeoptions).length !== 0  ? true : false : false,
					videoundertakeoptions: this.props.videoundertakeoptions,
					switchoptions: switchoptions,
					vrmodeoptions: vrmodeoptions

				})
			} else {
				this.state = ({
					jumpModal: false,
					switchModal: false,
					vrmodeModal:false,
					videoundertakejump: false,
					videoundertakestop: false,
					videoundertakeloop: false,
					videoundertakeswitch: this.props.switchoptions ? Object.keys(this.props.switchoptions).length !== 0  ? true : false : false,
					videoundertakevrmode:this.props.vrmodeoptions ? Object.keys(this.props.vrmodeoptions).length !== 0  ? true : false : false,
					videoundertakeoptions: {},
					switchoptions: switchoptions,
					vrmodeoptions: vrmodeoptions

				})
			}
		} else {
			this.state = ({
				jumpModal: false,
				switchModal: false,
				vrmodeModal:false,
				videoundertakejump: false,
				videoundertakestop: false,
				videoundertakeloop: false,
				videoundertakeswitch:this.props.switchoptions ? Object.keys(this.props.switchoptions).length !== 0  ? true : false : false,
				videoundertakevrmode:this.props.vrmodeoptions ? Object.keys(this.props.vrmodeoptions).length !== 0  ? true : false : false,
				videoundertakeoptions: {},
				switchoptions: switchoptions,
				vrmodeoptions: vrmodeoptions


			})
		}
	}

	actionManagerInput = (event) => {
		switch (event.target.name) {
			case 'vestopinput':
				if (event.target.checked === true) {
					this.setState({ videoundertakejump: false, videoundertakestop: true, videoundertakeloop: false });
					var newinfo = { 'type': 'stop', 'videoundertake': true };
					this.props.callbackParent('videoeventstop', newinfo);
				} else {
					this.setState({ videoundertakestop: false, videoundertakejump: false, videoundertakeloop: false });
				}
				break;
			case 'vesjumpinput':
				if (event.target.checked === true) {
					this.setState({ videoundertakejump: true, videoundertakestop: false, videoundertakeloop: false });
				} else {
					this.setState({ videoundertakejump: false, videoundertakestop: false, videoundertakeloop: false });
				}
				break;
			case 'vesswitchinput':
				if (event.target.checked === true) {
					this.setState({ videoundertakeswitch: true });
				} else {					
					var newinfo = {};
					this.props.callbackParent('switchbackground', newinfo);
					this.setState({ videoundertakeswitch: false,switchoptions: newinfo  });
				}
				break;
				case 'vesvrmodeinput':
				if (event.target.checked === true) {
					// TODO :salva il dato che fa vedere la bolla in vrmode di modo che possa vederla anche senza la foto destra 
					this.setState({ videoundertakevrmode: true });
				} else {					
					var newinfo = {};
					this.props.callbackParent('vrmode', newinfo);
					this.setState({ videoundertakevrmode: false,vrmodeoptions: newinfo  });
				}
				break;
			case 'vesloopinput':
				if (event.target.checked === true) {
					this.setState({ videoundertakejump: false, videoundertakestop: false, videoundertakeloop: true });
					var newinfo = { 'type': 'loop', 'videoundertake': true };
					this.props.callbackParent('videoeventloop', newinfo);
				} else {
					this.setState({ videoundertakestop: false, videoundertakejump: false, videoundertakeloop: false });
					var newinfo = { 'type': 'loop', 'videoundertake': false };
					this.props.callbackParent('videoeventloop', newinfo);
				}
				break;
			default:
				break;
		}
	}

	jumpActionManager = (event) => {
		this.setState({ jumpModal: true });
	}
	switchActionManager = (event) => {
		this.setState({ switchModal: true });
	}
	vrmodeActionManager = (event) => {
		this.setState({ vrmodeModal: true });
	}
	childCallBack = (tstart, configid, resourcename, items, actionoptions) => {
		console.log("childcallback : " + this.state.vrmodeModal)
		if (this.state.jumpModal === true) {
			var newinfo = { 'type': 'jump', 'idVideo': resourcename, 'idConfig': configid, 'tStart': tstart, 'itemSelect': items, 'actionOptions': actionoptions, 'videoundertake': true };
			this.props.callbackParent('videoeventjump', newinfo);
			this.setState({ jumpModal: false, videoundertakeoptions: newinfo });
		} else if (this.state.switchModal === true) {
			var newinfo = { 'type': 'switchbackground', 'itemSelect': items, 'actionOptions': actionoptions };
			this.props.callbackParent('switchbackground', newinfo);
			this.setState({ switchModal: false, switchoptions: newinfo });
		} else if (this.state.vrmodeModal === true) {
			var newinfo = { 'type': 'vrmodeoptions', 'righteyeresource': items, 'actionOptions': actionoptions ,'status':this.state.videoundertakevrmode};
			this.props.callbackParent('vrmodeoptions', newinfo);
			this.setState({ vrmodeModal: false, vrmodeoptions: newinfo });
		}
	}

	closeModal = (e, data) => {
		this.setState({ jumpModal: false });
	}
	closeSwitchModal = (e, data) => {
		this.setState({ switchModal: false });
	}
	closeVrModeModal = (e, data) => {
		this.setState({ vrmodeModal: false });
	}

	render() {
		return (
			<div id='videoeventdiv'>
				<Grid columns={12} divided>
					<Grid.Row>
						{this.props.reducer.resType ==="VIDEO3"||this.props.reducer.resType ==="VIDEOCLOUD3"||this.props.reducer.resType ==="VIDEO2"||this.props.reducer.resType ==="VIDEOCLOUD2"?
						<div  style={{ 'marginLeft': '10px' }}>
						<Grid.Column>
							<Input style={{ margin: '10px 10px 10px 10px' }} name='vestopinput' type="checkbox" onChange={this.actionManagerInput} checked={this.state.videoundertakestop} />

							Stop
						</Grid.Column>
						<Grid.Column>
							<Input style={{ margin: '10px 10px 10px 10px' }} name='vesloopinput' type="checkbox" onChange={this.actionManagerInput} checked={this.state.videoundertakeloop} />
							Loop

						</Grid.Column>
						<Grid.Column>
							<Input style={{ margin: '10px 10px 10px 10px' }} name='vesjumpinput' type="checkbox" onChange={this.actionManagerInput} checked={this.state.videoundertakejump} />
							Jump

							{this.state.videoundertakejump === true ? <div> <Button name='vesjumpbutton' circular icon='settings' onClick={this.jumpActionManager} size='tiny' />
								<Popup flowing hoverable wide position='bottom center' size='small' trigger={

									<span>
										<Icon name='video' size='small' color="grey" /> <p className="dimensionfont">{this.state.videoundertakeoptions.idVideo} </p>
									</span>} on='hover'>
									<p id='section-edit-card-link'>{this.state.videoundertakeoptions.idVideo}</p>
								</Popup>
								<Popup flowing hoverable wide position='bottom center' size='small' trigger={
									<span>
										<Icon name='list alternate' size='small' color="grey" /><p className="dimensionfont">{this.state.videoundertakeoptions.idConfig}</p>
									</span>} on='hover'>
									<p id='section-edit-card-link'>{this.state.videoundertakeoptions.idConfig}</p>
								</Popup>

								<p className="dimensionfont"> - {this.state.videoundertakeoptions.actionOptions ? this.state.videoundertakeoptions.actionOptions.initialDegree : ''}</p>
								<p className="dimensionfont"> <Image src={this.state.videoundertakeoptions.itemSelect ? this.state.videoundertakeoptions.itemSelect.cover : ''} /></p>
							</div> : ''}
						</Grid.Column>
						</div>
						:<></>}
						<Grid.Column>
							<Input style={{ margin: '10px 10px 10px 10px' }} name='vesswitchinput' type="checkbox" onChange={this.actionManagerInput} checked={this.state.videoundertakeswitch} />
							Switch

							{this.state.videoundertakeswitch === true ? <div> <Button name='vesjumpbutton' circular icon='exchange' onClick={this.switchActionManager} size='tiny' />
								{/* <Popup flowing hoverable wide position='bottom center' size='small' trigger={

									<span>
										<Icon name='video' size='small' color="grey" /> <p className="dimensionfont">{this.state.videoundertakeoptions.idVideo} </p>
									</span>} on='hover'>
									<p id='section-edit-card-link'>{this.state.videoundertakeoptions.idVideo}</p>
								</Popup> */}
								{/* <Popup flowing hoverable wide position='bottom center' size='small' trigger={
									<span>
										<Icon name='list alternate' size='small' color="grey" /><p className="dimensionfont">{this.state.videoundertakeoptions.idConfig}</p>
									</span>} on='hover'>
									<p id='section-edit-card-link'>{this.state.videoundertakeoptions.idConfig}</p>
								</Popup> */}

								<p className="dimensionfont"> - {this.state.switchoptions? this.state.switchoptions.actionOptions ? this.state.switchoptions.actionOptions.switchresource.name ? this.state.switchoptions.actionOptions.switchresource.name : "" : '':""}</p>
								{/* <p className="dimensionfont"> <Image src={this.state.videoundertakeoptions.itemSelect ? this.state.videoundertakeoptions.itemSelect.cover : ''} /></p> */}
							</div> : ''}
						</Grid.Column>
						</Grid.Row>
						<Grid.Row>
						<Grid.Column>
							<Input style={{ margin: '10px 10px 10px 10px' }} name='vesvrmodeinput' type="checkbox" onChange={this.actionManagerInput} checked={this.state.videoundertakevrmode} />
							 VR mode 
							
							{this.state.videoundertakevrmode === true ? <div> <p>Select right eye resource for better VR experience </p><Button name='vesjumpbutton' circular icon='dot circle outline' onClick={this.vrmodeActionManager} size='tiny' />
								{/* <Popup flowing hoverable wide position='bottom center' size='small' trigger={

									<span>
										<Icon name='video' size='small' color="grey" /> <p className="dimensionfont">{this.state.videoundertakeoptions.idVideo} </p>
									</span>} on='hover'>
									<p id='section-edit-card-link'>{this.state.videoundertakeoptions.idVideo}</p>
								</Popup> */}
								{/* <Popup flowing hoverable wide position='bottom center' size='small' trigger={
									<span>
										<Icon name='list alternate' size='small' color="grey" /><p className="dimensionfont">{this.state.videoundertakeoptions.idConfig}</p>
									</span>} on='hover'>
									<p id='section-edit-card-link'>{this.state.videoundertakeoptions.idConfig}</p>
								</Popup> */}

								<p className="dimensionfont"> - {this.state.vrmodeoptions? this.state.vrmodeoptions.actionOptions ? this.state.vrmodeoptions.actionOptions.righteyeresource.name ? this.state.vrmodeoptions.actionOptions.righteyeresource.name : "" : '':""}</p>
								{/* <p className="dimensionfont"> <Image src={this.state.videoundertakeoptions.itemSelect ? this.state.videoundertakeoptions.itemSelect.cover : ''} /></p> */}
							</div> : ''}
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Modal open={this.state.jumpModal} size={'large'} closeIcon={true} onClose={this.closeModal}>
					<Modal.Content scrolling>
						<Header as='h1'>
							<Image id='image-logo-modal' src={this.props.store.getState().reducer.group === "IDEHA" ? '/assets/images/logo_H.png' : '/assets/images/logo.png'} avatar floated='left' alt="H360" />
							<Header.Subheader>Choose the video to jump</Header.Subheader>
						</Header>
						<JumpModal store={this.props.store} callbackFather={this.childCallBack} ></JumpModal>
					</Modal.Content>
				</Modal>
				<Modal open={this.state.switchModal} size={'large'} closeIcon={true} onClose={this.closeSwitchModal}>
					<Modal.Content scrolling>
						<Header as='h1'>
							<Image id='image-logo-modal' src={this.props.store.getState().reducer.group === "IDEHA" ? '/assets/images/logo_H.png' : '/assets/images/logo.png'} avatar floated='left' alt="H360" />
							<Header.Subheader>Choose the image to switch</Header.Subheader>
						</Header>
						<SwitchBackgroundResource store={this.props.store} callbackFather={this.childCallBack}></SwitchBackgroundResource>
					</Modal.Content>
				</Modal>
				<Modal open={this.state.vrmodeModal} size={'large'} closeIcon={true} onClose={this.closeVrModeModal}>
					<Modal.Content scrolling>
						<Header as='h1'>
							<Image id='image-logo-modal' src={this.props.store.getState().reducer.group === "IDEHA" ? '/assets/images/logo_H.png' : '/assets/images/logo.png'} avatar floated='left' alt="H360" />
							<Header.Subheader>Choose the image to show in right eye when vr mode is starting </Header.Subheader>
						</Header>
						<SwitchBackgroundResource store={this.props.store} callbackFather={this.childCallBack} modaltype="vrmode"></SwitchBackgroundResource>
					</Modal.Content>
				</Modal>
			</div>
		)
	}
}
export default VideoEvent
