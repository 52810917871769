/** * Created by michelefuschillo on 06/12/17.*/
import React, { Component } from 'react'
import { Tab, Header, Icon , Divider} from 'semantic-ui-react'
import menuItem from '../../redux/actions/menustate'
import ReactDOM from 'react-dom'
import /*SettingAction,*/ { loadsetting } from '../../redux/actions/SettingAction'
import '../../css/content.css'
import { connect } from 'react-redux'

const mapStateToProps = (state) => ({
  reducer: state.reducer
})
// {menuItem: {key: 'shape', icon: 'cubes', content: 'Shape', disabled: false}},

class TabBarSetting extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeIndex: this.props.settingOpenModalOn,
      panes: [
        {menuItem: {key: 'video2D', icon: 'video camera', content: '2DVideo', disabled: false}},
        {menuItem: {key: 'image', icon: 'smile', content: 'Image', disabled: false}},
        {menuItem: {key: 'audio', icon: 'volume up', content: 'Audio', disabled: false}},
      ]
    }
    this.onTabChange = this.onTabChange.bind(this)
  }

  componentDidMount () {
    this.onTabChange(undefined, {data: {activeIndex: 0}})
    this.props.dispatch(loadsetting('video', {dom: 'columnright', modalfrom: 'settingsbar'}))
    this.setState({activeIndex: 0})
  }

  componentWillMount () {
    if (this.props.reducer) {
      //authentication check
      if (!this.props.reducer.logged) {
        this.props.history.push('/login')
        this.props.dispatch(menuItem('login'))
      }
    } else if (!this.props.logged) {
      this.props.history.push('/login')
      this.props.dispatch(menuItem('login'))
    }
  }

  onTabChange (event, data) {
    this.setState({activeIndex: data.activeIndex})
    ReactDOM.unmountComponentAtNode(document.getElementById('columnright'))
    switch (data.activeIndex) {
      // case 0:
      //   this.props.dispatch(loadsetting('shape', {dom: 'columnright', modalfrom: 'settingsbar'}))
      //   break
      case 0:
        this.props.dispatch(loadsetting('video', {dom: 'columnright',modalfrom: 'settingsbar'}))
        break
      case 1:
        this.props.dispatch(loadsetting('image', {dom: 'columnright',modalfrom: 'settingsbar'}))
        break
      case 2:
        this.props.dispatch(loadsetting('audio', {dom: 'columnright',modalfrom: 'settingsbar'}))
        break
      default:
        break
    }
  }

  render () {
    return (
      <div>
        <Header as='h3' inverted>
          <Icon name='settings' color='grey'/>
          <Header.Content inverted>General Settings</Header.Content>
        </Header>
        <Divider />
        <Tab menu={{secondary: true}} panes={this.state.panes} onTabChange={this.onTabChange}
             activeIndex={this.state.activeIndex} menuPosition='right' style={{margin: 'auto', width: '100%', background:'#FFFFFF'}}/>
        <div id="columnright" style={{margin: 'auto', width: '100%'}}></div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(TabBarSetting)
