/**
 * Created by michelefuschillo on 15/11/17.
 */
import { createStore } from 'redux';
import storage from 'redux-persist/es/storage' // default: localStorage if web, AsyncStorage if react-native
import { persistStore, persistCombineReducers } from 'redux-persist';
import  reducers from '../redux/reducers/rootReducer';

const config = {
  key: 'Hyper360',
  storage,
}

const reducer = persistCombineReducers(config, reducers)
export const configureStore = function configureStore(callback){
  const store = createStore(reducer, /* preloadedState, */
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
   persistStore(store, {}, () => {
    callback(store, persistStore);
  })
}


