import React, { Component } from 'react';
import {Menu, Icon, Button,Image} from 'semantic-ui-react';

class HeaderRightMenu extends Component {
  constructor (props) {
    super(props);
    this.state=({category:"",iconname:""})
    this.handleMenuVertical = this.handleMenuVertical.bind(this);
  }

  componentWillReceiveProps(props) {
    var icon="";
    switch (props.category) {
      case "video2d":
        if (props.data.mentor){
          if (props.data.mentor===true){
            icon="mentor";
          }else{
            icon="video";
          }
        }else{
          icon="video";
        }      
        break;
        case "audio":
        icon="music";
          break;
          case "shape":
          icon="object group";
            break;
            case "image":
            icon="image";
              break;
              case "htmlpage":
              icon="html5";
                break;
                case "textline":
                icon="minus";
                  break;
                  case "text":
                  icon="font";
                    break;
      default:

    }
    this.setState({category:props.category,iconname:icon});
  }

  /*
   *  SHAPE
   * */
  render () {
    return (
        <div style={{"margin":"10px"}}>
          {this.state.iconname==="mentor"?
          <div>
           <Image style={{'display':'inline'}}src='/assets/images/mentoraddon/mentorinsidew.png' height="16" width="16" name='circle'/> 
           <p style={{"display":"inline", 'marginLeft':'5px',"color":"white"}}>{this.props.reducer.focusobj.obj.name ? this.props.reducer.focusobj.obj.name:'Info'}</p>
           </div>
          :
          <div>
           <Icon inverted name={this.state.iconname}/><p style={{"display":"inline", "color":"white"}}>{this.props.reducer.focusobj.obj.name ? this.props.reducer.focusobj.obj.name:'Info'}</p>
           </div>
          }
        </div>
    )
  }

  handleMenuVertical(event, data){
    this.setState({activeItem:data.name});
    this.props.callbackParent(data.name);
  }
}

export default HeaderRightMenu;
