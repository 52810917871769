/**
 * Created by michelefuschillo on 02/08/18.
 */

export function launchModal (modalType, subType, extra,contexttoreq) {
  
  // Return action
  return {
    // Unique identifier
    type: 'MODALTYPE',
    // Payload
    payload:{
      modalType,
      subType,
      extra,
      contexttoreq
    }
  };
};