import React, { Component } from 'react';
import {Button,  Divider,Input,Modal,Grid,Dropdown} from 'semantic-ui-react';
import { transitions, positions, Provider as AlertContainer } from 'react-alert';
import Axios from 'axios';
import * as services from '../../../../config/services.json';



class CategoryAssign extends Component {
  constructor (props) {
    super(props);
    this.state=({nameCat:"",dropDownCatState:[],selectedCategory:""});

    this.handleMessage=this.handleMessage.bind(this);
    this.save=this.save.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.loadCategories=this.loadCategories.bind(this);
    this.onChangeCategory=this.onChangeCategory.bind(this);
  }
  componentWillMount(){
    // console.log(this.props.videoObj)
    this.loadCategories();
  }

  alertOptions = {    offset: 14,    position: 'bottom center',    theme: 'dark',    time: 5000,    transition: 'scale'  };

  handleMessage(event,data){
    // console.log("nome nuovo "+ data.value)  ;
    this.setState({nameCat:data.value,selectedCategory:"" });
  }
  save (){
    var self=this;


    if ((this.state.nameCat!=="")&&(this.state.selectedCategory!=="")){
      this.showAlert('New Category and Existing Category are both valued ', 'error')
    }else {
      if (this.state.nameCat!==""){
        // chiamata nuova categoria
        const found = this.state.dropDownCatState.some(el => el.text.toUpperCase() === this.state.nameCat.toUpperCase());
        if (!found) {
          console.log("Chiamata nuova categoria ");
          var newCategory=
          {
            "name" :this.state.nameCat ,
            "icon" : {
              "ldpi" : "",
              "mdpi" : "",
              "hdpi" : "",
              "xhdpi" : "",
              "xxhdpi" : ""
            },
            "description" : {
              "caption" : {
                "it" : "Caption",
                "en" : "",
                "fr" : "",
                "de" : "",
                "es" : ""
              },
              "text" : {
                "it" : "Description",
                "en" : "",
                "fr" : "",
                "de" : "",
                "es" : ""
              }
            },

            "group" : this.props.videoObj.group,
          };
          newCategory.videos=[];
          newCategory.videos.push(this.props.videoObj);
          var param={
            createCategory:newCategory
          };

          const headers = {headers:{ 'content-type': 'application/json' }};
          Axios.post(services.domain + services.createCategory,param, headers)
          .then(function (response) {
            if(response.status === 200){
              console.log('Funzione chiamata createCategory 200');
              self.showAlert('Category Saved ', 'success')
              self.props.callbackParent();
            }
          })
          .catch(function (error) {
            console.log('Errore in funzione [Configuration.jsx/createCategory]: ', error);
            self.showAlert('Category Not Saved ', 'error')
          });
        }else{
          this.showAlert('Category already exists ', 'error')

        }
      }else {
        if (this.state.selectedCategory!==""){
          // aggiornamento vecchia categoira
          var alreadyCat=this.props.actualCategory.some(el => el === this.state.selectedCategory);
          if(!alreadyCat){
          console.log("Chiamata aggiornamento vecchia  categoria ");
          var param={
            categoryName: this.state.selectedCategory,
            group:this.props.videoObj.group,
            videoObj:this.props.videoObj
          };

          const headers = {headers:{ 'content-type': 'application/json' }};
          Axios.post(services.domain + services.addVideoToCategory,param, headers)
          .then(function (response) {
            if(response.status === 200){
              console.log('Funzione chiamata updateCategory 200');
              self.showAlert('Category Saved ', 'success')
              self.props.callbackParent();
            }
          })
          .catch(function (error) {
            console.log('Errore in funzione [Configuration.jsx/updateCategory]: ', error);
            self.showAlert('Category Not Saved ', 'error')

          });

           }else{
             this.showAlert('Category already assigned ', 'error')

           }
          }else {
          this.showAlert('Category not selected ', 'error')
        }
      }
    }
  }


  handleError(error){
    this.showAlert('Name is arleady taken ', 'error')
  }
  showAlert (message, result) {
    this.msg.show(message, { time: 5000, type: result })  }

    onChangeCategory(event,data){
      this.setState({selectedCategory:data.value,nameCat:""});
    }



    loadCategories(){
      var self=this;
      var servicePath = services.getCategoriesList.replace('[group]', this.props.videoObj.group);
      Axios.get(services.domain+servicePath)
      .then(function (response) {
        console.log(response.data.message);
        var arrayDropDownCat=[];
        for (var i=0;i<response.data.message.length;i++){
          if (response.data.message[i].name!=="all"){
          var dropDownCat={
            key:i ,
            value:response.data.message[i].name ,
            text:  response.data.message[i].name
          };
          arrayDropDownCat.push(dropDownCat);
        }
      }

        self.setState({dropDownCatState:arrayDropDownCat})

      })
      .catch(function (error) {
        console.log("Errore in funzione [Configuration.jsx/loadCategories]: ",error);
      });
    }



    render () {
      return(
        <div>
          <div >
            <Divider style={{marginTop:'35px'}}/>

            <Grid columns={2} divided>

              <Grid.Column >
                <p>New Category:</p>
                <Input focus placeholder="Category Name " id="inputcat_new" onChange={this.handleMessage} value={this.state.nameCat}/>

              </Grid.Column>
              <Grid.Column >
                <p>Existing Categories:</p>
                <Dropdown placeholder='Select Existing Category' clearable selection onChange={this.onChangeCategory} options={this.state.dropDownCatState} value={this.state.selectedCategory}></Dropdown>

              </Grid.Column>


              <Grid.Row >
                  <Button  style={{marginTop:'100px'}} fluid positive size='small' content='Save' onClick={this.save} />

              </Grid.Row>
            </Grid>




          </div>
          <div>
            <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
          </div>
        </div>
      )
    }
  }
  export default CategoryAssign;
