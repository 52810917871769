import React, { Component } from 'react';
import {Grid,Label,Form,Divider} from 'semantic-ui-react';
import {setfocusobj} from '../../../../redux/actions/ObjectAction';
let unsubscribe;

class TextlineTag extends Component {
  constructor (props) {
    super(props);
    this.addTag = this.addTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.inputValue = this.inputValue.bind(this);
    this.updateState = this.updateState.bind(this);
    var updated = this.props.store.getState();
    this.state=({
      tags:updated.reducer.focusobj.obj.properties.tags,
      previousState:updated,
      element:{name:""},
      checkInput: false,
      });
    }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj("textline", focusobj,{}));
    unsubscribe();
  }


  updateState(){
    var updated = this.props.store.getState() //NEW
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'textline') {
      this.setState({
        previousState:updated,
        tags:updated.reducer.focusobj.obj.properties.tags,
        element:{name:""}
      })
	  this.props.parent.waitingAnswer();
  }
}

  addTag(){
   this.setState({ checkInput: false, element:{name:""}}); //pulisco input
   if (this.state.element.name){
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    var found = false;

    for(var i = 0; i < this.state.tags.length; i++) {
      if (this.state.tags[i].name.toUpperCase() === this.state.element.name.toUpperCase()) {
        found = true;
        this.setState({ checkInput: true, element:{name:""}});
        //break;
        return;
      }
    }
    if(!found){//NON TROVATO
      var myTag=this.state.tags;
        if(this.state.element.name.split(";").length -1){//inserimento multiplo
         var result = this.state.element.name.split(";");
           for (var j = 0; j < result.length; j++) {
              var element={name:result[j]};
                if(!myTag.includes(element)){  
                  myTag.push(element);
                }              
            }

            const filteredArray = myTag.map(e => e['name'])
                                       .map((e, i, final) => final.indexOf(e) === i && i)
                                       .filter(e => myTag[e]).map(e => myTag[e]);
                  myTag = [];
                  myTag = filteredArray;
             
                  focusobj.properties.tags = myTag;
                  this.props.dispatch(setfocusobj("textline", focusobj,{}));//sostituisci con tipo da esterno
                  unsubscribe();
        }else{//inserimento singolo
              if(this.state.element.name.length >20){
                this.setState({ checkInput: true, element:{name:""}});
              }else{//Tutti i controlli superati
                myTag.push(this.state.element);
                focusobj.properties.tags = myTag;
                this.props.dispatch(setfocusobj("textline", focusobj,{}));//sostituisci con tipo da esterno
                unsubscribe();
              }  
        }
     }
   }
  }

  removeTag (event, data){
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    var myTag=this.state.tags;
    myTag.splice(data.index,1);
    console.log(focusobj);
    focusobj.properties.tags = myTag;
    this.props.dispatch(setfocusobj("textline", focusobj,{}));//sostituisci con tipo da esterno
    unsubscribe();
  }

  inputValue (event, data){
      this.setState({element:{name:data.value}});
  }

  handleChange = (e, { name, value }) => {
    this.setState({element:{ [name]: value } });    
  }

  render () {
     const tags =Object.values(this.state.tags);
     //const {name} = this.state.element;
    return (
      <div>
  {/* <Input action={{ icon: 'add',circular: true, onClick: this.addTag }} placeholder='Add Tag...' fluid onChange={this.inputValue} value={this.state.element.name}/>*/}
        {/*<Form onSubmit={this.addTag}>*/} 
        <Form>
          <Form.Group>
            <Form.Input  placeholder='Add Tag..(max20 char)'  name='name'  size='mini'
              action={{ icon: 'add',circular: true, onClick: this.addTag }} 
              value={this.state.element.name}
              onChange={this.handleChange} 
              error={this.state.checkInput} />
           {/*<Form.Button content='Submit' />*/}
          </Form.Group>
        </Form>
        <Divider hidden/>
        <Grid column='3' selection="true" id="LabelTag">
         { tags.map((item,index) =>
            <Label content={item.name} removeIcon='delete' onRemove={this.removeTag} index={index} key={index} basic color='grey'/>
          )}
        </Grid>
      </div>
    )
  }
}

export default TextlineTag;
