import React, { Component } from 'react';
import {List, Image, Button} from 'semantic-ui-react';
import {setfocusobj} from '../../../../redux/actions/ObjectAction';
import '../../../../css/config.css';

let unsubscribe;
class MetaComp extends Component {
	constructor (props) {
		super(props);
		this.objSelected=this.objSelected.bind(this);
		this.updateState = this.updateState.bind(this);
	}

	updateState(){
		this.props.parent.waitingAnswer();
	}
	
	objSelected(event, data){
		unsubscribe = this.props.store.subscribe(this.updateState);
		this.props.dispatch(setfocusobj('metadata', {},{'type':'metadata','command':'new','subtype':data.name}));
		unsubscribe();
	}

	render () {
		return (
			<div>
			<List horizontal size="mini">
			<List.Item name='circle'>
			<Button color="grey" circular inverted size='mini' compact onClick={this.objSelected} name='circle'>
			<Image src='/assets/images/metadata/circle-dashed.png' height="16" width="16" name='circle'/>
			</Button>
			</List.Item>
			<List.Item name='squarerounded'>
			<Button color="grey" circular inverted size='mini' compact onClick={this.objSelected} name='squarerounded'>
			<Image src='/assets/images/metadata/square-dashed-rounded.png' height="16" width="16" name='squarerounded'/>
			</Button>
			</List.Item>
			<List.Item name='square'>
			<Button color="grey" circular inverted size='mini' compact onClick={this.objSelected} name='square'>
			<Image src='/assets/images/metadata/square-dashed.png' height="16" width="16" name='square'/>
			</Button>
			</List.Item>
			</List>
			</div>
		)
	}
}

export default MetaComp;