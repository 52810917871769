import React, { Component } from 'react';
import {Container, Button,Grid,Segment} from 'semantic-ui-react';
import Axios from 'axios';
import * as services from '../../../../config/services.json';
import menuItem from '../../../../redux/actions/menustate';
import actionLogout from '../../../../redux/actions/logout';
import { Graph } from 'react-d3-graph';
import ReactDOM from 'react-dom'




class LinkDetail extends Component {
    constructor (props) {
      super(props);
      this.state = {
      data:{},
      };
  

    }
  
  
    componentWillMount() {
     
   } 
   
   
  
  
  
  
  
    render () {
  
      
      return (
         <div></div>
            )
          }
  
        }
  
        export default LinkDetail;
  