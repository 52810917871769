import React, { Component } from 'react';
import {Table,Button,TextArea} from 'semantic-ui-react';
import Axios from 'axios';
import * as services from '../../../../config/services.json';

import '../../../../css/config.css';



class PlayList extends Component {
  constructor (props) {
    super(props);
    this.state=({type:"edit",
      configurations:[],
      configId:"",
      ModalOpenTrash: false,
      itemToDel:null,
      selected: "",
      videoId:this.props.videoId,      
      uploaded:this.props.uploaded,
      resType:this.props.restype,
      fsname:this.props.fsname,
      indexDel:"",
      loadConfigurationCheck:true});

    this.loadConfigurations=this.loadConfigurations.bind(this);
    this.createPlayTab=this.createPlayTab.bind(this);


  }

   static getDerivedStateFromProps(props, state) {
    return { //return new state
      videoId: props.videoId,
      // ... other derived state properties
    };
  }

   componentDidUpdate(prevProps, prevState) { //call after state change (called every time)
     if (this.state.loadConfigurationCheck === true) {
       this.setState({ loadConfigurationCheck: false }, () => {
         this.loadConfigurations();
       })
    }
  }

  componentDidMount() {
  };


  

createPlayTab(event,data){
  const identifierVideo = this.state.videoId;
 
  if (identifierVideo) {
    var servicePath="";
    servicePath = services.video_use_GET_AUTH.replace('[id]', data.value);        
    servicePath = servicePath.replace('[resType]', this.props.reducer.resType);
    servicePath = servicePath.replace('[appname]',  this.props.reducer.appname);
    servicePath = servicePath.replace('[domainAsset]', this.props.reducer.domain);
    servicePath = servicePath.replace('[resourceName]', identifierVideo);
    servicePath = servicePath+ '&userid=' + this.props.userId;
    servicePath = servicePath + '&local=' + true;
    servicePath = servicePath + '&cover=' + false;
    servicePath = servicePath+'&controls=true&postmsg=false&autoplay=true';
    servicePath = servicePath+ "&mediares=first&medialan=it"
    console.log("sto chiamando questo link ma devo controllare i dati in ingresso ")
    console.log(services.domain + servicePath)

    // window.open(h.replace(/&amp;/g, "&");
    window.open(services.domain + servicePath,"_blank");
    // if (data.compass){
    //     if (data.compass.compassStatus===true){
    //     servicePath = servicePath+'&controls=true&postmsg=false&autoplay=true&group='+(this.props.group ? this.props.group:'rti')+"&compassStatus="+data.compass.compassStatus+"&compassPosition="+data.compass.compassPosition+'&uploaded='+(this.state.uploaded ? this.state.uploaded:'NO');
    //   }else{
    //     servicePath = servicePath+'&controls=true&postmsg=false&autoplay=true&group='+(this.props.group ? this.props.group:'rti')+"&compassStatus="+data.compass.compassStatus+'&uploaded='+(this.state.uploaded ? this.state.uploaded:'NO');
    //   }
    // }else {
      
    // }
    // console.log(services.domain + servicePath);
    // if (data.name==='play'){
      

    // } else {
    //   var textArea = document.getElementById("textareaurl");
    // textArea.value=services.domain + servicePath;
    // textArea.select();
    // document.execCommand("copy");
    // }
    } 
  }
 


  


  loadConfigurations(){    
    if(this.props.videoId){
      var self=this;
      // self.setState({configurations:[]}); //Azzero configuration per bind Delete
      var servicePath = services.retrieve_configurations_GET_AUTH.replace("[id]",self.state.videoId);
      servicePath = servicePath.replace('[appname]',  self.props.reducer.appname);
      servicePath = servicePath.replace('[domainAsset]', self.props.reducer.domain);
      servicePath = servicePath.replace('[reqType]', "edit");
      Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.jwt;
      Axios.get(services.domain+servicePath)
        .then(function (response) {
          if(response.data.message.length >0) {
            let newobjArray=[];       
            for (var key in response.data.message) {
              var obj = response.data.message[key];
              var newobj = {
                key: obj._id, //configurazione
                elements: obj.elements, //elementi configurazione (array)
              };
              newobjArray.push(newobj);              
            }
            self.setState({configurations:newobjArray,loadConfigurationCheck:false});
          }else{
            self.setState({configurations:[],loadConfigurationCheck:false});
          }
        })
        .catch(function (error) {
          console.log("Errore in funzione [Configuration.jsx/loadConfigurations]: ",error);
        });
    }
  }

  render () {
    /*const { ModalOpenTrash } = this.state;*/
    return (
      <div>
        <Table singleLine selectable  color='grey' fluid='true' size='small' >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell id='section-content-tableHeader'>Name     </Table.HeaderCell>
              <Table.HeaderCell id='section-content-tableHeader'>Elements </Table.HeaderCell>
              <Table.HeaderCell id='section-content-tableHeader'>Actions  </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>

            <Table.Row >
              <Table.Cell ><strong>Basic  video</strong></Table.Cell>
                <Table.Cell >--</Table.Cell>
                <Table.Cell>
                <Button name='play' basic circular  icon="play"  onClick={this.createPlayTab} />

                  <TextArea id="textareaurl" className='textAreaHidden' value=" " />
                </Table.Cell>
              </Table.Row>

            {this.state.configurations.sort((a, b) => a.key > b.key).map((item,indexCfg) =>
              <Table.Row key={indexCfg}>
                <Table.Cell >{item.key}</Table.Cell>
                <Table.Cell >{item.elements.length}</Table.Cell>

                <Table.Cell >
                  <Button name='play'index={indexCfg} basic circular icon="play" value={item.key} onClick={this.createPlayTab}  compass={item.elements[0].compass} />
                      <TextArea id="textareaurl" className='textAreaHidden' value=" " />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default PlayList;
