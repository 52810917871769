import React, { Component } from "react";
import {
  List,
  Checkbox,
  Input,
} from "semantic-ui-react";
import { setfocusobj } from "../../../redux/actions/ObjectAction";
import "../../../css/config.css";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { transitions, positions, Provider as AlertContainer } from 'react-alert'
let unsubscribe;

class TimeComponent extends Component {
  constructor(props) {
    super(props);
    var updated = this.props.store.getState();
    this.state = {
      previousState: updated,
      show: updated.reducer.focusobj.obj.show,
      hide: updated.reducer.focusobj.obj.hide,
      fulltime: updated.reducer.focusobj.obj.fulltime,
      tStart: updated.reducer.focusobj.obj.tStart,
      tEnd: updated.reducer.focusobj.obj.tEnd,
      vduration: updated.reducer.componentinfo.videoduration,
      value: {
        max: updated.reducer.focusobj.obj.tEnd,
        min: updated.reducer.focusobj.obj.tStart,
	  },
	  tStartTemp: updated.reducer.focusobj.obj.tStart,
	  tEndTemp: updated.reducer.focusobj.obj.tEnd
    };
    this.getValuesFromSlider = this.getValuesFromSlider.bind(this);
    this.setValuesFromTextInput = this.setValuesFromTextInput.bind(this);
    this.updateState = this.updateState.bind(this);
    this.fulltimecheckbox = this.fulltimecheckbox.bind(this);
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    //console.log(updated.reducer.focusobj.obj.category);
    this.props.dispatch(
      setfocusobj(updated.reducer.focusobj.obj.category, focusobj, {})
    );
	  unsubscribe();
    let hideInner = document.getElementById("alertrangehide");
    if(hideInner){
      hideInner.innerHTML = "";
    }
    let showInner = document.getElementById("alertrangeshow");
    if(showInner){
      showInner.innerHTML = "";
    }
  }

  updateState() {
    var updated = this.props.store.getState();
    if (this.state.previousState !== updated) {
      this.setState({
        show: updated.reducer.focusobj.obj.show,
        hide: updated.reducer.focusobj.obj.hide,
        fulltime: updated.reducer.focusobj.obj.fulltime,
        tStart: updated.reducer.focusobj.obj.tStart,
        tEnd: updated.reducer.focusobj.obj.tEnd,
        value: {
          max: updated.reducer.focusobj.obj.tEnd,
          min: updated.reducer.focusobj.obj.tStart,
		},
		tStartTemp: updated.reducer.focusobj.obj.tStart,
		tEndTemp: updated.reducer.focusobj.obj.tEnd
      });
      this.props.parent.waitingAnswer();
    } else {
      unsubscribe();
    }
  }

  getValuesFromSlider(data) {
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.tStart = Number(data.min);
    focusobj.tEnd = Number(data.max);

    this.props.dispatch(
      setfocusobj(focusobj.type, focusobj, { type: "", command: "" })
    );
    unsubscribe();
    let hideInner = document.getElementById("alertrangehide");
    if(hideInner){
      hideInner.innerHTML = "";
    }
    let showInner = document.getElementById("alertrangeshow");
    if(showInner){
      showInner.innerHTML = "";
    }
  }

  setValuesFromTextInput(event, data) {
      var self = this;
      switch (data.name) {
        case "show":
			this.setState({tStartTemp:Number(data.value)});
          if (Number(data.value) < Number(self.state.tEnd) && Number(data.value) >= 0) {
            if (unsubscribe) {
              unsubscribe();
            }
            unsubscribe = self.props.store.subscribe(self.updateState);
            var updated = self.props.store.getState();
            var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
            focusobj.tStart = Number(data.value);
            self.props.dispatch(
              setfocusobj(focusobj.type, focusobj, { type: "", command: "" })
            );
			unsubscribe();
			document.getElementById("alertrangeshow").innerHTML = "";
          } else {
			  document.getElementById("alertrangeshow").innerHTML = "invalid time of appearance";
            //self.showAlertError("Invalid range");
          }
          break;
        case "hide":
		  this.setState({tEndTemp:Number(data.value)});
          if (
            Number(data.value) > Number(self.state.tStart) &&
            data.value <= self.state.vduration
          ) {
            if (unsubscribe) {
              unsubscribe();
            }
            unsubscribe = self.props.store.subscribe(self.updateState);
            var updated = self.props.store.getState();
            var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
            focusobj.tEnd = Number(data.value);
            self.props.dispatch(
              setfocusobj(focusobj.type, focusobj, { type: "", command: "" })
            );
			unsubscribe();
			document.getElementById("alertrangehide").innerHTML = "";
          } else {
			document.getElementById("alertrangehide").innerHTML = "invalid disappearance time";
            //self.showAlertError("Invalid range");
          }
          break;
        default:
          break;
      }
  }

  fulltimecheckbox(e, data) {
    if (unsubscribe) {
      unsubscribe();
    }
    unsubscribe = this.props.store.subscribe(this.updateState);

    var fulltime;

    if (this.state.fulltime) {
      fulltime = false;
    } else {
      fulltime = true;
    }

    var focusobj = Object.assign({}, this.props.reducer.focusobj.obj);
    focusobj.fulltime = fulltime;
    this.props.dispatch(
      setfocusobj(focusobj.type, focusobj, {
        type: focusobj.type,
        command: "fulltime",
        action: fulltime,
      })
    );
    unsubscribe();
  }

  render() {
    return (
      <List>
        <List.Item>
          <List.Content>
            <Checkbox
              name="fulltime"
              label="fulltime"
              checked={this.state.fulltime}
              onChange={this.fulltimecheckbox}
            />
          </List.Content>
        </List.Item>
        {this.state.fulltime != true ? (
          <div style={{ width: "100%", margin: "25px 10px 75px 5px" }}>
            <List.Item name="show">
              <List.Content>
                <InputRange
                  name="Slide"
                  draggableTrack={false}
                  maxValue={this.state.vduration.toFixed()}
                  minValue={0}
                  value={this.state.value}
                  onChange={(value) => this.setState({ value: value })}
                  onChangeComplete={this.getValuesFromSlider}
                />
                <div style={{ width: "100%", margin: "25px 10px 75px 5px" }}>
                  <label
                    style={{
                      margin: "25px 10px 75px 0px",
                      fontStyle: "italic",
                      fontSize: "12px",
                      color: "#aaaaaa",
                    }}
                  >
                    from
                  </label>
                  <Input
                    name="show"
                    value={this.state.tStartTemp}
					onChange={this.setValuesFromTextInput}
                    style={{
                      width: "35%",
					  height: "39px",
					  borderColor:"red"
                    }}
                  />
                  <label
                    style={{
                      margin: "25px 10px 75px 5px",
                      fontStyle: "italic",
                      fontSize: "12px",
                      color: "#aaaaaa",
                    }}
                  >
                    to
                  </label>
                  <Input
                    name="hide"
                    value={this.state.tEndTemp}
                    onChange={this.setValuesFromTextInput}
                    style={{
                      width: "35%",
                      height: "39px",
                    }}
                  />
				  <div id="alertrangehide" style={{
					   width: "100%", 
					   margin: "0px 0px 0px 0px", 
					   fontStyle: "italic",
                      fontSize: "12px",
                      color: "red", }}>
				</div>
				<div id="alertrangeshow" style={{
					   width: "100%", 
					   margin: "0px 0px 0px 0px", 
					   fontStyle: "italic",
                      fontSize: "12px",
                      color: "red", }}>
				</div>
                  <AlertContainer
                    ref={(a) => (this.msg = a)}
                    {...this.alertOptions}
                  />
                </div>
              </List.Content>
            </List.Item>
          </div>
        ) : (
          ""
        )}
      </List>
    );
  }

  alertOptions = {
    offset: 14,
    position: "bottom center",
    theme: "dark",
    time: 5000,
    transition: "scale",
  };

  showAlert = () => {
    this.msg.show("Range correctly saved!", {
      time: 7000,
      type: "success",
    });
  };

  showAlertError = (msg) => {
    this.msg.show(msg, {
      time: 6000,
      type: "error",
    });
  };
}
export default TimeComponent;
