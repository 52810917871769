import React, { Component } from 'react'
import {Icon,Button,Header,Modal,Image,List,Popup,Grid,Input,Container,Radio,Divider, Checkbox, Segment, Dropdown} from 'semantic-ui-react'
import Axios from 'axios'
import * as services from '../../../../config/services.json'
import '../../../../css/config.css'
import InputRange from 'react-input-range';
import { setfocusobj } from "../../../../redux/actions/ObjectAction";

var unsubscribe;
const timelineSearchOptions = [
  { key: 'name', text: 'Name', value: 'name' },
  { key: 'description', text: 'Description', value: 'description' }
]
class TimeLine extends Component {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    var updated = this.props.store.getState();
    this.state = {
      previousState: updated,
      accordionIndexDx: false,
      objDeleteIdentifier: "",
      objDeleteIndex: "",
      items: this.itemsSort(this.props.items),
      searchvalue: "",
      searchType:"name",
      copyItems: this.props.items,
      helpItems: this.props.items,
      selectedFilter: "ALL",
      videoduration: updated.reducer.componentinfo.videoduration,
      value: {
        max: updated.reducer.focusobj.obj.tEnd,
        min: updated.reducer.focusobj.obj.tStart,
      },
    };
    this.setConfigurationItems = this.setConfigurationItems.bind(this);
    this.itemsSort = this.itemsSort.bind(this);
  }

  componentDidMount() {
    //It's necessary to register updateState at first start
    unsubscribe = this.props.store.subscribe(this.updateState);
  }
  updateState() {
    var updated = this.props.store.getState(); //NEW
    this.setState({
      videoduration: updated.reducer.componentinfo.videoduration,
      value: {
        max: updated.reducer.focusobj.obj.tEnd,
        min: updated.reducer.focusobj.obj.tStart,
      },
    });
    this.props.parent.waitingAnswer();
    unsubscribe();
  }

  setConfigurationItems(data) {
    this.setState({ items: this.itemsSort(data) });
  }

  clearCurrentSelection = ()=>{
    for (let i = 0; i < this.state.items.length; i++) {
      this.state.items[i].focused = false;
      let temp = document.getElementById(
        this.state.items[i].objIdentifier + "_timeline"
      );
      if (temp) {
        temp.style.backgroundColor = "";
      }
    }
    let itemsCopy = this.state.items.slice(); //copy array
    this.setState({items:itemsCopy});
  }

  setFocusObjsWithId = (objIdentifier) => {
    //objIdentifier = properties.id (NON è _id di mongo)
    for (let i = 0; i < this.state.items.length; i++) {
      this.state.items[i].focused = false;
      let temp = document.getElementById(
        this.state.items[i].objIdentifier + "_timeline"
      );
      if (temp) {
        temp.style.backgroundColor = "";
      }
    }
    let selectObjectFocused = document.getElementById(
      objIdentifier + "_timeline"
    );
    if (selectObjectFocused) {
      selectObjectFocused.style.backgroundColor = "rgba(119,136,153, 0.5)";
        //update object focused in timeline
        let objIndex = this.state.items.findIndex(
          (x) => x.objIdentifier === objIdentifier
        );
        if(objIndex !== -1 && objIndex < this.state.items.length){
          let itemsCopy = this.state.items.slice(); //copy array
          itemsCopy[objIndex].focused = true;
          this.setState({items:itemsCopy});
        }
      //let objPos = selectObjectFocused.offsetTop;
      //let divRefer = document.getElementById("listobjectimeline");
      //if (divRefer) {
      //if((objPos - divRefer.clientHeight) >= divRefer.clientHeight){
      // divRefer.scrollTop = divRefer.scrollHeight;
      // }else {
      //divRefer.scrollTop = 0;
      //}
      //}
    }
  };

  handleAccordionClick = (e, _ref) => {
    const { index } = _ref;
    const { accordionIndexDx } = this.state;
    var newIndex = accordionIndexDx === index ? -1 : index;
    this.setState({ accordionIndexDx: newIndex });
  };

  modalDeleteClose = (event, data) => {
    if (data.modaltype === "delete") {
      this.setState({ modalDelete: false });
    }
  };

  modalDeleteOpen = (event, data) => {
    if (data.modaltype === "delete") {
      //objid is an identifier in mongo style to identify a "local object"
      this.setState({
        modalDelete: true,
        objDeleteIdentifier: data.id,
        objDeleteIndex: data.index,
        objid: data.objid,
      });
    }
  };

  deleteObject = () => {
    var self = this;
    Axios.defaults.headers.common["Authorization"] =
      "Bearer " + self.props.reducer.jwt;
    Axios({
      method: "delete",
      url: services.domain + services.delete_config_DELETE_AUTH,
      data: {
        identifier: self.state.objDeleteIdentifier,
      },
    })
      .then(function (response) {
        if (response.status === 204) {
          var itemsCopy = self.state.items.slice(); //copy array
          itemsCopy.splice(self.state.objDeleteIndex, 1); //remove element
          self.setState({ items: itemsCopy, modalDelete: false });
          self.props.callbackParent("delete", self.state.objid);
        }
      })
      .catch(function (error) {
        self.setState({ modalDelete: false });
        console.log(
          "Errore in funzione [Configuration.jsx/deleteObject]: ",
          error
        );
      });
    self.setState({ modalDelete: false });
  };

  goto = (event, data) => {
    this.props.callbackParent("gotoedit", data.id);
  };

  gotoPureHtml = (event, data) => {
    this.props.callbackParent("gotoedit", data.id);
  };

  //metodo identico a goto ma la modalità di chiamata
  //è diversa tra semantic e html in jsx
  gotoSpecificMenu = (id) => {
    console.log(id);
    this.props.callbackParent("gotoeditspecificmenu", id);
  };

  filterInput = (event, data) => {
    if (data.value.length > 0) {
      var filtered;
      if(this.state.searchType === 'name'){
        filtered = this.state.helpItems.filter(function (el, index, arr) {
          // Filtering here
          return el.name.toUpperCase().indexOf(data.value.toUpperCase()) !== -1;
        });
      }else{
        filtered = this.state.helpItems.filter(function (el, index, arr) {
          // Filtering here
          return el.description.toUpperCase().indexOf(data.value.toUpperCase()) !== -1;
        });
      }
      this.setState({
        items: this.itemsSort(filtered),
        searchvalue: data.value,
      });
    } else {
      var itemsCopy = this.state.helpItems.slice(); //copy array
      this.setState({
        items: this.itemsSort(itemsCopy),
        searchvalue: data.value,
      });
    }
  };


  searchTypeChoice = (event, data)=>{
    this.setState({searchType:data.value})
  }

  itemsSort(ascend) {
    // let ascend = this.state.items.slice();
    ascend.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    return ascend;
    // this.setState({items:ascend})
  }

  filterCategory = (data, finderCategory, arrayFilter) => {
    var filtered = arrayFilter.filter(function (el, index, arr) {
      // Filtering here
      return el.category === finderCategory;
    });
    this.setState({
      items: this.itemsSort(filtered),
      helpItems: filtered.slice(),
      selectedFilter: data.value,
      searchvalue: "",
    });
  };
  handleChange = (event, data) => {
    switch (data.name) {
      case "ALL":
        this.setState({
          items: this.itemsSort(this.state.copyItems),
          helpItems: this.state.copyItems,
          selectedFilter: data.value,
          searchvalue: "",
        });
        break;
      case "VIDEO":
        this.filterCategory(data, "video2d", this.state.copyItems);
        break;
      case "IMAGE":
        this.filterCategory(data, "image", this.state.copyItems);
        break;
      case "AUDIO":
        this.filterCategory(data, "audio", this.state.copyItems);
        break;
      case "HTMLPAGE":
        this.filterCategory(data, "htmlpage", this.state.copyItems);
        break;
      case "TEXT":
        this.filterCategory(data, "text", this.state.copyItems);
        break;
      case "TEXTLINE":
        this.filterCategory(data, "textline", this.state.copyItems);
        break;          
      default:
        this.setState({
          items: this.itemsSort(this.state.copyItems),
          helpItems: this.state.copyItems,
          selectedFilter: data.value,
          searchvalue: "",
        });
        break;
    }
    // this.setState({ selectedFilter:data.value })
  };

  getValuesFromSlider = (data) => {
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    if (focusobj.properties) {
      focusobj.tStart = Number(data.min);
      focusobj.tEnd = Number(data.max);
      this.props.dispatch(
        setfocusobj(focusobj.type, focusobj, { type: "", command: "" })
      );
      //update object focused in timeline
      var objIndex = this.state.items.findIndex(
        (x) => x.objIdentifier === focusobj.properties.id
      );
      if (objIndex !== -1 && objIndex < this.state.items.length) {
        var itemscopy = this.state.items.slice(); //copy array
        itemscopy[objIndex].tStart = data.min;
        itemscopy[objIndex].tEnd = data.max;
        this.setState({ items: itemscopy });
      }
      unsubscribe();
    }
  };

  fulltimecheckbox = (e, data) => {
    if (unsubscribe) {
      unsubscribe();
    }
    unsubscribe = this.props.store.subscribe(this.updateState);
    var focusobj = Object.assign({}, this.props.reducer.focusobj.obj);
    if (focusobj.properties) {
      if (focusobj.fulltime) {
        focusobj.fulltime = false;
      } else {
        focusobj.fulltime = true;
        focusobj.tStart = 0;
        focusobj.tEnd = this.state.videoduration;
      }
      this.props.dispatch(
        setfocusobj(focusobj.type, focusobj, {
          type: focusobj.type,
          command: "fulltime",
          action: focusobj.fulltime,
        })
      );
      //update object focused in timeline
      var objIndex = this.state.items.findIndex(
        (x) => x.objIdentifier === focusobj.properties.id
      );
      if (objIndex !== -1 && objIndex < this.state.items.length) {
        var itemscopy = this.state.items.slice(); //copy array
        itemscopy[objIndex].fulltime = focusobj.fulltime;
        if(itemscopy[objIndex].fulltime === true){
          itemscopy[objIndex].tStart = focusobj.tStart;
          itemscopy[objIndex].tEnd = focusobj.tEnd;
        }
        this.setState({ items: itemscopy });
      }
      unsubscribe();
    }
  }

  render() {
    return (
      <div id="timelinediv">
        <Grid divided>
          <Grid.Row columns={1} color='black'>
            <Grid.Column width={5} style={{ marginTop: "20px"}}>
            <Input
                style={{ marginLeft: "20px"}}
                fluid
                compact
                color="black"
                placeholder="Search..."
                inverted circular link
                value={this.state.searchvalue}
                onChange={this.filterInput}
                action={
                  <Dropdown  button inverted options={timelineSearchOptions} defaultValue='name' onChange={this.searchTypeChoice} />
                }
              />
               </Grid.Column>
              <Grid.Column width={11} style={{ marginTop: "20px"}}>
              <Button.Group compact circular fluid
      buttons={[
        { key: 'ALL', name: 'ALL', icon: 'unordered list', onClick: this.handleChange, color:'black'},
        { key: 'IMAGE', name: 'IMAGE', icon: 'image outline', onClick: this.handleChange, color:'black'},
        { key: 'VIDEO', name: 'VIDEO', icon: 'video', onClick: this.handleChange, color:'black'},
        { key: 'AUDIO', name: 'AUDIO', icon: 'music ', onClick: this.handleChange, color:'black' },
        { key: 'TEXT', name: 'TEXT', icon: 'font cursor', onClick: this.handleChange, color:'black'},
        { key: 'TEXTLINE', name: 'TEXTLINE', icon: 'minus width', onClick: this.handleChange, color:'black'}        
      ]}
    />{' '}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider style={{ marginTop: "0px" }} />
        <Grid id="listobjectimeline">
          {this.state.items.map((item, index) => (
            <Grid.Row
              columns={7}
              key={index}
              id={item.objIdentifier + "_timeline"}
            >
              <Grid.Column width={1}>
                <div className="timelineName">
                  {(() => {
                    switch (item.category) {
                      case "audio":
                        return <List.Icon name="itunes note" />;
                      case "video2d":
                        return <List.Icon name="video camera" />;
                      case "shape":
                        return <List.Icon name="object group" />;
                      case "text":
                        return <List.Icon name="font" />;
                      case "htmlpage":
                        return <List.Icon name="html5" />;
                      case "image":
                        return <List.Icon name="picture" />;
                      case "textline":
                        return <List.Icon name="minus" />;
                      default:
                        return <List.Icon name="pin" />;
                    }
                  })()}
                </div>
              </Grid.Column>
              <Grid.Column width={2}>
                <div className={
                          typeof item.properties.vo !== "undefined"
                            ? typeof item.properties.vo.showhotspot !==
                              "undefined"
                              ? item.properties.vo.showhotspot === true
                                ? "timelineName"
                                : "timelineNameHidden"
                              : "timelineName"
                            : "timelineName"
                        }>
                  <Popup
                    trigger={
                      <div>
                        {item.name ? item.name : item.category}
                      </div>
                    }
                    on="hover"
                    content={
                      <div>
                        {item.name ? item.name : item.category}
                      </div>
                    }
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={2}>
                <div style={{ width: "100%", margin: "-8px 0px 0px 0px" }}>
                  <Popup
                    trigger={
                      <Button
                        icon
                        as="a"
                        basic
                        circular
                        index={index}
                        id={item.tStart}
                        title="Display"
                      >
                        <Icon
                          name="info"
                          id={item.tStart}
                          color="grey"
                          size="small"
                        />
                      </Button>
                    }
                    on="hover"
                    content={
                      <div>
                        <Icon name="wpforms" color="grey" size="mini" />
                        Desc:
                        {item.description
                          ? item.description
                          : " no-description"}
                      </div>
                    }
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                {/*{item.fulltime === true ? ( */}
                <div id={item.objIdentifier+"_range"} style={{ width: "100%", margin: "0px 0px 0px 0px" }}>
                  {item.focused === true && item.fulltime === false ?
                <InputRange
                    name={item.objIdentifier}
                    draggableTrack={false}
                    maxValue={parseInt(this.state.videoduration.toFixed())}
                    minValue={0}
                    value={{ min: parseInt(item.tStart.toFixed()), max: parseInt(item.tEnd.toFixed())}}
                    onChange={this.getValuesFromSlider}
                /> :
                <InputRange
                    disabled = {true}
                    name={item.objIdentifier}
                    draggableTrack={false}
                    maxValue={parseInt(this.state.videoduration.toFixed())}
                    minValue={0}
                    value={{ min: parseInt(item.tStart.toFixed()), max: parseInt(item.tEnd.toFixed())}}
                    onChange={function(){}}
                />
                }
                </div>
              </Grid.Column>
              <Grid.Column width={2}>
              <div style={{ width: "100%", margin: "-10px 0px 0px 0px"}}>
                <Button
                  size="mini"
                  icon
                  basic
                  circular
                  index={index}
                  id={item}
                  title="Focus"
                  onClick={this.goto}
                >
                  <img src={"/assets/images/focus.png"} style={{width: "20px", height: "20px"}}/>
                </Button>
                </div>
              </Grid.Column>
              <Grid.Column width={2}>
              <div className="timelineName">
              fulltime
              </div>
              </Grid.Column>
              <Grid.Column width={1}>
                  <Checkbox
                    className="timelineName"
                    name="fulltime"
                    checked={item.fulltime}
                    onChange={this.fulltimecheckbox}
                    disabled={item.focused === true ? false : true}
                  />
              </Grid.Column>
              <Grid.Column width={2}>
              <div style={{ width: "100%", margin: "-8px 0px 0px 0px" }}>
                <Button
                  size="mini"
                  modaltype="delete"
                  circular
                  basic
                  icon
                  as="a"
                  index={index}
                  id={item.key}
                  objid={item.objIdentifier}
                  title="Delete"
                  onClick={this.modalDeleteOpen}
                >
                  <Icon name="trash" color="grey" />
                </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
        <Modal
          size="large"
          open={this.state.modalDelete}
          onClose={this.modalDeleteClose}
          id="ModalTemp"
        >
          <Modal.Header as="h3">
            <Icon name="question circle" inverted color="black" />
            Question ?
          </Modal.Header>
          <Modal.Content scrolling>
            <Header as="h4">
              <Image
                id="image-logo-modal"
                src={
                  this.props.reducer.group === "IDEHA"
                    ? "/assets/images/logo_H.png"
                    : "/assets/images/logo.png"
                }
                avatar
                floated="left"
                alt="H360"
              />
              <Header.Subheader>
                Are you sure you want to delete Hotspot?
              </Header.Subheader>
            </Header>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="grey"
              size="tiny"
              content="No"
              onClick={this.modalDeleteClose}
              modaltype="delete"
            ></Button>
            <Button
              primary
              icon="trash"
              size="tiny"
              content="Yes"
              onClick={this.deleteObject}
            ></Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }

  componentWillUnmount() {
    unsubscribe();
  }
}
export default TimeLine 
