
/**
 * Created by Michele Fuschillo 10/09/2018
 */
import React from 'react'
import {Table,Button,Confirm,Image,Divider,Header,Icon,Loader,Input,Grid} from 'semantic-ui-react'
import '../../css/content.css'

const ImageSetting = (parameters) => {
  if (!parameters.self) {
    return null;
  }
  const filterInput = (event, data) => {
    if(data.value.length > 0) {
      var filtered = parameters.self.state.itemscopy.filter(function (el, index, arr) {
        // Filtering here
        return ((el.name.toUpperCase()).indexOf(data.value.toUpperCase()) !== -1);
      });
      parameters.self.setState({items:filtered.slice()});
    }
    else {
      parameters.self.setState({items:parameters.self.state.itemscopy.slice()});
    }
  }

  return (
    <div>
      { (parameters.self.props.modalfrom !=='settingsbar' ||  parameters.self.props.modalfrom == undefined) ? 
        <Header as='h3' color='grey'>
          <Icon name='image' color='grey'/>
          <Header.Content>Image loading</Header.Content>
				</Header>
      : '' }
      <Divider hidden/>
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              <Input inverted icon='search' placeholder='Search...' transparent onChange={filterInput}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      <Table singleLine selectable color='grey' compact size='small' striped id='section-content-headerTable'
             stackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell id='section-content-tableHeader' width={1}></Table.HeaderCell>
            <Table.HeaderCell id='section-content-tableHeader' width={1}>Image </Table.HeaderCell>
            <Table.HeaderCell id='section-content-tableHeader' width={3}>Name </Table.HeaderCell>
            <Table.HeaderCell id='section-content-tableHeader' width={3}>Dimension </Table.HeaderCell>
            <Table.HeaderCell id='section-content-tableHeader' width={3}>Extension </Table.HeaderCell>
            <Table.HeaderCell id='section-content-tableHeader' width={3}>CreatedBy </Table.HeaderCell>
            <Table.HeaderCell id='section-content-tableHeader' width={5}>Actions </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      </Table>
      <div id='section-content-baseTable'>
        <Table singleLine selectable compact size='small' striped id='section-content-tableshape' stackable>
          <Table.Body>
            {parameters.self.state.items.map((item, index) =>
              <Table.Row key={index}>
                <Table.Cell singleLine width={1} ></Table.Cell>
                <Table.Cell singleLine width={1}><Image src={item.pathorigin} avatar size='mini'/></Table.Cell>
                <Table.Cell singleLine width={3}>{item.name}            </Table.Cell>
                <Table.Cell singleLine width={3}>{item.size}{' '}byte </Table.Cell>
                <Table.Cell singleLine width={3}>{item.extension}       </Table.Cell>
                <Table.Cell singleLine width={3}>{item.ownername}       </Table.Cell>
                <Table.Cell singleLine width={5}>
                  <Button icon='trash' basic title='deleted'
                          circular onClick={parameters.self.showDelete} actiontype='delete'
                    value={item.key} index={index} public_id={item.public_id} itemname={item.name}></Button>
                  <Button icon='copy' basic title='copy'
                    circular onClick={() => {
                      const temp = document.createElement("input");
                      document.body.appendChild(temp);
                      temp.value = item.pathorigin;
                      temp.select();
                      document.execCommand("copy");
                      document.body.removeChild(temp);
                    }} actiontype='delete'
                    value={item.key} index={index} public_id={item.public_id} itemname={item.name}></Button>
                  { parameters.self.props.modalfrom !=='settingsbar' ? (
                  <Button icon='check' basic title='selected' selected={item}
                          circular onClick={parameters.self.resourceSelected} actiontype='selectable'
                          value={item.key} index={index} itemname={item.name}></Button>):(
                  <span></span>)}

                  <Confirm id='ModalTemp' open={parameters.self.ModalOpenTrash} onCancel={parameters.self.funcModalClose}
                           onConfirm={parameters.self.funcDeleteConfirmation} header='Delete Icon'
                           content="Are you sure you want to delete Icon?"/>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
        <Divider />
          <Button name="image" icon='upload' floated='right' color='grey' content='New' basic size='tiny'
                  onClick={parameters.self.showUpload} extra={parameters.self.props.extra} ></Button>     
    </div>
  )
}
export default ImageSetting
