/**
 * Created by michelefuschillo on 14/11/17.
 */

function actionLogin(jwt, userid, logged, name, role, group, fsname, resType, appname, uri, resourceid, domain, metadata, itemname, publicStatusExtRes) {
  // Return action  
  return {
    // Unique identifier
    type: 'LOGIN',
    // Payload
    payload:{
      jwt,
      userid,
      logged,
      name,
      role,
      group,
      fsname,
      resType,
      appname,
      uri,
      resourceid,
      domain,
      metadata,
      itemname,
      publicStatusExtRes
    }
  };
};

export var changeName = function(name) {
  // Return action
  return {
    // Unique identifier
    type: 'NAME',
    // Payload
    payload:{
      name
    }
  };
};

export default actionLogin;
