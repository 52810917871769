import React, { Component, Fragment } from 'react';
import { Button, Input, Image, Icon, Dropdown, Step, Radio, Checkbox, Popup, Message } from 'semantic-ui-react';
import Axios from 'axios';
import * as services from '../../../../config/services.json';
import menuItem from '../../../../redux/actions/menustate';
import actionLogout from '../../../../redux/actions/logout';
import { getResourceDataForLanguage } from './../utils';
import '../../../../css/config.css';

const resTypeOptions = [
  {
    key: 'video',
    text: 'Video 360',
    value: 'VIDEO3',
  },
  {
    key: 'image',
    text: 'Image 360',
    value: 'IMAGE3',
  },
  {
    key: 'video',
    text: 'Video 2D',
    value: 'VIDEO2',
  },
  {
    key: 'image2d',
    text: 'Image 2D',
    value: 'IMAGE2',
  }
];

const typeToShwInCreate = ["image", "video"];


class JumpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      itemscopy: [],
      active: [],
      activeFalse: [],
      loading: false,
      coverPath: "",
      video2dToAnnotate: [],
      video3dToAnnotate: [],
      image2dToAnnotate: [],
      image3dToAnnotate: [],
      destinationResType: "",
      selectedConfig: [],
      selectedVideo: [],
      showSelectedConfig: [],
      configurations: [],
      tStart: [],
      idVideo: "",
      idConfig: "",
      initialDegree: "defaultview",
      synchronized: false,
      searchValue: "",
      azimut: 0,
      inclination: 0,
      showMessage: false,
      justBackground: []
    };

    this.onClickVideo = this.onClickVideo.bind(this);
    this.loadConfigurations = this.loadConfigurations.bind(this);
    this.onChangeDropDown = this.onChangeDropDown.bind(this);
    this.timeSecondPicker = this.timeSecondPicker.bind(this);
    this.onClickOkButton = this.onClickOkButton.bind(this);


  }


  itemsSort = (itemToOrder, dataArray) => {
    let ascend = dataArray.slice();
    ascend.sort(function (a, b) {
      return a[itemToOrder].localeCompare(b[itemToOrder]);
    }
    );
    return ascend;
  }



  coverUrl = (resource) => {
    console.log(resource)
    var updated = this.props.store.getState();
    let ser =
      services.ASSETBACKEND + services.singleresourcemanager_GET_AUTH.replace(
        "{APPNAME}",
        updated.reducer.appname
      );
    ser = ser.replace("{DOMAIN}", updated.reducer.domain);
    ser = ser.replace("{RESOURCEID}", resource._id);
    ser = ser.replace("{USERID}", resource.owner);
    ser = ser.replace("{COVER}", true);
    ser = ser.replace("{MEDIARES}", "first");
    ser = ser.replace("{MEDIALAN}", getResourceDataForLanguage(resource));
    ser = ser + "&t=" + new Date().getTime();
    return ser;
  };


  componentWillMount() {
    console.log("component will mount di jumpmodal")
    var updated = this.props.store.getState();
    this.props.store.dispatch(menuItem('home'));
    var self = this;
    this.setState({ loading: true });
    let ser = services.ASSETBACKEND + services.resourcemanager_GET_AUTH.replace("{APPNAME}", updated.reducer.appname);
    ser = ser.replace("{DOMAIN}", updated.reducer.domain);
    ser = ser.replace("{DEST}", "resource");
    ser = ser + "&origin=asset"
    console.log("servizio chiamato res jump ")
    console.log(ser)
    Axios.get(ser).then(function (response) {
      console.log(response.status)
      if (response.status === 200) {
        let elemntsToShowNotOrd = [];
        for (var i = 0; i < response.data.message.length; i++) {
          if (typeToShwInCreate.includes(response.data.message[i].type) && (response.data.message[i].edmCodecName !== "png")) {
            elemntsToShowNotOrd.push(response.data.message[i]);
          }
        }

        let elemntsToShow = self.itemsSort("name", elemntsToShowNotOrd);
        console.log("element to show jump ordered")
        console.log(elemntsToShow)
        self.setState({
          items: elemntsToShow,
          itemscopy: elemntsToShow,
          selectedConfig: new Array(elemntsToShow.length).fill(false),
          selcetedVideo: new Array(elemntsToShow.length).fill(false),
          showSelectedConfig: new Array(elemntsToShow.length).fill(""),
          justBackground: new Array(elemntsToShow.length).fill(false),
          tStart: new Array(elemntsToShow.length).fill(0),
          loading: false,
          selectedVideo: []
        });
      }
    })
      .catch(function (error) {
        console.log("ERRORE:" + error);
        self.setState({ loading: false });
        self.setState({
          items: [],
          selectedConfig: [],
          selcetedVideo: [],
          showSelectedConfig: [],
          tStart: [],
          loading: false,
          selectedVideo: []
        });
        console.log("Errore in funzione [home.jsx/componentWillMount]: ", error);
      });
  };


  handleShow(e) {
    console.log("[handleShow]: ", e.target);
    var itemsCopy = this.state.activeFalse.slice(); //copy array
    itemsCopy.splice(e.target.getAttribute("name"), 1, true); //remove element
    this.setState({ active: itemsCopy });
  }

  onClickVideo(e, d) {
    var stateVideoCopy = this.state.selectedVideo.slice();
    stateVideoCopy[Number(d.name)] = !d.completed;
    var iItems = (this.state.items.findIndex(element => element._id === d.value));
    let videoSelected = this.state.items[iItems];
    // this.setState({selectedVideo:stateVideoCopy, idVideo:d.value},this.loadConfigurations(d.value));
    if (this.state.showSelectedConfig[Number(d.name)] !== "") {
      var showSelectedConfigCopy = this.state.showSelectedConfig.slice();
      showSelectedConfigCopy[Number(d.name)] = "";
      this.setState({ selectedVideo: stateVideoCopy, idVideo: d.value, showSelectedConfig: showSelectedConfigCopy, destinationResType: videoSelected.type.toUpperCase() + videoSelected.degree }, this.loadConfigurations(d.value, d.name));
    } else {
      this.setState({ selectedVideo: stateVideoCopy, idVideo: d.value, destinationResType: videoSelected.type.toUpperCase() + videoSelected.degree }, this.loadConfigurations(d.value, d.name));
    }
    // }

  }



  loadConfigurations(videoId, index) {
    console.log(videoId)
    console.log(index)
    if (videoId) {
      var self = this;
      // self.setState({configurations:[]}); //Azzero configuration per bind Delete
      var updated = this.props.store.getState();
      console.log(videoId)
      var servicePath = services.retrieve_configurations_GET_AUTH.replace("[id]", videoId);
      servicePath = servicePath.replace('[appname]', updated.reducer.appname);
      servicePath = servicePath.replace('[domainAsset]', updated.reducer.domain);
      servicePath = servicePath.replace('[reqType]', "edit");
      Axios.defaults.headers.common['Authorization'] = 'Bearer ' + updated.reducer.jwt;
      Axios.get(services.domain + servicePath)
        .then(function (response) {
          console.log(response)
          if (response.data.message.length > 0) {
            var helpArray = [];
            for (var key in response.data.message) {
              var obj = response.data.message[key];
              var newobj = {
                key: key, //configurazione
                // visibility:obj.elements[0].visibility,
                text: obj._id,
                value: obj._id
                // elements: obj.elements, //elementi configurazione (array)
              };

              // self.setState({configurations: [...self.state.configurations, newobj]});
              helpArray.push(newobj);
            }
            var cpyconfig = self.state.configurations.slice();
            cpyconfig[index] = helpArray;
            self.setState({ configurations: cpyconfig });
          } else {
            self.setState({ configurations: [] });
          }
        })
        .catch(function (error) {
          console.log("Errore in funzione [JumpModal.jsx/loadConfigurations]: ", error);
        });
    }
  }


  onChangeDropDown(e, d) {
    // console.log(e);
    // console.log(d);
    //ricordati di valorizzare destinationResType
    var stateConfigCopy = this.state.selectedConfig.slice();
    var stateCopyShowConfigCopy = this.state.showSelectedConfig.slice();
    stateConfigCopy[Number(d.index)] = !this.state.selectedConfig[Number(d.index)];
    stateCopyShowConfigCopy[Number(d.index)] = d.value;
    this.setState({ selectedConfig: stateConfigCopy, idConfig: d.value, showSelectedConfig: stateCopyShowConfigCopy });
  }





  timeSecondPicker(e, data) {
    // console.log(data);
    var stateTimeCopy = this.state.tStart.slice();
    stateTimeCopy[data.eventindex] = Number(data.value);
    this.setState({ tStart: stateTimeCopy })
  }

  onClickOkButton(e, d) {
    //console.log(this.state.tStart[d.index] + "------"+this.state.idConfig+"----"+ this.state.idVideo);
    if (this.state.initialDegree === 'defaultview') {
      if ((parseInt(this.state.azimut) <= 360) && (parseInt(this.state.azimut) >= 0) && (parseInt(this.state.inclination) <= 90) && (parseInt(this.state.inclination) >= -90)) {
        var updated = this.props.store.getState();
        if (this.state.idVideo) {
          for (var i = 0; i < this.state.items.length; i++) {
            if (this.state.idVideo === this.state.items[i]._id) {
              var actionOptions = {
                initialDegree: this.state.initialDegree,
                synchronized: this.state.synchronized,
                destinationType: this.state.destinationResType,
                appname: updated.reducer.appname,//dato ridondante se nel be riusciamo ad  accedere al data e non properties si può evitare
                domainAsset: updated.reducer.domain,//dato ridondante se nel be riusciamo ad  accedere al data e non properties si può evitare
                medialan: "it",//dato mock con l'avvento della lingua si può migliorare
                mediares: "first",//dato mock con l'avvento della lingua si può migliorare
                azimut: this.state.azimut,
                inclination: this.state.inclination,
              }
              this.props.callbackFather(this.state.tStart[d.index], this.state.idConfig, this.state.idVideo, this.state.items[i], actionOptions);
            }
          }
        }
      } else {
        this.setState({
          showMessage: true, azimut: 0, inclination: 0
        }, () => (window.setTimeout(() => {
          this.setState({
            showMessage: false
          });
        }, 2000))
        );
      }
    } else {
      var updated = this.props.store.getState();
      if (this.state.idVideo) {
        for (var i = 0; i < this.state.items.length; i++) {
          if (this.state.idVideo === this.state.items[i]._id) {
            var actionOptions = {
              initialDegree: this.state.initialDegree,
              synchronized: this.state.synchronized,
              destinationType: this.state.destinationResType,
              appname: updated.reducer.appname,//dato ridondante se nel be riusciamo ad  accedere al data e non properties si può evitare
              domainAsset: updated.reducer.domain,//dato ridondante se nel be riusciamo ad  accedere al data e non properties si può evitare
              medialan: "it",//dato mock con l'avvento della lingua si può migliorare
              mediares: "first"//dato mock con l'avvento della lingua si può migliorare
            }
            this.props.callbackFather(this.state.tStart[d.index], this.state.idConfig, this.state.idVideo, this.state.items[i], actionOptions);
          }
        }
      }
    }
  }

  onChangeAction = (event, data) => {
    this.setState({ initialDegree: data.name, azimut: 0, inclination: 0 });
  }
  onChangeActionSynchronized = (event, data) => {
    console.log(data)
    this.setState({ synchronized: data.checked, idConfig: undefined });
  }
  searchInput = (e, d) => {

    if (d.value.length > 0) {
      let filtered = this.state.items.filter(
        function (el, index, arr) {
          let found = false;
          let activeFilter = [];
          if (el.name.toUpperCase().indexOf(d.value.toUpperCase()) !== -1) {
            activeFilter.push("name");
            found = true;
          }
          el.filter = activeFilter;
          return found;
        }
      );
      console.log(filtered);
      this.setState(
        {
          itemscopy: filtered,
          searchValue: d.value,
          selectedConfig: new Array(filtered.length).fill(false),
          selcetedVideo: new Array(filtered.length).fill(false),
          showSelectedConfig: new Array(filtered.length).fill(""),
          tStart: new Array(filtered.length).fill(0),
          loading: false,
          selectedVideo: []
        }
      );
    } else {
      this.setState(
        {
          itemscopy: this.state.items,
          searchValue: d.value,
          selectedConfig: new Array(this.state.items.length).fill(false),
          selcetedVideo: new Array(this.state.items.length).fill(false),
          showSelectedConfig: new Array(this.state.items.length).fill(""),
          tStart: new Array(this.state.items.length).fill(0),
          loading: false,
          selectedVideo: []
        }
      );
    }
  }
  searchInputCancel = () => {
    // document.getElementById("resourcesearchinput").value = "";
    this.setState(
      {
        itemscopy: this.state.items,
        searchValue: "",
        selectedConfig: new Array(this.state.items.length).fill(false),
        selcetedVideo: new Array(this.state.items.length).fill(false),
        showSelectedConfig: new Array(this.state.items.length).fill(""),
        tStart: new Array(this.state.items.length).fill(0),
        loading: false,
        selectedVideo: []
      }
    );
  };

  handleOffsets = (e, d) => {
    console.log(d.id);
    switch (d.id) {
      case "azimut":
        this.setState({ azimut: d.value });
        break;
      case "inclination":
        this.setState({ inclination: d.value });
        break;
      default:
        break;
    }
    // let pattern=/^[0-9][0-9]?$|^100$/ only number btw 0 e 100 
    // let pattern=/^[-+]?\d*$/ // only number positive and negative
    // 100)|[1-9]\d?
    // let pattern=/^[0-9]$|^[1-9][0-0]$/
    // let pattern=/^[-+]?\b([0-9]|[12345678][0-9]|9[0])\b/
    // console.log(pattern.test(d.value))
    // if (pattern.test(d.value) ||d.value==="" ){
    //   this.setState({azimut:d.value});  
    // }
    // let pattern=/^-?\d*\.?\d+$/        


  }

  handleDismiss = () => {
    this.setState({ showMessage: false });
  }

  handleJustBackground = (e, data) => {
    console.log(this.state.showSelectedConfig[data.i]);
    console.log(data.checked)
    let helparr = this.state.justBackground.slice()
    helparr[data.i] = data.checked;
    this.setState({ justBackground: helparr });
  }

  render() {
    const j = Object.values(this.state.itemscopy);
    // const j = Object.values(this.state.items);
    // console.log(this.state.configurations);
    return (
      <div className="jumpdiv">
        <Input
          id="bubblesearchinput"
          onChange={this.searchInput}
          placeholder="search"
          value={this.state.searchValue}
        />
        <Icon name="close" size="small" onClick={this.searchInputCancel} />

        {j.map((item, index) => {
          var name = item.name;
          return (


            <Step.Group key={index} ordered fluid>
              <Step name={index} completed={this.state.selectedVideo[index]} onClick={this.onClickVideo} value={item._id}>
                {/* <Image style={{"paddingRight":"5px"}} src={this.state.coverPath+item.covername} size='tiny' /> */}
                <Step.Content  >
                  <Image src={this.coverUrl(item)} size='small' alt={name} />


                  <Step.Description>
                    <div style={{"display":"flex"}}>
                      <Icon size='large' name={item.type.toUpperCase() === "IMAGE" ? "image" : "video"} color='grey'  />
                      <Popup pinned hoverable trigger={<Step.Title>{name}</Step.Title>}>
                        <span>{name}</span>
                      </Popup>
                      </div>
                  </Step.Description>

                </Step.Content>
              </Step>
              {this.state.selectedVideo[index] ?
                <Step name={index} completed={this.state.selectedConfig[index]}>
                  <Step.Content>

                    <Step.Title>Choose configuration</Step.Title>
                    <Step.Description>
                      {/* {this.state.configurations.length>0?  */}
                      <Dropdown disabled={this.state.justBackground[index]} placeholder='Select Configuration' clearable selection onChange={this.onChangeDropDown} index={index} options={this.state.configurations[index]} />
                      {/* : "no configuration exits"} */}
                    </Step.Description>
                    <Checkbox label='just background' checked={this.state.justBackground[index]} onChange={this.handleJustBackground} i={index} />
                  </Step.Content>
                </Step> : <span />}

              {this.state.showSelectedConfig[index] !== "" || this.state.justBackground[index] === true ?
                <Step name={index} active>
                  <Step.Content>
                    <Step.Title>Confirm </Step.Title>
                    {(this.state.destinationResType === "VIDEO3" || this.state.destinationResType === "VIDEO2" ?
                      <div>
                        <Step.Description>Start time (seconds)</Step.Description>
                        <Input name='showminute' type='range' min={0} max={item.duration} step={0.1} value={this.state.tStart[index]} eventindex={index} onChange={this.timeSecondPicker}
                          style={{ height: 'auto', width: '50%' }} />

                        <Input name='showminute' value={this.state.tStart[index]} eventindex={index} onChange={this.timeSecondPicker} placeholder={"Seconds..."} style={{ height: '30px', width: '40%' }} />

                        <span> <Checkbox onChange={this.onChangeActionSynchronized} label={"Synchronized"} /></span>
                      </div>
                      :
                      <span></span>
                    )}
                    {(this.state.destinationResType === "VIDEO3" || this.state.destinationResType === "IMAGE3" ?
                      <div>
                        <p style={{ marginTop: '10px' }}>Landing orientation</p>
                        <Radio name='defaultview' label='Default orientation' onChange={this.onChangeAction}
                          checked={this.state.initialDegree === 'defaultview' ? true : false} style={{ marginLeft: '5px' }} />
                        <Radio name='takeoff' label='TakeOff orientation' onChange={this.onChangeAction}
                          checked={this.state.initialDegree === 'takeoff' ? true : false} style={{ marginLeft: '5px' }} />
                        {/*<Radio name='setdegree' label='Set Degree' onChange={this.onChangeAction}
                      checked={this.state.initialDegree === 'setdegree' ? true : false} style={{ marginLeft:'5px'}}/>*/}
                        {this.state.initialDegree === 'defaultview' ?
                          <>
                            <p style={{ marginTop: '10px' }}>Azimut Offset [0,360]</p>
                            <Input
                              type="number"
                              id="azimut"
                              max={360}
                              min={0}
                              placeholder="Enter azimut"
                              onChange={this.handleOffsets}
                              value={this.state.azimut}
                            />
                            <p style={{ marginTop: '10px' }}>Inclination Offset [-90,90]</p>
                            <Input
                              type="number"
                              max={90}
                              min={-90}
                              id="inclination"
                              placeholder="Enter inclination"
                              onChange={this.handleOffsets}
                              value={this.state.inclination}
                            />
                            {this.state.showMessage ?
                              <Message
                                color='red'
                                size='mini'
                                onDismiss={this.handleDismiss}
                                content='Value of Azimut and/or Inclination is not allowed. '
                              />
                              : null}
                          </>
                          : <></>}
                      </div>
                      :
                      <span></span>
                    )}

                    <br />
                    <Button onClick={this.onClickOkButton} style={{ marginTop: '7px' }} index={index}>OK</Button>
                  </Step.Content>
                </Step> : <span />}
            </Step.Group>
          )
        })}

      </div>


    )
  }

}

export default JumpModal;
