/** Created by michelefuschillo on 31/01/18. */
import React, { Component }   from 'react';
import {Accordion,Icon,Dropdown,Image,Popup,Button} from 'semantic-ui-react';
//import ValidUrl from 'valid-url';
import { setfocusobj} from '../../../../redux/actions/ObjectAction';
import * as menu from '../SelectEventsObj.json';
import * as menuEvents from '../EventsAccordion.json';

import SwitcherAction from '../SwitcherAction.jsx'

import * as desc from '../../menu/descriptionmenu.json';

const ShowInfo= true;

class TextAction extends Component {
  constructor (props) {
    super(props);
    var updated = this.props.store.getState();
    this.state=({
      action:"",
      previousState:updated,
      events:updated.reducer.focusobj.obj.properties.events,
      accordionActiveIndex:-1,
      accordionActiveIndexInternal:-1,
      selectedAction:[],
      videoAction:[],
      videoIDAction:[],
      tStartsVideo:[],
      utilsObj:"",
      errorUrl:false})

      this.manageAccordion=this.manageAccordion.bind(this);
      this.onChange=this.onChange.bind(this);
      this.handleRes=this.handleRes.bind(this);
      this.updateState = this.updateState.bind(this);
      this.updateData=this.updateData.bind(this);
      this.onChangeEvent=this.onChangeEvent.bind(this);
      //aggiorna dati letti
      // console.log("update state" + JSON.stringify(this.state.events));

    }
    componentDidMount() {
      this.updateData();
    }

    updateData(){
      var helpEvents=this.state.events.slice();
      var helpActions=this.state.selectedAction.slice();
      var helpVideo=[];
      var helpTstart=[];
      var helpVideoC=[];
      var helpItemSelect=[];

      for(var i=0;i<helpEvents.length;i++){
        switch (helpEvents[i].evenType) {
          case "onAppear":
          helpActions[0]=helpEvents[i].actionType
          helpVideo[0]=helpEvents[i].idConfig;
          helpVideoC[0]=helpEvents[i].idVideo;
          helpTstart[0]=helpEvents[i].tStart;
          helpItemSelect[0]=helpEvents[i].itemSelect;
          break;
          case "onDisappear":
          helpActions[1]=helpEvents[i].actionType
          helpVideo[1]=helpEvents[i].idConfig;
          helpVideoC[1]=helpEvents[i].idVideo;
          helpTstart[1]=helpEvents[i].tStart;
          helpItemSelect[1]=helpEvents[i].itemSelect;
          break;
          case "onCLick":
          helpActions[2]=helpEvents[i].actionType
          helpVideo[2]=helpEvents[i].idConfig;
          helpVideoC[2]=helpEvents[i].idVideo;
          helpTstart[2]=helpEvents[i].tStart;
          helpItemSelect[2]=helpEvents[i].itemSelect;
          break;
          // case "onDoubleCLick":
          // helpActions[3]=helpEvents[i].actionType
          // helpVideo[3]=helpEvents[i].idConfig;
          // helpVideoC[3]=helpEvents[i].idVideo;
          // helpTstart[3]=helpEvents[i].tStart;
          // helpItemSelect[3]=helpEvents[i].itemSelect;
          // break;
          // case "onGaze":
          // helpActions[4]=helpEvents[i].actionType
          // helpVideo[4]=helpEvents[i].idConfig;
          // helpVideoC[4]=helpEvents[i].idVideo;
          // helpTstart[4]=helpEvents[i].tStart;
          // helpItemSelect[4]=helpEvents[i].itemSelect;
          // break;
          // /*case "onStopGazing":
          // helpActions[5]=helpEvents[i].actionType
          // helpVideo[5]=helpEvents[i].idConfig;
          // helpVideoC[5]=helpEvents[i].idVideo;
          // helpTstart[5]=helpEvents[i].tStart;
          // break;*/
          // case "onGazeLongerThan":
          // helpActions[5]=helpEvents[i].actionType
          // helpVideo[5]=helpEvents[i].idConfig;
          // helpVideoC[5]=helpEvents[i].idVideo;
          // helpTstart[5]=helpEvents[i].tStart;
          // helpItemSelect[5]=helpEvents[i].itemSelect;
          // break;
          default:console.log("[textAction-updateData]: No choice");
            break;

        }
      }
      this.setState({ selectedAction: helpActions, videoAction:helpVideo,   videoIDAction:helpVideoC, tStartsVideo: helpTstart});

    }
    componentWillReceiveProps(props) {
      var unsubscribe = this.props.store.subscribe(this.updateState);
      var updated = this.props.store.getState();
      var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
      this.props.dispatch(setfocusobj('text', focusobj,{'type':'text','action':'text'}));
      unsubscribe();

    }


    updateState(){
      var updated = this.props.store.getState();
      if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'text') {
        this.setState({
          previousState:updated,
          events:updated.reducer.focusobj.obj.properties.events
        }, this.updateData);
		this.props.parent.waitingAnswer();
      }
    }

    manageAccordion(event, data){
      switch(data.name){
        case 'accordionBox':
        if(data.index === this.state.accordionActiveIndex){
          this.setState({accordionActiveIndex:-1});
        }else{
          this.setState({accordionActiveIndex:data.index});
        }
        break;
        case 'accordionInternalfirst':
        if(data.index === this.state.accordionActiveIndexInternal){
          this.setState({accordionActiveIndexInternal:-1});
        }else{
          this.setState({accordionActiveIndexInternal:data.index});
        }
        break;
        default:console.log("[textAction-manageAccordion]: No choice");
        break;
      }

    }

    handleRes(tStart,idConfig,idVideo,action,itemSelect,actionOptions){
      var unsubscribe = this.props.store.subscribe(this.updateState);
      var updated = this.props.store.getState();
      var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
      var eventObj = {};

      switch (this.state.accordionActiveIndex) {
        case 0:
        eventObj.evenType="onAppear";
        break;
        case 1:
        eventObj.evenType="onDisappear";
        break;
        case 2:
        eventObj.evenType="onCLick";
        break;
        // case 3:
        // eventObj.evenType="onDoubleCLick";
        // break;
        // case 4:
        // eventObj.evenType="onGaze";
        // break;
        // /*case 5:
        // eventObj.evenType="onStopGazing";
        // break;*/
        // case 5:
        // eventObj.evenType="onGazeLongerThan";
        // break;
        default:console.log("[textAction-handleRes]: No choice");
          break;
      }
      eventObj.actionType=action;
      eventObj.tStart=tStart;
      eventObj.idVideo=idVideo;
      eventObj.idConfig=idConfig;
      eventObj.itemSelect=itemSelect;
      eventObj.actionOptions=actionOptions;

      //eventObj.res= res che deve tornare
      if (typeof focusobj.properties === "undefined") {
        return;
      }

      var indexExistEvent =focusobj.properties.events.findIndex(x => x.evenType === eventObj.evenType);
      if  (indexExistEvent !== -1) {

        // console.log(" esiste evento"+ indexExistEvent);
        focusobj.properties.events.splice(indexExistEvent,1);
        focusobj.properties.events.push(eventObj);
      }else {
        // console.log("non esiste evento" +indexExistEvent );
        focusobj.properties.events.push(eventObj);
      }

      // focusobj.properties.events.push(eventObj);
      // console.log(JSON.stringify(eventObj));
      this.props.dispatch(setfocusobj('text',focusobj,{'type':'text','action':'text'}));
      unsubscribe();
    }


    /*GESTIONE CARD PER INSERIMENTO NUOVO ELEMENTO
    * START
    * */
    onChange(event, data){
      console.log("idaction : "+data.value  +  " idevent:" + this.state.accordionActiveIndex + "evento:" + data.eventobj);
      var actions=this.state.selectedAction.slice();
      actions[this.state.accordionActiveIndex]=data.value;
      if (data.value===""){
      this.setState({selectedAction: [], utilsObj:[]}, this.onChangeEvent);
    }else {
      this.setState({selectedAction: actions});
    }
    }

	onChangeEvent(){
		var unsubscribe = this.props.store.subscribe(this.updateState);
		var updated = this.props.store.getState();
		var focusobj = Object.assign({}, updated.reducer.focusobj.obj);

    if (typeof focusobj.properties === "undefined") {
        return;
    }

		var indexExistEvent =focusobj.properties.events.findIndex(x => x.evenType === this.state.utilsObj);
		if (indexExistEvent !== -1 ){
			focusobj.properties.events.splice(indexExistEvent,1);
			// events[indexExistEvent]={};
			
		}else {
      focusobj.properties.events.splice(-1,1);
    }
    this.props.dispatch(setfocusobj('text',focusobj,{'type':'text','action':'text'}));
		unsubscribe();
	}

    render () {
      const j = Object.values(menuEvents.textMenu);
      return (
        <Accordion styled exclusive={false} fluid >
          {j.map((item,index) =>
            <div key={index}>
              {this.actionType1=this.state.events.findIndex(x => x.evenType === item.event) !== -1 ?
                <div>
                  <Accordion.Title name="accordionBox" className="accordionTitle" active={this.state.accordionActiveIndex === index} index={index} onClick={this.manageAccordion} position='left' >
                    <Icon name='dropdown' id='section-config-IconAccordion'/>

                    <i id='section-config-AccordionHotspot' style={{color: 'blue'}} >{item.text}    </i>
                    { ShowInfo=== true ? 
                      <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                            <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                          }>
                                  <p id='section-edit-card-link'>{item.description}</p>
                      </Popup>
                    :
                      <span></span>}
                  </Accordion.Title>
                  <Accordion.Content active={this.state.accordionActiveIndex  === index}>
                    <Dropdown placeholder='Select action'  clearable selection onChange={this.onChange} options={menu.MenuAll} eventobj={item.text} value={this.state.selectedAction[index]}  />
                    { this.state.selectedAction[index] !== "" ?
                      <span>
                        <SwitcherAction action={this.state.selectedAction[index]} event={item.event}  events={this.state.events}saveFunction={this.props.saveFunction} parent={this.props.parent} store={this.props.store} handleRes={this.handleRes}></SwitcherAction>

                      </span>
                    :<span/>
                    }
                  </Accordion.Content>
                </div>
                :
                <div>
                  <Accordion.Title name="accordionBox" className="accordionTitle" active={this.state.accordionActiveIndex === index} index={index} onClick={this.manageAccordion} position='left' >
                    <Icon name='dropdown' id='section-config-IconAccordion'/>

                    <i id='section-config-AccordionHotspot'>{item.text}</i>
                    { ShowInfo=== true ? 
                      <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                            <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                          }>
                                  <p id='section-edit-card-link'>{item.description}</p>
                      </Popup>
                    :
                      <span></span>}

                  </Accordion.Title>
                  <Accordion.Content active={this.state.accordionActiveIndex  === index}>
                    <Dropdown placeholder='Select action'  clearable selection onChange={this.onChange}options={menu.MenuAll}   />
                    {this.state.selectedAction[index] ? <SwitcherAction action={this.state.selectedAction[index]} parent={this.props.parent} store={this.props.store} handleRes={this.handleRes} ></SwitcherAction> : <span/>}
                  </Accordion.Content>
                </div>
              }

            </div>
          )}
        </Accordion>

      )
    }
}

export default TextAction;
