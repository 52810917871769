import React, { Component } from 'react';
import {Form,Button,Segment,Image,Header,Grid , Container} from 'semantic-ui-react';
import actionLogin from '../redux/actions/auth';
import {connect} from 'react-redux'
import * as data from '../config/services.json';
import { transitions, positions, Provider as AlertContainer } from 'react-alert'
import Axios from 'axios';
import menuItem from '../redux/actions/menustate';


import '../css/login.css';

const mapStateToProps = (state) => ({
  reducer: state.reducer,
})

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {email: '', password:''};
    // This binding is necessary to make `this` work in the callback
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loginAction = this.loginAction.bind(this);
    this.goToEdit=this.goToEdit.bind(this);
    this.idehaAccess=this.idehaAccess.bind(this);

  }

  componentWillMount() {

    const params = new URLSearchParams(this.props.location.search)     
    let originRequest=params.get('originRequest');
    if ( originRequest!==null){
      if (originRequest.toUpperCase()==="IDEHA"){ 
         this.idehaAccess()
        }
    }else if(this.props.reducer.logged){
      this.props.history.push("/");
    } 
  }

  handleChangeEmail(event) {
    this.setState({email: event.target.value});
  }

  handleChangePassword(event) {
    this.setState({password: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    this.loginAction();
  }

  idehaAccess(){
    const params = new URLSearchParams(this.props.location.search)     
        let destinationRequest=params.get('destinationRequest');
        let token = atob (params.get('token'));
        let group = params.get('group');
        let userId = atob (params.get('userId'));
        let role = atob (params.get('role'));
        let name = atob (params.get('name'));        
        if (destinationRequest.toUpperCase()==="HOME"){ //codice che porta alla home 
        this.props.dispatch(actionLogin(token, userId, true,name,role,group));
            this.props.history.push("/");
            this.props.dispatch(menuItem('home'));
        }else{          //codice che porta all'edit
          let title = atob (params.get('title'));
              let resname= atob (params.get('resname')); // nome originale piu estensio ne 
              let resdomain= atob (params.get('resdomain'));
              let restype=atob (params.get('restype'));
              let resid=atob (params.get('resid'));
              //string di test 
              // let title = "Prova di storia da ideha ";
              // let resname= "resource_1604325781918_11.jpg";
              // let resdomain= "http://localhost:3000/public/uploads/IDEHA/resourcetoannotate/2d/image/"
              // let restype="IMAGE2"
              // let resid="5fa011957a04a0156e5bd126"             
              this.props.dispatch(actionLogin(token, userId, true,name,role,group));
              this.props.dispatch(menuItem('home'));              
              this.goToEdit(title , resname,resdomain,restype,resid,destinationRequest);
        }            
    
  }



  loginAction(){
  
      var self=this;
      const parameters = {
        email:this.state.email,
        password:this.state.password
      };
      console.log(parameters);
      //console.log(data.domain + data.auth_register_temp_POST);
      Axios.post(data.domain + data.auth_login_POST,parameters)
        .then(function (response) {
          console.log(response.data.message.token);
          console.log(response.data.message);
          if (response.data.message.role!=="user" ){
          self.props.dispatch(actionLogin(response.data.message.token, response.data.message.userId, true,response.data.message.name,response.data.message.role,response.data.message.group));
          self.props.history.push("/");
          self.props.dispatch(menuItem('home'));
          window.location.reload();
        }else {
          self.showAlertError("You don't have necessary permissions ");
        }
          //self.showAlert();
          //setStart();
        })
        .catch(function (error) {
          console.log(error);
          self.showAlertError("Your login is failed");
        });
    
    //store.subscribe(); //calls callback to update UI
  }


  goToEdit(title , resname,resdomain,restype,resid,destinationRequest) {
    this.props.history.push({ 
      pathname: '/Config', 
      search: 'id=' + resid, 
      state: { 
      title: title,
      topic: "", 
      uploaded: "",
      videoArray: [],
      resType:restype, 
      resname:resname,
      destinationRequest:destinationRequest,
      resdomain:resdomain,// devi gestirlo nel servizio learning per poter comporre il link 

    } });
}



  render(){
    return(
      <div>
        <Grid textAlign='center'  verticalAlign='middle'  id='section-login'>
            <Grid.Column id='section-login-grid'>
              <Segment raised >
                <Header as='h2' color='black' textAlign='center'>
                  <Image src="assets/images/logo.png" />{' '}Login</Header>

                <Form size='large' as='form' onSubmit={this.handleSubmit}>
                  <Segment basic >
                    <Form.Input  fluid   icon='user' iconPosition='left' placeholder='E-mail address'
                                 value={this.state.Email} onChange={this.handleChangeEmail}/>
                    <Form.Input  fluid   icon='lock' iconPosition='left' placeholder='Password' type='password'
                                 value={this.state.Password} onChange={this.handleChangePassword}/>
     <Button animated fluid color='orange' inverted >
                      <Button.Content visible>Login</Button.Content>
                      <Button.Content hidden>Go... </Button.Content>
                    </Button>
<Container textAlign='justified'>
    <p>
   
    </p>
  </Container>
               
                  </Segment>
                </Form>
              </Segment>

              <Segment raised >
                <div>New to us?<a href="Signup"><strong>  Sign Up</strong></a></div>
                <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
              </Segment>
            </Grid.Column>
        </Grid>

     </div>
  )}

  alertOptions = {
    offset: 14,
    position: 'bottom center',
    theme: 'dark',
    time: 5000,
    transition: 'scale'
  }

  showAlert = () => {
    this.msg.show('Login is successful', {
      time: 3000,
      type: 'success',
    })
  }

  showAlertError = (msg) => {
    this.msg.show(msg, {
      time: 6000,
      type: 'error',
    })
  }
}


// Use connect to put them together
export default connect(mapStateToProps)(Login);
