/** Created by michelefuschillo on 31/01/18. */
import React, { Component }   from 'react';
import {Button,Modal,Header,Icon,Popup,Grid,Label,Dropdown} from 'semantic-ui-react';

let unsubscribe


const lowVel=2;
const highVel=10;
class AccurancyPos extends Component {
  constructor (props) {
    super(props);
    this.state=({
    });
    this.handlePosition=this.handlePosition.bind(this);
  }

  componentWillReceiveProps(props) {
  }


  handlePosition(e,d){

    switch (d.name) {
      case "up2":
      console.log("up2");
      this.props.callbackParent("up",highVel);
      break;
      case "up1":
      console.log("up1");
      this.props.callbackParent("up",lowVel);
      break;
      case "down2":
      console.log("down2");
      this.props.callbackParent("down",highVel);
      break;
      case "down1":
      console.log("down1");
      this.props.callbackParent("down",lowVel);
      break;
      case "left1":
      console.log("left1");
      this.props.callbackParent("left",lowVel);
      break;
      case "left2":
      console.log("left2");
      this.props.callbackParent("left",highVel);
      break;
      case "right1":
      console.log("right1");
      this.props.callbackParent("right",lowVel);
      break;
      case "right2":
      console.log("right2");
      this.props.callbackParent("right",highVel);
      break;
      default:

    }
  }

  render () {
    return (
      <Grid columns={5} style={{"marginTop":"4px"}} >
        <Grid.Row style={{"paddingTop":"0px","paddingBottom":"0px"}}>
          <Grid.Column  >
          </Grid.Column>
          <Grid.Column >
          </Grid.Column>
          <Grid.Column  style={{"paddingBottom":"3px"}}>
            <Button  name='up2' circular icon basic onClick={this.handlePosition} >
              <Icon size='small' name='angle double up' /></Button>
            </Grid.Column>
            <Grid.Column >
            </Grid.Column>
            <Grid.Column >
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{"paddingTop":"0px","paddingBottom":"0px"}}>
            <Grid.Column >
            </Grid.Column>
            <Grid.Column >
            </Grid.Column>
            <Grid.Column >
              <Button  name='up1' circular icon basic onClick={this.handlePosition}>
                <Icon size='small' name='angle up' /></Button>
              </Grid.Column>
              <Grid.Column >
              </Grid.Column>
              <Grid.Column >
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{"paddingTop":"0px","paddingBottom":"0px"}}>
              <Grid.Column style={{"paddingLeft":"-5px"}}>
                <Button name='left2'  circular icon basic onClick={this.handlePosition}>
                  <Icon size='small' name='angle double left' /></Button>
                </Grid.Column>
                <Grid.Column  style={{"paddingLeft":"6px","paddingRight":"6px","marginLeft": "7px"}}>
                  <Button name='left1'  circular icon basic onClick={this.handlePosition}>
                    <Icon size='small' name='angle left' /></Button>
                  </Grid.Column>
                  <Grid.Column style={{"margin":"-4px"}}>
                  </Grid.Column>
                  <Grid.Column style={{"paddingLeft":"6px"}}>
                    <Button  name='right1' circular icon basic onClick={this.handlePosition} >
                      <Icon size='small' name='angle right' /></Button>
                    </Grid.Column>
                    <Grid.Column  style={{"paddingLeft":"6px"}}>
                      <Button  name='right2' circular icon basic onClick={this.handlePosition}>
                        <Icon size='small' name='angle double right' /></Button>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{"paddingTop":"0px","paddingBottom":"0px"}}>
                      <Grid.Column >
                      </Grid.Column>
                      <Grid.Column  >
                      </Grid.Column>
                      <Grid.Column  style={{"paddingBottom":"3px"}} >
                        <Button name='down1'  circular icon basic onClick={this.handlePosition}>
                          <Icon size='small' name='angle down' /></Button>
                        </Grid.Column>
                        <Grid.Column >
                        </Grid.Column>
                        <Grid.Column >
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row style={{"paddingTop":"0px","paddingBottom":"0px"}}>
                        <Grid.Column >
                        </Grid.Column>
                        <Grid.Column >
                        </Grid.Column>
                        <Grid.Column >
                          <Button name='down2'  circular icon basic onClick={this.handlePosition}>
                            <Icon size='small' name='angle double down' /></Button>
                          </Grid.Column>
                          <Grid.Column >
                          </Grid.Column>
                          <Grid.Column >
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    )
                  }
                }

  export default AccurancyPos;
