/* Created by obbiettivo on 18/06/18. */
import React, { Component } from 'react';
import {Checkbox,Form}      from 'semantic-ui-react';
import {setfocusobj} from '../../../../redux/actions/ObjectAction';
import '../../../../css/config.css';

let unsubscribe;

class Video2doptions extends Component {
  constructor (props) {
    super(props);
    
    this.updateState = this.updateState.bind(this);
    this.optionHandler = this.optionHandler.bind(this);

    var updated = this.props.store.getState();
      this.state = {
        previousState:updated,
        loop:updated.reducer.focusobj.obj.properties.loop,
        autoplay:updated.reducer.focusobj.obj.properties.autoplay,
		stopback:updated.reducer.focusobj.obj.properties.stopback
      };
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('video2d', focusobj,{}));
    unsubscribe();
  }

   updateState(){
    var updated = this.props.store.getState() //NEW
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'video2d') {
      
      this.setState({
        previousState:updated,
        loop:updated.reducer.focusobj.obj.properties.loop,
        autoplay:updated.reducer.focusobj.obj.properties.autoplay,
		stopback:updated.reducer.focusobj.obj.properties.stopback
    });
	this.props.parent.waitingAnswer();
  }

}


  optionHandler = (event, data) => {
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);

    switch (data.name){
      case 'loop':
        focusobj.properties.loop = data.checked;
        this.props.dispatch(setfocusobj('video2d', focusobj,{}));
        break;
      case 'autoplay':
        focusobj.properties.autoplay = data.checked;
        this.props.dispatch(setfocusobj('video2d', focusobj,{}));
        break;
      case 'stopback':
      focusobj.properties.stopback = data.checked;
      this.props.dispatch(setfocusobj('video2d', focusobj,{}));
      break;	
      default:
        break;
    }
    unsubscribe();
  }

  
  render () {
    return (
     <Form>
       <Form.Field>
        <Checkbox className="check" label='loop' name='loop' checked={this.state.loop} onChange={this.optionHandler}/>
        </Form.Field>

        <Form.Field>
        <Checkbox className="check" label='autoplay' name='autoplay' checked={this.state.autoplay} onChange={this.optionHandler}/>
        </Form.Field>
		
        <Form.Field>
        <Checkbox className="check" label='stop background video' name='stopback' checked={this.state.stopback} onChange={this.optionHandler}/>
        </Form.Field>
      </Form>
    )
  }
}

export default Video2doptions;
