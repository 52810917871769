/* Created by obbiettivo on 18/06/18. */
import React, { Component } from 'react';
import {Image, List, Button}         from 'semantic-ui-react';
import {setfocusobj} from '../../../../redux/actions/ObjectAction';
import '../../../../css/config.css';

let unsubscribe;
class AudioComp extends Component {
	constructor (props) {
		super(props);
		this.updateState = this.updateState.bind(this);
		this.audioOnclick = this.audioOnclick.bind(this);
	}

	updateState(){
		this.props.parent.waitingAnswer();
	}
  
	audioOnclick(e){
		unsubscribe = this.props.store.subscribe(this.updateState);
		this.props.dispatch(setfocusobj(
			'audio',
			{type: 'audio', action: '', name:'', description:'', properties: {width:10, height:10, audioPath:'',coveraction:'', name:''}},
			{'type':'audio','command':'new','aspect':e.target.title}));
			unsubscribe();
		}

		render () {
			return (
				<List size="mini">
				<List.Item>
				<Button color="grey" circular inverted size='mini' compact onClick={this.audioOnclick}>
				<Image src='/assets/images/audio/audio.png' height="16" width="16" name='circle'/>
				</Button>
				</List.Item>
				</List>
			)
		}
	}

export default AudioComp;
