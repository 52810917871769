import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Image,Divider,Container,Header,Grid,Segment,Item,Icon} from 'semantic-ui-react';


import '../css/about.css';

const mapStateToProps = (state) => ({
  reducer: state.reducer,
})


class About extends Component {
  /*constructor (props) {
    super(props);
  }*/

  render(){
    return(
		<div>
		<Segment basic id='section-about-segment'>
      	 <Image centered id='section-about-image' src="/assets/images/about-page_01.jpg" size='medium'  />

      	 </Segment>
      	 <Divider hidden />

      	 <Segment basic id='section-about-segment'>
    	  <Container textAlign='left' >
    		<Header as='h1' id='section-about-header'>About</Header>
    		<span>Hyper360 is aimed at Introducing a complete solution for the capture, production, enhancement, delivery and consumption of an innovative free viewpoint video (FVV) media format to the OTT media sectors, through careful validation and large demonstrations. Envisioning increasingly immersive experiences, the convergence of omnidirectional (360°) and 3D content will extend current short productions of 360° videos with novel and powerful storytelling opportunities. Furthermore, leveraging on the – inherent to the selected format – capabilities, the broadband delivered content will be offered with additional audio-visual functionalities, bringing together more types of digital content, and adapted according to the viewer’s preferences offering unique experiences upon content consumption. With novel content through the fusion of modern formats (360° and 3D), the European media sector can gain an edge over global competition, reduce the global players’ market share and even penetrate global markets that have been unreachable until now, both through content and service provisions.</span>
       	  </Container>
       	  </Segment>

       	  <Divider hidden />

       	  <Segment basic id='section-about-segment'>

       	  <Container textAlign='left' >
    		<Grid columns={3} divided  centered >
			
		    <Grid.Row>
      			<Grid.Column>
	       	    	<Item>
    		    	
          		 	<Item.Content>
          			<Item.Header as='h3'><Icon name='line chart' size='large' color="orange"/>Increasing</Item.Header>
          			<Item.Meta><em>Increasing the quality of 360° video capturing</em></Item.Meta>
          			</Item.Content>
         		  	</Item>
		 	    </Grid.Column>
    
				<Grid.Column>
	       	    	<Item>
    		    	
          		 	<Item.Content>
          			<Item.Header as='h3'><Icon name='check' size='large' color="orange"/>Enabling</Item.Header>
          			<Item.Meta><em>Enabling the production of 3D narrative content, by capturing in 3D the narrator’s appearance and performance</em></Item.Meta>
          			</Item.Content>
         		  	</Item>
		 	    </Grid.Column>


		 	    <Grid.Column>
	       	    	<Item>
    		    	
          		 	<Item.Content>
          			<Item.Header as='h3'><Icon name='pencil' size='large' color="orange"/>Annotating</Item.Header>
          			<Item.Meta><em>Annotating 360° video with dynamic placeholder objects as well as media hyperlinks</em></Item.Meta>
          			</Item.Content>
         		  	</Item>
		 	    </Grid.Column>
		    </Grid.Row>
  			</Grid>
  			</Container>

         	<Divider hidden />

         	<Container textAlign='left' >
    		<Grid columns={3} divided  centered >
			
		    <Grid.Row>
      			<Grid.Column>
	       	    	<Item>
          		 	<Item.Content>
          			<Item.Header as='h3'><Icon name='newspaper' size='large' color="orange"/>Fusing</Item.Header>
          			<Item.Meta><em>Fusing the annotated 360° video and 3D storytelling into a new free viewpoint format</em></Item.Meta>
          			</Item.Content>
         		  	</Item>
		 	    </Grid.Column>
    
				<Grid.Column>
	       	    	<Item>
          		 	<Item.Content>
          			<Item.Header as='h3'><Icon name='flask' size='large' color="orange"/>Extracting and Modelling</Item.Header>
          			<Item.Meta><em>Implicitly extracting and modelling user preferences to personalise the viewing experience, both on the long-term as well as with respect to contextual circumstances</em></Item.Meta>
          			</Item.Content>
         		  	</Item>
		 	    </Grid.Column>


		 	    <Grid.Column>
	       	    	<Item>
          		 	<Item.Content>
          			<Item.Header as='h3'><Icon name='video' size='large' color="orange"/>Recommending</Item.Header>
          			<Item.Meta><em>Recommending both converged media content items and content narrative cues</em></Item.Meta>
          			</Item.Content>
         		  	</Item>
		 	    </Grid.Column>
		    </Grid.Row>
  			</Grid>
  			</Container>
  			</Segment>
  			<Divider hidden />


  		 <Segment basic id='section-about-segment'>		
       	  <Container textAlign='left' >
    		<span>In this context, the principal goal of Hyper360 will be built on top of existing technological expertise on 360° video, on 3D human appearance and performance capturing and personalisation (FOKUS/CERTH), integrated (ENG) and commercialised by the industry (ENG, DRK), validated within two scenarios by our broadcasters (RBB, RTI) with existing experience in 360° productions and broadcasts, and prepare its market entry through careful planning and outreach (EUROKLEIS) as well as capitalise on its incubation capabilities (DRK) to create new job opportunities. Most of this existing knowledge has been gained within past European projects, thus Hyper360 aims to fuse the consortium’s expertise and innovate accordingly</span>
       	  </Container>
		</Segment>	
		<Divider hidden />
		<Divider />


		<Segment basic id='section-about-segment' >		
			<Image  src='assets/images/ec-logo-horiz-web_en-1.jpg'  verticalAlign='middle' floated='left' alt="" id='section-footer-EU' />
			<Image  src='assets/images/logo.png'  verticalAlign='middle' floated='left' alt="" id='section-footer-logo' />
			<p id='section-footer-text'>This project has received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement N. 761934</p>
		</Segment>	
	</div>   
   	)}
}
											

export default connect(mapStateToProps)(About);


