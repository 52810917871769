/**
* Created by michelefuschillo on 31/01/18.
*/
import React, { Component } from 'react';
import {Accordion,Icon,Popup} from 'semantic-ui-react';
import { setfocusobj} from '../../../redux/actions/ObjectAction';

import '../../../css/config.css';
import * as desc from './descriptionmenu.json'
const ShowInfo=true;

class MenuEight extends Component {
  constructor (props) {
    super(props);
    this.state=({accordionActiveIndex:-1,accordionActiveIndexInternal:-1})
    this.manageAccordion=this.manageAccordion.bind(this);
    this.handleMenuVertical = this.handleMenuVertical.bind(this);
  }
  
  componentWillMount() {
    this.setState({accordionActiveIndex:-1,accordionActiveIndexInternal:-1});
  }
  
  componentWillReceiveProps(props) {
    if (this.props.action==="menutime"){
      //accordionActiveIndex in questo caso assume il valore della posizione del menu time 
      //cambiare se cambi l'ordine del menù 
      this.setState({accordionActiveIndex:1,accordionActiveIndexInternal:-1});
      let i= document.getElementById('timeaccordion')    
      if (i){
        this.props.callbackParent("time")
      }
    }else{
      this.setState({accordionActiveIndex: -1, accordionActiveIndexInternal: -1});
    }
  }
  
  manageAccordion(event, data){
    if(data.index === this.state.accordionActiveIndex){
      this.setState({accordionActiveIndex:-1});
    }else{
      this.setState({accordionActiveIndex:data.index});
      this.props.callbackParent(data.name);
    }
  }
  
  openToChoice(event, data) {
    this.props.callbackParent(data.name)
  }
  
  
  
  cancelSelected () {
    const focusobj = this.props.reducer.focusobj.obj;
    focusobj.properties.audioPath = "";
    focusobj.properties.name = "";
    this.props.dispatch(setfocusobj('textline', focusobj,{'type':'color','action':'change'}));
    this.setState({name:""});
  }
  
  
  componentDidMount() {
    
    if (this.props.action==="menutime"){
      //accordionActiveIndex in questo caso assume il valore della posizione del menu time 
      //cambiare se cambi l'ordine del menù 
      this.setState({accordionActiveIndex:1,accordionActiveIndexInternal:-1});
      let i= document.getElementById('timeaccordion')    
      if (i){
        this.props.callbackParent("time")
      }
    }
  }
  
  
  /*
  *  audio obj
  * */
  render () {
    return (
      <div>
      <Accordion styled>
      <Accordion.Title name="textlineinfo" className="accordionTitle" active={this.state.accordionActiveIndex === 0} index={0} onClick={this.manageAccordion} position='left' >
      <Icon name='dropdown' id='section-config-IconAccordion'/>
      <Icon color="grey" name='info circle'/>
      { ShowInfo=== true ? 
        <Popup flowing hoverable position='bottom right' size='small' trigger={                      
          <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
        }>
        <p id='section-edit-card-link'>{desc.info.description}</p>
        </Popup>
        :
        <span></span>}
        <i id='section-config-AccordionHotspot'>Info</i>
        {this.props.reducer.focusobj.obj.properties.vo ?( this.props.reducer.focusobj.obj.properties.vo.showhotspot === true ? <Icon color="grey" name='eye'/> : <Icon color="grey" name='eye slash'/>):''}
        
        </Accordion.Title>
        
        <Accordion.Content active={this.state.accordionActiveIndex  === 0}>
        <div id='textlineinfo'></div>
        </Accordion.Content>
        { this.props.reducer.resType !=="IMAGE2" && this.props.reducer.resType !=="IMAGE3"?
            <div>
        <Accordion.Title name="time" active={this.state.accordionActiveIndex === 1} index={1}
        onClick={this.manageAccordion} position='left'>
        <Icon name='dropdown' id='section-config-IconAccordion'/>
        <Icon color="grey" name='time'/><i id='section-config-AccordionHotspot'>Time</i>
        { ShowInfo=== true ? 
          <Popup flowing hoverable position='bottom right' size='small' trigger={                      
            <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
          }>
          <p id='section-edit-card-link'>{desc.time.description}</p>
          </Popup>
          :
          <span></span>}
          </Accordion.Title>
          <Accordion.Content active={this.state.accordionActiveIndex === 1}>
          <div id='timeaccordion'></div>
          </Accordion.Content>
          </div>
            :null} 
          <Accordion.Title name="textlinecolor"  active={this.state.accordionActiveIndex  === 2} index={2}
          onClick={this.manageAccordion} position='left'>
          <Icon name='dropdown' id='section-config-IconAccordion'/>
          <Icon color="grey" name='tint'/><i id='section-config-AccordionHotspot'>Color</i>
          { ShowInfo=== true ? 
            <Popup flowing hoverable position='bottom right' size='small' trigger={                      
              <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
            }>
            <p id='section-edit-card-link'>{desc.color.description}</p>
            </Popup>
            :
            <span></span>}
            </Accordion.Title>
            <Accordion.Content active={this.state.accordionActiveIndex  === 2}> <div id='textlinecoloraccordion'></div></Accordion.Content>
            
            
            <Accordion.Title name="textlineviewoptions" className="accordionTitle" active={this.state.accordionActiveIndex === 3} index={3} onClick={this.manageAccordion} position='left' >
            <Icon name='dropdown' id='section-config-IconAccordion'/>
            <Icon color="grey" name='move'/><i id='section-config-AccordionHotspot'>View options</i>
            { ShowInfo=== true ? 
              <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
              }>
              <p id='section-edit-card-link'>{desc.viewoptions.description}</p>
              </Popup>
              :
              <span></span>}
              </Accordion.Title>
              
              <Accordion.Content active={this.state.accordionActiveIndex  === 3}>
              <div id='textlineviewoptions'></div>
              </Accordion.Content>
              
              
              {/* <Accordion.Title name="textlineshapetag"  active={this.state.accordionActiveIndex  === 4} index={4} onClick={this.manageAccordion} position='left'>
              <Icon name='dropdown' id='section-config-IconAccordion'/>
              <Icon color="grey" name='tag'/><i id='section-config-AccordionHotspot'>Tags</i>
              { ShowInfo=== true ? 
                <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                  <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                }>
                <p id='section-edit-card-link'>{desc.tags.description}</p>
                </Popup>
                :
                <span></span>}
                </Accordion.Title>
                <Accordion.Content active={this.state.accordionActiveIndex  === 4}><div id='textlinetagshapeaccordion'></div></Accordion.Content> */}
                
                </Accordion>
                </div>
                )
              }
              
              handleMenuVertical(event, data){
                this.setState({activeItem:data.name});
                this.props.callbackParent(data.name);
              }
            }
            
            export default MenuEight;
            