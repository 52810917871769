import React, { Component } from 'react';
import {Button,  Divider,Input,Modal} from 'semantic-ui-react';
import { transitions, positions, Provider as AlertContainer } from 'react-alert'




class CreateConfigModal extends Component {
  constructor (props) {
    super(props);
    this.state=({name:"",consumerName:""});

    this.handleMessage=this.handleMessage.bind(this);
    this.save=this.save.bind(this);
    this.showAlert = this.showAlert.bind(this);


  }


  alertOptions = {    offset: 14,    position: 'bottom center',    theme: 'dark',    time: 5000,    transition: 'scale'  };

  handleMessage(event,data){
    // console.log("nome nuovo "+ data.value)  ;
    this.props.onchangecallback(event,data);

    if (data.id==="configName"){
    this.setState({name:data.value});
    }else{
    this.setState({consumerName:data.value});
    }
  }

  save (){
    // &&(this.state.consumerName!=="")
    if (this.state.name!==""){
      this.props.callbackOnSave();
    }else {
      this.showAlert('Empty name ', 'error')
    }
  }
  showAlert (message, result) {
    this.msg.show(message, { time: 5000, type: result })  }

    render () {
      return(
        <div>
          <div >
            <Divider  horizontal/>

            Name:{' '}            <br/>

            <Input focus placeholder='Name...' id="configName" onChange={this.handleMessage} />
            <br/>
            
            <Modal.Actions>
              <Button primary floated='right' size='small' onClick={this.save}> OK  </Button>
            </Modal.Actions>
          </div>
          <div>
            <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
          </div>
        </div>
      )
    }
  }
  export default CreateConfigModal;
