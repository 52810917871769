/**
 * Created by michelefuschillo on 26/11/17.
*/
function menuItem (itemName) {
  // Return action
  return {
    // Unique identifier
    type: 'ACTIVEMENUITEM',
    // Payload
    payload:{
      itemName
    }
  };
};
export default menuItem;