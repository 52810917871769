/**
 * Created by michelefuschillo on 14/02/18.
 */
import React, { Component } from 'react';
import {List, Input,Divider} from 'semantic-ui-react';

import '../../../../css/config.css';

class ObjCustomization extends Component {
  constructor (props) {
    super(props);
    this.state=({type:"objcustomization",alpha:1, scale: 0.1,turny:0,turnx:0,turnz:0});
    this.inputHandler = this.inputHandler.bind(this);
  }

  componentDidMount() {

  };

  componentWillMount() {

  }

  callbackParent(){
    this.props.callbackParent(this.state);
  }

  inputHandler (event, data) {
    switch (data.name){
      case 'scale':
        this.setState({scale: data.value}, this.callbackParent);
        break;
      case 'alpha':
        this.setState({alpha: data.value}, this.callbackParent);
        break;
      case 'turny':
        this.setState({turny: data.value}, this.callbackParent);
        break;
      case 'turnx':
        this.setState({turnx: data.value}, this.callbackParent);
        break;
      case 'turnz':
        this.setState({turnz: data.value}, this.callbackParent);
        break;
        default:
        break;
    }
  }

  render () {
    return (
      <List animated className="componentOnIframeClass">
      <Divider hidden />
        <List.Item>
          <List.Content>
            <List.Header className="listitemheaderobj" ><i style={{'color': '#ac975e', 'fontSize': 12}} >Size: {this.state.scale} </i></List.Header>
            <List.Description className="listitemobj" >
              <Input name = 'scale' type='range' min={0.1} max={100} step={0.1} value={this.state.scale} onChange={this.inputHandler}/>
            </List.Description>
          </List.Content>
        </List.Item>
        <Divider hidden />
        <List.Item>
          <List.Content>
            <List.Header className="listitemheaderobj" ><i style={{'color': '#ac975e', 'fontSize': 12}} >Alpha: {this.state.alpha}</i></List.Header>
            <List.Description className="listitemobj" >
              <Input name='alpha' type='range' min={0.1} max={1} step={0.1} value={this.state.alpha} onChange={this.inputHandler}/>
            </List.Description>
          </List.Content>
        </List.Item>
        <Divider hidden />
        <List.Item>
          <List.Content>
            <List.Header className="listitemheaderobj" ><i style={{'color': '#ac975e', 'fontSize': 12}} >Y: {this.state.turny}</i></List.Header>
            <List.Description className="listitemobj" >
              <Input name='turny' type='range' min={0} max={2*Math.PI} step={0.1} value={this.state.turny} onChange={this.inputHandler}/>
            </List.Description>
          </List.Content>
        </List.Item>
        <Divider hidden />
        <List.Item>
          <List.Content>
            <List.Header className="listitemheaderobj" ><i style={{'color': '#ac975e', 'fontSize': 12}} >X: {this.state.turnx}</i></List.Header>
            <List.Description className="listitemobj" >
              <Input name='turnx' type='range' min={0} max={2*Math.PI} step={0.1} value={this.state.turnx} onChange={this.inputHandler}/>
            </List.Description>
          </List.Content>
        </List.Item>
        <Divider hidden />
        <List.Item>
          <List.Content>
            <List.Header className="listitemheaderobj" ><i style={{'color': '#ac975e', 'fontSize': 12}} >Z: {this.state.turnz}</i></List.Header>
            <List.Description className="listitemobj" >
              <Input name='turnz' type='range' min={0} max={2*Math.PI} step={0.1} value={this.state.turnz} onChange={this.inputHandler}/>
            </List.Description>
          </List.Content>
        </List.Item>
        <Divider hidden />
      </List>
    )
  }
}

export default ObjCustomization;