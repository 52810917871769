/**
 * Created by michelefuschillo on 29/01/18.
 */
import React, { Component } from 'react';
import { Input, Table, Button, TextArea, Modal, Dropdown } from 'semantic-ui-react';
import { setfocusobj } from '../../../../redux/actions/ObjectAction';
import { getResourceDataForLanguage } from './../utils';
import * as services from '../../../../config/services.json';
import { PhotoshopPicker } from 'react-color'



// Arial (sans-serif)
// Verdana (sans-serif)
// Tahoma (sans-serif)
// Trebuchet MS (sans-serif)8
// Times New Roman (serif)
// Georgia (serif)
// Garamond (serif)
// Courier New (monospace)
// Brush Script MT
const familyFont = [
  {
    key: 'Arial',
    text: 'Arial',
    value: 'Arial',   
  },  
  {
    key: 'Verdana',
    text: 'Verdana',
    value: 'Verdana',   
  },  
  {
    key: 'Tahoma',
    text: 'Tahoma',
    value: 'Tahoma',   
  },  
  {
    key: 'Trebuchet MS',
    text: 'Trebuchet MS',
    value: 'Trebuchet MS',   
  },  
  {
    key: 'Times New Roman',
    text: 'Times New Roman',
    value: 'Times New Roman',   
  },  
  {
    key: 'Georgia',
    text: 'Georgia',
    value: 'Georgia',   
  },  
  {
    key: 'Garamond',
    text: 'Garamond',
    value: 'Garamond',   
  },  
  {
    key: 'Courier New',
    text: 'Courier New',
    value: 'Courier New',   
  },  
  {
    key: 'Brush Script MT',
    text: 'Brush Script MT',
    value: 'Brush Script MT',   
  }
];


let unsubscribe;

class TextFont extends Component {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    var updated = this.props.store.getState();
    this.state = {
      fontsize: updated.reducer.focusobj.obj.properties.fontsize ? updated.reducer.focusobj.obj.properties.fontsize :40,
      deleteButtonStatus: updated.reducer.focusobj.obj.properties.nameres ? false : true,
      nameres: updated.reducer.focusobj.obj.properties.nameres,
      resourceid: updated.reducer.focusobj.obj.properties.resourceid,
      text: updated.reducer.focusobj.obj.properties.text,
      textAreaStatus: updated.reducer.focusobj.obj.properties.resourceid ? true : false,
      previousState: updated,
      color: updated.reducer.focusobj.obj.properties.color,
      boldStatus: updated.reducer.focusobj.obj.properties.boldStatus ? updated.reducer.focusobj.obj.properties.boldStatus : false,
      italicStatus: updated.reducer.focusobj.obj.properties.italicStatus? updated.reducer.focusobj.obj.properties.italicStatus : false,
      underlineStatus: updated.reducer.focusobj.obj.properties.underlineStatus? updated.reducer.focusobj.obj.properties.underlineStatus : false,
      selectedFont:updated.reducer.focusobj.obj.properties.selectedFont?updated.reducer.focusobj.obj.properties.selectedFont :""
    };
    this.inputHandler = this.inputHandler.bind(this);
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('text', focusobj, { 'type': 'fontsize', 'action': 'change' }));
    unsubscribe();
  }

  updateState() {
    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'text') {
      this.setState({
        fontsize: this.props.reducer.focusobj.obj.properties.fontsize ?this.props.reducer.focusobj.obj.properties.fontsize:40,
        previousState: updated,
        text: updated.reducer.focusobj.obj.properties.text,
        name: updated.reducer.focusobj.obj.name,
        description: updated.reducer.focusobj.obj.description,
        resourceid: updated.reducer.focusobj.obj.properties.resourceid,
        nameres: updated.reducer.focusobj.obj.properties.nameres,
        color: updated.reducer.focusobj.obj.properties.color,
        tempTextColor: updated.reducer.focusobj.obj.properties.color,
        boldStatus: updated.reducer.focusobj.obj.properties.boldStatus ? updated.reducer.focusobj.obj.properties.boldStatus : false,
        italicStatus: updated.reducer.focusobj.obj.properties.italicStatus? updated.reducer.focusobj.obj.properties.italicStatus : false,
        underlineStatus: updated.reducer.focusobj.obj.properties.underlineStatus? updated.reducer.focusobj.obj.properties.underlineStatus : false,
        selectedFont:updated.reducer.focusobj.obj.properties.selectedFont?updated.reducer.focusobj.obj.properties.selectedFont :""
      });
      this.props.parent.waitingAnswer();
    }
  }

  inputHandler(event, data) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.properties.fontsize = data.value;
    this.props.dispatch(setfocusobj('text', focusobj, { 'type': 'fontsize', 'action': 'change' }));
    unsubscribe();
  }

  removeText = (event) => {
    console.log("remove text");
    var focusobj = Object.assign({}, this.props.reducer.focusobj.obj);
    focusobj.properties.text = "";
    focusobj.properties.resourceid = "";
    focusobj.properties.nameres = "";
    this.props.dispatch(setfocusobj('text', focusobj, { 'type': 'text', 'action': 'text' }));
    // textAreaStatus, resourceid, nameres:""
    this.setState({ textAreaStatus: false, resourceid: "", nameres: "", text: "" });
  }
  openUploadModal = (event, data) => {
    //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'text' });

    /**NUOVA MOADLITà ASSET 07-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "upload",
        typeres: "text"
      }
    }, "*");

    window.addEventListener('message', this.postMessageHandler);
  }

  openListModal = (event, data) => {
    //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'text' });
    /**NUOVA MOADLITà ASSET 07-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "textlist"
      }
    }, "*");
    window.addEventListener('message', this.postMessageHandler);
  }



  postMessageHandler = (event) => {
    if (event.origin === services.ASSETDOMAIN) { //FROM ASSETLOGIN
      switch (event.data.type) {
        case "NEWRESOURCE":
          console.log("Gestione new resource in text")
          console.log(event.data.payload);
          //testMock
          // let textcloudcontent={
          //   "it":"mock ita",
          //   "en":"mock en",
          //   "bg":"mock bg"
          // };
          // event.data.payload.textcloudcontent=textcloudcontent;
          // //fine mock rimuovere
          let data = {};
          data.medialan = event.data.payload.textcloudcontent;
          unsubscribe = this.props.store.subscribe(this.updateState);
          var focusobj = Object.assign({}, this.props.reducer.focusobj.obj);
          console.log(event.data.payload.textcloudcontent[getResourceDataForLanguage(data)]);
          focusobj.properties.text = event.data.payload.textcloudcontent[getResourceDataForLanguage(data)];
          focusobj.properties.resourceid = event.data.payload._id;
          focusobj.properties.nameres = event.data.payload.name;
          this.props.dispatch(setfocusobj('text', focusobj, { 'type': 'text', 'action': 'text' }));
          this.setState({
            resourceid: event.data.payload._id,
            nameres: event.data.payload.name,
            deleteButtonStatus: event.data.payload.name ? false : true,
            textAreaStatus: event.data.payload._id ? true : false
          });
          //prendi l'ogetto che arriva 
          //analizza la lingua da prendere secondo algoritmo base ma customizzato per le lingue 
          //setta il testo giusto 
          //utiliza il dispatch come sopra al posto di quello imamgini 
          // devi fare il controllo se il testo è preso da res manager non puoi permettere la modifica quindi se vuoi che 
          //il testo sia messo a mano devi fare la cancellazione del settaggio di un testo preso da res manager 
          //devi anche settare il testo preso dal res manager nella textinput e disabilitarlo se viene da res manager 
          // focusobj.properties.coverpath = this.coverUrl(event.data.payload);
          // focusobj.properties.covername = event.data.payload.name;
          // focusobj.properties.resourceid = event.data.payload._id;  //CREDO DI DOVER SALVARE ANCHE RESOURCE ID UNICO MODO PER POTER AVERE RIFERIMENTO ALLA RISORSA 
          // this.props.dispatch(setfocusobj("image", focusobj, { 'type': 'image', 'action': 'image' }));
          window.removeEventListener('message', this.postMessageHandler);
          //this.setState({ childmessage: event.data.payload.msg });
          break;
        default: break;
      }
    }
  }
  onChangeText = (event, data) => {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.properties.text = data.value;
    this.props.dispatch(setfocusobj('text', focusobj, { 'type': 'text', 'action': 'text' }));
    unsubscribe();
  }
 
  openTextColorPicker = (event, data) => {
    this.setState({ textColorModal: true })//), backgroundColorModal: false})
  };
  closeColorPicker = (event, data) => {
    this.setState({ textColorModal: false })//, backgroundColorModal: false})
  };

  colorChangePickerHandler = (color) => {
    this.setState({ tempTextColor: color.hex })
  };

  colorPickerHandler = (event) => {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.properties.color = this.state.tempTextColor;
    this.props.dispatch(setfocusobj('text', focusobj, { 'type': 'color', 'action': 'change' }));
    this.closeColorPicker();
    unsubscribe();
  }
//dati da salvare nel sistema BE 
  onChangeFont=(event,data)=>{
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.properties.selectedFont = data.value;
    this.props.dispatch(setfocusobj('text', focusobj, { 'type': 'color', 'action': 'change' }));
    unsubscribe();
  }
  styleHandler = (e, d) => {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    
    
    switch (d.name) {
      case "bold":
        focusobj.properties.boldStatus = !d.active;
        // this.setState({ boldStatus: !d.active });
        break;
      case "italic":
        focusobj.properties.italicStatus = !d.active;
        // this.setState({ italicStatus: !d.active });
        break;
      case "underline":
        focusobj.properties.underlineStatus = !d.active;
        // this.setState({ underlineStatus:  });
        break;
      default:
        break;
    }
    this.props.dispatch(setfocusobj('text', focusobj, { 'type': 'color', 'action': 'change' }));    
    unsubscribe();
  }

  render() {
    return (
      <>
        <Table basic='very'>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Button active={this.state.boldStatus} compact
                  basic
                  color={this.state.boldStatus ? 'blue' : null}
                  icon='bold'
                  onClick={this.styleHandler}
                  name="bold"
                />
              </Table.Cell>
              <Table.Cell>
                <Button
                  active={this.state.italicStatus}
                  compact
                  basic
                  color={this.state.italicStatus ? 'blue' : null}
                  icon='italic'
                  onClick={this.styleHandler}
                  name="italic"
                />
              </Table.Cell>
              <Table.Cell>
                <Button
                  active={this.state.underlineStatus}
                  compact
                  basic
                  color={this.state.underlineStatus ? 'blue' : null}
                  icon='underline'
                  onClick={this.styleHandler}
                  name="underline"
                />
              </Table.Cell>
              <Table.Cell>
                <Button
                  // active={this.state.underlineStatus}
                  style={{ backgroundColor: this.state.color, borderColor: 'black', border: 'solid 2px' }}
                  onClick={this.openTextColorPicker}
                  compact
                  icon='tint'
                // color={this.state.underlineStatus ? 'blue' : null}
                // onClick={this.styleHandler}
                // name="underline"
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Table basic='very'>
          <Table.Body>
            <Table.Row>
              {/* <Table.Cell>Size:</Table.Cell> */}
              <Table.Cell >                
                <p>Font:</p>            
                <Dropdown compact selection placeholder='Select ' value={this.state.selectedFont} clearable onChange={this.onChangeFont} options={familyFont}/>
                  {/* index={index} /> */} 
              </Table.Cell>
              <Table.Cell >        
                {/* vecchia implementazione con slider            */}
                {/* <Input name='size' type='range' min={20} max={100} step={1} value={this.state.fontsize} onChange={this.inputHandler}
                style={{ height: 'auto', width: '65px' }} /> */}               
                <p>Font Size:</p>            
                <Input                  
                  type="number"
                  id="fontsize"
                  max={450}
                  min={0}
                  placeholder="Font Size"
                  onChange={this.inputHandler}
                  value={this.state.fontsize}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Table basic='very'>
          <Table.Body>
            <Table.Row>
              <TextArea name="text" placeholder='Text to show...' disabled={this.state.textAreaStatus} onChange={this.onChangeText} value={this.state.text} autoHeight style={{ minHeight: 50, width: '100%', height: 'auto', resize: 'none' }} maxLength="200" />
            </Table.Row>
            <Table.Row>
              <Input size='mini' placeholder='Media Text type name ' fluid disabled value={this.state.nameres} />
            </Table.Row>
            <Table.Row>
              <Button compact basic icon='list' size="small" onClick={this.openListModal} />
              <Button compact basic icon='upload' size="small" onClick={this.openUploadModal} />
              <Button compact basic icon='trash' size="small" disabled={this.state.deleteButtonStatus} onClick={this.removeText} />
            </Table.Row>
          </Table.Body>
        </Table>
        <Modal basic open={this.state.textColorModal} onClose={this.closeColorPicker}>
          <PhotoshopPicker header={'Text color'} color={this.state.tempTextColor}
            onChange={this.colorChangePickerHandler} onAccept={this.colorPickerHandler}
            onCancel={this.closeColorPicker} />
        </Modal>
      </>
    )
  }
}
export default TextFont;

