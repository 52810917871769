import React, { Component } from 'react';
import { Form,Button,Checkbox, Segment,Header,Image,Grid,Dropdown} from 'semantic-ui-react';
import * as data from '../config/services.json';
import Axios from 'axios';
import { transitions, positions, Provider as AlertContainer } from 'react-alert'
import {connect} from 'react-redux';


import '../css/login.css';

const mapStateToProps = (state) => ({
  reducer: state.reducer,
})

const stylesDefault = {
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    color:'#4A4A4A'
  },
  titleError: {
    fontSize: 12,
    fontWeight: 'bold',
    color:'red'
  },
};

const menu = {
"role":[
  { "key":"1" , "value": "admin", "text": "admin" },
  { "key":"2" , "value": "editor", "text": "editor" },
  { "key":"3" , "value": "user", "text": "user" }
],
"group":[
  { "key":"1" , "value": "IDEHA", "text": "IDEHA" }
]
};


class Signup extends Component {

  constructor (props) {
    super(props);
    this.state = {
      username:"",
      email:"",
      password:"",
      role:"",
      group:"",
      confirm:"",
      region:{},
      styleUsername: stylesDefault.title,
      styleEmail: stylesDefault.title,
      stylePassword: stylesDefault.title,
      styleConfirm: stylesDefault.title,
      formErrors:{email:'', password:'', confirm:''},
      emailValid:false,
      passwordValid:false,
      confirmValid:false,
      formValid:false,
      checkConditions:false,
      modal:false
    };
    this.changeValue=this.changeValue.bind(this);
    this.regSend=this.regSend.bind(this);
    this.checkConditionsChange=this.checkConditionsChange.bind(this);
    this.showAlert=this.showAlert.bind(this);
    this.showAlertError=this.showAlertError.bind(this);
    this.onChangeRole=this.onChangeRole.bind(this);
    this.onChangeGroup=this.onChangeGroup.bind(this);
  }

  componentDidMount() {
    navigator.geolocation.watchPosition((position) => {
      // Create the object to update this.state.mapRegion through the onRegionChange function
      this.setState({region:{
        latitude:       position.coords.latitude,
        longitude:      position.coords.longitude,
        latitudeDelta:  0.00922*1.5,
        longitudeDelta: 0.00421*1.5
      }})
      //this.onRegionChange(region, region.latitude, region.longitude);
    });
  }

  componentWillMount() {
    //authentication check
    if(this.props.reducer.logged){
      this.props.history.push("/");
    }
  }

  render(){
    return(
      <div>
        <Grid textAlign='center'  verticalAlign='middle'  id='section-login'>
          <Grid.Column id='section-login-grid'>
            <Segment raised >
              <Header as='h2' color='black' textAlign='center'>
              <Image src="assets/images/logo.png" />{' '}Login</Header>

              <Form size='large'>
                <Segment basic >
                <Form.Field width="16">
                  <div className="ui left icon input"><i className="user icon"></i>
                    <input className="username" placeholder='Username' onChange={this.changeValue}/>
                  </div>
                    </Form.Field>
                      <Form.Field width="16">
                        <div className="ui left icon input"><i className="mail icon"></i>
                          <input className="email" placeholder='Email' onChange={this.changeValue}/>
                        </div>
                      </Form.Field>

                      <Form.Field width="16">
                        <div className="ui left icon input"><i className="lock icon"></i>
                          <input className="password" placeholder='Password' type='password' onChange={this.changeValue}/>
                        </div>
                     </Form.Field>

                      <Form.Field width="16">
                        <div className="ui left icon input"><i className="lock icon"></i>
                          <input className="confirm" placeholder='Confirm Password' type='password' onChange={this.changeValue}/>
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <Dropdown placeholder='Choose the role'  clearable selection onChange={this.onChangeRole} options={menu.role}  value={this.state.role}  />
                   </Form.Field>
                   <Form.Field>
                     <Dropdown placeholder='Choose the group'  clearable selection onChange={this.onChangeGroup} options={menu.group}  value={this.state.group}  />
                </Form.Field>
                   <Form.Field>
                  <Checkbox className="check" label='I agree to the Terms and Conditions' onChange={this.checkConditionsChange}/>
                </Form.Field>
                <Button animated fluid  color='orange' onClick={this.regSend} disabled={!this.state.formValid} inverted >
                  <Button.Content visible >Signup</Button.Content>
                  <Button.Content hidden  >Go... </Button.Content>
                </Button>
                </Segment>
              </Form>
          </Segment>

          <Segment raised >
                <div>Already Registred?<a href="Login"><strong>  Login</strong></a></div>
                <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
              </Segment>
        </Grid.Column>
      </Grid>
    </div>



  )}

  alertOptions = {
    offset: 14,
    position: 'bottom center',
    theme: 'dark',
    time: 5000,
    transition: 'scale'
  }

  showAlert = () => {
    this.msg.show('Thank you for your registration! You can login.', {
      time: 7000,
      type: 'success',
    })
  }

  showAlertError = () => {
    this.msg.show('Your registration is failed', {
      time: 6000,
      type: 'error',
    })
  }


  changeValue(e){
    const name = e.target.className;
    const value = e.target.value;
    this.setState({[name]: value},
      () => { this.validateField(name, value) });
  }


  regSend(){

    var self=this;
    const parameters = {
      email:this.state.email,
      password:this.state.password,
      lat:this.state.region.latitude,
      lng:this.state.region.longitude,
      name: this.state.username,
      group: this.state.group
    };

    //var headers = { 'Content-Type': 'application/json' }
    //console.log(data.domain + data.auth_register_temp_POST);
    Axios.post(data.domain + data.auth_register_temp_POST,parameters)
      .then(function (response) {
        //console.log(response);
        self.showAlert();
      })
      .catch(function (error) {
        //console.log(error);
        self.showAlertError();
      });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let confirmValid = this.state.confirmValid;

    switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? this.setState({styleEmail:stylesDefault.title}) : this.setState({styleEmail:stylesDefault.titleError});
        break;
      case 'password':
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ?  this.setState({stylePassword:stylesDefault.title}) : this.setState({stylePassword:stylesDefault.titleError});
        break;
      case 'confirm':
        confirmValid = this.state.password === this.state.confirm;
        fieldValidationErrors.confirm = confirmValid ? this.setState({styleConfirm:stylesDefault.title}) : this.setState({styleConfirm:stylesDefault.titleError});
        break;
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
      emailValid: emailValid,
      passwordValid: passwordValid,
      confirmValid:confirmValid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.emailValid && this.state.passwordValid && this.state.confirmValid && this.state.checkConditions &&this.state.group && this.state.role});
  }

  checkConditionsChange(event, data){
      this.setState({checkConditions:data.checked}, this.validateForm);
  }
  onChangeGroup(event, data){
      this.setState({group:data.value}, this.validateForm);
  }
  onChangeRole(event, data){
      this.setState({role:data.value}, this.validateForm);
  }




}
// Use connect to put them together
export default connect(mapStateToProps)(Signup);
