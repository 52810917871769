/**
 * Created by michelefuschillo on 05/09/17.
 */
import registerServiceWorker from './registerServiceWorker';
import React, { Component } from 'react';
import {Menu,Dropdown,Image,Icon,Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import menuItem from './redux/actions/menustate';
import * as data from './config/services.json';
import actionLogout from './redux/actions/logout';
import { Link,} from 'react-router-dom';
import Axios from 'axios';
import { launchModal } from './redux/actions/ModalAction'

import './css/header.css';

const mapStateToProps = (state) => ({
  reducer: state.reducer,
})


// The Header creates links that can be used to navigate
// between routes.
class Header extends Component {
  state = { activeItem: 'home' }

  handleItemClick = (e, { name }) => this.props.dispatch(menuItem(name))

  constructor (props) {
    super(props);
    this.state={authName:"", authPath:"", avatar:''};
    this.logout = this.logout.bind(this);
    //services loading and set session
    //loadServices();
  }



  componentWillMount() {
    var self=this;
    var logged=self.props.reducer.logged;
    var imageDefault={avatar:'/assets/images/no-avatar.png'}

    if(logged){
      var imageUrl=data.domain + data.retrieve_photo_GET + self.props.reducer.userid;

      Axios.get(imageUrl)
        .then(function (response) {
          if(response.config.url){
            self.setState({avatar:response.config.url})
        }else{

          self.setState(imageDefault)
        }
      })
      .catch(function (error) {
        self.setState(imageDefault)
      });
    }else{
      this.setState(imageDefault)
    }
  }



render(){
  const trigger = (
    <span>
      <Image src={this.state.avatar} avatar title={this.props.reducer.logged ? this.props.reducer.name : 'guest'}
            style={{height:'30px',width:'30px'}}  />
      <figcaption className="caption">Me</figcaption>
    </span>
  )
    if(!this.props.reducer.logged){ return(<div></div>) }


  return(
    <Segment basic id='blackHeader' size='tiny'>
      <Image src={'/assets/images/logo_H.png'} height="40" width="40"  style={{"display":"inline-block"}}/>
    <Menu pointing secondary inverted size='mini' icon='labeled' floated='right' id='blackHeaderMenu' >
      <Menu.Item as={Link} to='/' name='home' active={this.props.reducer.itemName === 'home'} onClick={this.handleItemClick}  disabled={!this.props.reducer.logged} >
        <Icon name='home' size='mini'/>Home
      </Menu.Item>

      <Menu.Item as={Link} to='/Profile' name='profile' active={this.props.reducer.itemName === 'profile'} onClick={this.handleItemClick}  disabled={!this.props.reducer.logged} >
        <Icon name='user' size='mini'/>Profile
      </Menu.Item>

      <Menu.Item  name='settings'  onClick={() =>{this.props.dispatch(launchModal('SETTING_BAR_MODAL', ''))}}  disabled={!this.props.reducer.logged} >
        <Icon name='settings' size='mini'/>Settings
      </Menu.Item>

      <Menu.Item as={Link} to='/About'   name='about'   active={this.props.reducer.itemName === 'about'  } onClick={this.handleItemClick}>
        <Icon name='info circle' size='mini'/>About
      </Menu.Item>

      <Menu.Menu position='right'>
        <Menu.Item>
          <Dropdown trigger={trigger} icon={null} pointing='top right' >
            <Dropdown.Menu id='section-header-dropdown' >
              <Dropdown.Header icon='user outline' content={this.props.reducer.logged ? this.props.reducer.name : 'guest'} />
              <Dropdown.Header icon='group' content={this.props.reducer.group ? this.props.reducer.group : 'guest'} />

              <Dropdown.Divider />
               <Dropdown.Item as={Link} to={!this.props.reducer.logged ? '/Login':""}  name={!this.props.reducer.logged?'login':'logout'}
                              active={this.props.reducer.itemName === 'login'} onClick={this.props.reducer.logged ? this.logout : this.handleItemClick}  icon="key" text={this.props.reducer.logged?'Logout':'Login'}/>

                  {!this.props.reducer.logged ?
                    <Dropdown.Item as={Link} to='/Signup' name='signup' active={this.props.reducer.itemName === 'Signup'}
                                   onClick={this.handleItemClick}
                                   disabled={this.props.reducer.logged ? true : false}>{!this.props.reducer.logged ? 'Signup' : ''}</Dropdown.Item>
                    :''}
                  {/* this.props.reducer.itemName === 'configuration' ?
                    <Dropdown.Item onClick={() =>{this.props.dispatch(launchModal('SETTING_BAR_MODAL', ''))}} icon="settings" label="Settings"/> : ''*/}
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu.Menu>
      </Menu>
      </Segment>
    )
  }
  componentDidMount() {
    /*console.log("Componente [Header.jsx/componentDidMount()]: caricato");*/
  }

  componentDidUnMount()  {
    console.log("Componente [Header.jsx/componentDidMount()]: rilasciato");
  }

  logout(){
   this.props.dispatch(actionLogout());
     // if(typeof this.props.history !== "undefined"){
     //    this.props.history.push("/");
     //  }
      this.props.history.push("/");
      // window.location.reload();
   }
}
// Route is the conditionally shown component based on matching a path to a URL.
// Switch returns only the first matching route rather than all matching routes.
registerServiceWorker();
export default connect(mapStateToProps)(Header);
