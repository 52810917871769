/**
 * Created by michelefuschillo on 29/01/18.
 */
import React, { Component } from 'react';
import {List,TextArea} from 'semantic-ui-react';
import { setfocusobj} from '../../../../redux/actions/ObjectAction'

class Shapetext extends Component {
  constructor (props) {
    super(props);
    this.neOnChange=this.neOnChange.bind(this);
    this.updateState = this.updateState.bind(this);
    var updated = this.props.store.getState();
    this.state = {
      previousState:updated,
      description:updated.reducer.focusobj.obj.description ? updated.reducer.focusobj.obj.description :"",
      name:updated.reducer.focusobj.obj.name ? updated.reducer.focusobj.obj.name:""};
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('shape', focusobj,{'type':'shape','action':'shape'}));
    unsubscribe();
  }

  neOnChange(event, data){
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);

    switch(data.name){
      case 'shapename':
        focusobj.name = data.value;
        this.props.dispatch(setfocusobj('shape', focusobj,{'type':'shape','action':'shape'}));
        break;
      case 'shapedescription':
        focusobj.description = data.value;
        this.props.dispatch(setfocusobj('shape', focusobj,{'type':'shape','action':'shape'}));
        break;
      default:
        break;
    }
    unsubscribe();
  }

  updateState(){
    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'shape') {
      this.setState({
        previousState:updated,
        description: updated.reducer.focusobj.obj.description,
        name: updated.reducer.focusobj.obj.name});
		this.props.parent.waitingAnswer();
    }
  }

  render () {
    return (
      <List className="componentOnIframeClass">
        <List.Item>
          <List.Content>
            <List.Header className="listitemheaderobj" ></List.Header>
            <List.Description className="listitemobj" >
              <TextArea name="shapename" placeholder='Name...' onChange={this.neOnChange} value={this.state.name} autoHeight style={{ minHeight: 30,maxHeight: 30, width:'100%',}} maxLength="30" />
              <div>
              <TextArea name="shapedescription" placeholder='Description...' onChange={this.neOnChange} value={this.state.description} autoHeight style={{ minHeight: 50, width:'100%',height:'auto'}} maxLength="100" />
              </div>
            </List.Description>
          </List.Content>
        </List.Item>
      </List>
    )
  }
}

export default Shapetext;