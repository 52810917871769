/**
 * Created by michelefuschillo on 06/12/17.
 */
import React, { Component } from 'react';
import {connect}      from 'react-redux';
import UploadModal from './modal/UploadModal';
import DeleteModal from './modal/DeleteModal';
import DeleteModalv2 from './modal/DeleteModalv2';

import SettingModal from '../SettingContainer/component/GenericSetting'
import SettingbarModal from '../SettingContainer/component/TabBarSetting';
import Modal from 'react-responsive-modal';
import {launchModal} from '../redux/actions/ModalAction';

const mapStateToProps = (state) => {
  return {
    modalType: state.reducer.modalType,
    subType: state.reducer.subType,
    jwt: state.reducer.jwt,
    userId: state.reducer.userid,
	group: state.reducer.group,
    extra:state.reducer.extra,
    contexttoreq:state.reducer.contexttoreq,
    reducer: state.reducer
  };
};

const MODAL_COMPONENTS = {
  UPLOAD_MODAL: UploadModal,
  DELETE_MODAL: DeleteModalv2,
  SETTING_MODAL: SettingModal,
  SETTING_BAR_MODAL: SettingbarModal
};

const ModalContainer = (props) => {
  if (!props.modalType) {
    return null;
  }
  //console.log("MODAL TYPE",props.subType);
  /*
  * settingType={props.subType} radio={true} to init SETTING_MODAL
   */
  const SpecificModal = MODAL_COMPONENTS[props.modalType];
  console.log("MODALCONTAINER");
  return  <Modal
    open={true}
    center={true}
    showCloseIcon={true}
    closeOnOverlayClick={true}
    classNames={{modal:    'modal-custom-modal'}}
    closeIconSize={26}
    closeIconSvgPath={<svg>
      <line x1="1" y1="26"
            x2="26" y2="1"
            stroke="white"
            strokeWidth="2"/>
      <line x1="1" y1="1"
            x2="26" y2="26"
            stroke="white"
            strokeWidth="2"/>
    </svg>}
    onClose={ ()=>{ props.dispatch(launchModal("","",""))} } >
            <SpecificModal
              jwt={props.jwt}
              userId={props.userId}
			  group={props.group}
              dispatch={props.dispatch}
              store={props.store}
              subType={props.subType}
              extra={props.extra}
              contexttoreq={props.contexttoreq}
              reducer={props.reducer}
              settingType={props.subType}
              radio={true} />
          </Modal>;
};

export default connect(mapStateToProps)(ModalContainer);
