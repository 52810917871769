
export function setAutoSavingAction (result) {
  // Return action
  return {
    // Unique identifier

    type: 'AUTOSAVING',
    // Payload
    payload:{
      result
    }
  };
};
