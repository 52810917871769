/**
 * Created by michelefuschillo on 01/02/18.
 */
import React, { Component } from 'react';
import { List, Input, Button, Icon, Popup } from 'semantic-ui-react';
import { setfocusobj } from '../../../../redux/actions/ObjectAction';
import * as services from '../../../../config/services.json';
import { getResourceDataForLanguage } from './../utils';


let unsubscribe;
class ImageImport extends Component {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);

    var updated = this.props.store.getState();
    this.state = {
      previousState: updated,
      covername: updated.reducer.focusobj.obj.properties.covername,
      coverpath: updated.reducer.focusobj.obj.properties.coverpath,
      coveraction: updated.reducer.focusobj.obj.properties.coveraction,
      resourceid: updated.reducer.focusobj.obj.properties.resourceid,
    };
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('image', focusobj, {}));
    unsubscribe();
  }

  updateState() {
    var updated = this.props.store.getState() //NEW
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'image') {
      this.setState({
        previousState: updated,
        covername: updated.reducer.focusobj.obj.properties.covername,
        coverpath: updated.reducer.focusobj.obj.properties.coverpath,
        coveraction: updated.reducer.focusobj.obj.properties.coveraction,
        resourceid: updated.reducer.focusobj.obj.properties.resourceid
      });
      this.props.parent.waitingAnswer();
    } else {
      unsubscribe(); //the state update will be made into another component (asynchronous mode)
    }
  }

  openUploadModal = (event, data) => {
    //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'image' });

    /**NUOVA MOADLITà ASSET 07-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "upload",
        typeres: "image"
      }
    }, "*");

    window.addEventListener('message', this.postMessageHandler);
  }

  openListModal = (event, data) => {
    //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'image' });
    /**NUOVA MOADLITà ASSET 07-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "imglist"
      }
    }, "*");
    window.addEventListener('message', this.postMessageHandler);
  }

  /**NUOVA MOADLITà ASSET 08-12-2021 */
  postMessageHandler = (event) => {
    if (event.origin === services.ASSETDOMAIN) { //FROM ASSETLOGIN
      switch (event.data.type) {
        case "NEWRESOURCE":
          console.log(event.data.payload);
          unsubscribe = this.props.store.subscribe(this.updateState);
          var focusobj = Object.assign({}, this.props.reducer.focusobj.obj);
          if (event.data.payload.type === "imagecloud") {
            let data = {};
            data.medialan = event.data.payload.url;
            focusobj.properties.coverpath = event.data.payload.url[getResourceDataForLanguage(data)];
          } else {
            focusobj.properties.coverpath = this.coverUrl(event.data.payload);
          }
          focusobj.properties.covername = event.data.payload.name;
          focusobj.properties.resourceid = event.data.payload._id;
          this.props.dispatch(setfocusobj("image", focusobj, { 'type': 'image', 'action': 'image' }));
          window.removeEventListener('message', this.postMessageHandler);
          //this.setState({ childmessage: event.data.payload.msg });
          break;
        default: break;
      }
    }
  }

  coverUrl = (resoruce) => {
    let ser =
      services.ASSETBACKEND + services.singleresourcemanager_GET_AUTH.replace(
        "{APPNAME}",
        this.props.reducer.appname
      );
    ser = ser.replace("{DOMAIN}", this.props.reducer.domain);
    ser = ser.replace("{RESOURCEID}", resoruce._id);
    ser = ser.replace("{USERID}", this.props.reducer.userid);
    ser = ser.replace("{COVER}", false);
    ser = ser.replace("{MEDIARES}", "first");
    ser = ser.replace("{MEDIALAN}", getResourceDataForLanguage(resoruce));
    ser = ser + "&t=" + new Date().getTime();
    return ser;
  };

  cancelCoverSelected = () => {
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.properties.coverpath = "";
    focusobj.properties.covername = "";
    focusobj.properties.resourceid = "";
    this.props.dispatch(setfocusobj('image', focusobj, {}));
    this.setState({ covername: "" });
  }

  openListModalLink = (event, data) => {
    //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'image' });
    /**NUOVA MOADLITà ASSET 07-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "imagecloudlist"
      }
    }, "*");
    window.addEventListener('message', this.postMessageHandler);
  }


  render() {
    return (
      <List className="componentOnIframeClass">
        <List.Item>
          <List.Content>
            <List.Description>
              <List>
                <List.Item>

                  <Input size='mini'  disabled
                    value={this.state.covername} placeholder='Add image...'
                    action={!this.state.covername ?
                      { icon: 'upload', circular: true, onClick: this.openUploadModal, name: "IMAGE" }
                      :
                      { icon: 'delete', circular: true, onClick: this.cancelCoverSelected, name: "IMAGE" }} />
                  <Button icon onClick={this.openListModal}>
                    <Icon style={{ padding: 0 }} name='list' />
                  </Button>
            
                  {/* commentato pulsante che apre le risorse create come link */}
                  {/* 
                  <Popup flowing hoverable position='bottom right' size='small' trigger={
                    <Icon name='linkify' size="small" onClick={this.openListModalLink} />
                  }>
                    <p id='section-edit-card-link'>{"Load resource stored as a link "}</p>
                  </Popup> */}
                </List.Item>
              </List>
            </List.Description>
          </List.Content>
        </List.Item>
      </List>
    )
  }
}

export default ImageImport;