/**
 * Created by michelefuschillo on 29/01/18.
 */
import React, { Component } from 'react';
import {Input,List,Modal,Button,} from 'semantic-ui-react';
import { PhotoshopPicker } from 'react-color';
import '../../../../css/config.css';

import { setfocusobj} from '../../../../redux/actions/ObjectAction';

class ColorComp extends Component {
  constructor (props) {
    super(props);

    this.colorPickerHandler = this.colorPickerHandler.bind(this);
    this.colorChangePickerHandler = this.colorChangePickerHandler.bind(this);
    this.shapeAlphaSlider = this.shapeAlphaSlider.bind(this);
    this.funcModalOpen = this.funcModalOpen.bind(this);
    this.funcModalClose=this.funcModalClose.bind(this);
    this.updateState = this.updateState.bind(this);

    var updated = this.props.store.getState();
    this.state={
      previousState:updated,
      color:updated.reducer.focusobj.obj.color,
      alpha:updated.reducer.focusobj.obj.alpha,
      ModalOpenUpload:false};
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('shape', focusobj,{'type':'color','action':'change'}));
    unsubscribe();
  }

  /* color picker */
  colorPickerHandler(color, event){
    this.setState({ModalOpenUpload:false});
  }

  colorChangePickerHandler(color, event){
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.color = color.hex;
    this.props.dispatch(setfocusobj('shape', focusobj,{'type':'color','action':'change'}));
    unsubscribe();
  }

  shapeAlphaSlider(event, data){
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.alpha = data.value;
    this.props.dispatch(setfocusobj('shape', focusobj,{'type':'color','action':'change'}));
    unsubscribe();
  }

  updateState(){
    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'shape') {
      this.setState({
        previousState:updated,
        color:updated.reducer.focusobj.obj.color,
        alpha:updated.reducer.focusobj.obj.alpha});
		this.props.parent.waitingAnswer();
    }
  }

  /*GESTIONE APERTURA/CHIUSURA MODAL*/
  funcModalOpen(event, data) {
      this.setState({ModalOpenUpload:true });
  };

  funcModalClose(event, data) {
      this.setState({ModalOpenUpload:false});
  };

  render () {
    const cover = {margin:'auto', marginTop:'unset', position: 'relative',top: '25%',left: '10%'};
    return (
    <List animated className="componentOnIframeClass">
      <List.Item>
        <List.Content>
          <List.Header className="listitemheaderobj" >
          </List.Header>
          <List.Description>
            <Button style={{backgroundColor:this.state.color,width:'100%', borderColor: 'black',border: 'solid 1px'}} onClick={this.funcModalOpen}></Button>
            <Modal  basic open={this.state.ModalOpenUpload} onClose={this.funcModalClose} style={ cover }>
              <PhotoshopPicker color={this.state.color} onChange={this.colorChangePickerHandler} onAccept={this.colorPickerHandler}  onCancel={this.funcModalClose}/>
            </Modal>
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item name="shapebox">
        <List.Content>
          <List.Header className="listitemheaderobj" >
            <i style={{'color': '#000000', 'fontSize': 12}} >
                  Alpha:{''} <Input type='number' min={0.10} max={1.0} step={0.10} value={this.state.alpha} onChange={this.shapeAlphaSlider} size='small' style={{ height:'auto', width:'55px'}} />
            </i></List.Header>
          <List.Description className="listitemobj" >
            {/*<Input type='range' min={0.10} max={1.0} step={0.10} value={this.state.alpha} onChange={this.shapeAlphaSlider} style={{ height:'auto', width:'100%'}}/>*/}
          </List.Description>
        </List.Content>
      </List.Item>
    </List>
    )
  }
}
export default ColorComp;
