import React, { Component } from 'react';
import {Input, Button,Radio,Grid} from 'semantic-ui-react';
import Axios from 'axios';
import * as services from '../../../../config/services.json';
import menuItem from '../../../../redux/actions/menustate';
import actionLogout from '../../../../redux/actions/logout';
import '../../../../css/config.css';
import { launchModal } from '../../../../redux/actions/ModalAction'
let unsubscribe;

class Hdlinkmodal extends Component {
  constructor (props) {
    super(props);
    this.state = {
   	 	link:"",
      linkAction:'newwindow',
      covername:"",
      audiolink:"",
      audioname:""
    };
  }

  componentWillReceiveProps(props) {
    // var unsubscribe = this.props.store.subscribe(this.updateState);
    // var updated = this.props.store.getState();
    // var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    // this.props.dispatch(setfocusobj('image', focusobj,{'type':'image','action':'image'}));
    // unsubscribe();
  }

  componentWillMount() {
    //authentication check
    var updated = this.props.store.getState();
    if(!updated.reducer.logged){
      this.props.store.history.push("/login");
      this.props.store.dispatch(menuItem('login'));
    }else{
      this.props.store.dispatch(menuItem('home'));
    }
  }

  inputLinkChange =(event, data)=>{
	  this.setState({link:data.value});
	  console.log(data.value);
  }

  onClickOkButton =(event, data)=>{
	  console.log(data.value);
    // var actionOptions={
    //   linkAction:this.state.linkAction,
    //   audiolink:this.state.audiolink
    // }
      this.props.callbackFather('' ,'', '',this.state.link,{});
  }

  onChangeAction = (event, data) => {
    this.setState({linkAction:data.name});
  }
updatestate=()=>{
  console.log("updatestate")

     var updated = this.props.store.getState();
     console.log(updated.reducer.linkevent);     
     if (updated.reducer.linkevent!==undefined ){
       console.log("diverso da undefined")
      if (updated.reducer.linkevent.path!=="" ){
        console.log("diverso da stringa vuota ")
       console.log(updated.reducer.linkevent)
       this.setState({audiolink:updated.reducer.linkevent.path,audioname:updated.reducer.linkevent.name});      
      }else{
        console.log("uns1 ")
        
        
      unsubscribe();  
      }
      
     }else {
       console.log("uns2")
      // unsubscribe();
     }
    //  if (this.state.previousState !== updated){
    //   this.setState({audiolink:updated.audiolink.path,audioname:updated.audiolink.name});
    //  }else {
    //     unsubscribe();
    //  }
    // if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'audio') {
    //   this.setState({
    //     previousState:updated,
    //     width:updated.reducer.focusobj.obj.properties.width,
    //     height:updated.reducer.focusobj.obj.properties.height,
    //     name:updated.reducer.focusobj.obj.properties.name,
    //     audioPath:updated.reducer.focusobj.obj.properties.audioPath,
    //     coveraction:updated.reducer.focusobj.obj.properties.coveraction});
		// this.props.parent.waitingAnswer();
    // }else{
    //   unsubscribe(); //the state update will be made into another component (asynchronous mode)
    // }
  }
//  openUploadModal =(event, data)=> {
// 	  unsubscribe = this.props.store.subscribe(this.updatestate);
// 	  this.props.store.dispatch(launchModal('SETTING_MODAL', 'AUDIO',"audiolink"));
// 	  this.setState({macrocategory: 'audio'});
//   }

  render () {
    return (
      <div>
        <Input name='inputlink' type='text' value={this.state.link} onChange={this.inputLinkChange}
               style={{ height:'auto', width:'50%'}}/>
        {/* <Radio name='newwindow' label='New window' onChange={this.onChangeAction}
                      checked={this.state.linkAction === 'newwindow' ? true : false} style={{ marginLeft:'5px'}}/>
        <Radio name='fullscreen' label='Full screen' onChange={this.onChangeAction}
                             checked={this.state.linkAction === 'fullscreen' ? true : false} style={{ marginLeft:'5px'}}/>                       */}
      
      {/* {this.state.linkAction==='fullscreen' && this.props.store.getState().reducer.focusobj.obj.type==="image"?
      <Input size='mini'  disabled
      value={this.state.audioname} placeholder = "Add audio.."
      style={{ height:'auto', width:'50%', marginLeft:"50px",paddingTop:"5px"}}
      action={{ icon: 'upload',circular: true, onClick: this.openUploadModal, name:"AUDIO" }}/>       
        :null} */}

		<Button onClick={this.onClickOkButton} style={{ marginLeft:'5px'}}>OK</Button>
    </div>
    )
  }

}

export default Hdlinkmodal;
