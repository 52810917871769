import React, { Component } from 'react';
import {Container, Button,Grid,Segment,Popup,Icon} from 'semantic-ui-react';
import Axios from 'axios';
import * as services from '../../../../config/services.json';
import menuItem from '../../../../redux/actions/menustate';
import actionLogout from '../../../../redux/actions/logout';
import { Graph } from 'react-d3-graph';
import ReactDOM from 'react-dom'




class NodeDetail extends Component {
    constructor (props) {
      super(props);
      this.state = {
      node:this.props.data,
      configId:"",
      consumerName:"",
      type:"" ,
      videoId:"",
      configuration:this.props.data.configuration
      }
    }
    
 
    componentWillMount() {
     console.log(this.props.data)
     this.setState({node:this.props.data});
   } 
    
   callbackParent = () =>{
    this.props.callbackParent(this.state);
  }
  
   gotoConfigurationEdit = () => {
    this.setState({configId:this.props.data.parentConfig, consumerName:"", type:"graph", videoId:this.props.data.parentVideo, configuration:this.props.data.configuration},this.callbackParent);
   }
  
  
  
    render () {
  
      
      return (
         <div>
            <p> Configurazione : {this.props.data.parentConfig}</p> 
            <p>Video : {this.props.data.parentVideo} </p>
             {this.props.data.parentVideo ?
               <Popup trigger={
                
                  <Button circular icon='arrow alternate circle right outline' basic title='arrow' onClick={this.gotoConfigurationEdit}></Button>
                }on='hover'>

                <p >Go to configuration</p>
              </Popup>
              : 
              <span></span>

            }
         </div>
            )
          }
  
        }
  
        export default NodeDetail;
  