/**
 * Created by michelefuschillo on 10/09/18.
 */

export function loadsetting (settingType, settingExtra) {
  // Return action
  return {
    // Unique identifier
    type: 'SETTINGTYPE',
    // Payload
    payload:{
      settingType,
      settingExtra
    }
  };
};