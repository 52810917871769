/**
 * Created by obbiettivo on 23/05/18.
 */
import React, { Component } from "react";
import Axios from "axios";
import { Header, Image, Modal, Divider } from "semantic-ui-react";
import PreviewModal from "../../ModalContainer/modal/PreviewModal";
import * as services from "../../config/services.json";
import "../../css/content.css";
import { launchModal } from "../../redux/actions/ModalAction";
//import DataSourceAction, {saveDataSource} from '../../redux/actions/DataSourceAction'
import VideoSetting from "./VideoSetting";
//import IconSetting from './IconSetting'
import ShapeSetting from "./ShapeSetting";
import AudioSetting from "./AudioSetting";
import HtmlPageCoverSetting from "./HtmlPageCoverSetting";
import ImageSetting from "./ImageSetting";
import { Loader } from "semantic-ui-react";
import { setfocusobj } from "../../redux/actions/ObjectAction";
import { setLinkEvent } from "../../redux/actions/LinkEvent";

class GenericSetting extends Component {
  alertOptions = {
    offset: 14,
    position: "bottom center",
    theme: "dark",
    time: 5000,
    transition: "scale",
  };

  constructor(props) {
    super(props);
    this.state = {
      //Setto la clessidra di attesa a ON
      loadingObject: false,
      name: null,
      description: null,
      file: null,
      loading: false,
      previewOpen: false,
      resourcename: "",
      type: "",
      path: "",
      items: [],
      itemscopy: [],
      objdel: { id: "", index: "", public_id: "" },
      subtype: null,
      checked: false,
      upload: false,
      radio: this.props.radio ? this.props.radio : false,
    };

    this.loadInfo = this.loadInfo.bind(this);
    this.showAlert = this.showAlert.bind(this);
    //this.toggleSelect = this.toggleSelect.bind(this)
    this.resourceSelected = this.resourceSelected.bind(this);
    this.showUpload = this.showUpload.bind(this);
    this.showDelete = this.showDelete.bind(this);
    this.showPreview = this.showPreview.bind(this);
    this.previewOnClose = this.previewOnClose.bind(this);
  }

  componentDidMount() {
    console.log("genericsetting")
    //settingType: VIDEO, HTMLPAGE, AUDIO, IMAGE
    this.setState(
      { type: this.props.settingType.toUpperCase() },
      this.loadInfo
    );
  }

  /*CARICA RISORSE*/
  loadInfo() {
    this.setState({ items: [], itemscopy: [], loadingObject: true });
    var self = this;
    var servicePath = services.upload_resource_GET_AUTH.replace(
      "[type]",
      this.state.type
    );
    servicePath = servicePath.replace("[group]", this.props.group);
    //subType
    Axios.defaults.headers.common["Authorization"] = "Bearer " + this.props.jwt;
    Axios.get(services.domain + servicePath)
      .then(function (response) {
        if (response.data.message.length > 0) {
          var itemsTemp = [];
          for (var key in response.data.message) {
            var obj = response.data.message[key];
            var newobj = {
              key: obj._id,
              name: obj.name,
              description: obj.description,
              pathorigin: obj.pathorigin,
              ownerid: obj.owner ? obj.owner._id : "",
              owneremail: obj.owner ? obj.owner.email : "",
              ownername: obj.owner ? obj.owner.name : "",
              extension: obj.extension,
              size: obj.size,
              action: obj.action,
              image: obj.image,
            };
            itemsTemp.push(newobj);
          }
          itemsTemp.sort (function (a, b){
            return a.name.localeCompare(b.name);
          })
          self.setState({
            items: itemsTemp.slice(),
            itemscopy: itemsTemp.slice(),
            loadingObject: false
          }); //itemsTemp for research
        } else {
          self.setState({ items: [], itemscopy: [], loadingObject: false });
        }
      })
      .catch(function (error) {
        self.setState({ items: [], itemscopy: [], loadingObject: false });
        console.log(error);
      });
  }

  showAlert(message, result) {
    this.msg.show(message, {
      time: 5000,
      type: result,
    });
  }

  /*seleziona risorsa
  toggleSelect (event, data) {
    this.setState({
      type: 'video2dactions', action: 'selected', checked: data.selected.key, pathorigin: data.selected.pathorigin,
      name: data.selected.name
    })
  };*/

  //salva informazioni sulla risorsa salvata
  resourceSelected(event, data) {
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    console.log(this.props.extra);
    console.log("generic settings ");
    if (this.props.extra === "video2dcover") {
      focusobj.properties.coverpath = data.selected.pathorigin;
      focusobj.properties.covername = data.selected.name;
      this.props.dispatch(launchModal("", "", ""));
      this.props.dispatch(
        setfocusobj("video2d", focusobj, { type: "path", command: "cover" })
      );
    } else if (this.props.extra === "audiocover") {      
      focusobj.properties.cover = data.selected.pathorigin;
      focusobj.properties.covername = data.selected.name;
      this.props.dispatch(launchModal("", "", ""));
      this.props.dispatch(
        setfocusobj("audio", focusobj, { type: "path", command: "cover" })
      );
    } 
    else if (this.props.extra === "audiolink") { //aggiunge un linkaudio all evento link     
      var media={};
      media.path=data.selected.pathorigin;
      media.name=data.selected.name;
      this.props.dispatch(launchModal("", "", ""));
      this.props.dispatch(setLinkEvent(media));
    }     
    else {
      if (this.props.reducer.focusobj.type === "video2d") {
        focusobj.properties.pathresource = data.selected.pathorigin;
        focusobj.properties.nameresource = data.selected.name;

        this.props.dispatch(launchModal("", "", ""));
        this.props.dispatch(
          setfocusobj("video2d", focusobj, { type: "path", action: "selected" })
        );
      } else if (this.props.reducer.focusobj.type === "htmlpage") {
        focusobj.properties.coverpath = data.selected.pathorigin;
        focusobj.properties.covername = data.selected.name;
        this.props.dispatch(launchModal("", "", ""));
        this.props.dispatch(
          setfocusobj("htmlpage", focusobj, {
            type: "htmlpage",
            action: "htmlpage",
          })
        );
      } else if (this.props.reducer.focusobj.type === "image") {
        focusobj.properties.coverpath = data.selected.pathorigin;
        focusobj.properties.covername = data.selected.name;
        this.props.dispatch(launchModal("", "", ""));
        this.props.dispatch(
          setfocusobj("image", focusobj, { type: "image", action: "image" })
        );
      } else if (this.props.reducer.focusobj.type === "audio") {
        focusobj.properties.audioPath = data.selected.pathorigin;
        focusobj.properties.name = data.selected.name;
        this.props.dispatch(launchModal("", "", ""));
        this.props.dispatch(
          setfocusobj("audio", focusobj, { type: "audio", action: "audio" })
        );
      }
    }
  }

  showUpload(event, data) {
    if (typeof data.contexttoreq !== 'undefined'){//if for requesto from home to reload page after resource upload 
    this.props.dispatch(
      launchModal("UPLOAD_MODAL", event.target.name, data.extra,data.contexttoreq)
    ); //launchModal (modalType, subType, extra)
    }else {
      this.props.dispatch(
        launchModal("UPLOAD_MODAL", event.target.name, data.extra,"")
      ); //launchModal (modalType, subType, extra)
    }
  }

  showDelete(event, data) {
    this.props.dispatch(
      launchModal("DELETE_MODAL", event.target.name, {
        id: data.value,
        public_id: data.public_id,
        type: "video",
        itemname: data.itemname,
      })
    );
  }

  showPreview(event, data) {
    console.log("showPreview");
    console.log(data);
    this.setState({
      previewOpen: true,
      resourcename: data.resourcename,
      type: data.type,
      path: data.path,
    });
  }

  previewOnClose() {
    this.setState({ previewOpen: false });
  }
  // this.props.settingType === 'shape' ? (
  //   <ShapeSetting state={this.state} props={this.props} self={this}/>) :
  render() {
    return (
      <div>
        <div>
          {this.state.loadingObject === true ? (
            <div className="ui active loader"></div>
          ) : (
            <div className="ui disabled loader"></div>
          )}
        </div>
        <div>
          {this.props.settingType.toUpperCase() === "VIDEO" ? (
            <VideoSetting self={this}  />
          ) : this.props.settingType.toUpperCase() === "AUDIO" ? (
            <AudioSetting self={this}  />
          ) : this.props.settingType === "HTMLPAGE" ? (
            <HtmlPageCoverSetting self={this} />
          ) : this.props.settingType.toUpperCase() === "IMAGE" ? (
            <ImageSetting self={this} />
          ) : (
            ""
          )}
          <Modal
            open={this.state.previewOpen}
            size={"tiny"}
            closeIcon={true}
            onClose={this.previewOnClose}
          >
            <Modal.Content style={{ background: "#000000" }} scrolling>
              <Header as="h1">
                <Image
                  id="image-logo-modal"
                  src={
                    this.props.group === "IDEHA"
                      ? "/assets/images/logo_H.png"
                      : "/assets/images/logo.png"
                  }
                  avatar
                  floated="left"
                  alt="H360"
                />
                <Header.Subheader style={{ color: "#f0f0f0" }}>
                  Preview {this.state.type}{" "}
                  <i style={{ fontWeight: "bold" }}>
                    {this.state.resourcename}
                  </i>{" "}
                </Header.Subheader>
                <Divider style={{ marginTop: "20px" }} />
              </Header>
            </Modal.Content>
            <PreviewModal path={this.state.path} type={this.state.type} />
          </Modal>
        </div>
      </div>
    );
  }
}

export default GenericSetting;
