/**
 * Created by michelefuschillo on 31/01/18.
 */
import React, { Component,  } from 'react';
import {Icon,Accordion}       from 'semantic-ui-react';

import '../../../css/config.css';
import MetaComp from '../types/metadata/metacomp';

class MenuMetadata extends Component {
  constructor (props) {
    super(props);
    this.state=({accordionActiveIndex:-1,accordionActiveIndexInternal:-1})
    this.manageAccordion=this.manageAccordion.bind(this);
  }

  componentWillMount() {
    this.setState({accordionActiveIndex:-1,accordionActiveIndexInternal:-1});
  }

  manageAccordion(event, data){
    if(data.index === this.state.accordionActiveIndex){
      this.setState({accordionActiveIndex:-1});
    }else{
      this.setState({accordionActiveIndex:data.index});
      // this.props.callbackParent(data.name);
    }
  }

  /*
   *  Metadata
   * */
  render () {
    return (
      <div>
      <Accordion styled exclusive={false} fluid>
        <Accordion.Title name="metadata" className="accordionTitle" active={this.state.accordionActiveIndex === 0} index={0} onClick={this.manageAccordion} position='left' >
          <Icon name='dropdown' id='section-config-IconAccordion'/>
          <Icon color="grey" name='expand'/><i id='section-config-AccordionHotspot'>Metadata</i>
        </Accordion.Title>
          <Accordion.Content active={this.state.accordionActiveIndex  === 0}>
            <MetaComp reducer={this.props.reducer}
                      dispatch={this.props.dispatch}
                      store={this.props.store}
					  parent={this.props.parent}/>
          </Accordion.Content>
      </Accordion>
      </div>
    )
  }
}

export default MenuMetadata;
