/**
 * Created by michelefuschillo on 01/02/18.
 */
import React, { Component } from 'react';
import {TextArea, List, Input, Radio,Icon} from 'semantic-ui-react';
import { setfocusobj} from '../../../../redux/actions/ObjectAction'
import ValidUrl from 'valid-url';
import { getResourceDataForLanguage } from './../utils';
import * as services from '../../../../config/services.json';
let unsubscribe;

class HtmlPageLink extends Component {
  constructor (props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    var updated = this.props.store.getState();
    if(this.props.reducer.focusobj.obj.properties) {
      this.state = {
        previousState:updated,
        link: updated.reducer.focusobj.obj.properties.link,
        resourceid:updated.reducer.focusobj.obj.properties.resourceid ,
        nameres:updated.reducer.focusobj.obj.properties.nameres,
        deleteButtonStatus:updated.reducer.focusobj.obj.properties.nameres ? false:true,
        textAreaStatus:updated.reducer.focusobj.obj.properties.resourceid ? true:false};
    }
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('htmlpage', focusobj,{'type':'link','action':'link'}));
    unsubscribe();
  }

  updateState(){
    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'htmlpage') {
      this.setState({
        previousState:updated,
        link: updated.reducer.focusobj.obj.properties.link,
      });
		this.props.parent.waitingAnswer();
    }
  }

  onChangeLink = (event, data)=>{
    switch(data.name){
      case 'link':
        // if(ValidUrl.isUri(data.value)){
        //   this.setState({errorUrl:false});
        // }else{
        //   this.setState({errorUrl:true});
        // }
        var unsubscribe = this.props.store.subscribe(this.updateState);
        var updated = this.props.store.getState();
        var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
        focusobj.properties.link = data.value;
        this.props.dispatch(setfocusobj('htmlpage', focusobj,{'type':'link','action':'link'}));
        unsubscribe();
        break;
      default:
        break;
    }
  }
  openUploadModal =(event, data)=> {
	  //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'htmlpage' });
    
    /**NUOVA MOADLITà ASSET 07-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "upload",
        typeres: "webcloud"
      }
    }, "*");

    window.addEventListener('message', this.postMessageHandler);
  }

  openListModal = (event, data) => {
    //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'htmlpage' });
    /**NUOVA MOADLITà ASSET 07-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "webcloudlist"
      }
    }, "*");
    window.addEventListener('message', this.postMessageHandler);
  }



  postMessageHandler = (event) => {
    if (event.origin === services.ASSETDOMAIN) { //FROM ASSETLOGIN
      switch (event.data.type) {
        case "NEWRESOURCE":
          console.log("Gestione new resource in html page")
          console.log(event.data.payload);
          
          let data={};
          data.medialan=event.data.payload.url;
          unsubscribe = this.props.store.subscribe(this.updateState);
          var focusobj = Object.assign({}, this.props.reducer.focusobj.obj);
          console.log(event.data.payload.url[getResourceDataForLanguage(data)]);
          focusobj.properties.link = event.data.payload.url[getResourceDataForLanguage(data)];
          focusobj.properties.resourceid = event.data.payload._id;  
          focusobj.properties.nameres = event.data.payload.name;                  
          this.props.dispatch(setfocusobj('htmlpage', focusobj,{'type':'link','action':'link'}));
          this.setState({
            resourceid:event.data.payload._id , 
            nameres:event.data.payload.name,
            deleteButtonStatus:event.data.payload.name ? false:true,
            textAreaStatus: event.data.payload._id? true:false});
          //prendi l'ogetto che arriva 
          //analizza la lingua da prendere secondo algoritmo base ma customizzato per le lingue 
          //setta il testo giusto 
          //utiliza il dispatch come sopra al posto di quello imamgini 
          // devi fare il controllo se il testo è preso da res manager non puoi permettere la modifica quindi se vuoi che 
          //il testo sia messo a mano devi fare la cancellazione del settaggio di un testo preso da res manager 
          //devi anche settare il testo preso dal res manager nella textinput e disabilitarlo se viene da res manager 
          // focusobj.properties.coverpath = this.coverUrl(event.data.payload);
          // focusobj.properties.covername = event.data.payload.name;
          // focusobj.properties.resourceid = event.data.payload._id;  //CREDO DI DOVER SALVARE ANCHE RESOURCE ID UNICO MODO PER POTER AVERE RIFERIMENTO ALLA RISORSA 
          // this.props.dispatch(setfocusobj("image", focusobj, { 'type': 'image', 'action': 'image' }));
          window.removeEventListener('message', this.postMessageHandler);
          //this.setState({ childmessage: event.data.payload.msg });
          break;
        default: break;
      }
    }
  }
  removeText =(event)=>{    
    var focusobj = Object.assign({}, this.props.reducer.focusobj.obj);
    focusobj.properties.link = "";
    focusobj.properties.resourceid = "";  
    focusobj.properties.nameres = "";                  
    this.props.dispatch(setfocusobj('htmlpage', focusobj,{'type':'link','action':'link'}));
    // textAreaStatus, resourceid, nameres:""
    this.setState({textAreaStatus:false, resourceid:"", nameres:"", link:""});

  } 
  render () {
    return (
      <List  className="componentOnIframeClass">
        <List.Item>
          <List.Content>
            <List.Header></List.Header>
            <List.Description className="listitemobj" >
              <Input  name="link" placeholder='Link...' icon="linkify"  disabled={this.state.textAreaStatus} fluid onChange={this.onChangeLink} value={this.state.link} error={this.state.errorUrl}/>
            </List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
        <Input size='mini' fluid disabled value={this.state.nameres}/>
        <Icon name='list' size="small" onClick={this.openListModal}/>
        <Icon name='upload' size="small" onClick={this.openUploadModal}/> 
        <Icon name='trash' size="small"   disabled={this.state.deleteButtonStatus} onClick={this.removeText}/> 
        </List.Item>
      </List>
    )
  }
}

export default HtmlPageLink;