export function setLinkEvent (linkevent) {
  // Return action
  return {
    // Unique identifier

    type: 'LINKEVENT',
    // Payload
    payload:{
      linkevent
    }
  };
};
