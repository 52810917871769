/**
* Created by michelefuschillo on 31/01/18.
*/
import React, { Component } from 'react';
import {Accordion,Icon,Popup} from 'semantic-ui-react';

import '../../../css/config.css';
import * as desc from './descriptionmenu.json'

const ShowInfo=true;

class MenuFourth extends Component {
  constructor (props) {
    super(props);
    this.state=({accordionActiveIndex:-1,accordionActiveIndexInternal:-1})
    this.manageAccordion=this.manageAccordion.bind(this);
    this.handleMenuVertical = this.handleMenuVertical.bind(this);
  }
  
  componentWillMount() {
    this.setState({accordionActiveIndex:-1,accordionActiveIndexInternal:-1});
  }
  
  componentWillReceiveProps(props) {
    if (this.props.action==="menutime"){
      //accordionActiveIndex in questo caso assume il valore della posizione del menu time 
      //cambiare se cambi l'ordine del menù 
      this.setState({accordionActiveIndex:3,accordionActiveIndexInternal:-1});
      let i= document.getElementById('timeaccordion')
      if (i){
        this.props.callbackParent("time")
      }
    }else {
      this.setState({accordionActiveIndex: -1, accordionActiveIndexInternal: -1});
    }
  }
  
  componentDidMount() {
    
    if (this.props.action==="menutime"){
      //accordionActiveIndex in questo caso assume il valore della posizione del menu time 
      //cambiare se cambi l'ordine del menù 
      this.setState({accordionActiveIndex:3,accordionActiveIndexInternal:-1});
      let i= document.getElementById('timeaccordion')
      if (i){
        this.props.callbackParent("time")
      }
    }
  }
  
  manageAccordion(event, data){
    if(data.index === this.state.accordionActiveIndex){
      this.setState({accordionActiveIndex:-1});
    }else{
      this.setState({accordionActiveIndex:data.index});
      this.props.callbackParent(data.name);
    }
  }
  /*
  *  TESTO
  * */
  render () {
    return (
      <div>
      <Accordion styled>
      <Accordion.Title name="htmlpageinfo" className="accordionTitle" active={this.state.accordionActiveIndex === 0} index={0} onClick={this.manageAccordion} position='left' >
      <Icon name='dropdown' id='section-config-IconAccordion'/>
      <Icon color="grey" name='info circle'/>
      <i id='section-config-AccordionHotspot'>Info</i>
      
      
      {this.props.reducer.focusobj.obj.properties.vo ?( this.props.reducer.focusobj.obj.properties.vo.showhotspot === true ? <Icon color="grey" name='eye'/> : <Icon color="grey" name='eye slash'/>):''}
      
      { ShowInfo=== true ? 
        <Popup flowing hoverable position='bottom right' size='small' trigger={                      
          <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
        }>
        <p id='section-edit-card-link'>{desc.info.description}</p>
        </Popup>
        :
        <span></span>}
        </Accordion.Title>
        
        <Accordion.Content active={this.state.accordionActiveIndex  === 0}>
        <div id='htmlpageinfo'></div>
        </Accordion.Content>
        <Accordion.Title name="htmlposition" className="accordionTitle" active={this.state.accordionActiveIndex === 1} index={1} onClick={this.manageAccordion} position='left' >
        <Icon name='dropdown' id='section-config-IconAccordion'/>
        <Icon color="grey" name='globe'/><i id='section-config-AccordionHotspot'>Position</i>
        
        { ShowInfo=== true ? 
          <Popup flowing hoverable position='bottom right' size='small' trigger={                      
            <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
          }>
          <p id='section-edit-card-link'>{desc.position.description}</p>
          </Popup>
          :
          <span></span>}
          </Accordion.Title>
          <Accordion.Content active={this.state.accordionActiveIndex  === 1}>
          <div id='htmlposition'></div>
          </Accordion.Content>
          
          <Accordion.Title name="htmlpagelink" className="accordionTitle" active={this.state.accordionActiveIndex === 2} index={2} onClick={this.manageAccordion} position='left' >
          <Icon name='dropdown' id='section-config-IconAccordion'/>
          <Icon color="grey" name='linkify'/><i id='section-config-AccordionHotspot'>Link</i>
          
          { ShowInfo=== true ? 
            <Popup flowing hoverable position='bottom right' size='small' trigger={                      
              <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
            }>
            <p id='section-edit-card-link'>{desc.link.description}</p>
            </Popup>
            :
            <span></span>}
            </Accordion.Title>
            
            <Accordion.Content active={this.state.accordionActiveIndex  === 2}>
            <div id='htmlpagelink'></div>
            </Accordion.Content>
            { this.props.reducer.resType !=="IMAGE2" && this.props.reducer.resType !=="IMAGE3"?
            <div>
            <Accordion.Title name="time" active={this.state.accordionActiveIndex === 3} index={3}
            onClick={this.manageAccordion} position='left'>
            <Icon name='dropdown' id='section-config-IconAccordion'/>
            <Icon color="grey" name='time'/><i id='section-config-AccordionHotspot'>Time</i>
            
            { ShowInfo=== true ? 
              <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
              }>
              <p id='section-edit-card-link'>{desc.time.description}</p>
              </Popup>
              :
              <span></span>}
              </Accordion.Title>
              <Accordion.Content active={this.state.accordionActiveIndex === 3}>
              <div id='timeaccordion'></div>
              </Accordion.Content>
              </div>
            :null}   
              
              <Accordion.Title name="htmlpageposition"  active={this.state.accordionActiveIndex  === 4} index={4} onClick={this.manageAccordion} position='left'>
              <Icon name='dropdown' id='section-config-IconAccordion'/>
              <Icon color="grey" name='expand arrows alternate'/><i id='section-config-AccordionHotspot'> Size </i>
              
              { ShowInfo=== true ? 
                <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                  <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                }>
                <p id='section-edit-card-link'>{desc.size.description}</p>
                </Popup>
                :
                <span></span>}
                </Accordion.Title>
                <Accordion.Content active={this.state.accordionActiveIndex  === 4}> <div id='htmlpageposition'></div></Accordion.Content>
                
                <Accordion.Title name="htmlpageappearance" className="accordionTitle" active={this.state.accordionActiveIndex === 5} index={5} onClick={this.manageAccordion} position='left' >
                <Icon name='dropdown' id='section-config-IconAccordion'/>
                <Icon color="grey" name='picture'/><i id='section-config-AccordionHotspot'>Cover</i>
                
                { ShowInfo=== true ? 
                  <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                    <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                  }>
                  <p id='section-edit-card-link'>{desc.cover.description}</p>
                  </Popup>
                  :
                  <span></span>}
                  </Accordion.Title>
                  <Accordion.Content active={this.state.accordionActiveIndex  === 5}>
                  <div id='htmlpageappearance'></div>
                  </Accordion.Content>
                  
                  <Accordion.Title name="htmlpageviewoptions" className="accordionTitle" active={this.state.accordionActiveIndex === 6} index={6} onClick={this.manageAccordion} position='left' >
                  <Icon name='dropdown' id='section-config-IconAccordion'/>
                  <Icon color="grey" name='move'/><i id='section-config-AccordionHotspot'>View options</i>
                  
                  { ShowInfo=== true ? 
                    <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                      <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                    }>
                    <p id='section-edit-card-link'>{desc.viewoptions.description}</p>
                    </Popup>
                    :
                    <span></span>}
                    </Accordion.Title>
                    
                    <Accordion.Content active={this.state.accordionActiveIndex  === 6}>
                    <div id='htmlpageviewoptions'></div>
                    </Accordion.Content>
                    
                    {/* <Accordion.Title name="htmlshapetag"  active={this.state.accordionActiveIndex  === 7} index={7} onClick={this.manageAccordion} position='left'>
                    <Icon name='dropdown' id='section-config-IconAccordion'/>
                    <Icon color="grey" name='tag'/><i id='section-config-AccordionHotspot'>Tags</i>
                    
                    { ShowInfo=== true ? 
                      <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                        <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                      }>
                      <p id='section-edit-card-link'>{desc.tags.description}</p>
                      </Popup>
                      :
                      <span></span>}
                      </Accordion.Title>
                      <Accordion.Content active={this.state.accordionActiveIndex  === 7}><div id='htmltagshapeaccordion'></div></Accordion.Content> */}
                      </Accordion>
                      </div>
                      )
                    }
                    
                    handleMenuVertical(event, data){
                      this.setState({activeItem:data.name});
                      this.props.callbackParent(data.name);
                    }
                  }
                  
                  export default MenuFourth;
                  