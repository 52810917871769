import React, { Component } from 'react';
import { Container, Button, Grid, Segment, Icon, Dimmer, Loader } from 'semantic-ui-react';
import Axios from 'axios';
import * as services from '../../../../config/services.json';
//import { Graph } from 'react-d3-graph';
import ReactDOM from 'react-dom'
import NodeDetail from './nodedetail';
import LinkDetail from './linkdetail';
import { getResourceDataForLanguage } from './../utils';
//import { ForceGraph2D, ForceGraph3D, ForceGraphVR, ForceGraphAR } from 'react-force-graph';


import '../../../../css/config.css';
//esempio di oggetto 
// const data = {
//   nodes : [
//    { id : 'Harry' },
//    { id : 'Sally' },
//    { id : 'Alice' }
//  ],
//  collegamenti : [
//      { fonte : "Harry" , target : "Sally" },
//      { fonte : "Harry" , target : "Alice" },
//  ]
// };

// the graph configuration, you only need to pass down properties
// that you want to override, otherwise default ones will be used
const myConfig = {
  //puoi ingrandire
  //  height: "600px",
  //  width: "300px",
  // collapsible: true,

  nodeHighlightBehavior: true,
  directed: true,
  collapsible: true,
  node: {
    color: 'red',
    labelPosition: 'bottom',
    size: 300,
    highlightStrokeColor: 'blue',
    symbolType: "circle",
    mouseCursor: "pointer",
    fontSize: 10
  },
  link: {
    strokeWidth: 3,
    renderLabel: true,
    highlightColor: 'blue'
    // mouseCursor:"pointer"
  },
  d3: {
    // alphaTarget: 0.05,
    gravity: -200,
    linkLength: 350,
    // disableLinkForce :false
  },
};


// graph event callbacks
//  const onClickNode = function(nodeId) {
//     console.log(this.state.data)
//     window.alert('Clicked node ${nodeId}');
// };

// const onMouseOverNode = function(nodeId) {
//     window.alert(`Mouse over node ${nodeId}`);
// };

// const onMouseOutNode = function(nodeId) {
//     window.alert(`Mouse out node ${nodeId}`);
// };

// const onClickLink = function(source, target) {
//     window.alert(`Clicked link between ${source} and ${target}`);
// };

// const onMouseOverLink = function(source, target) {
//     window.alert(`Mouse over in link between ${source} and ${target}`);
// };

// const onMouseOutLink = function(source, target) {
//     window.alert(`Mouse out link between ${source} and ${target}`);
// };


// if (data.message[i].properties.resourcedata.type==="imagecloud"){ //gestione hotspot con risorsa esterna come sorgente 
//   console.log("[imageEdit.js]gestione nuovo url associata a hotspot image")
//   let helpData={};//oggetto di appoggio creato xke la utils purtroppo ragiona sul campo medialan si dovrebbe correggere in tropi punti 
//   helpData.medialan=data.message[i].properties.resourcedata.url;
//   let dataLang=getResourceDataForLanguage(helpData);  //there is a second param (string as "en","it" etc.) to select  language explicitly
//   console.log("[imageEdit.js]sgestione nuovo url associata a hotspot image "+dataLang+" :");
//   console.log(data.message[i].properties.resourcedata.url[dataLang]);
//   data.message[i].properties.coverpath=data.message[i].properties.resourcedata.url[dataLang];                  
// }else{
// let dataLang=getResourceDataForLanguage(data.message[i].properties.resourcedata);  //there is a second param (string as "en","it" etc.) to select  language explicitly
// data.message[i].properties.coverpath=updateQueryStringParameter(data.message[i].properties.coverpath,"medialan",dataLang);
// }

class GraphModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      node: [],
      link: [],
      option: 0,
      configId: "",
      consumerName: "",
      type: "",
      configuration: {},
      videoId: "",
      videoArray: this.props.videoArray,
      loading: true
    };

    this.attach = this.attach.bind(this);
    this.onClickNode = this.onClickNode.bind(this);
    this.onClickLink = this.onClickLink.bind(this);
    this.checkImageExists = this.checkImageExists.bind(this);


  }


  componentWillMount() {
    console.log("componentWillMount")
    console.log(this.props.videoArray)
    var self = this
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt;
    var path = services.data_graph_AUTH.replace('[group]', this.props.reducer.group);
    path = path.replace('[resourceName]', this.props.videoId);
    path = path.replace('[processId]', this.props.configid);
    path = path + "&domainAsset=" + self.props.reducer.domain;
    path = path + "&appname=" + self.props.reducer.appname;
    console.log(path);
    // path="https://hyper360.api.eng.it/H360/roadmap/?processId=version 3&resourceName=11.VIDEO_6_CIONET VENUE&group=RTI"
    Axios.get(services.domain + path)
      .then(function (response) {
        const firstNode = self.props.videoId + "_" + self.props.configid + "_" + self.props.reducer.group;
        var data = "";
        if (response.status !== 400) {
          var result = response.data.message;
          console.log(result)
          if (result.length > 0) {
            var nodes = [], links = [];
            for (var i = 0; i < result.length; i++) {
              let indexExist = nodes.findIndex(element => element.id === result[i].parentVideo + "_" + result[i].parentConfig + "_" + self.props.reducer.group);
              if (indexExist === -1) {
                let elem = {};
                elem.id = result[i].parentVideo + "_" + result[i].parentConfig + "_" + self.props.reducer.group;
                elem.parentVideo = result[i].parentVideo;
                elem.parentConfig = result[i].parentConfig;
                elem.configuration = result[i].configuration;
                //check immagine da verificare bene il funzionamento 
                //  let variabile= self.checkImageExists(services.domain + services.cover_video_path.replace("[name]",self.props.reducer.group +"/"+result[i].parentVideo+".jpg")); 
                //  if (variabile){
                //   elem.svg=services.domain + services.cover_video_path.replace("[name]",self.props.reducer.group +"/"+result[i].parentVideo+".jpg");
                //  }else {
                //   elem.svg=services.domain + services.cover_video_path.replace("[name]",self.props.reducer.group +"/film"+".jpg");
                //  }
                console.log("result[i].parentVideo")

                console.log(result[i].parentVideo)

                // if (data.message[i].properties.resourcedata.type==="imagecloud"){ //gestione hotspot con risorsa esterna come sorgente 
                //     console.log("[imageEdit.js]gestione nuovo url associata a hotspot image")
                //     let helpData={};//oggetto di appoggio creato xke la utils purtroppo ragiona sul campo medialan si dovrebbe correggere in tropi punti 
                //     helpData.medialan=data.message[i].properties.resourcedata.url;
                //     let dataLang=getResourceDataForLanguage(helpData);  //there is a second param (string as "en","it" etc.) to select  language explicitly
                //     console.log("[imageEdit.js]sgestione nuovo url associata a hotspot image "+dataLang+" :");
                //     console.log(data.message[i].properties.resourcedata.url[dataLang]);
                //     data.message[i].properties.coverpath=data.message[i].properties.resourcedata.url[dataLang];                  
                //   }else{
                //   let dataLang=getResourceDataForLanguage(data.message[i].properties.resourcedata);  //there is a second param (string as "en","it" etc.) to select  language explicitly
                //   data.message[i].properties.coverpath=updateQueryStringParameter(data.message[i].properties.coverpath,"medialan",dataLang);
                //   }
                elem.img = "/assets/images/logo.png"
                if (firstNode === elem.id) {
                  // elem.color="#00FF00";
                  elem.size = 450;
                  // elem.strokeColor="#FF0080	";
                  // elem.highlightColor="#FF0080";
                  elem.fontSize = 12;
                  // console.log(services.domain + services.cover_video_path.replace("[name]",result[i].parentVideo+".jpg"))
                  // elem.svg=services.domain + services.cover_video_path.replace("[name]","film"+".jpg" )
                }
                nodes.push(elem);
              }
              for (var y = 0; y < result[i].sons.length; y++) {
                let elem2 = {};
                elem2.target = result[i].sons[y].jump[0].idVideo + "_" + result[i].sons[y].jump[0].idConfig + "_" + self.props.reducer.group; //questo array sarà sempre con un solo elemento?
                elem2.source = result[i].parentVideo + "_" + result[i].parentConfig + "_" + self.props.reducer.group;
                //  elem2.label="Linked Through : "+result[i].configuration.category+"|"+result[i].configuration.name;
                let indexExist1 = links.findIndex(element => element.target === elem2.target && element.source === elem2.source);
                // console.log(indexExist1)
                if (elem2.target !== elem2.source && indexExist1 === -1) { //evitiamo link su se stessi prima parte e link allo stesso elemento da piu hotspot nella seocnda parte 
                  links.push(elem2);
                }
                //ricerca per capire se esiste un salto verso un video che non ho come nodo 
                //cosa che succede quando uno dei miei video salta verso 
                //una coppia configurazione video che non ho analizzato 
                let index = nodes.findIndex(element => element.id === elem2.target);
                if (index === -1) {
                  let elem = {};
                  elem.id = elem2.target;
                  elem.parentVideo = result[i].sons[y].jump[0].idVideo;
                  elem.parentConfig = result[i].sons[y].jump[0].idConfig;
                  elem.configuration = result[i].sons[y].elements;
                  // let variabile1= self.checkImageExists(services.domain + services.cover_video_path.replace("[name]",self.props.reducer.group +"/"+result[i].sons[y].jump[0].idVideo+".jpg")); 
                  // if (variabile1){
                  //  elem.svg=services.domain + services.cover_video_path.replace("[name]",self.props.reducer.group +"/"+result[i].sons[y].jump[0].idVideo+".jpg");
                  // }else {
                  elem.img = "/assets/images/logo.png"
                  // }

                  // if (data.message[i].properties.resourcedata.type==="imagecloud"){ //gestione hotspot con risorsa esterna come sorgente 
                  //   console.log("[imageEdit.js]gestione nuovo url associata a hotspot image")
                  //   let helpData={};//oggetto di appoggio creato xke la utils purtroppo ragiona sul campo medialan si dovrebbe correggere in tropi punti 
                  //   helpData.medialan=data.message[i].properties.resourcedata.url;
                  //   let dataLang=getResourceDataForLanguage(helpData);  //there is a second param (string as "en","it" etc.) to select  language explicitly
                  //   console.log("[imageEdit.js]sgestione nuovo url associata a hotspot image "+dataLang+" :");
                  //   console.log(data.message[i].properties.resourcedata.url[dataLang]);
                  //   data.message[i].properties.coverpath=data.message[i].properties.resourcedata.url[dataLang];                  
                  // }else{
                  // let dataLang=getResourceDataForLanguage(data.message[i].properties.resourcedata);  //there is a second param (string as "en","it" etc.) to select  language explicitly
                  // data.message[i].properties.coverpath=updateQueryStringParameter(data.message[i].properties.coverpath,"medialan",dataLang);
                  // }



                  nodes.push(elem);
                }
              }
            }
            data = { nodes, links };
            console.log(data);
            self.setState({ data: data, loading: false }, self.attach)
          }
        } else {
          self.setState({ data: data, loading: false });//caso jump non presenti 
        }
      })
      .catch(function (error) {
        self.setState({ data: "", loading: false });//caso jump non presenti
        console.log('Errore in funzione [Configuration.jsx/data_graph_AUTH]: ', error);
      })
  }

  attach() {
    //  console.log(this.state.data)
    // ReactDOM.render(<Graph
    //   id="graph-id" // id is mandatory, if no id is defined rd3g will throw an error
    //   data={this.state.data}
    //   config={myConfig}
    //   // style={{"height": "826px","width": "1157px"}}
    //   // onClickNode={this.onClickNode}
    //   // onClickLink={this.onClickLink}
    //   // onMouseOverNode={this.onClickNode}
    // // onMouseOutNode={onMouseOutNode}
    // // onMouseOverLink={this.onMouseOverLink}
    // // onMouseOutLink={onMouseOutLink}
    // />, document.getElementById('graphanchor'))



    ReactDOM.render(
      /*<ForceGraph3D
      width={900}
      height={500}
      showNavInfo={true}
      backgroundColor="grey"
      onNodeClick={this.onClickNode}
        graphData={this.state.data}
        linkDirectionalArrowLength={3.5}
        linkDirectionalArrowRelPos={1}
        linkCurvature={0.25}
   
      
      />,*/
      document.getElementById('graphanchor')
    );


  }

  checkImageExists(imageUrl) {
    var http = new XMLHttpRequest();
    http.open('HEAD', imageUrl, false);
    http.send();
    return http.status != 404;
  }



  callbackParent = () => {
    this.props.callbackParent(this.state);
  }


  onClickNode = function (node, event) {
    // console.log(this.state.data)
    // var index = this.state.data.nodes.findIndex(x => x.id === nodeId);
    // console.log(index)
    // if (index !== -1) {
    //   console.log(this.state.data.nodes[index])
    //   this.setState({ node: this.state.data.nodes[index], option: 0 });
    // }
  
    console.log(node)
    let printerString = "Nome bolla : "+ node.parentConfig;        
      // this.setState({ node: node, option: 0 });
    window.alert(printerString);
  };

  onClickLink = function (source, target) {
    // window.alert(`Clicked link between ${source} and ${target}`);
  };

  callToCallbackParent = (parentState) => {
    this.setState({ configId: parentState.configId, consumerName: parentState.consumerName, type: parentState.type, videoId: parentState.videoId, configuration: parentState.configuration }, this.callbackParent);

  }
  render() {


    return (
      <div>
        <Dimmer active={this.state.loading} > <Loader indeterminate>Loading</Loader></Dimmer>
        {this.state.data === "" ?
          <Segment>
            <Icon name="times circle" color="red" />
            <span>Sorry! there are no jumps </span>
            <Icon name="times circle" color="red" />

          </Segment>

          :
          <Grid columns='2' style={{ "marginLeft": "10px", "marginBottom": "1px" }} >

            <Grid.Row>
              <Grid.Column width={12}>
                {/* <Segment>
             <Button></Button>                          
             <Button></Button>
             <Button></Button>                          
             <Button></Button>
             <Button></Button>                          
             <Button></Button>
             </Segment>             */}
                <Segment id="graphanchor" style={{  "marginRight": "10px", "marginBottom": "1px" }}>
                </Segment>
              </Grid.Column>
              <Grid.Column width={4}>
                <Segment id="menuanchor" style={{ "marginRight": "10px", "marginBottom": "1px" }}>
                  {/* {this.state.option === 0 ? 
                    <NodeDetail data={this.state.node} callbackParent={this.callToCallbackParent}></NodeDetail>
                     : <span></span>} */}
                    {/* :
                    <LinkDetail data={this.state.node}></LinkDetail>} */} 
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>}
      </div>)
  }

}

export default GraphModal;
