/**
 * Created by michelefuschillo on 14/11/17.
 */

const initialState = {
  logged: false,
  userid: '',
  jwt: '',
  itemName: 'home',
  name: '',
  postMessage: {},
  job: '',
  settingType: '',
  extra: {id: '', public_id: ''},
  settingExtra: {dom: ''},
  datasource: {type: '',}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return Object.assign({}, state, {
        jwt: action.payload.jwt,
        userid: action.payload.userid,
        logged: action.payload.logged,
        name: action.payload.name,
        role: action.payload.role,
        group: action.payload.group,
        fsname: action.payload.fsname,
        resType: action.payload.resType,
        appname: action.payload.appname,
        uri: action.payload.uri,
        resourceid: action.payload.resourceid,
        domain: action.payload.domain,
        metadata:action.payload.metadata,
        itemname: action.payload.itemname,
        publicStatusExtRes: action.payload.publicStatusExtRes
          
    })
    case 'LOGOUT':
      state = initialState
      return Object.assign({}, state, {})
    case 'ACTIVEMENUITEM':
      return Object.assign({}, state, {itemName: action.payload.itemName})
    case 'NAME':
      return Object.assign({}, state, {name: action.payload.name})
    case 'FOCUSOBJ':
      return Object.assign({}, state, {focusobj:{type:action.payload.type, obj:action.payload.focusobj, action:action.payload.action}})
    case 'MODALTYPE':
      return Object.assign({}, state, {
        modalType: action.payload.modalType,
        subType: action.payload.subType,
        extra: action.payload.extra,
        contexttoreq:action.payload.contexttoreq
      })
    case 'SETTINGTYPE':
      return Object.assign({}, state, {
        settingType: action.payload.settingType,
        settingExtra: action.payload.settingExtra
      })
    case 'SAVEDATASOURCE':
      return Object.assign({}, state, {type: {type: action.payload.type, datasource: action.payload.datasource}})
    case 'TIME':
      return Object.assign({}, state, {time: action.payload.ctime})
    case 'COMPONENTINFO':
      return Object.assign({}, state, {componentinfo:{videoduration:action.payload.videoduration}})
      case 'LISTACTION':
        return Object.assign({}, state, {hotspotlist:action.payload.hotspotlist })
        case 'LINKEVENT':
        return Object.assign({}, state, {linkevent:action.payload.linkevent })
        case 'AUTOSAVING':
          return Object.assign({}, state, {result:action.payload.result })
    default:
      return state
  }
}

export default {
  reducer: reducer
}
