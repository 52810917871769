/* Created by obbiettivo on 29/06/18.
 */
import React, { Component } from 'react';
import { Checkbox, Form, Dropdown, Label, Divider, Table, Input, Radio } from 'semantic-ui-react';
import '../../../../css/config.css';
import { setfocusobj } from '../../../../redux/actions/ObjectAction';
import AccurancyPosition from '../AccurancyPos.jsx';


const optionsOntopHor = [
  { key: 1, text: 'left', value: 'left' },
  { key: 2, text: 'center', value: 'center' },
  { key: 3, text: 'right', value: 'right' }
];

const optionsOntopVer = [
  { key: 1, text: 'top', value: 'top' },
  { key: 2, text: 'middle', value: 'middle' },
  { key: 3, text: 'down', value: 'bottom' }
];

const optionsOnblink = [
  { key: 1, text: 'fast', value: 'fast' },
  { key: 2, text: 'medium', value: 'medium' },
  { key: 3, text: 'slow', value: 'slow' }
];

let unsubscribe;
class AudioViewOptions extends Component {
  constructor(props) {
    super(props);

    var updated = this.props.store.getState();

    this.state = {
      previousState: updated,
      none: updated.reducer.focusobj.obj.properties.vo.none,
      ontop: updated.reducer.focusobj.obj.properties.vo.ontop,
      ontopoptionshor: updated.reducer.focusobj.obj.properties.vo.ontopoptionshor ? updated.reducer.focusobj.obj.properties.vo.ontopoptionshor : 'center',
      ontopoptionsver: updated.reducer.focusobj.obj.properties.vo.ontopoptionsver ? updated.reducer.focusobj.obj.properties.vo.ontopoptionsver : 'middle',
      fov: updated.reducer.focusobj.obj.properties.vo.fov,
      onblink: updated.reducer.focusobj.obj.properties.vo.onblink,
      onblinkduration: updated.reducer.focusobj.obj.properties.vo.onblinkduration ? updated.reducer.focusobj.obj.properties.vo.onblinkduration : 'medium',
      showhotspot: updated.reducer.focusobj.obj.properties.vo.showhotspot,
      valuerange: updated.reducer.focusobj.obj.properties.vo.valuerange,
      free2d: updated.reducer.focusobj.obj.properties.vo.free2d ? updated.reducer.focusobj.obj.properties.vo.free2d : false,
      y2d: updated.reducer.focusobj.obj.properties.vo.y2d ? updated.reducer.focusobj.obj.properties.vo.y2d : 10,
      x2d: updated.reducer.focusobj.obj.properties.vo.x2d ? updated.reducer.focusobj.obj.properties.vo.x2d : 10,
      depth: updated.reducer.focusobj.obj.properties.vo.depth ? updated.reducer.focusobj.obj.properties.vo.depth : 0,
      width: updated.reducer.focusobj.obj.properties.width,
      height: updated.reducer.focusobj.obj.properties.height


    };
    this.updateState = this.updateState.bind(this);
    this.isNumeric = this.isNumeric.bind(this);
    this.moveObj = this.moveObj.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('audio', focusobj, {}));
    unsubscribe();
  }

  moveObj(dir, vel) {
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    console.log(dir + vel);
    this.props.dispatch(setfocusobj('audio', focusobj, { "dir": dir, "vel": vel, "type": "free2d" }));
    unsubscribe();

  }
  inputHandler(event, data) {
    console.log("iH");
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);

    focusobj.properties.vo.depth = data.value;

    this.props.dispatch(setfocusobj('audio', focusobj, {}));

    unsubscribe();
  }


  updateState() {
    console.log("updateState: start");

    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'audio') {
      console.log('showhotspot', updated.reducer.focusobj.obj.properties.vo.showhotspot);
      this.setState({
        previousState: updated,
        none: updated.reducer.focusobj.obj.properties.vo.none,
        ontop: updated.reducer.focusobj.obj.properties.vo.ontop,
        ontopoptionshor: updated.reducer.focusobj.obj.properties.vo.ontopoptionshor,
        ontopoptionsver: updated.reducer.focusobj.obj.properties.vo.ontopoptionsver,
        fov: updated.reducer.focusobj.obj.properties.vo.fov,
        onblink: updated.reducer.focusobj.obj.properties.vo.onblink,
        onblinkduration: updated.reducer.focusobj.obj.properties.vo.onblinkduration,
        showhotspot: updated.reducer.focusobj.obj.properties.vo.showhotspot,
        free2d: updated.reducer.focusobj.obj.properties.vo.free2d ? updated.reducer.focusobj.obj.properties.vo.free2d : false,
        valuerange: updated.reducer.focusobj.obj.properties.vo.valuerange,
        y2d: updated.reducer.focusobj.obj.properties.vo.y2d ? updated.reducer.focusobj.obj.properties.vo.y2d : 10,
        x2d: updated.reducer.focusobj.obj.properties.vo.x2d ? updated.reducer.focusobj.obj.properties.vo.x2d : 10,
        depth: updated.reducer.focusobj.obj.properties.vo.depth ? updated.reducer.focusobj.obj.properties.vo.depth : 0,
        width: updated.reducer.focusobj.obj.properties.width,
       height: updated.reducer.focusobj.obj.properties.height,

      });
      this.props.parent.waitingAnswer();
    }
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  optionsHandler = (event, data) => {

    console.log('optionsHandler: Start...');

    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);

    console.log('optionsHandler: data.name: ', data.name);

    switch (data.name) {

      case 'none':
        focusobj.properties.vo.none = data.checked;
        focusobj.properties.vo.ontop = false;
        focusobj.properties.vo.fov = false;
        focusobj.properties.vo.free2d = false;
        this.props.dispatch(setfocusobj('audio', focusobj, {}));
        break;

      case 'ontop':
        if (data.checked) {
          focusobj.properties.vo.none = false;
          focusobj.properties.vo.fov = false;
          focusobj.properties.vo.ontopoptionshor = focusobj.properties.vo.ontopoptionshor ? focusobj.properties.vo.ontopoptionshor : 'left';
          focusobj.properties.vo.ontopoptionsver = focusobj.properties.vo.ontopoptionsver ? focusobj.properties.vo.ontopoptionsver : 'top';
        }
        focusobj.properties.vo.ontop = data.checked;
        this.props.dispatch(setfocusobj('audio', focusobj, {}));
        break;
      case 'free2d':
        focusobj.properties.vo.free2d = data.checked;
        this.props.dispatch(setfocusobj('audio', focusobj, {}));
        break;
      case 'fov':
        if (data.checked) {
          // focusobj.properties.vo.none = false;
          focusobj.properties.vo.ontop = false;
        }
        focusobj.properties.vo.fov = data.checked;
        this.props.dispatch(setfocusobj('audio', focusobj, {}));
        break;

      case 'blink':
        if (data.checked) {
          focusobj.properties.vo.none = false;
          focusobj.properties.vo.fov = false;
          focusobj.properties.vo.ontop = false;
          focusobj.properties.vo.onblinkduration = focusobj.properties.vo.onblinkduration ? focusobj.properties.vo.onblinkduration : 'medium';
        }
        focusobj.properties.vo.onblink = data.checked;
        this.props.dispatch(setfocusobj('audio', focusobj, {}));
        break;

      case 'showhotspot':
        focusobj.properties.vo.showhotspot = data.checked;
        this.props.dispatch(setfocusobj('audio', focusobj, {}));
        break;

      case 'rangeop':
        var esito = this.isNumeric(data.value);
        if (esito == true) {
          var myValue = Number(data.value);
          console.log(myValue)
          myValue = myValue.toFixed(1);
          console.log(myValue)

          if (myValue >= 0 && myValue <= 1) {
            console.log('optionsHandler: valore digitato: ', myValue);
            focusobj.properties.vo.valuerange = myValue;
            this.props.dispatch(setfocusobj('audio', focusobj, {}));
          }
        }
        break;

      default:
        console.log('optionsHandler: case default');
        break;
    }
    unsubscribe();
    console.log('optionsHandler: End.');

  }

  ontopoptionhandle = (target, data) => {
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);

    switch (data.name) {

      case 'hor':
        focusobj.properties.vo.ontopoptionshor = data.value;
        focusobj.properties.vo.ontop = true;
        this.props.dispatch(setfocusobj('audio', focusobj, {}));
        break;

      case 'ver':
        focusobj.properties.vo.ontopoptionsver = data.value;
        focusobj.properties.vo.ontop = true;
        this.props.dispatch(setfocusobj('audio', focusobj, {}));
        break;

      case 'blink':
        focusobj.properties.vo.onblinkduration = data.value;
        focusobj.properties.vo.onblink = true;
        this.props.dispatch(setfocusobj('audio', focusobj, {}));
        break;

      default:
        break;

    }

    unsubscribe();

  }

  sizehandler = (event, data) => {
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    let img = document.createElement('img');
    img.src = focusobj.properties.cover.length > 0 ? focusobj.properties.cover : "/assets/images/play-3-64.png"
      var self = this;
      img.onload = function () {
        var aspectRatio = (img.width / img.height);
        focusobj.properties.width = data.value;
        var newheight = (data.value / aspectRatio);
        focusobj.properties.height = newheight;
        self.props.dispatch(setfocusobj('audio', focusobj, {}));
        unsubscribe();
      }
  }

  render() {
    return (
      <Form>

        <Form.Field>
          <div>
          Alpha {this.state.valuerange}
          </div>
        <div>
            <Input name='rangeop' type='range' max={1} min={0} step={0.1} value={this.state.valuerange} onChange={this.optionsHandler} style={{ height: 'auto', width: '90%' }}/>
          </div>
          <div>
            <Input name='rangeop' max={1} min={0} step={0.1} value={this.state.valuerange} onChange={this.optionsHandler} style={{ height: 'auto', width: '90%' }}  />
          </div>
          <Divider/>
          <div>
          Size {this.state.width}
          </div>
          <div>
            <Input name='width' type='range' min={1} max={500} step={1} value={this.state.width} onChange={this.sizehandler}
              style={{ height: 'auto', width: '90%' }} />
          </div>
        <div>
        <Input name='width' min={1} max={500} step={1} value={this.state.width} onChange={this.sizehandler}
                                        style={{ height: 'auto', width: '90%' }} />
        </div>
        </Form.Field>


        <hr />
        <Form.Field>
          <Checkbox className="check" label='show hotspot' name='showhotspot' checked={this.state.showhotspot} onChange={this.optionsHandler} />
        </Form.Field>
        <Form.Field>
          <Radio className="check" label='on background' name='none' checked={this.state.none} onChange={this.optionsHandler} />
        </Form.Field>
        {/* {this.state.none ?
          <Form.Field>
            <Checkbox style={{ "marginLeft": "10px" }} className="check" label='forced view' name='fov' checked={this.state.fov} onChange={this.optionsHandler} />
          </Form.Field> : ""} */}
        <Form.Field>
          <Radio className="check" label='fixed on screen' name='ontop' checked={this.state.ontop} onChange={this.optionsHandler} />
        </Form.Field>


        {this.state.ontop ? <div>
          <Checkbox style={{ "marginLeft": "10px" }} className="check" label='Free positioning' name='free2d' checked={this.state.free2d} disabled onChange={this.optionsHandler} />
          <br />
          <Input type="range" name='depthvalue' value={this.state.depth} min={0} max={100} step={1} onChange={this.inputHandler} style={{ height: 'auto', width: '10vw' }} />
          <br />
          <Input type='number' min={0} max={100} step={1} value={this.state.depth} onChange={this.inputHandler} size='small'
            style={{ height: 'auto', width: '7vw' }} /><span>z-index</span>
          {!this.state.free2d ?
            <div>
              <Form.Field inline>
                <Label basic color='black' pointing='right'>
                  Hor alignment
                </Label>
                <Dropdown
                  compact
                  name='hor'
                  onChange={this.ontopoptionhandle}
                  options={optionsOntopHor}
                  selection
                  value={this.state.ontopoptionshor}
                />
              </Form.Field>
              <Form.Field inline>
                <Label basic color='black' pointing='right'>
                  Ver alignment
                </Label>
                <Dropdown
                  compact
                  name='ver'
                  onChange={this.ontopoptionhandle}
                  options={optionsOntopVer}
                  selection
                  value={this.state.ontopoptionsver}
                  upward
                />
              </Form.Field>
              <Divider hidden />
            </div> :
            <AccurancyPosition callbackParent={this.moveObj}> </AccurancyPosition>
          }
        </div> : ''}
      </Form>
    )
  }
}

export default AudioViewOptions;
