/**
 * Created by michelefuschillo on 01/02/18.
 */
import React, { Component } from 'react';
import { List, Icon, Input, Button } from 'semantic-ui-react';
import { setfocusobj } from '../../../../redux/actions/ObjectAction'
import { launchModal } from '../../../../redux/actions/ModalAction'
import * as services from '../../../../config/services.json';
import { getResourceDataForLanguage } from './../utils';

let unsubscribe;
class AudioCover extends Component {
    constructor(props) {
        super(props);
        this.updateState = this.updateState.bind(this);
        var updated = this.props.store.getState();
        this.state = {
            previousState: updated,
            cover: updated.reducer.focusobj.obj.properties.cover,
            covername: updated.reducer.focusobj.obj.properties.covername,
            width: updated.reducer.focusobj.obj.properties.width,
            height: updated.reducer.focusobj.obj.properties.height,
        };
    }

    componentWillReceiveProps(props) {
        var unsubscribe = this.props.store.subscribe(this.updateState);
        var updated = this.props.store.getState();
        var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
        this.props.dispatch(setfocusobj('audio', focusobj, {}));
        unsubscribe();
    }

    updateState() {
        var updated = this.props.store.getState();
        if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'audio') {
            this.setState({
                previousState: updated,
                cover: updated.reducer.focusobj.obj.properties.cover,
                covername: updated.reducer.focusobj.obj.properties.covername,
                width: updated.reducer.focusobj.obj.properties.width,
                height: updated.reducer.focusobj.obj.properties.width,
            });
            this.props.parent.waitingAnswer();
        } else {
            unsubscribe(); //the state update will be made into another component (asynchronous mode)
        }
    }

    openUploadModal = (event, data) => {
        //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
        this.setState({ macrocategory: 'image' });

        /**NUOVA MOADLITà ASSET 07-12-2021 */
        window.parent.postMessage({
            type: "CHILDMSG-ANNOTATOR", payload: {
                msg: "upload",
                typeres: "audio"
            }
        }, "*");
        window.addEventListener('message', this.postMessageHandler);
        //unsubscribe = this.props.store.subscribe(this.updateState);
        //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE', 'audiocover'));
        //this.setState({ macrocategory: 'image' });
    }

    openListModal = (event, data) => {
        //unsubscribe = this.props.store.subscribe(this.updateState);
        //this.props.dispatch(launchModal('SETTING_MODAL', 'AUDIO'));
        //this.setState({ macrocategory: 'audio' });
        //unsubscribe = this.props.store.subscribe(this.updateState);
        //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
        this.setState({ macrocategory: 'audio' });
        /**NUOVA MOADLITà ASSET 15-12-2021 */
        window.parent.postMessage({
            type: "CHILDMSG-ANNOTATOR", payload: {
                msg: "imglist"
            }
        }, "*");
        window.addEventListener('message', this.postMessageHandler);
    }

    /**NUOVA MOADLITà ASSET 15-12-2021 */
    postMessageHandler = (event) => {
        if (event.origin === services.ASSETDOMAIN) { //FROM ASSETLOGIN
            switch (event.data.type) {
                case "NEWRESOURCE":
                    //console.log(event.data.payload);
                    unsubscribe = this.props.store.subscribe(this.updateState);
                    var focusobj = Object.assign({}, this.props.reducer.focusobj.obj);
                    focusobj.properties.cover = this.coverUrl(event.data.payload);
                    focusobj.properties.covername = event.data.payload.name;
                    // focusobj.properties.resourceid = event.data.payload._id;
                    this.props.dispatch(setfocusobj("audio", focusobj, { 'type': 'audio', 'action': 'audio' }));
                    window.removeEventListener('message', this.postMessageHandler);
                    //this.setState({ childmessage: event.data.payload.msg });
                    break;
                default: break;
            }
        }
    }

    coverUrl = (resource) => {
        let ser =
            services.ASSETBACKEND + services.singleresourcemanager_GET_AUTH.replace(
                "{APPNAME}",
                this.props.reducer.appname
            );
        ser = ser.replace("{DOMAIN}", this.props.reducer.domain);
        ser = ser.replace("{RESOURCEID}", resource._id);
        ser = ser.replace("{USERID}", this.props.reducer.userid);
        ser = ser.replace("{COVER}", false);
        ser = ser.replace("{MEDIARES}", "first");
        ser = ser.replace("{MEDIALAN}", getResourceDataForLanguage(resource));
        ser = ser + "&t=" + new Date().getTime();
        return ser;
    };

    cancelCoverSelected = () => {
        unsubscribe = this.props.store.subscribe(this.updateState);
        var updated = this.props.store.getState();
        var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
        focusobj.properties.cover = "";
        focusobj.properties.covername = "";
        this.props.dispatch(setfocusobj('audio', focusobj, {}));
        this.setState({ name: "" });
    }

    sizehandler = (event, data) => {
        unsubscribe = this.props.store.subscribe(this.updateState);
        var updated = this.props.store.getState();
        var focusobj = Object.assign({}, updated.reducer.focusobj.obj);

        let img = document.createElement('img');
        img.src = focusobj.properties.cover; // (*)
        var self = this;
        img.onload = function () {
            var aspectRatio = (img.width / img.height);
            focusobj.properties.width = data.value;
            var newheight = (data.value / aspectRatio);
            focusobj.properties.height = newheight;
            self.props.dispatch(setfocusobj('audio', focusobj, {}));
            unsubscribe();
        }
    }

    render() {
        return (
            <List className="componentOnIframeClass">
                <List.Item>
                    <List.Content>
                        <List.Description>
                            <List>
                                <List.Item>
                                    <Input size='mini'  disabled
                                        value={this.state.covername} placeholder='Add Audio cover...'
                                        action={!this.state.covername ?
                                            { icon: 'upload', circular: true, onClick: this.openUploadModal, name: "IMAGE" }
                                            :
                                            { icon: 'delete', circular: true, onClick: this.cancelCoverSelected, name: "IMAGE" }} />
                                    <Button icon onClick={this.openListModal}>
                                        <Icon style={{ padding: 0 }} name='list' />
                                    </Button>
                                    {/* <Icon name='list' size="small" onClick={this.openListModal} /> */}
                                </List.Item>
                                {/*<List.Item>
                                    <List.Header> size -  {this.state.width}</List.Header>
                                    <Input name='width' type='range' min={1} max={500} step={1} value={this.state.width} onChange={this.sizehandler}
                                        style={{ height: 'auto', width: '90%' }} />
                                </List.Item>
                                <List.Item>
                                    <Input name='width' min={1} max={500} step={1} value={this.state.width} onChange={this.sizehandler}
                                        style={{ height: 'auto', width: '90%' }} />
                                        </List.Item>*/}
                            </List>
                        </List.Description>
                    </List.Content>
                </List.Item>
            </List>
        )
    }
}

export default AudioCover;
