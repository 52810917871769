/** Created by michelefuschillo on 31/01/18. */
import React, { Component } from 'react';
import { Button, Modal, Header, Image, Popup, Grid, Label, Dropdown } from 'semantic-ui-react';
import JumpModal from "./actionModal/jumpmodal.jsx";
import LinkModal from "./actionModal/linkmodal.jsx";
import Hdlinkmodal from "./actionModal/hdlinkmodal.jsx";
import DHSModal from "./actionModal/driveHotSpotModal.jsx";
import SwitchBackgroundResource from "./actionModal/switchBackgroundResource.jsx";
import { setfocusobj } from '../../../redux/actions/ObjectAction';
import { setLinkEvent } from "../../../redux/actions/LinkEvent";

let unsubscribe

class SwitcherAction extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      action: "",
      jumpModal: false,
      linkModal: false,
      DHSModalFlag: false,
      DHSModalFlagModify: false,
      hdlinkmodal: false,
      switchbackgroundModal: false,
      events: []
    });

    this.onHandlerAction = this.onHandlerAction.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.childFunction = this.childFunction.bind(this);
    this.saveConfiguration = this.saveConfiguration.bind(this);
    this.savingCallback = this.savingCallback.bind(this);
    this.savingCallbackModify = this.savingCallbackModify.bind(this);


  }

  componentWillReceiveProps(props) {

    //  var updated = this.props.store.getState().reducer.group;
    // console.log(updated.reducer.group);
    this.setState({ events: this.props.events });
    // var unsubscribe = this.props.store.subscribe(this.updateState);
    // var updated = this.props.store.getState();
    // var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    // this.props.dispatch(setfocusobj('image', focusobj,{'type':'image','action':'image'}));
    // unsubscribe();
  }

  onHandlerAction(e, data) {
    switch (data.name) {
      case 'jump':
        this.setState({ jumpModal: true });
        break;
      case 'link':
        this.setState({ linkModal: true });
        break;
      case 'hdlink':
        this.setState({ hdlinkModal: true });
        break;
      case 'drivehotspot':
        this.saveConfiguration(data.name);
        // this.setState({DHSModalFlag:true});
        break;
      case 'drivehotspotModify':
        this.saveConfiguration(data.name);
        // this.setState({DHSModalFlag:true});
        break;
      case 'switchbackground':
        this.setState({ switchbackgroundModal: true });
        break;
      default: break;
    }


  }

  closeModal(e, data) {
    this.setState({ jumpModal: false, linkModal: false, hdlinkModal: false, DHSModalFlag: false, DHSModalFlagModify: false, switchbackgroundModal: false });
  }

  childFunction(tStart, idConfig, idVideo, itemSelect, actionOptions) {
    console.log("itemselect")
    console.log(itemSelect)
    this.props.handleRes(tStart, idConfig, idVideo, this.props.action, itemSelect, actionOptions);
    this.props.store.dispatch(setLinkEvent({ path: "", name: "" }));
    this.setState({ jumpModal: false, linkModal: false, hdlinkModal: false, DHSModalFlag: false, DHSModalFlagModify: false, switchbackgroundModal: false });
  }

  saveConfiguration(type) {
    console.log("richiesta dati ")
    var updated = this.props.store.getState();
    if (type === "drivehotspot") {
      unsubscribe = this.props.store.subscribe(this.savingCallback);
    } else if (type === "drivehotspotModify") {
      unsubscribe = this.props.store.subscribe(this.savingCallbackModify);
    }
    this.props.store.dispatch(setfocusobj(updated.reducer.focusobj.type, updated.reducer.focusobj.obj, { 'type': 'autosaving', 'action': 'autosaving' }));
    this.props.parent.waitingAnswer();
    //mettila dopo this.setState({DHSModalFlag:true});

  }
  savingCallback() {
    console.log("savingCallback")
    var updated = this.props.store.getState();
    // console.log(updated.reducer.result)
    this.setState({ DHSModalFlag: true });
    unsubscribe();


  }
  savingCallbackModify() {
    console.log("savingCallback")
    var updated = this.props.store.getState();
    // console.log(updated.reducer.result)
    this.setState({ DHSModalFlagModify: true });
    unsubscribe();


  }






  render() {
    console.log(this.state.events)
    console.log(this.props.action)
    console.log(this.props.event)
    var j = [];
    if (this.state.events) {
      j = Object.values(this.state.events);
    }

    return (
      <div id='switcher-root' style={{ height: '10vh' }} >{
        this.props.action === "playV" ? <p>playV</p> :
          this.props.action === "playA" ? <p>playA</p> :
            this.props.action === "jump" ?
              <div>
                <div>

                  {j.map((item, index) =>
                    <>
                      {item.evenType === this.props.event ?
                        <Grid  >
                          {/* <Grid.Column width={3}>
            { this.state.events[index].itemSelect ?
              <Image size='tiny' style={{display: 'inline-block',marginTop: '-3vh'}} src={this.state.events[index].itemSelect.cover} floated='left' alt="..."
              title={this.state.events[index].itemSelect.key}/>:<span/>}
              </Grid.Column> */}
                          <Grid.Column width={6}>

                            {/* <Label  size='tiny' >{this.state.events[index].idVideo}</Label>  
                        <Label  size='tiny' style={{display: 'inline-block',marginTop: '-3vh'}}>{this.state.events[index].idConfig}</Label><br/>
                        <Label  size='tiny' >{this.state.events[index].actionOptions ? [(this.state.events[index].actionOptions.azimut ? this.state.events[index].actionOptions.azimut:"NA" )] : "NA"}</Label>  
                        <Label  size='tiny'>{this.state.events[index].actionOptions ? [(this.state.events[index].actionOptions.inclination ?this.state.events[index].actionOptions.inclination:"NA" )] : "NA"}</Label> */}



                            <Popup flowing hoverable position='top center' size='mini'
                              trigger={<Button basic circular icon="info" size='mini' floated='left'></Button>}>
                              <strong>Video selected:        </strong>{this.state.events[index].idVideo} <br />
                              <strong>Configuration selected:</strong>{this.state.events[index].idConfig}   <br />
                              <strong>Time to start:         </strong>{this.state.events[index].tStart}  <br />
                              <strong>Duration:              </strong>{this.state.events[index].itemSelect.duration}  <br />
                              <strong>Date:                  </strong>{this.state.events[index].itemSelect.modifieddate}<br />
                              <strong>Orientation:             </strong>{this.state.events[index].actionOptions ? this.state.events[index].actionOptions.initialDegree : "Default"}<br />
                              <strong>Jump type:             </strong>{this.state.events[index].actionOptions ? [(this.state.events[index].actionOptions.synchronized ? "Synchronized" : "Basic")] : "Basic"}<br />
                              <strong>Azimut:             </strong>{this.state.events[index].actionOptions ? [(this.state.events[index].actionOptions.azimut ? this.state.events[index].actionOptions.azimut : "NA")] : "NA"}<br />
                              <strong>Inclination:             </strong>{this.state.events[index].actionOptions ? [(this.state.events[index].actionOptions.inclination ? this.state.events[index].actionOptions.inclination : "NA")] : "NA"}<br />
                            </Popup>
                          </Grid.Column>
                        </Grid>

                        :
                        <span />
                      }
                    </>
                  )}
                </div>
                <div >

                  <Popup flowing hoverable position='top center' size='mini'
                    trigger={<Button style={{ display: 'inline-block' }} name='jump' basic circular icon="upload" size='mini' value={this.props.action} floated='right' onClick={this.onHandlerAction}></Button>}>
                    <p id='section-edit-card-link'>Import</p>
                  </Popup>

                  <Modal open={this.state.jumpModal} size={'large'} closeIcon={true} onClose={this.closeModal}>
                    <Modal.Content scrolling>
                      <Header as='h1'>
                        <Image id='image-logo-modal' src={this.props.store.getState().reducer.group === "IDEHA" ? '/assets/images/logo_H.png' : '/assets/images/logo.png'} avatar floated='left' alt="H360" />
                        <Header.Subheader>Choose the video to jump</Header.Subheader>
                      </Header>
                      <JumpModal store={this.props.store} callbackFather={this.childFunction} ></JumpModal>
                    </Modal.Content>
                  </Modal>
                </div>


              </div>

              :
              this.props.action === "fov" ? <p>fov</p> :
                this.props.action === "switchbackground" ?
                  <div>
                    <div>
                      {j.map((item, index) =>
                        <div>
                          {item.evenType === this.props.event ?
                            <Grid style={{ marginTop: '2px' }} >
                              <Grid.Column width={13}>
                                {this.state.events[index].itemSelect ?
                                  <div>
                                    <Popup flowing hoverable position='top center' size='mini'
                                      trigger={<Button basic circular icon="info" size='mini' floated='left'></Button>}>
                                      <strong>Resource to switch :        </strong>{typeof (this.state.events[index].itemSelect) === "string" ? this.state.events[index].itemSelect : this.state.events[index].itemSelect.name} <br />
                                      <strong>Type Resource :        </strong>{typeof (this.state.events[index].itemSelect) === "string" ? this.state.events[index].itemSelect : this.state.events[index].itemSelect.type} <br />
                                    </Popup>
                                  </div>
                                  :
                                  <span />
                                }
                              </Grid.Column>
                            </Grid>
                            :
                            <span />
                          }
                        </div>
                      )}
                    </div>
                    <div>
                      <Popup flowing hoverable position='top center' size='mini'
                        trigger={<Button name='switchbackground' basic circular icon="exchange" size='mini' value={this.props.action} floated='right' onClick={this.onHandlerAction}></Button>}>
                        <p id='section-edit-card-link'>Switch Background Resoruce</p>
                      </Popup>

                      <Modal open={this.state.switchbackgroundModal} size={'large'} closeIcon={true} onClose={this.closeModal}>
                        <Modal.Content scrolling>
                          <Header as='h1'>
                            <Image id='image-logo-modal' src={this.props.store.getState().reducer.group === "IDEHA" ? '/assets/images/logo_H.png' : '/assets/images/logo.png'} avatar floated='left' alt="H360" />
                            <Header.Subheader>Selected Resource to switch</Header.Subheader>
                          </Header>

                          {/* <LinkModal store={this.props.store} callbackFather={this.childFunction}></LinkModal> */}
                          <SwitchBackgroundResource store={this.props.store} callbackFather={this.childFunction}></SwitchBackgroundResource>
                        </Modal.Content>
                      </Modal>
                    </div>
                  </div>
                  :
                  this.props.action === "hdlink" ?
                    <div>
                      <div>

                        {j.map((item, index) =>
                          <div>
                            {item.evenType === this.props.event ?
                              <Grid style={{ marginTop: '2px' }} >
                                <Grid.Column width={13}>
                                  {this.state.events[index].itemSelect ?
                                    <div>
                                      <Label size='small' style={{ display: 'inline-block' }} floated='left'>Hd Link: {this.state.events[index].itemSelect}</Label> <br />
                                      {/* { this.state.events[index].actionOptions ?
                            <Label size='small' style={{display: 'inline-block'}}  floated='left'>Mode : {this.state.events[index].actionOptions.linkAction}</Label>:
                            <span/>
                          } */}
                                    </div>
                                    :
                                    <span />
                                  }
                                </Grid.Column>
                              </Grid>

                              :
                              <span />
                            }
                          </div>
                        )}
                      </div>
                      <div>
                        <Popup flowing hoverable position='top center' size='mini'
                          trigger={<Button name='hdlink' basic circular icon="linkify" size='mini' value={this.props.action} floated='right' onClick={this.onHandlerAction}></Button>}>
                          <p id='section-edit-card-link'>HD link setting</p>
                        </Popup>

                        <Modal open={this.state.hdlinkModal} size={'large'} closeIcon={true} onClose={this.closeModal}>
                          <Modal.Content scrolling>
                            <Header as='h1'>
                              <Image id='image-logo-modal' src={this.props.store.getState().reducer.group === "IDEHA" ? '/assets/images/logo_H.png' : '/assets/images/logo.png'} avatar floated='left' alt="H360" />
                              <Header.Subheader>Insert HD background link</Header.Subheader>
                            </Header>
                            <Hdlinkmodal store={this.props.store} callbackFather={this.childFunction}></Hdlinkmodal>
                          </Modal.Content>
                        </Modal>
                      </div>
                    </div>
                    :
                    this.props.action === "link" ?

                      <div>
                        <div>

                          {j.map((item, index) =>
                            <div>
                              {item.evenType === this.props.event ?
                                <Grid style={{ marginTop: '2px' }} >
                                  <Grid.Column width={13}>
                                    {this.state.events[index].itemSelect ?
                                      <div>

                                        <Popup flowing hoverable position='top center' size='mini'
                                          trigger={<Button basic circular icon="info" size='mini' floated='left'></Button>}>
                                          <strong>Resource to link :        </strong>{typeof (this.state.events[index].itemSelect) === "string" ? this.state.events[index].itemSelect : this.state.events[index].itemSelect.name} <br />
                                          {this.state.events[index].actionOptions ?
                                            <><strong>Mode:         </strong> {this.state.events[index].actionOptions.linkAction}</>

                                            :
                                            <span />
                                          }
                                        </Popup>



                                        {/* <Label size='small' style={{display: 'inline-block'}}  floated='left'>Target Link: {this.state.events[index].itemSelect}</Label> <br/> */}
                                        {/* { this.state.events[index].actionOptions ?
                                        <Label size='small' style={{display: 'inline-block'}}  floated='left'>Mode : {this.state.events[index].actionOptions.linkAction}</Label>:
                                        <span/>
                                      } */}
                                      </div>
                                      :
                                      <span />
                                    }
                                  </Grid.Column>
                                </Grid>

                                :
                                <span />
                              }
                            </div>
                          )}
                        </div>
                        <div>
                          <Popup flowing hoverable position='top center' size='mini'
                            trigger={<Button name='link' basic circular icon="linkify" size='mini' value={this.props.action} floated='right' onClick={this.onHandlerAction}></Button>}>
                            <p id='section-edit-card-link'>Link setting</p>
                          </Popup>

                          <Modal open={this.state.linkModal} size={'large'} closeIcon={true} onClose={this.closeModal}>
                            <Modal.Content scrolling>
                              <Header as='h1'>
                                <Image id='image-logo-modal' src={this.props.store.getState().reducer.group === "IDEHA" ? '/assets/images/logo_H.png' : '/assets/images/logo.png'} avatar floated='left' alt="H360" />
                                <Header.Subheader>Insert link</Header.Subheader>
                              </Header>
                              <LinkModal store={this.props.store} callbackFather={this.childFunction}></LinkModal>
                            </Modal.Content>
                          </Modal>
                        </div>

                      </div> :
                      this.props.action === "playVM" ? <p>playVM</p> :
                        this.props.action === "playAM" ? <p>playAM</p> :
                          this.props.action === "drivehotspot" ?
                            <div style={{ marginTop: '1vh' }}>


                              {j.length !== 0 ?
                                <div>
                                  {j.map((item, index) =>
                                    <div>
                                      {item.evenType === this.props.event ?
                                        <div>
                                          <Dropdown text='HotSpots Selected' >
                                            <Dropdown.Menu>
                                              {item.itemSelect.map((item1, index1) =>
                                                <Dropdown.Item text={item1.name} />
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>

                                          <Popup flowing hoverable position='top center' size='mini'
                                            trigger={<Button name='drivehotspotModify' basic circular icon="reply all" size='mini' value={this.props.action} floated='right' onClick={this.onHandlerAction}></Button>}>
                                            <p id='section-edit-card-link'>Modify Drive HotSpot to....</p>
                                          </Popup>

                                          <Modal open={this.state.DHSModalFlagModify} size={'large'} closeIcon={true} onClose={this.closeModal}>
                                            <Modal.Content scrolling>
                                              <Header as='h1'>
                                                <Image id='image-logo-modal' src={this.props.store.getState().reducer.group === "IDEHA" ? '/assets/images/logo_H.png' : '/assets/images/logo.png'} avatar floated='left' alt="H360" />
                                                <Header.Subheader>Choose the hotspots</Header.Subheader>
                                              </Header>
                                              <DHSModal store={this.props.store} parent={this.props.parent} callbackFather={this.childFunction} events={this.state.events[index]}></DHSModal>
                                            </Modal.Content>
                                          </Modal>
                                        </div>

                                        :
                                        <span />

                                      }
                                    </div>
                                  )}
                                </div>

                                :
                                <div>
                                  <Popup flowing hoverable position='top center' size='mini'
                                    trigger={<Button name='drivehotspot' basic circular icon="reply " size='mini' value={this.props.action} floated='right' onClick={this.onHandlerAction}></Button>}>
                                    <p id='section-edit-card-link'>Drive HotSpot to.....</p>
                                  </Popup>

                                  <Modal open={this.state.DHSModalFlag} size={'large'} closeIcon={true} onClose={this.closeModal}>
                                    <Modal.Content scrolling>
                                      <Header as='h1'>
                                        <Image id='image-logo-modal' src={this.props.store.getState().reducer.group === "IDEHA" ? '/assets/images/logo_H.png' : '/assets/images/logo.png'} avatar floated='left' alt="H360" />
                                        <Header.Subheader>Choose the hotspots</Header.Subheader>
                                      </Header>
                                      <DHSModal store={this.props.store} parent={this.props.parent} callbackFather={this.childFunction} ></DHSModal>
                                    </Modal.Content>
                                  </Modal>
                                </div>
                              }


                            </div>


                            :
                            <span />}
      </div>)
  }
}

export default SwitcherAction;
