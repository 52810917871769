/**
 * Created by michelefuschillo on 31/01/18.
 */
import React, { Component } from 'react';
import {Icon,Accordion} from 'semantic-ui-react';
import '../../../css/config.css';

class MenuSecond extends Component {
  constructor (props) {
    super(props);
    this.state=({accordionActiveIndex:-1})
    this.manageAccordion = this.manageAccordion.bind(this);
  }

  componentWillMount() {
    this.setState({accordionActiveIndex:-1});
  }

  componentWillReceiveProps(props) {
    this.setState({accordionActiveIndex: -1, accordionActiveIndexInternal: -1});
  }

  manageAccordion(event, data){
    if(data.index === this.state.accordionActiveIndex){
      this.setState({accordionActiveIndex:-1});
    }else{
      this.setState({accordionActiveIndex:data.index});
      this.props.callbackParent(data.name);
    }
  }

  /*
   *  OGGETTO 3D
   * */
  render () {
    return (
      <div>
      <div id='section-scrollable-menuAccScrollSx'>
      <Accordion styled>
        <Accordion.Title name='objcustomization' className="accordionTitle" active={this.state.accordionActiveIndex === 0} index={0} onClick={this.manageAccordion} position='left' >
          <Icon name='dropdown' id='section-config-IconAccordion'/>
          <Icon color="grey" name='options'/><i id='section-config-AccordionHotspot'>Text</i>
        </Accordion.Title>

        <Accordion.Content active={this.state.accordionActiveIndex  === 0}>
          <div id='textshapeaccordion'></div>

        </Accordion.Content>

        {/* <Accordion.Title name="textshapetag"  active={this.state.accordionActiveIndex  === 1} index={1} onClick={this.manageAccordion} position='left'>
          <Icon name='dropdown' id='section-config-IconAccordion'/>
          <Icon color="grey" name='tag'/><i id='section-config-AccordionHotspot'>Tags</i>
        </Accordion.Title>
        <Accordion.Content active={this.state.accordionActiveIndex  === 1}><div id='texttagshapeaccordion'></div></Accordion.Content> */}
      </Accordion>
      </div>
    </div>
    )
  }
}

export default MenuSecond;
