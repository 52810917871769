import React, { Component } from 'react';
import {Form,Divider,Radio,Checkbox,Input} from 'semantic-ui-react';
import { setfocusobj} from '../../../../redux/actions/ObjectAction'

let unsubscribe;
class TextlineViewOptions extends Component {
  constructor (props) {
    super(props);
    var updated = this.props.store.getState();
    this.state={
      previousState:updated,
      ontopscroll:updated.reducer.focusobj.obj.properties.vo.ontopscroll,
      onscrollver:updated.reducer.focusobj.obj.properties.vo.onscrollver ? updated.reducer.focusobj.obj.properties.vo.onscrollver : 'bottom',
      onscrolloptionstxt:updated.reducer.focusobj.obj.properties.vo.onscrolloptionstxt ? updated.reducer.focusobj.obj.properties.vo.onscrolloptionstxt : 'fix',
      onscrolliteration:updated.reducer.focusobj.obj.properties.vo.onscrolliteration ? updated.reducer.focusobj.obj.properties.vo.onscrolliteration : 'loop',
      onscrolldirection:updated.reducer.focusobj.obj.properties.vo.onscrolldirection ? updated.reducer.focusobj.obj.properties.vo.onscrolldirection : 'left',
      onscrollduration:updated.reducer.focusobj.obj.properties.vo.onscrollduration ? updated.reducer.focusobj.obj.properties.vo.onscrollduration : 'medium',
      showhotspot:updated.reducer.focusobj.obj.properties.vo.showhotspot,
      y2d: updated.reducer.focusobj.obj.properties.vo.y2d ?updated.reducer.focusobj.obj.properties.vo.y2d :10,
      x2d: updated.reducer.focusobj.obj.properties.vo.x2d ?updated.reducer.focusobj.obj.properties.vo.x2d :10,
      depth:updated.reducer.focusobj.obj.properties.vo.depth ? updated.reducer.focusobj.obj.properties.vo.depth : 0


    };
    this.updateState = this.updateState.bind(this);
    this.inputHandler=this.inputHandler.bind(this);

  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('textline', focusobj,{}));
    unsubscribe();
  }
  inputHandler (event, data)  {
      var unsubscribe = this.props.store.subscribe(this.updateState);
      var updated = this.props.store.getState();
      var focusobj = Object.assign({}, updated.reducer.focusobj.obj);

        focusobj.properties.vo.depth = data.value;

        this.props.dispatch(setfocusobj('textline', focusobj, {}));

      unsubscribe();
    }
  updateState(){
    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'textline') {
      this.setState({
        previousState:updated,
        ontopscroll:updated.reducer.focusobj.obj.properties.vo.ontopscroll,
        onscrollver:updated.reducer.focusobj.obj.properties.vo.onscrollver,
        onscrolloptionstxt:updated.reducer.focusobj.obj.properties.vo.onscrolloptionstxt,
        onscrolliteration:updated.reducer.focusobj.obj.properties.vo.onscrolliteration,
        onscrolldirection:updated.reducer.focusobj.obj.properties.vo.onscrolldirection,
        onscrollduration:updated.reducer.focusobj.obj.properties.vo.onscrollduration,
        y2d: updated.reducer.focusobj.obj.properties.vo.y2d ?updated.reducer.focusobj.obj.properties.vo.y2d :10,
        x2d: updated.reducer.focusobj.obj.properties.vo.x2d ?updated.reducer.focusobj.obj.properties.vo.x2d :10,
        depth:updated.reducer.focusobj.obj.properties.vo.depth ? updated.reducer.focusobj.obj.properties.vo.depth : 0,
        showhotspot:updated.reducer.focusobj.obj.properties.vo.showhotspot

      });
	  this.props.parent.waitingAnswer();
    }
  }


  ontopoptionhandle =(target,data) =>{
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    switch (data.name){
      case 'radioGroupPosition':
          focusobj.properties.vo.onscrollver = data.value;
          focusobj.properties.vo.ontop = true;
          this.props.dispatch(setfocusobj('textline', focusobj,{}));
      break;
      case 'radioGroupScroll':
          focusobj.properties.vo.onscrolloptionstxt = data.value;
          focusobj.properties.vo.ontop = true;
          this.props.dispatch(setfocusobj('textline', focusobj,{}));
      break;
      case 'radioGroupIter':
          focusobj.properties.vo.onscrolliteration = data.value;
          focusobj.properties.vo.ontop = true;
          this.props.dispatch(setfocusobj('textline', focusobj,{}));
      break;
      case 'radioGroupDir':
          focusobj.properties.vo.onscrolldirection = data.value;
          focusobj.properties.vo.ontop = true;
          this.props.dispatch(setfocusobj('textline', focusobj,{}));
      break;
      case 'radioGroupDur':
          focusobj.properties.vo.onscrollduration = data.value;
          focusobj.properties.vo.ontop = true;
          this.props.dispatch(setfocusobj('textline', focusobj,{}));
      break;
      case 'showhotspot':
        focusobj.properties.vo.showhotspot = data.checked;
        this.props.dispatch(setfocusobj('textline', focusobj,{}));
        break;
      default:
        break;
    }
    unsubscribe();
  }

  render () {
    return (
      <Form>
        <Form.Field>
        <Checkbox className="check" label='show hotspot' name='showhotspot' checked={this.state.showhotspot} onChange={this.ontopoptionhandle}/>
      </Form.Field>
      <Form.Field><span style={{fontSize: '1.5vh'}}>z-index positioning:</span></Form.Field>
      <Form.Field>
      <Input type="range" name='depthvalue'  value={this.state.depth}  min={0}   max={100}  step={1} onChange={this.inputHandler} style={{height: 'auto', width: '10vw'}}/>
      <br/>
      <Input type='number' min={0}   max={100} step={1} value={this.state.depth} onChange={this.inputHandler} size='small'
                                   style={{height:'auto', width:'7vw'}}/><span>z-index</span>
     </Form.Field>
        <Form.Field><span style={{fontSize: '1.5vh'}}>Position Bar:</span></Form.Field>
        <Form.Field>
          <Radio  label='top'   name='radioGroupPosition' value='top' checked={this.state.onscrollver === 'top'}    onChange={this.ontopoptionhandle} />
            <span style={{marginRight:'1vw'}}></span>
          <Radio label='bottom'  name='radioGroupPosition' value='bottom' checked={this.state.onscrollver === 'bottom'} onChange={this.ontopoptionhandle} />
        </Form.Field>
        <Divider/>
        <Form.Field><span style={{fontSize: '1.5vh'}}>Scroll Bar:</span></Form.Field>
        <Form.Field>
          <Radio  label='scroll' name='radioGroupScroll' value='scroll' checked={this.state.onscrolloptionstxt === 'scroll'} onChange={this.ontopoptionhandle} />
            <span style={{marginRight:'1vw'}}></span>
          <Radio  label='fix'    name='radioGroupScroll' value='fix'    checked={this.state.onscrolloptionstxt === 'fix'} onChange={this.ontopoptionhandle} />
        </Form.Field>

        <Form.Field>

        {this.state.onscrolloptionstxt === 'scroll'  ?
        <div>
        <Divider/>
        <Form.Field><span style={{fontSize: '1.5vh'}}>Iteration Bar:</span></Form.Field>
        <Form.Field>
          <Radio  label='oneshoot' name='radioGroupIter' value='oneshoot' checked={this.state.onscrolliteration === 'oneshoot'} onChange={this.ontopoptionhandle} />
            <span style={{marginRight:'1vw'}}></span>
          <Radio  label='loop'    name='radioGroupIter' value='loop'    checked={this.state.onscrolliteration === 'loop'} onChange={this.ontopoptionhandle} />
        </Form.Field>
        <Divider/>

        <Form.Field><span style={{fontSize: '1.5vh'}}>Direction Bar:</span></Form.Field>
        <Form.Field>
          <Radio  label='left'  name='radioGroupDir' value='left'  checked={this.state.onscrolldirection === 'left'} onChange={this.ontopoptionhandle} />
            <span style={{marginRight:'1vw'}}></span>
          <Radio  label='right' name='radioGroupDir' value='right' checked={this.state.onscrolldirection === 'right'} onChange={this.ontopoptionhandle} />
        </Form.Field>
        <Divider/>
        <Form.Field><span style={{fontSize: '1.5vh'}}>Duration Bar:</span></Form.Field>
        <Form.Field>
          <Radio  label='fast' name='radioGroupDur' value='fast'  checked={this.state.onscrollduration === 'fast'} onChange={this.ontopoptionhandle} />
          <span style={{marginRight:'0.5vw'}}></span>
          <Radio  label='medium' name='radioGroupDur' value='medium' checked={this.state.onscrollduration === 'medium'} onChange={this.ontopoptionhandle} />
          <span style={{marginRight:'0.5vw'}}></span>
          <Radio  label='low' name='radioGroupDur' value='low' checked={this.state.onscrollduration === 'low'} onChange={this.ontopoptionhandle} />
        </Form.Field>
         </div> : ''}
        </Form.Field>
      </Form>
    )
  }
}

export default TextlineViewOptions;
