/**
 * Created by obbiettivo on 10/09/18.
 */
import React, { Component } from "react";
import {
  Button,
  Divider,
  Icon,
  Dimmer,
  Loader,
  Header,
  Image,
  Table,
} from "semantic-ui-react";

import Axios from "axios";
import Services from "../../config/services.json";
import { transitions, positions, Provider as AlertContainer } from 'react-alert'
import "../../css/modal.css";
import LabelsBox from "../../config/labels.json";
import { launchModal } from "../../redux/actions/ModalAction";

class DeleteModalv2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      items: [],
      denied: false,
    };
    this.showAlert = this.showAlert.bind(this);
    this.deleteResource = this.deleteResource.bind(this);
    this.deleteCheck = this.deleteCheck.bind(this);
  }

  alertOptions = {
    offset: 14,
    position: "bottom center",
    theme: "dark",
    time: 5000,
    transition: "scale",
  };

  componentDidMount(){
    this.deleteCheck();
  }

  deleteCheck () {
    var self = this;
    self.setState({loading: true});
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.jwt;
    Axios.get(Services.domain + Services.upload_resource_GET_CHECKDEL_AUTH.replace('[id]',self.props.extra.id))
    .then(
      function (response) {
        if (response.status === 200) { //you can proceed to delete
          self.setState({denied: false, loading: false});
        }else  if (response.status === 202) { //the resource has used in one or more configuration (the output is an array that contains the objects) 
        //the resource has used in one or more configuration (the output is an array that contains the objects)
          self.setState({ items: response.data.message, denied:true, loading: false });
        }
      })
      .catch(function (error) {
        console.log('Errore in funzione [other.jsx/deleteResource]: ', error)
      })
  }

  deleteResource () {
    var self = this
    self.setState({loading: true});
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.jwt;
    Axios({
      method: 'delete',
      url: Services.domain + Services.upload_resource_DELETE_AUTH,
      data: {identifier: self.props.extra.id}
    }).then(
      function (response) {
        if (response.status === 204) {
          self.showAlert('Delete Successfully', 'success')
          self.setState({loading: false});
        }else { //the resource has used in one or more configuration (the output is an array that contains the objects) 
          self.showAlert('Delete is failed','');
          self.setState({loading: false});
        }
      })
      .catch(function (error) {
        self.setState({loading: false});
        console.log('Errore in funzione [other.jsx/deleteResource]: ', error)
      })
  }

  showAlert(message, result) {
    this.msg.show(message, {
      time: 1000,
      type: result,
      onOpen: () => {
        //console.log('hey')
      }, // callback that will be executed after this alert open
      onClose: () => {
        //console.log('closed')
        this.props.dispatch(launchModal("", "", ""));
      }, // callback that will be executed after this alert is removed
    });
  }

  render() {
    const j = Object.values(this.state.items);
    return (
      <div>
        <Header as="h3" inverted>
          <Icon name="question circle" color="grey" />
          <Header.Content>
            {this.state.denied ===false ? LabelsBox["deletemodalv2"].confirmtitle : LabelsBox["deletemodalv2"].deniedtitle }
          </Header.Content>
        </Header>
        <Divider />
        <Dimmer active={this.state.loading}>
        
          <Loader>Loading</Loader>{" "}
        </Dimmer>
        <Header as="h4" inverted>
          <Image
            id="image-logo-modal"
            src={
              this.props.reducer.group === "IDEHA"
                ? "/assets/images/logo_H.png"
                : "/assets/images/logo.png"
            }
            avatar
            floated="left"
            alt="H360"
          />
          <Header.Subheader>
          {this.state.denied ===false ? LabelsBox["deletemodalv2"].confirm : LabelsBox["deletemodalv2"].denied }
          {this.state.denied ===false ? <div> <strong>{this.props.extra.itemname}</strong> </div>: ""}
          </Header.Subheader>
        </Header>
        {this.state.denied === true ? 
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Video</Table.HeaderCell>
              <Table.HeaderCell>Configuration</Table.HeaderCell>
              <Table.HeaderCell>Object Name</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {j.map((item, index) => (
              <Table.Row key={index} name={index}>
                <Table.Cell>{item.resourceName}</Table.Cell>
                <Table.Cell> {item.processId}</Table.Cell>
                <Table.Cell> {item.name}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        :""}
        <Divider />
        {(this.state.denied === false) ? <div>
        <Button
          basic
          color="grey"
          icon="trash"
          size="tiny"
          content="Yes"
          onClick={this.deleteResource}
          floated="right"
        ></Button>
        </div>
      :""}
      <AlertContainer ref={(a) => (this.msg = a)} {...this.alertOptions} />
      </div>
    );
  }
}

export default DeleteModalv2;
