/**
 * Created by michelefuschillo on 04/01/18.
 */
import React, { Component } from 'react';
import {Segment} from 'semantic-ui-react'
import {connect} from 'react-redux';
//import Axios from 'axios';
//import * as data from '../config/services.json';
//import AlertContainer from 'react-alert';
import menuItem from '../redux/actions/menustate';

const mapStateToProps = (state) => ({
  reducer: state.reducer,
})


class Profile extends Component {
  constructor (props) {
    super(props);
    this.state = {title:""};
  }

  componentWillMount() {
    //authentication check
    if(!this.props.reducer.logged){
      this.props.history.push("/login");
      this.props.dispatch(menuItem('login'));
    }else{

    }
  }

  render(){
    return(
      <div>
        <h4 className="ui icon header section-profile-header">
          <i className="street view icon circular"></i>
          <div className="content">Mentor</div>
        </h4>
        <h4 className="ui horizontal divider header section-profile-divider">
          <i className="user circle icon"></i>Mentor Configuration
        </h4>
        <Segment padded secondary className='section-profile'>

        </Segment>
      </div>
    )}
}

export default connect(mapStateToProps)(Profile);