/**
 * Created by michelefuschillo on 06/03/19.
 */

export function setComponentInfo (videoduration) {
  // Return action
  return {
    // Unique identifier
    type: 'COMPONENTINFO',
    // Payload
    payload:{
      videoduration
    }
  };
};


