import React, { Component } from 'react';
import {Input,Icon, Button,Radio,Grid} from 'semantic-ui-react';
import Axios from 'axios';
import * as services from '../../../../config/services.json';
import menuItem from '../../../../redux/actions/menustate';
import actionLogout from '../../../../redux/actions/logout';
import '../../../../css/config.css';
import { launchModal } from '../../../../redux/actions/ModalAction'

let unsubscribe;

class LinkModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
   	 	link:"",
      linkAction:'newwindow',
      covername:"",
      audiolink:"",
      audioname:"",
      inputLinkStatus:false
    };
  }

  componentWillReceiveProps(props) {
    // var unsubscribe = this.props.store.subscribe(this.updateState);
    // var updated = this.props.store.getState();
    // var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    // this.props.dispatch(setfocusobj('image', focusobj,{'type':'image','action':'image'}));
    // unsubscribe();
  }

  componentWillMount() {
    //authentication check
    var updated = this.props.store.getState();
    if(!updated.reducer.logged){
      this.props.store.history.push("/login");
      this.props.store.dispatch(menuItem('login'));
    }else{
      this.props.store.dispatch(menuItem('home'));
    }
  }

  inputLinkChange =(event, data)=>{
	  this.setState({link:data.value});
  }

  onClickOkButton =(event, data)=>{
	  console.log(data.value);
    var actionOptions={
      linkAction:this.state.linkAction,
      audiolink:this.state.audiolink
    }
      this.props.callbackFather('' ,'', '',this.state.link,actionOptions);
  }

  onChangeAction = (event, data) => {
    this.setState({linkAction:data.name});
  }

  openUploadModal = (event, data) => {
	  /*unsubscribe = this.props.store.subscribe(this.updatestate);
	  this.props.store.dispatch(launchModal('SETTING_MODAL', 'AUDIO',"audiolink"));
	  this.setState({macrocategory: 'audio'});*/
    /**
    * Modifica nuova modalità asset 03/02/2022
    */

    console.log(data.name.toLowerCase());
    this.setState({ macrocategory: 'image' });
    /**NUOVA MOADLITà ASSET 07-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "upload",
        typeres: data.name.toLowerCase()
      }
    }, "*");
    window.addEventListener('message', this.postMessageHandler);
  }

  openListModal = (event, data) => {
    //unsubscribe = this.props.store.subscribe(this.updateState);
    //this.props.dispatch(launchModal('SETTING_MODAL', 'AUDIO'));
    //this.setState({ macrocategory: 'audio' });
    //unsubscribe = this.props.store.subscribe(this.updateState);
    //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'audio' });
    /**NUOVA MOADLITà ASSET 15-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "audiolist"
      }
    }, "*");
    window.addEventListener('message', this.postMessageHandler);
  }

  openExternalModal = (event, data) => {
    //unsubscribe = this.props.store.subscribe(this.updateState);
    //this.props.dispatch(launchModal('SETTING_MODAL', 'AUDIO'));
    //this.setState({ macrocategory: 'audio' });
    //unsubscribe = this.props.store.subscribe(this.updateState);
    //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'audio' });
    /**NUOVA MOADLITà ASSET 15-12-2021 */
   
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "external"
      }
    }, "*");
    window.addEventListener('message', this.postMessageHandler);
  }


  /**NUOVA MOADLITà ASSET 03-02-2022 */
  postMessageHandler = (event) => {
    if (event.origin === services.ASSETDOMAIN) { //FROM ASSETLOGIN
      switch (event.data.type) {
        case "NEWRESOURCE":
          console.log("newRes:")
         console.log(event.data)      
        //old implementation use cover url with event.data.payload._id to store only direct link to resoruce
      // this.setState({ audiolink: this.coverUrl(event.data.payload._id), audioname: event.data.payload.name });
      //now store all resource data to check in play mode wich langauage choiced
      if (event.data.payload.type==="audio"){
          this.setState({ audiolink: event.data.payload, audioname: event.data.payload.name });
          window.removeEventListener('message', this.postMessageHandler);
      }else {
        //gestisci risorsa estermna         
        this.setState({ link: event.data.payload, inputLinkStatus:true});
        window.removeEventListener('message', this.postMessageHandler);
      }
          break;
        default: break;
      }
    }
  }

  coverUrl = (id) => {
    var updated = this.props.store.getState();
    let ser =
      services.ASSETBACKEND + services.singleresourcemanager_GET_AUTH.replace(
        "{APPNAME}",
        updated.reducer.appname
      );
    ser = ser.replace("{DOMAIN}", updated.reducer.domain);
    ser = ser.replace("{RESOURCEID}", id);
    ser = ser.replace("{USERID}", updated.reducer.userid);
    ser = ser.replace("{COVER}", false);
    ser = ser.replace("{MEDIARES}", "first");
    ser = ser.replace("{MEDIALAN}", "it");
    // ser = ser + "&t=" + new Date().getTime();
    return ser;
  };
  removeSelection=(e,d)=>{
     this.setState({link:"",inputLinkStatus:false});
  }
  render () {
    return (
      <div>
        <Input name='inputlink' type='text' value={typeof(this.state.link)==="string"?this.state.link :this.state.link.name} onChange={this.inputLinkChange} disabled={this.state.inputLinkStatus}
               style={{ height:'auto', width:'50%'}}/>
          <Button icon style={{ marginLeft: '5px' }}  onClick={this.openUploadModal} name="external">
            <Icon name='upload' />
          </Button>
          <Button icon  onClick={this.openExternalModal}>
              <Icon name='unordered list' />
          </Button>
          <Button icon onClick={this.removeSelection}>
              <Icon name='delete' />
          </Button>
          
        {/* <Button onClick={this.openExternalModal} style={{ marginLeft: '5px' }}>List external object</Button>               */}
       {/* <Icon name= 'delete' onClick={this.deleteSelection}/> */}
        <Radio name='newwindow' label='New window' onChange={this.onChangeAction}
                      checked={this.state.linkAction === 'newwindow' ? true : false} style={{ marginLeft:'5px'}}/>
        <Radio name='fullscreen' label='Full screen' onChange={this.onChangeAction}
                             checked={this.state.linkAction === 'fullscreen' ? true : false} style={{ marginLeft:'5px'}}/>                      
      
      {this.state.linkAction==='fullscreen' && this.props.store.getState().reducer.focusobj.obj.type==="image"?
      <><Input size='mini'  disabled
      value={this.state.audioname} placeholder = "Add audio.."
      style={{ height:'auto', width:'50%', marginLeft:"50px",paddingTop:"5px"}}
            action={{ icon: 'upload', circular: true, onClick: this.openUploadModal, name: "AUDIO" }} /> 
            <Button onClick={this.openListModal} style={{ marginLeft: '5px' }}>List</Button>
          </> :null}

		<Button onClick={this.onClickOkButton} style={{ marginLeft:'5px'}}>OK</Button>
    </div>
    )
  }

}

export default LinkModal;
