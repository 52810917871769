/**
 * Created by michelefuschillo on 02/08/18.
 */

export function setfocusobj (type, focusobj, action) {
  // Return action
  return {
    // Unique identifier
    type: 'FOCUSOBJ',
    // Payload
    payload:{
      type,
      focusobj,
      action //shape: {focus, color, text, time}
    }
  };
};


