/** * Created by michelefuschillo on 14/02/18. */
import React, { Component } from 'react';
import {Input,Table} from 'semantic-ui-react';
import '../../../../css/config.css';
import { setfocusobj} from '../../../../redux/actions/ObjectAction';

class HtmlPagePosition extends Component {
  constructor (props) {
    super(props);
    var updated = this.props.store.getState();
      this.state = {
        previousState:updated,
        width: updated.reducer.focusobj.obj.properties.width,
        height: updated.reducer.focusobj.obj.properties.height
      };

    this.inputHandler = this.inputHandler.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('htmlpage', focusobj,{'type':'width','action':'change'}));
    unsubscribe();
  }

  updateState(){
    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'htmlpage') {
      this.setState({
        previousState:updated,
        width:updated.reducer.focusobj.obj.properties.width,
        height:updated.reducer.focusobj.obj.properties.height});
		this.props.parent.waitingAnswer();
    }
  }

  inputHandler (event, data) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    switch (data.name){
      case 'width':
        focusobj.properties.width = data.value;
        this.props.dispatch(setfocusobj('htmlpage', focusobj,{'type':'width','action':'change'}));
        break;
      case 'height':
        focusobj.properties.height = data.value;
        this.props.dispatch(setfocusobj('htmlpage', focusobj,{'type':'height','action':'change'}));
        break;
      default:
        break;
    }
    unsubscribe();
  }

  render () {
    return (
      <Table collapsing id='section-config-tableTextPosition' basic='very' >
        <Table.Body>
          <Table.Row>
            <Table.Cell>Width</Table.Cell>
            <Table.Cell><Input name='width' type='range' min={10} max={1000} step={1} value={this.state.width} onChange={this.inputHandler}
                               style={{ height:'auto', width:'65px'}}/></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Height</Table.Cell>
            <Table.Cell><Input name='height' type='range' min={10} max={1000} step={1} value={this.state.height} onChange={this.inputHandler}
                               style={{ height:'auto', width:'65px'}}/></Table.Cell>
          </Table.Row>
          {/*  <Input name='turny' type='range' min={0} max={2*Math.PI} step={0.1} value={this.state.turny} onChange={this.inputHandler}
                     style={{height:'auto', width:'100%'}}/>
   <Input name='turnx' type='range' min={0} max={2*Math.PI} step={0.1} value={this.state.turnx} onChange={this.inputHandler}
                     style={{ height:'auto', width:'100%'}}/>
   <Input name='turnz' type='range' min={0} max={2*Math.PI} step={0.1} value={this.state.turnz} onChange={this.inputHandler}
                      style={{ height:'auto', width:'100%'}}/> */}

        </Table.Body>
      </Table>
    )
  }
}
export default HtmlPagePosition;