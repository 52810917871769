/**
* Created by michelefuschillo on 29/01/18.
*/
import React, { Component } from 'react';
import {List, Image, Button} from 'semantic-ui-react';
import {setfocusobj} from '../../../../redux/actions/ObjectAction';
import '../../../../css/config.css';

let unsubscribe;
class TextComp extends Component {
	constructor (props) {
		super(props);
		this.objSelected=this.objSelected.bind(this);
		this.updateState = this.updateState.bind(this);
	}
  
	updateState(){
		this.props.parent.waitingAnswer();
	}

	objSelected(event, data){
		unsubscribe = this.props.store.subscribe(this.updateState);
		this.props.dispatch(setfocusobj(
			'text',
			{type: 'text', action: '', name:'', description:'', properties: {color:'',background:'',backgroundObj:{},backgroundAlpha:'', fontsize:10, text:'', width:10,height:10}},
			{'type':'text','command':'new','text':data.name}));
			unsubscribe();
		}


		render () {
			return (
				<div>
				<List size="mini">
				<List.Item>
				<Button color="grey" circular inverted size='mini' compact onClick={this.objSelected}>
				<Image src='/assets/images/text/justify.png' height="16" width="16" name='circle'/>
				</Button>
				</List.Item>
				</List>
				</div>
			)
		}
	}

	export default TextComp;