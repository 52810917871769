/**
* Created by michelefuschillo on 06/11/18.
*/
import React, { Component } from 'react'
import {Grid,Button,Icon,Divider,Image,Popup} from 'semantic-ui-react'
import * as services from '../../../../config/services.json'

import '../../../../css/config.css'

var normalstyle={'fontSize':'1.5vh'};
var normalstyleItalic={'fontSize':'1.5vh',"fontStyle": "italic"};



//var unsubscribe;
class Jumps extends Component {
	constructor (props) {
		super(props)
		var dataFilter = this.props.items.filter(function (el) {
			if ((typeof el.properties !== 'undefined') && (el.properties !== null)) {
				if(!el.properties.events){
					return false;
				}else if(el.properties.events.length > 0){
					return true;
				}
				return false;
			}
			return false
		})

		this.state = ({
			items: dataFilter})
			this.setConfigurationItems = this.setConfigurationItems.bind(this);
		}


		setConfigurationItems (data){
			var dataFilter = data.filter(function (el) {
				console.log(el);
				if ((typeof el.properties !== 'undefined') && (el.properties !== null)) {
					if(!el.properties.events){
						return false;
					}else if(el.properties.events.length > 0){
						return true;
					}
					return false;
				}
				return false
			})
			this.setState({items:dataFilter});
		}


		gotoEdit = (event, data) => {
			console.log(data.id);
			this.props.callbackParent('gotoedit', data.id, 'event');
		}


		render () {
			return (
				<div id='listobjectimeline' >
						{this.state.items.map((item, index1) =>
							<div>

										{item.properties.events.map((event, index2) =>
										<Grid  columns={5} style={{'margin':'2px'}}>
											{event.actionType=='jump'?
											<Grid.Row key={index2} style={{'height':'5vh','margin':'2px'}}>

												<Grid.Column   width={1} style={{'overflow':'hidden','text-overflow':'ellipsis'}} >
													{(() => {
														switch(item.category) {
															case 'audio':
																return  <Icon size='small' name='itunes note' />
															case 'video2d':
																return  <Icon size='small' name='video camera'/>
															case 'shape':
																return <Icon size='small' name='object group' />
															case 'text':
																return <Icon size='small' name='font' />
															case 'htmlpage':
																return <Icon size='small'  name='html5'/>
															case 'image':
																return <Icon size='small' name='picture'/>
															default:
																return <Icon size='small'  name='pin' />
														}
													})()}
												</Grid.Column>
												<Popup flowing hoverable wide position='bottom center' size='small' trigger={

												<Grid.Column   width={2} style={{'overflow':'hidden','text-overflow':'ellipsis'}} >
													<span style={normalstyle}>	{item.name ? item.name : item.category }</span>
												</Grid.Column>
											} on='hover'>
														<p id='section-edit-card-link'>{item.name ? item.name : item.category }</p>
														</Popup>
												<Grid.Column   width={3} style={{'overflow':'hidden','text-overflow':'ellipsis'}} >
												<span style={normalstyleItalic}>	{event.evenType} 	 </span>
												</Grid.Column>
												<Grid.Column   width={1}  >
												<span style={normalstyle}> 	<Icon size="big"name='long arrow alternate right' color='grey' /> </span>
												</Grid.Column>
												<Popup flowing hoverable wide position='bottom center' size='small' trigger={
														<Grid.Column width={3}  style={{'overflow':'hidden','text-overflow':'ellipsis'}} >
														<span style={normalstyle}>	{event.idVideo}</span>
													</Grid.Column>
																} on='hover'>
														<p id='section-edit-card-link'>{event.idVideo}</p>
														</Popup>
														<Popup flowing hoverable wide position='bottom center' size='small' trigger={

												<Grid.Column  width={3} style={{'overflow':'hidden','text-overflow':'ellipsis'}} >
													<span style={normalstyle}>	{event.idConfig}</span>
													</Grid.Column>
												} on='hover'>
														<p id='section-edit-card-link'>{event.idConfig}</p>
														</Popup>
												

												<Grid.Column  width={1}  >
													<Button   circular icon basic index={index1} id={item} title='Focus' style={{'margin':'-18px'}} onClick={this.gotoEdit}>
														<Icon name='edit' id={item.tStart} color='grey' />
													</Button>
												</Grid.Column>
												{  event.actionOptions.synchronized ?
														<Popup flowing hoverable wide position='bottom right' size='small' trigger={
													<Grid.Column  width={1}  >
															<Icon name='sync' id={index2} color='teal' />
														</Grid.Column>
													} on='hover'>
															<p id='section-edit-card-link'>Synchronized jump</p>
															</Popup> 
															:
															<span></span>
														}
											</Grid.Row>:<span style={{"display":"none"}}></span> }
												</Grid>
										)}
</div>

						)}

				</div>
			)
		}
	}
	export default Jumps
