/* Created by obbiettivo on 18/06/18. */
import React, { Component } from 'react';
import {Checkbox,Form, Input, Divider, Image}      from 'semantic-ui-react';
import {setfocusobj} from '../../../../redux/actions/ObjectAction';
import '../../../../css/config.css';

let unsubscribe;

class Audiooptions extends Component {
  constructor (props) {
	  super(props);

	  this.updateState = this.updateState.bind(this);
	  this.optionHandler = this.optionHandler.bind(this);

	  var updated = this.props.store.getState();
	  if (typeof updated.reducer.focusobj.obj.properties.positional === 'undefined') {
		  updated.reducer.focusobj.obj.properties.positional = {
			  active: false,
			  coneInnerAngle: 90,
			  coneOuterAngle: 180,
			  coneOuterGain:0
		  }
	  }

	  this.state = {
		  previousState:updated,
		  loop:updated.reducer.focusobj.obj.properties.loop,
		  autoplay:updated.reducer.focusobj.obj.properties.autoplay,
		  activePositional: updated.reducer.focusobj.obj.properties.positional ? updated.reducer.focusobj.obj.properties.positional.active : false,
		  coneInnerAngle: updated.reducer.focusobj.obj.properties.positional ? updated.reducer.focusobj.obj.properties.positional.coneInnerAngle : 90,
		  coneOuterAngle:updated.reducer.focusobj.obj.properties.positional ? updated.reducer.focusobj.obj.properties.positional.coneOuterAngle : 180,
		  coneOuterGain:updated.reducer.focusobj.obj.properties.positional ? updated.reducer.focusobj.obj.properties.positional.coneOuterGain : 0,
	  };
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('audio', focusobj,{}));
    unsubscribe();
  }

  updateState(){
	  var updated = this.props.store.getState() //NEW
	  if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'audio') {

		  this.setState({
			  previousState:updated,
			  loop:updated.reducer.focusobj.obj.properties.loop,
			  autoplay:updated.reducer.focusobj.obj.properties.autoplay,
			  activePositional: updated.reducer.focusobj.obj.properties.positional ? updated.reducer.focusobj.obj.properties.positional.active : false,
			  coneInnerAngle: updated.reducer.focusobj.obj.properties.positional ? updated.reducer.focusobj.obj.properties.positional.coneInnerAngle : 90,
			  coneOuterAngle:updated.reducer.focusobj.obj.properties.positional ? updated.reducer.focusobj.obj.properties.positional.coneOuterAngle : 180,
			  coneOuterGain:updated.reducer.focusobj.obj.properties.positional ? updated.reducer.focusobj.obj.properties.positional.coneOuterGain : 0,
		  })
		  this.props.parent.waitingAnswer();
	  }
  }



  optionHandler = (event, data) => {
	  unsubscribe = this.props.store.subscribe(this.updateState);
	  var updated = this.props.store.getState();
	  var focusobj = Object.assign({}, updated.reducer.focusobj.obj);

	  switch (data.name){
	  case 'loop':
		  focusobj.properties.loop = data.checked;
		  this.props.dispatch(setfocusobj('audio', focusobj,{}));
		  break;
	  case 'autoplay':
		  focusobj.properties.autoplay = data.checked;
		  this.props.dispatch(setfocusobj('audio', focusobj,{}));
		  break;
	  case 'positional':
		  if (typeof focusobj.properties.positional !== 'undefined') {
			  focusobj.properties.positional.active = data.checked;
		  }else{
			  focusobj.properties.positional = {
				  active: data.checked,
				  coneInnerAngle: 90,
				  coneOuterAngle: 180,
				  coneOuterGain:0
			  	}
			  }
			  this.props.dispatch(setfocusobj('audio', focusobj,{}));
			  break;
		  case 'coneInnerAngle':
			  var valOut = parseFloat(document.getElementById("outerangleaudiooptions").value);
			  var valOut2 = parseFloat(document.getElementById("outerangleaudiooptions2").value);

			  if(data.value.length === 0){
				  focusobj.properties.positional.coneInnerAngle = 0;
				  this.props.dispatch(setfocusobj('audio', focusobj,{}));
			  } else if(parseFloat(data.value) >= 0 && parseFloat(data.value) <= 360 && parseFloat(data.value) <= valOut && parseFloat(data.value) <= valOut2){
				  focusobj.properties.positional.coneInnerAngle = data.value;
				  this.props.dispatch(setfocusobj('audio', focusobj,{}));
			  }
			  break;
		  case 'coneOuterAngle':
			  var valIn = parseFloat(document.getElementById("innerangleaudiooptions").value);
			  var valIn2 = parseFloat(document.getElementById("innerangleaudiooptions2").value);
			  if(data.value.length === 0){
				  focusobj.properties.positional.coneOuterAngle = 0;
				  focusobj.properties.positional.coneInnerAngle = 0;
				  this.props.dispatch(setfocusobj('audio', focusobj,{}));
			  } else if(parseFloat(data.value) >= 0 && parseFloat(data.value) <= 360 && parseFloat(data.value) >= valIn && data.value >= valIn2){
				  focusobj.properties.positional.coneOuterAngle = data.value;
				  this.props.dispatch(setfocusobj('audio', focusobj,{}));
			  }
			  break;
		  case 'coneOuterGain':
			  if(data.value >= 0 && data.value <= 1){
				  focusobj.properties.positional.coneOuterGain = data.value;
				  this.props.dispatch(setfocusobj('audio', focusobj,{}));
			  }
			  break;
		  default:
			  break;
		  }
		  unsubscribe();
	  }


  render () {
    return (
     <Form>
       <Form.Field>
				 <Checkbox className="check" label='loop' name='loop' checked={this.state.loop} onChange={this.optionHandler}/>
			 </Form.Field>

			 <Form.Field>
				 <Checkbox className="check" label='autoplay' name='autoplay' checked={this.state.autoplay} onChange={this.optionHandler}/>
			 </Form.Field>

			{ /*<Form.Field>
				 <Checkbox className="check" label='directional' name='positional' checked={this.state.activePositional} onChange={this.optionHandler}/>
			 </Form.Field>
			 {this.state.activePositional ?
				 <div>
			<Image src='/assets/images/audio/cone.png' height="30" width="30" name='textscroll'/>
			<Form.Field inline>
			<label> InnerAngle</label>
			<Input id='innerangleaudiooptions' name='coneInnerAngle' type='range' min={1} max={360} step={1} value={this.state.coneInnerAngle} onChange={this.optionHandler}
			                     style={{ height:'auto', width:'65px'}}/>
			<Input id='innerangleaudiooptions2' name='coneInnerAngle' type='text' min={1} max={360} step={1} value={this.state.coneInnerAngle} onChange={this.optionHandler}
			                     style={{ height:'20px', width:'60px'}}/>
			</Form.Field>
			<Form.Field inline>
			<label> OuterAngle</label>
			<Input id='outerangleaudiooptions' name='coneOuterAngle' type='range' min={1} max={360} step={1} value={this.state.coneOuterAngle} onChange={this.optionHandler}
			                     style={{ height:'auto', width:'65px'}}/>
			<Input id='outerangleaudiooptions2' name='coneOuterAngle' type='text' min={1} max={360} step={1} value={this.state.coneOuterAngle} onChange={this.optionHandler}
			                     style={{ height:'20px', width:'60px'}}/>
			</Form.Field>
			 <Form.Field inline>
			<label>AmbientSound</label>
			<Input name='coneOuterGain' type='range' min={0} max={1} step={0.1} value={this.state.coneOuterGain} onChange={this.optionHandler}
			                     style={{ height:'auto', width:'65px'}}/>
			<Input name='coneOuterGain' type='text' min={1} max={360} step={1} value={this.state.coneOuterGain} onChange={this.optionHandler}
			                     style={{ height:'20px', width:'60px'}} />
			 </Form.Field></div> : ''*/}
	 </Form>
    )
  }
}

export default Audiooptions;
