/**
* Created by michelefuschillo on 29/01/18.
*/
import React, { Component } from 'react';
import {List, Image, Button} from 'semantic-ui-react';
import {setfocusobj} from '../../../../redux/actions/ObjectAction';
import '../../../../css/config.css';

let unsubscribe;
class MentorAddon extends Component {
    constructor (props) {
		super(props);
		this.dragIconEvent = this.dragIconEvent.bind(this);
		this.updateState = this.updateState.bind(this);
	}
  
	updateState(){
		this.props.parent.waitingAnswer();
	}

	dragIconEvent(e){
		unsubscribe = this.props.store.subscribe(this.updateState);
		this.props.dispatch(setfocusobj(
			'video2d',
			{type: 'video2d', action: '', sizew: 100, sizeh: 56.25, name: '',description:'',properties: {nameresource:"", pathresource:""}},
			{'type':'video2d','command':'new','mentor':true,'mentorsnippet':true,'aspect':e.target.title}));
			unsubscribe();
		}

		render () {
			return (
				<List size="mini">
				<List.Item>
				<Button color="grey" circular inverted size='mini' compact onClick={this.dragIconEvent}>
				<Image src='/assets/images/mentoraddon/mentor.png' height="16" width="16" name='circle'/>
				</Button>
				</List.Item>
				</List>
			)
		}
	}

	export default MentorAddon;