/**
 * Created by michelefuschillo on 29/01/18.
 */
import React, { Component } from 'react'
import { List, Input, Icon, Popup, Button } from 'semantic-ui-react'
import { setfocusobj } from '../../../../redux/actions/ObjectAction'
import { launchModal } from '../../../../redux/actions/ModalAction'
import * as services from '../../../../config/services.json';
import { getResourceDataForLanguage } from './../utils';

/*
* let will not be added as properties on the global window object like those defined with var
 */
let unsubscribe;
class Video2dImport extends Component {
  constructor(props) {
    super(props)
    var updated = this.props.store.getState();
    this.state = {
      previousState: updated,
      nameresource: updated.reducer.focusobj.obj.properties ? updated.reducer.focusobj.obj.properties.nameresource : '',
      pathresource: updated.reducer.focusobj.obj.properties ? updated.reducer.focusobj.obj.properties.pathresource : ''
    }
    this.updateState = this.updateState.bind(this)
    this.cancelSelected = this.cancelSelected.bind(this)
    this.openToChoice = this.openToChoice.bind(this)
  }

  openToChoice(event, data) {
    /*unsubscribe = this.props.store.subscribe(this.updateState);
    this.props.dispatch(launchModal('SETTING_MODAL', 'video'));
    this.setState({ macrocategory: 'video2d' });*/
    /**NUOVA MOADLITà ASSET 15-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "videolist"
      }
    }, "*");
    window.addEventListener('message', this.postMessageHandler);
  }

  /**NUOVA MOADLITà ASSET 08-12-2021 */
  postMessageHandler = (event) => {
    if (event.origin === services.ASSETDOMAIN) { //FROM ASSETLOGIN event.origin === "http://localhost:3002"
      switch (event.data.type) {
        case "NEWRESOURCE":
          //console.log(event.data.payload);
          unsubscribe = this.props.store.subscribe(this.updateState);
          var focusobj = Object.assign({}, this.props.reducer.focusobj.obj);
          if (event.data.payload.type === "imagecloud") {
            let data = {};
            data.medialan = event.data.payload.url;
            focusobj.properties.pathresource = event.data.payload.url[getResourceDataForLanguage(data)];
          } else {
            focusobj.properties.pathresource = this.coverUrl(event.data.payload);
          }
          focusobj.properties.nameresource = event.data.payload.name;
          focusobj.properties.resourceid = event.data.payload._id;
          this.props.dispatch(setfocusobj("video2d", focusobj, { 'type': 'video2d', 'action': 'video2d' }));
          window.removeEventListener('message', this.postMessageHandler);
          //this.setState({ childmessage: event.data.payload.msg });
          break;
        default: break;
      }
    }
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('video2d', focusobj, { 'type': 'video2d', 'action': 'change' }));
    unsubscribe();
  }

  updateState() {
    var updated = this.props.store.getState() //NEW
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'video2d') {
      this.setState({
        previousState: updated,
        nameresource: updated.reducer.focusobj.obj.properties.nameresource,
        pathresource: updated.reducer.focusobj.obj.properties.pathresource
      });
      this.props.parent.waitingAnswer();
    } else {
      unsubscribe(); //the state update will be made into another component (asynchronous mode)
    }
  }

  cancelSelected() {
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.properties.nameresource = '';
    focusobj.properties.pathresource = '';
    this.props.dispatch(setfocusobj('video2d', focusobj, { 'type': 'video2d', 'action': 'change' }));
    this.setState({ name: '' });
  }

  openUploadModal = (event, data) => {
    //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'image' });
    /**NUOVA MOADLITà ASSET 07-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "upload",
        typeres: "video"
      }
    }, "*");
    window.addEventListener('message', this.postMessageHandler);
  }

  coverUrl = (resoruce) => {
    let ser =
      services.ASSETBACKEND + services.singleresourcemanager_GET_AUTH.replace(
        "{APPNAME}",
        this.props.reducer.appname
      );
    ser = ser.replace("{DOMAIN}", this.props.reducer.domain);
    ser = ser.replace("{RESOURCEID}", resoruce._id);
    ser = ser.replace("{USERID}", this.props.reducer.userid);
    ser = ser.replace("{COVER}", false);
    ser = ser.replace("{MEDIARES}", "first");
    ser = ser.replace("{MEDIALAN}", getResourceDataForLanguage(resoruce));
    ser = ser + "&t=" + new Date().getTime();
    return ser;
  };
  openListModalLink = (event, data) => {
    //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'image' });
    /**NUOVA MOADLITà ASSET 07-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "videocloudlist"
      }
    }, "*");
    window.addEventListener('message', this.postMessageHandler);
  }

  render() {
    return (
      <List className="componentOnIframeClass">
        <List.Item>
          <List.Content>
            <List.Description>
              <List>
                <List.Item>
                  <Input size='mini'  disabled
                    value={this.state.nameresource} placeholder='Add Video...'
                    action={!this.state.nameresource ?
                      { icon: 'upload', circular: true, onClick: this.openUploadModal, name: 'video2dimport' }
                      :
                      { icon: 'delete', circular: true, onClick: this.cancelSelected, name: 'video2dimport' }} />
                 

                  <Button icon onClick={this.openToChoice}>
                    <Icon style={{ padding: 0 }} name='list' />
                  </Button>
                  {/* commentato pulsante che apre le risorse create come link */}

                  {/* <Popup flowing hoverable position='bottom right' size='small' trigger={
                    <Icon name='linkify' size="small" onClick={this.openListModalLink} />

                  }>
                    <p id='section-edit-card-link'>{"Load resource stored as a link "}</p>
                  </Popup> */}
                </List.Item>
              </List>
            </List.Description>
          </List.Content>
        </List.Item>
      </List>
    )
  }
}

export default Video2dImport