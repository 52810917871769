/**
 * Created by michelefuschillo on 21/11/17.
 */
import {connect} from 'react-redux';
import React, { Component } from 'react';
import actionLogout from '../redux/actions/logout';

const mapStateToProps = (state) => ({
  reducer: state.reducer,

})

class Logout extends Component {

  componentDidMount(){
    this.props.dispatch(actionLogout());
    this.props.router.push('/');
  }

  render() {
    return (
      <h1 className="loading-text">
        Logging out...
      </h1>
    );
  }
}

export default connect(mapStateToProps)(Logout);