/**
 * Created by michelefuschillo on 01/02/18.
 */
import React, { Component } from 'react'
import { Table, Input } from 'semantic-ui-react'
import { setfocusobj } from '../../../../redux/actions/ObjectAction'

class MetadataAppearance extends Component {
  constructor (props) {
    super(props)
    this.updateState = this.updateState.bind(this)
    var updated = this.props.store.getState();
    if (this.props.reducer.focusobj.obj.properties) {
      this.state = {
        previousState:updated,
        coverwidth: updated.reducer.focusobj.obj.properties.coverwidth,
        coverheight: updated.reducer.focusobj.obj.properties.coverheight
      }
    }
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('metadata', focusobj,{}));
    unsubscribe();
  }

  updateState () {
    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'metadata') {
      this.setState({
        previousState:updated,
        coverwidth: updated.reducer.focusobj.obj.properties.coverwidth,
        coverheight: updated.reducer.focusobj.obj.properties.coverheight
      });
	  this.props.parent.waitingAnswer();
    }
  }

  inputHandler = (event, data) => {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    switch (data.name) {
      case 'coverwidth':
		  focusobj.properties.coverwidth = data.value;
		  this.props.dispatch(setfocusobj('metadata', focusobj, {}));
        break
      case 'coverheight':
		  focusobj.properties.coverheight = data.value;
		  this.props.dispatch(setfocusobj('metadata', focusobj, {}));
        break
      default:
        break
    }
    unsubscribe();
  }

  render () {
    return (
      <Table collapsing id='section-config-tableTextPosition' basic='very' >
        <Table.Body>
          <Table.Row>
            <Table.Cell>Width</Table.Cell>
            <Table.Cell><Input name='coverwidth' type='range' min={1} max={500} step={1} value={this.state.coverwidth}
                               onChange={this.inputHandler}
                               style={{height: 'auto', width: '65px'}}/></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Height</Table.Cell>
            <Table.Cell>  <Input name='coverheight' type='range' min={1} max={500} step={1} value={this.state.coverheight}
                                 onChange={this.inputHandler}
                                 style={{height: 'auto', width: '65px'}}/></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    )
  }
}

export default MetadataAppearance