/* Created by michelefuschillo on 27/10/17. */
import React, { Component } from 'react'

import { Icon, Button, Dimmer, Loader, Container, Popup, Grid, Header, Modal, Tab, Image, Segment, Confirm, Checkbox, Radio, Form } from 'semantic-ui-react'

import Axios from 'axios'
import { connect } from 'react-redux'
import * as services from '../../config/services.json'
import menuItem from '../../redux/actions/menustate'
import ReactDOM from 'react-dom'

import TimeComponent from './types/time'

import ShapeText from './types/shapes/shapetext'
import ShapeColor from './types/shapes/shapecolor'
import ShapeScale from './types/shapes/shapescale'
import ShapeTag from './types/shapes/shapetag'

import Edit from './types/edit/edit'
import ObjCustomization from './types/objtrid/objcustomization'
import TextOnly from './types/text/textonly'
import TextColor from './types/text/textcolor'
import TextFont from './types/text/textfont'
import TextPosition from './types/text/textposition'
import TextViewOptions from './types/text/textviewoptions'
import TextAction from './types/text/textactions'
import TextTag from './types/text/texttag'
import TextPositionScene from './types/text/textpositionscene'



import TextLineAction from './types/textline/textlineaction'
import TextlineInfo from './types/textline/textlineinfo'
import TextlineViewOptions from './types/textline/textlineviewoptions'
import TextlineColor from './types/textline/textlinecolor'
import TextlineTag from './types/textline/textlinetag'

/*import AudioAction from './types/audio/audioaction'*/


import HtmlPageInfo from './types/htmlpage/htmlpageinfo'
import HtmlPageLink from './types/htmlpage/htmlpagelink'
import HtmlPageAppearance from './types/htmlpage/htmlappearance'
import HtmlPagePosition from './types/htmlpage/htmlpageposition'
import HtmlPageViewOptions from './types/htmlpage/htmlpageviewoptions'
import HtmlAction from './types/htmlpage/htmlaction'
import HtmlTag from './types/htmlpage/htmltag'
import HtmlPosition from './types/htmlpage/htmlposition'




import ImageImport from './types/images/imageimport'
import ImageAppearance from './types/images/imageappearance'
import ImageInfo from './types/images/imageinfo'
import ImageViewOptions from './types/images/imageviewoptions'
import ImageTag from './types/images/imagetag';
import ImagePosition from './types/images/imageposition';


import AudioInfo from './types/audio/audioinfo'
import AudioAppearance from './types/audio/audioappearance'
import AudioOptions from './types/audio/audiooptions'
import AudioViewOptions from './types/audio/audioviewoptions'
import AudioAction from './types/audio/audioaction'
import AudioTag from './types/audio/audiotag'
import AudioPosition from './types/audio/audioposition'
import AudioCover from './types/audio/audiocover'



import Video2dImport from './types/video2d/video2dimport'
import Video2dText from './types/video2d/video2dtext'
import Video2dScale from './types/video2d/video2dscale'
import Video2dOptions from './types/video2d/video2doptions'
import Video2dViewOptions from './types/video2d/video2dviewoptions'
import VideoAction from './types/video2d/video2dactions'
import Video2dTag from './types/video2d/video2dtag'
import Video2dAppearance from './types/video2d/video2dappearance'
import Video2dPosition from './types/video2d/video2dposition'


import MentorText from './types/mentoraddon/mentortext'
import Video2dImportMentor from './types/mentoraddon/video2dimportmentor'



import CategoriesComponent from './category/categorylist'
import MenuMetadata from './menumetadata/menumetadata'
import MenuFirst from './menu/menufirst'
import MenuSecond from './menu/menusecond'
import MenuThird from './menu/menuthird'
import MenuFifth from './menu/menufifth'
import MenuFifthMentor from './menu/menufifthmentor'
import MenuSixth from './menu/menusixth'
import MenuSeventh from './menu/menuseventh'
import MenuEight from './menu/menueight'
import SMenuFirst from './menuSecond/smenufirst'
import ImageAction from './types/images/imageaction'
import MetadataMenu from './menu/metadatamenu'
import HeaderRightMenu from './menu/headerrightmenu'

import MetadataInfo from './types/metadata/metadatainfo'
import MetadataAppearance from './types/metadata/metadataappearance'
import MetadataViewOptions from './types/metadata/metadataviewoptions'
import MetadataAction from './types/metadata/metadataaction'
import MetadataTag from './types/metadata/metadatatag'



import '../../css/config.css'
import { launchModal } from '../../redux/actions/ModalAction'
import { setfocusobj } from '../../redux/actions/ObjectAction'
import { setObjListAction } from '../../redux/actions/ListObjAction'
import { setAutoSavingAction } from '../../redux/actions/autosavingAction'
import { setComponentInfo } from '../../redux/actions/ComponentAction'

import TimeLine from './types/timeline/timeline'
import Jumps from './types/jumps/jumps'
import VideoEvent from './types/videoevents/videoevent'
import MenuFourth from './menu/menufourth'

import GraphView from './types/actionModal/graphview'
import actionLogin from '../../redux/actions/auth';
import Play from './types/player/play'; //crea il player modal


// const ShowGraph=true;
const userActiveGraphTestMichele="5e611b86882cf26da7d0bfdc"//michele locale 
const userActiveGraphTestPasquale="5bc0a06840e6b06022c32c2d" //pasquale locale 
const userActiveGraph="5bb1e149471c173b8d250c5f" //michele online locale 
var mentorVideoMockRBB = [  //dati mock
  {
    namementor: "mentor_1",
    mentorchoice: true,
    mentorsnippet: false, //true snippet
    group: "RBB",
    path: "https://rbb-hyper360.s3.eu-central-1.amazonaws.com/mentor/mentor.mp4"


  },
  {
    namementor: "mentor_2",
    mentorchoice: true,
    mentorsnippet: false, //true snippet
    group: "RBB",
    path: "https://rbb-hyper360.s3.eu-central-1.amazonaws.com/mentor/mentor_02.mp4"


  },
  {
    namementor: "mentor_3",
    mentorchoice: true,
    mentorsnippet: false, //true snippet
    group: "RBB",
    path: "https://rbb-hyper360.s3.eu-central-1.amazonaws.com/mentor/mentor_03.mp4"


  },
  {
    namementor: "mentor_4",
    mentorchoice: true,
    mentorsnippet: false, //true snippet
    group: "RBB",
    path: "https://rbb-hyper360.s3.eu-central-1.amazonaws.com/mentor/mentor_04.mp4"


  }
];
var mentorVideoMockRTI = [  //dati moc
  {
    namementor: "mentor_1",
    mentorchoice: false,
    mentorsnippet: false,//true snippet
    group: "RTI",
    path: "https://rti-hyper360.s3.eu-west-3.amazonaws.com/mentor/mentor.mp4"
  },
  {
    namementor: "mentor_2",
    mentorchoice: false,
    mentorsnippet: false,//true snippet
    group: "RTI",
    path: "https://rti-hyper360.s3.eu-west-3.amazonaws.com/mentor/mentor_02.mp4"
  },
  {
    namementor: "mentor_3",
    mentorchoice: false,
    mentorsnippet: false,//true snippet
    group: "RTI",
    path: "https://rti-hyper360.s3.eu-west-3.amazonaws.com/mentor/mentor_03.mp4"
  },
  {
    namementor: "mentor_4",
    mentorchoice: false,
    mentorsnippet: false,//true snippet
    group: "RTI",
    path: "https://rti-hyper360.s3.eu-west-3.amazonaws.com/mentor/mentor_04.mp4"
  }
];



//wraps all the action creators
const mapStateToProps = (state) => ({
  reducer: state.reducer
})

var shapeobj = { type: 'shape', alpha: 1, scale: 1, action: '', text: '', link: '', shape: 'shapecircle', properties: { events: [], tags: [] } }
var trediobj = { type: 'obj', alpha: 0.4, scale: 0.1, action: '', turny: 0, turnx: 0, turnz: 0, pathorigin: '' }
var textobj = {
  type: 'text', action: '', properties: {
    vo: {
      none: true, ontop: false, ontopoptionshor: 'center',
      ontopoptionsver: 'middle', fov: false, onblink: false, onblinkduration: 'medium', showhotspot: true
    }, events: [], tags: []
  }
};
var htmlpageobj = {
  type: 'htmlpage', action: '', properties: {
    vo: {
      none: true, ontop: false, ontopoptionshor: 'center',
      ontopoptionsver: 'middle', fov: false, onblink: false, onblinkduration: 'medium', showhotspot: true
    }, events: [], tags: []
  }
};
var video2dobj = { type: 'video2d', action: '', sizew: 100, sizeh: 56.25, name: '', properties: { nameresource: "", pathresource: "", events: [], vo: { showhotspot: true } } }
var imageobj = {
  type: 'image', action: '', properties: {
    vo: {
      none: true, ontop: false, ontopoptionshor: 'center',
      ontopoptionsver: 'middle', fov: false, onblink: false, onblinkduration: 'medium'
    }, events: [], tags: []
  }
};
var audioobj = {
  type: 'audio', action: '', properties: {
    vo: {
      none: true, ontop: false, ontopoptionshor: 'center',
      ontopoptionsver: 'middle', fov: false, onblink: false, onblinkduration: 'medium', showhotspot: true, valuerange: 1
    }, events: [], tags: []
  }
};
var metadataobj = {
  type: 'metadata', action: '', subtype: '', properties: {
    vo: {
      none: true, ontop: false, ontopoptionshor: 'center',
      ontopoptionsver: 'middle', fov: false, onblink: false, onblinkduration: 'medium'
    }, events: [], tags: []
  }
};
var textlineobj = {
  type: 'textline', action: '', properties: {
    color: '', background: '', backgroundObj: {}, backgroundAlpha: '', fontsize: 10, text: '', width: 10, height: 10, vo: {
      ontop: true, ontopscroll: 'textline',
      onscrollver: 'bottom', onscrolloptionstxt: 'fix', onscrolliteration: 'loop', onscrolldirection: 'left',
      onscrollduration: 'medium', vo: { showhotspot: true }
    }, events: [], tags: []
  }
};

var reduxStore;
var currentState;
let timelineref;

class Configuration extends Component {
  constructor(props) {
    super(props)
    reduxStore = this.props.store;
    this.state = {
      msgSavingOpen: false,
      mute: false,
      savingStatus: false,
      loading: false,
      msgIdentifierOpen: true,
      msgDeleteHotspotOpen: false,
      selected: '',
      control: '',
      videoIdentifier: '',
      macrocategory: '',
      identifier: '',
      consumerName: '',
      uploaded: '',//default NO
      title: '',
      activeItem: '',
      objDeleteIdentifier: '',
      objDeleteIndex: '',
      ModalOpenTrash: false,
      ModalOpenGraph:false,
      ModalOpenBackHome: false,
      ModalOpenfullscreen: false,
      time: '',
      category: '',
      duration: '',
      activeIndex: 0,
      tabBarActiveIndex: 0,
      tabBarActiveIndexSx: 0,
      tabBarActiveIndexCx:  0,
      objectfocus: {},
      items: [],
      configurations: [],
      editModal: false,
      rightMenuDeactive: true,
      visibility: true,
      compassStatus: false,
      compassPosition: "",
      jwtForGraph:"",
      topDX: false, topSX: false, bottomDX: false, BottomSX: false,
      status: { published: false, mentorized: false },
      compatibility: { web: true, ios: true, android: true, hbbtv: true, vr: true },
      videoundertakeoptions: {},
      switchoptions: {},
      vrmodeoptions:{},
      medialanguage:"",
      panesRight: [
        { menuItem: { key: 'props', icon: 'edit', content: 'Props', disabled: false } },
        { menuItem: { key: 'event', icon: 'hand pointer outline', content: 'Events', disabled: false } },
      ],
      panesLeft: [
        // {menuItem: {key: 'time', icon:'time' , content: 'Timeline', disabled: false}},
        { menuItem: { key: 'hotspot', icon: 'plus square outline', content: 'HotSpot', disabled: false }, },
        // { menuItem: { key: 'metadata', icon: 'expand', content: 'Metadata', disabled: false } }
      ],
      panesCenterDown: 
     
        [{ menuItem: { key: 'time', icon: 'time', content: 'Timeline', disabled: false} },
        { menuItem: { key: 'jumps', icon: 'linkify', content: 'Jumps', disabled: false } },
        { menuItem: { key: 'videoevent', icon: 'calendar', content: 'End of video', disabled: false } }],

        
      panesCenterDownImage: [{ menuItem: { key: 'time', icon: 'time', content: 'List', disabled: false} },
        { menuItem: { key: 'jumps', icon: 'linkify', content: 'Jumps', disabled: false } },
        { menuItem: { key: 'videoevent', icon: 'calendar', content: 'Options', disabled: false } }],
      
      videoFromGraph:"",
      underType:"",
      resType:this.props.reducer.resType,
      fsname: this.props.reducer.fsname,
      playModal:false,
      topublicicon:"eye slash",
      webcloudresid:""
      //videoArray:this.props.location.state.videoArray,
      //domainBackgroundSRC:this.props.location.state.resdomain
    }

    this.msgHandler = this.msgHandler.bind(this)
    this.msgSend = this.msgSend.bind(this)
    this.choiceCategory = this.choiceCategory.bind(this)
    this.renderIframe = this.renderIframe.bind(this)
    this.deleteObjectAll = this.deleteObjectAll.bind(this)
    this.loadVideo = this.loadVideo.bind(this)
    this.mergeConfigPlay = this.mergeConfigPlay.bind(this)
    this.funcModalClose = this.funcModalClose.bind(this)
    this.funcModalOpen = this.funcModalOpen.bind(this)
    this.onChildChanged = this.onChildChanged.bind(this)
    this.msgIdentifierOpen = this.msgIdentifierOpen.bind(this)
    this.msgSavingOpen = this.msgSavingOpen.bind(this)
    this.msgFocusOptionsDelete = this.msgFocusOptionsDelete.bind(this) //delete local object
    this.msgDefocus = this.msgDefocus.bind(this)
    this.menuCallback = this.menuCallback.bind(this)
    this.postControl = this.postControl.bind(this)
    this.animationHandle = this.animationHandle.bind(this)
    this.updateState = this.updateState.bind(this);
    this.editSave = this.editSave.bind(this)
    this.setComponentTime = this.setComponentTime.bind(this)
    this.sendPostMessage = this.sendPostMessage.bind(this)
    this.copyObject = this.copyObject.bind(this);
    this.visibilityClick = this.visibilityClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveVisibilitySatus = this.saveVisibilitySatus.bind(this);
    this.createPlayTab = this.createPlayTab.bind(this);
    this.saveTopic = this.saveTopic.bind(this);
    this.autoSaving = this.autoSaving.bind(this);
    this.handleCompass = this.handleCompass.bind(this);
    this.copyObjectAnotherVideo = this.copyObjectAnotherVideo.bind(this);
    this.funcModalGraphClose=this.funcModalGraphClose.bind(this);
  }

  componentDidMount() {
    //authentication check
    /*if (!this.props.reducer.logged) {
      this.props.history.push('/login')
      this.props.dispatch(menuItem('login'))
      return;
    }*/
    window.addEventListener('message', this.msgHandler);
  }

  /*
  * Post Message Manage
   */
  updateState() {

    var previousState = Object.assign({}, currentState);
    currentState = reduxStore.getState();
    // console.log(currentState)
    if (previousState !== currentState) {
      //When we set the timeShow and timeHide, the iframe receive only a command without values.
      //When the iframe received the command, will send to frontend updated values; in this case
      //the update must not be made
      //refill focusobj with updated data
      // console.log("update state chiamato" + currentState.reducer.focusobj.type);
      // console.log(currentState.reducer.focusobj);
      if (currentState.reducer.focusobj.action.type === 'autosaving') {
        // this.sendPostMessage({'type': currentState.reducer.focusobj.action.type})
        this.autoSaving();
      } else {
        switch (currentState.reducer.focusobj.type) {
          case 'shape':
            if (currentState.reducer.focusobj.action.type === 'free2d') {
              this.sendPostMessage({ 'type': currentState.reducer.focusobj.action.type, 'action': { 'typeObject': 'shape', 'dir': currentState.reducer.focusobj.action.dir, 'vel': currentState.reducer.focusobj.action.vel } })

            } else
              if (currentState.reducer.focusobj.action.type === 'hotspotlist') {
                this.sendPostMessage({ 'type': currentState.reducer.focusobj.action.type })

              } else if (currentState.reducer.focusobj.action.type === 'time') {
                //When we chosen a time interval (show/hide), the frontend will receive the interval through post message
                //timeshow and timehide
                if (currentState.reducer.focusobj.action.command !== 'null') {
                  this.setComponentTime(currentState);
                } else { //show/hide received from Iframe
                  shapeobj['show'] = currentState.reducer.focusobj.obj.show;
                  shapeobj['hide'] = currentState.reducer.focusobj.obj.hide;
                  shapeobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
                  shapeobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
                }
              } else if (currentState.reducer.focusobj.action.command === 'new') {
                this.sendPostMessage({
                  'type': 'shape',
                  'action': 'new',
                  'shape': currentState.reducer.focusobj.action.shape
                })
              } else {//action: text, color, link
                shapeobj['alpha'] = currentState.reducer.focusobj.obj.alpha ? currentState.reducer.focusobj.obj.alpha : 1;
                shapeobj['scale'] = currentState.reducer.focusobj.obj.scale ? currentState.reducer.focusobj.obj.scale : 1;
                shapeobj['link'] = currentState.reducer.focusobj.obj.link;
                shapeobj['description'] = currentState.reducer.focusobj.obj.description;
                shapeobj['name'] = currentState.reducer.focusobj.obj.name;
                shapeobj['shape'] = currentState.reducer.focusobj.obj.shape;
                shapeobj['color'] = currentState.reducer.focusobj.obj.color;
                shapeobj['show'] = currentState.reducer.focusobj.obj.show;
                shapeobj['hide'] = currentState.reducer.focusobj.obj.hide;
                shapeobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
                shapeobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
                shapeobj['fulltime'] = currentState.reducer.focusobj.obj.fulltime;
                shapeobj['properties'] = currentState.reducer.focusobj.obj.properties;
                this.sendPostMessage(shapeobj);
                this.timeLineTimeModifing(currentState.reducer.focusobj.obj.objIdentifier,currentState.reducer.focusobj.obj.tStart, currentState.reducer.focusobj.obj.tEnd, currentState.reducer.focusobj.obj.fulltime);
              }
            break
          case 'text':
            console.log("testo menu")
            if (currentState.reducer.focusobj.action.type === 'free2d') {
              console.log("453")
              this.sendPostMessage({ 'type': currentState.reducer.focusobj.action.type, 'action': { 'typeObject': 'text', 'dir': currentState.reducer.focusobj.action.dir, 'vel': currentState.reducer.focusobj.action.vel } })

            } else
              if (currentState.reducer.focusobj.action.type === 'hotspotlist') {
                console.log("457")
                this.sendPostMessage({ 'type': currentState.reducer.focusobj.action.type })

              } else
                if (currentState.reducer.focusobj.action.type === 'time') {
                  console.log("461")
                  //When we chosen a time interval (show/hide), the frontend will receive the interval through post message
                  //timeshow and timehide
                  if (currentState.reducer.focusobj.action.command !== 'null') {
                    this.setComponentTime(currentState)
                  } else {
                    textobj['show'] = currentState.reducer.focusobj.obj.show;
                    textobj['hide'] = currentState.reducer.focusobj.obj.hide;
                    textobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
                    textobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
                  }
                } else if (currentState.reducer.focusobj.action.command === 'new') {
                  console.log("472")
                  this.sendPostMessage({ 'type': 'text', 'action': 'new', 'color': '#ffffff', 'scale': '1' })
                } else {
                  console.log("478")
                  textobj['properties'] = currentState.reducer.focusobj.obj.properties;
                  textobj['fulltime'] = currentState.reducer.focusobj.obj.fulltime;
                  textobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
                  textobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
                  textobj['name'] = currentState.reducer.focusobj.obj.name;
                  textobj['description'] = currentState.reducer.focusobj.obj.description;
                  textobj['x'] = currentState.reducer.focusobj.obj.x;
                  textobj['y'] = currentState.reducer.focusobj.obj.y;
                  textobj['z'] = currentState.reducer.focusobj.obj.z;
                  this.sendPostMessage(textobj);
                  this.timeLineTimeModifing(
                    currentState.reducer.focusobj.obj.properties.id,
                    currentState.reducer.focusobj.obj.tStart, 
                    currentState.reducer.focusobj.obj.tEnd,
                     currentState.reducer.focusobj.obj.fulltime);
                }
            break
          case 'htmlpage':
            if (currentState.reducer.focusobj.action.type === 'free2d') {
              this.sendPostMessage({ 'type': currentState.reducer.focusobj.action.type, 'action': { 'typeObject': 'htmlpage', 'dir': currentState.reducer.focusobj.action.dir, 'vel': currentState.reducer.focusobj.action.vel } })

            } else
              if (currentState.reducer.focusobj.action.type === 'hotspotlist') {
                this.sendPostMessage({ 'type': currentState.reducer.focusobj.action.type })

              } else
                if (currentState.reducer.focusobj.action.type === 'time') {
                  //When we chosen a time interval (show/hide), the frontend will receive the interval through post message
                  //timeshow and timehide
                  if (currentState.reducer.focusobj.action.command !== 'null') {
                    this.setComponentTime(currentState)
                  } else {
                    htmlpageobj['show'] = currentState.reducer.focusobj.obj.show;
                    htmlpageobj['hide'] = currentState.reducer.focusobj.obj.hide;
                    htmlpageobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
                    htmlpageobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
                  }
                } else if (currentState.reducer.focusobj.action.command === 'new') {
                  this.sendPostMessage({ 'type': 'htmlpage', 'action': 'new', 'color': '#ffffff', 'scale': '1' })
                } else {
                  htmlpageobj['properties'] = currentState.reducer.focusobj.obj.properties;
                  htmlpageobj['fulltime'] = currentState.reducer.focusobj.obj.fulltime;
                  htmlpageobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
                  htmlpageobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
                  htmlpageobj['name'] = currentState.reducer.focusobj.obj.name;
                  htmlpageobj['description'] = currentState.reducer.focusobj.obj.description;
                  htmlpageobj['x'] = currentState.reducer.focusobj.obj.x;
                  htmlpageobj['y'] = currentState.reducer.focusobj.obj.y;
                  htmlpageobj['z'] = currentState.reducer.focusobj.obj.z;
                  this.sendPostMessage(htmlpageobj);
                  this.timeLineTimeModifing(
                    currentState.reducer.focusobj.obj.properties.id,
                    currentState.reducer.focusobj.obj.tStart, 
                    currentState.reducer.focusobj.obj.tEnd,
                    currentState.reducer.focusobj.obj.fulltime);                
                  }
            break
          case 'image':
            if (currentState.reducer.focusobj.action.type === 'free2d') {
              this.sendPostMessage({ 'type': currentState.reducer.focusobj.action.type, 'action': { 'typeObject': 'image', 'dir': currentState.reducer.focusobj.action.dir, 'vel': currentState.reducer.focusobj.action.vel } })

            } else if (currentState.reducer.focusobj.action.type === 'hotspotlist') {
              this.sendPostMessage({ 'type': currentState.reducer.focusobj.action.type })

            } else if (currentState.reducer.focusobj.action.type === 'time') {
              //When we chosen a time interval (show/hide), the frontend will receive the interval through post message
              //timeshow and timehide
              if (currentState.reducer.focusobj.action.command !== 'null') {
                this.setComponentTime(currentState)
              } else {
                imageobj['show'] = currentState.reducer.focusobj.obj.show;
                imageobj['hide'] = currentState.reducer.focusobj.obj.hide;
                imageobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
                imageobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
              }
            } else if (currentState.reducer.focusobj.action.command === 'new') {
              this.sendPostMessage({ 'type': 'image', 'action': 'new' })
            } else {
              imageobj['properties'] = currentState.reducer.focusobj.obj.properties;
              imageobj['fulltime'] = currentState.reducer.focusobj.obj.fulltime;
              imageobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
              imageobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
              imageobj['name'] = currentState.reducer.focusobj.obj.name;
              imageobj['description'] = currentState.reducer.focusobj.obj.description;
              imageobj['x'] = currentState.reducer.focusobj.obj.x;
              imageobj['y'] = currentState.reducer.focusobj.obj.y;
              imageobj['z'] = currentState.reducer.focusobj.obj.z;
              this.sendPostMessage(imageobj);
              this.timeLineTimeModifing(
                currentState.reducer.focusobj.obj.properties.id,
                currentState.reducer.focusobj.obj.tStart, 
                currentState.reducer.focusobj.obj.tEnd,
                currentState.reducer.focusobj.obj.fulltime);  
              }
            break
          case 'video2d':
            if (currentState.reducer.focusobj.action.type === 'free2d') {
              this.sendPostMessage({ 'type': currentState.reducer.focusobj.action.type, 'action': { 'typeObject': 'video2d', 'dir': currentState.reducer.focusobj.action.dir, 'vel': currentState.reducer.focusobj.action.vel } })

            } else
              if (currentState.reducer.focusobj.action.type === 'hotspotlist') {
                this.sendPostMessage({ 'type': currentState.reducer.focusobj.action.type })

              } else
                if (currentState.reducer.focusobj.action.type === 'time') {
                  //When we chosen a time interval (show/hide), the frontend will receive the interval through post message
                  //timeshow and timehide
                  if (currentState.reducer.focusobj.action.command !== 'null') {
                    this.setComponentTime(currentState)
                  } else {
                    video2dobj['show'] = currentState.reducer.focusobj.obj.show;
                    video2dobj['hide'] = currentState.reducer.focusobj.obj.hide;
                    video2dobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
                    video2dobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
                  }
                } else if (currentState.reducer.focusobj.action.command === 'new') {
                  console.log("currentState.reducer.focusobj.mentor");
                  console.log(currentState.reducer.focusobj.action.mentor);
                  if (currentState.reducer.focusobj.action.mentor == null) {
                    this.sendPostMessage({ 'type': 'video2d', 'action': 'new', 'scale': '1', 'sizew': '100', 'sizeh': '56.25' })
                  } else {
                    this.sendPostMessage({ 'type': 'video2d', 'action': 'new', 'mentor': currentState.reducer.focusobj.action.mentor, 'mentorsnippet': currentState.reducer.focusobj.action.mentorsnippet, 'scale': '1', 'sizew': '100', 'sizeh': '56.25' })
                  }
                } else {
                  //video2dobj['scale'] = currentState.reducer.focusobj.obj.scale;
                  video2dobj['description'] = currentState.reducer.focusobj.obj.description;
                  video2dobj['name'] = currentState.reducer.focusobj.obj.name;
                  video2dobj['data'] = currentState.reducer.focusobj.obj.data;
                  video2dobj['show'] = currentState.reducer.focusobj.obj.show;
                  video2dobj['hide'] = currentState.reducer.focusobj.obj.hide;
                  video2dobj['pathresource'] = currentState.reducer.focusobj.obj.pathresource;
                  video2dobj['name'] = currentState.reducer.focusobj.obj.name;
                  video2dobj['sizew'] = currentState.reducer.focusobj.obj.sizew;
                  video2dobj['sizeh'] = currentState.reducer.focusobj.obj.sizeh;
                  video2dobj['fulltime'] = currentState.reducer.focusobj.obj.fulltime;
                  video2dobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
                  video2dobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
                  video2dobj['x'] = currentState.reducer.focusobj.obj.x;
                  video2dobj['y'] = currentState.reducer.focusobj.obj.y;
                  video2dobj['z'] = currentState.reducer.focusobj.obj.z;
                  //to set scale or cover (texture)
                  video2dobj['action'] = currentState.reducer.focusobj.action.command;
                  this.sendPostMessage(video2dobj);
                  this.timeLineTimeModifing(
                    currentState.reducer.focusobj.obj.properties.id,
                    currentState.reducer.focusobj.obj.tStart, 
                    currentState.reducer.focusobj.obj.tEnd,
                    currentState.reducer.focusobj.obj.fulltime);  
               }
            break
          case 'audio':
            if (currentState.reducer.focusobj.action.type === 'free2d') {
              this.sendPostMessage({ 'type': currentState.reducer.focusobj.action.type, 'action': { 'typeObject': 'audio', 'dir': currentState.reducer.focusobj.action.dir, 'vel': currentState.reducer.focusobj.action.vel } })

            } else
              if (currentState.reducer.focusobj.action.type === 'hotspotlist') {
                this.sendPostMessage({ 'type': currentState.reducer.focusobj.action.type })

              } else
                if (currentState.reducer.focusobj.action.type === 'time') {
                  //When we chosen a time interval (show/hide), the frontend will receive the interval through post message
                  //timeshow and timehide
                  if (currentState.reducer.focusobj.action.command !== 'null') {
                    this.setComponentTime(currentState)
                  } else {
                    audioobj['show'] = currentState.reducer.focusobj.obj.show;
                    audioobj['hide'] = currentState.reducer.focusobj.obj.hide;
                    audioobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
                    audioobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
                  }
                } else if (currentState.reducer.focusobj.action.command === 'new') {
                  this.sendPostMessage({ 'type': 'audio', 'action': 'new', 'sizew': '100', 'sizeh': '56.25' })
                } else {
                  audioobj['properties'] = currentState.reducer.focusobj.obj.properties;
                  audioobj['fulltime'] = currentState.reducer.focusobj.obj.fulltime;
                  audioobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
                  audioobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
                  audioobj['name'] = currentState.reducer.focusobj.obj.name;
                  audioobj['description'] = currentState.reducer.focusobj.obj.description;
                  audioobj['x'] = currentState.reducer.focusobj.obj.x;
                  audioobj['y'] = currentState.reducer.focusobj.obj.y;
                  audioobj['z'] = currentState.reducer.focusobj.obj.z;
                  this.sendPostMessage(audioobj);
                  this.timeLineTimeModifing(
                    currentState.reducer.focusobj.obj.properties.id,
                    currentState.reducer.focusobj.obj.tStart, 
                    currentState.reducer.focusobj.obj.tEnd,
                    currentState.reducer.focusobj.obj.fulltime);   
           }
            break
          case 'metadata':
            if (currentState.reducer.focusobj.action.type === 'time') {
              //When we chosen a time interval (show/hide), the frontend will receive the interval through post message
              //timeshow and timehide
              if (currentState.reducer.focusobj.action.command !== 'null') {
                this.setComponentTime(currentState)
              } else {
                metadataobj['show'] = currentState.reducer.focusobj.obj.show;
                metadataobj['hide'] = currentState.reducer.focusobj.obj.hide;
                metadataobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
                metadataobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
              }
            } else if (currentState.reducer.focusobj.action.command === 'new') {
              this.sendPostMessage({ 'type': 'metadata', 'action': 'new', 'subtype': currentState.reducer.focusobj.action.subtype })
            } else {
              metadataobj['subtype'] = currentState.reducer.focusobj.action.subtype;
              metadataobj['properties'] = currentState.reducer.focusobj.obj.properties;
              metadataobj['fulltime'] = currentState.reducer.focusobj.obj.fulltime;
              metadataobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
              metadataobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
              metadataobj['name'] = currentState.reducer.focusobj.obj.name;
              metadataobj['description'] = currentState.reducer.focusobj.obj.description;
              this.sendPostMessage(metadataobj);
              this.timeLineTimeModifing(
                currentState.reducer.focusobj.obj.properties.id,
                currentState.reducer.focusobj.obj.tStart, 
                currentState.reducer.focusobj.obj.tEnd,
                currentState.reducer.focusobj.obj.fulltime);  
            }
            break

          case 'textline':
            if (currentState.reducer.focusobj.action.type === 'free2d') {
              this.sendPostMessage({ 'type': currentState.reducer.focusobj.action.type, 'action': { 'typeObject': 'textline', 'dir': currentState.reducer.focusobj.action.dir, 'vel': currentState.reducer.focusobj.action.vel } })

            } else
              if (currentState.reducer.focusobj.action.type === 'hotspotlist') {
                this.sendPostMessage({ 'type': currentState.reducer.focusobj.action.type })

              } else
                if (currentState.reducer.focusobj.action.type === 'time') {
                  //When we chosen a time interval (show/hide), the frontend will receive the interval through post message
                  //timeshow and timehide
                  if (currentState.reducer.focusobj.action.command !== 'null') {
                    this.setComponentTime(currentState)
                  } else {
                    textlineobj['show'] = currentState.reducer.focusobj.obj.show;
                    textlineobj['hide'] = currentState.reducer.focusobj.obj.hide;
                    textlineobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
                    textlineobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
                  }
                } else if (currentState.reducer.focusobj.action.command === 'new') {
                  this.sendPostMessage({ 'type': 'textline', 'action': 'new', 'color': '#ffffff', 'scale': '1', 'properties': textlineobj.properties })
                } else {
                  textlineobj['properties'] = currentState.reducer.focusobj.obj.properties;
                  textlineobj['fulltime'] = currentState.reducer.focusobj.obj.fulltime;
                  textlineobj['tStart'] = currentState.reducer.focusobj.obj.tStart;
                  textlineobj['tEnd'] = currentState.reducer.focusobj.obj.tEnd;
                  textlineobj['name'] = currentState.reducer.focusobj.obj.name;
                  textlineobj['description'] = currentState.reducer.focusobj.obj.description;
                  this.sendPostMessage(textlineobj);
                  this.timeLineTimeModifing(
                    currentState.reducer.focusobj.obj.properties.id,
                    currentState.reducer.focusobj.obj.tStart, 
                    currentState.reducer.focusobj.obj.tEnd,
                    currentState.reducer.focusobj.obj.fulltime);  
                }
            break
          default:
            break
        }
      }
    }
	  /*
	  unsubscribeConfig();
	  */
  }

  /* POST MESSAGE MANAGEMENT */
  msgHandler(e) {
    console.log("message handler called ")
  switch (e.data.type) {
    case "WHOLE":
      let currentconfig = Object.assign({}, e.data.appstate);
      /*this.getWeakResourcePublicStatus();
      let publicStatusExtRes = false;
      if(currentconfig.Annotator.publicStatusExtRes){
          if(currentconfig.Annotator.publicStatusExtRes.toString() === "true"){
            publicStatusExtRes = true;
          }
      }*/
      this.props.dispatch(actionLogin(
        currentconfig.App.user.token,
        currentconfig.App.user.userid,
        true,
        currentconfig.App.user.email,
        currentconfig.App.user.role,
        currentconfig.App.user.domain,
        currentconfig.Annotator.fsname,
        currentconfig.Annotator.resType,
        currentconfig.App.name,
        currentconfig.Annotator.uri,
        currentconfig.Annotator.resourceid,
        currentconfig.App.user.domain,
        currentconfig.Annotator.metadata ? currentconfig.Annotator.metadata : null,
        currentconfig.Annotator.itemname ? currentconfig.Annotator.itemname : "",
        "false"
      ));  
      console.log("nuovo dato stato ");
      console.log(currentconfig.Annotator.editUrl )
      if (currentconfig.Annotator.annotatorMode==="edit"){
      this.setState({
        medialanguage:currentconfig.Annotator.medialanguage,
        editModal: true,
        videoIdentifier: currentconfig.Annotator.resourceid
      }, () => {
        var data = {};
        data.activeIndex = 0;
        this.onTabChangeLeft("", data)});
        } else if(currentconfig.Annotator.annotatorMode==="play"){
           this.setState({
        playModal: true,
        videoIdentifier: currentconfig.Annotator.resourceid
      }, () => {
        var data = {};
        data.activeIndex = 0;
        this.onTabChangeLeft("", data)});
        }else if(currentconfig.Annotator.annotatorMode==="directEdit"){
          console.log("current config annotator")
            console.log(currentconfig.Annotator);
          var data = {};
          data.activeIndex = 0;
          this.onTabChangeLeft("", data);
          this.setState({
            items: [],
            selected:currentconfig.Annotator.editUrl ,
            urlLoading: currentconfig.Annotator.editUrl
          });
        }
      break;
      case 'hotspotlist':
        this.props.dispatch(setObjListAction(e.data.hotspotlist));
        break
      case 'configitems':
        /*if (e.data.items.length > 0) {
          this.setState({items: e.data.items.sort(function (a, b) {return parseFloat(a.tStart) - parseFloat(b.tStart)}).slice(),tabBarActiveIndexCx: 0}, this.timelineLoading());
          var data={};
          data.activeIndex=0;
          this.onTabChangeLeft("",data);
          this.onTabChangeCenter("",data);
        } else {
          this.setState({items: []},() =>{
            var data={};
            data.activeIndex=1;
            this.onTabChangeLeft("",data);
            this.onTabChangeCenter("",data);
          })
        }*/
        var data = { activeIndex: 0 };
        this.onTabChangeLeft("", data);

        if (e.data.items.length > 0) {
          // if (this.props.reducer.resType !=="IMAGE2" && this.props.reducer.resType !=="IMAGE3"){
          this.setState({
            items: e.data.items.sort(function (a, b) {
              return parseFloat(a.tStart) - parseFloat(b.tStart)
            }).slice(), 
            tabBarActiveIndexCx: 0,
            webcloudresid: e.data.items[0].webcloudresid
          },()=> {this.timelineLoading(); this.getWeakResourcePublicStatus()});

          var data = { activeIndex: 0 };
          this.onTabChangeCenter("", data);
          // }else {
          //   var data = { activeIndex: 1 };
          //   this.setState({
          //   items: e.data.items.sort(function (a, b) {
          //     return parseFloat(a.tStart) - parseFloat(b.tStart)
          //   }).slice(), tabBarActiveIndexCx: 0
          // }, this.jumpsLoading());            
          // this.onTabChangeCenter("", data);
          // }
        } else {
          this.setState({ items: [] });
        }
        break
      case 'objdeleted':
        if (e.data.objIdentifier) {
          for (var i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].objIdentifier === e.data.objIdentifier) {
              this.state.items.splice(i, 1);
              this.setState({ items: this.state.items, tabBarActiveIndexCx: 0 }, this.timelineLoading());
            }
          }
        }
        //break//?? questo break non lo capisco da verificare !!!
        //this.setState({ duration: e.data.duration, loading: false })
        break
      case 'loading':
        this.setState({ loading: false, duration: e.data.duration });
        this.props.dispatch(setComponentInfo(e.data.duration));
        this.waitingAnswer();
        break
      case 'timeshow':
        var updated = reduxStore.getState();
        console.log("show");

        var obj = Object.assign({}, updated.reducer.focusobj.obj);
        obj.show = e.data.current;
        obj.tStart = e.data.currentSecond;
        obj.hide = e.data.hide;
        obj.tEnd = e.data.tEnd;
        this.props.dispatch(setfocusobj(e.data.focus, obj, { 'type': 'time', 'command': 'null' }));
        this.waitingAnswer();
        break
      case 'timehide':
        var updated = reduxStore.getState();
        console.log("hide");
        var obj = Object.assign({}, updated.reducer.focusobj.obj);
        obj.hide = e.data.current;
        obj.tEnd = e.data.currentSecond;
        obj.show = e.data.show;
        obj.tStart = e.data.tStart;
        this.props.dispatch(setfocusobj(e.data.focus, obj, { 'type': 'time', 'command': 'null' }));
        this.waitingAnswer();
        break
      case 'fulltime':
        var updated = reduxStore.getState();
        var obj = Object.assign({}, updated.reducer.focusobj.obj);
        obj.hide = e.data.current;
        this.props.dispatch(setfocusobj(e.data.focus, obj, { 'type': 'time', 'command': 'null' }));
        this.waitingAnswer();
        break
      case 'autosavingconfirm':
        if (e.data.status) {

          this.props.dispatch(setAutoSavingAction(true));

        } else {
          this.setState({ msgSavingOpen: true, savingStatus: false })
        }
        // 	this.props.dispatch(setfocusobj("",{},""));
        // this.waitingAnswer();
        break
      case 'savingconfirm':
        if (e.data.status) {
          this.setState({ msgSavingOpen: true, savingStatus: true })
        } else {
          this.setState({ msgSavingOpen: true, savingStatus: false })
        }
        this.props.dispatch(setfocusobj("", {}, ""));
        this.waitingAnswer();
        var data = {};
        data.activeIndex = 0;
        this.onTabChangeLeft("", data);
        //this.setState({rightMenuDeactive:false});

        var data = {};
        data.activeIndex = 0;
        this.onTabChangeRight("", data);
        ReactDOM.render(<div></div>, document.getElementById('menuVerticalRight'));
        ReactDOM.render(<div></div>, document.getElementById('menuVerticalRightHeader'));
        break
      case 'objectfocus':

        this.setState({ tabBarActiveIndex: 0, rightMenuDeactive: false });
        if (document.getElementById('menuVerticalRight')) {

          /*
          * You Must reset the Menu to update it in correctly mode
          */
          //document.getElementById('menuVerticalRight') ? ReactDOM.unmountComponentAtNode(document.getElementById('menuVerticalRight')) : ''

          if (typeof e.data.properties !== 'undefined' && e.data.properties !== null) {
            if (!e.data.properties.events) {
              e.data.properties.events = [];
            }
          }
          this.setState({ objectfocus: e.data });

          var suffix = Math.floor((Math.random() * 100) + 1);
          var default_name = e.data.category + '_' + suffix;
          var defaulttStart = (!e.data.tStart ? 0 : e.data.tStart);
          var defaulttEnd = (!e.data.tEnd ? this.state.duration : e.data.tEnd);




          if (e.data.category === 'shape') {
            //update shape object
            shapeobj['alpha'] = e.data.alpha ? e.data.alpha : 1;
            shapeobj['scale'] = e.data.scale ? e.data.scale : 1;
            shapeobj['link'] = e.data.url;
            shapeobj['description'] = e.data.description;
            shapeobj['name'] = (e.data.name !== "" ? e.data.name : default_name);
            shapeobj['shape'] = e.data.shape;
            shapeobj['color'] = e.data.color;
            shapeobj['show'] = e.data.show;
            shapeobj['hide'] = e.data.hide;
            shapeobj['tStart'] = defaulttStart;
            shapeobj['tEnd'] = defaulttEnd;
            shapeobj['fulltime'] = e.data.fulltime;
            this.props.dispatch(setfocusobj('shape', shapeobj, 'focus'));
            this.waitingAnswer();
            //object properties
            ReactDOM.render(<MenuFirst
              reducer={this.props.reducer}
              callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
          } else if (e.data.category === 'obj') {
            trediobj['alpha'] = e.data.alpha ? e.data.alpha : 1
            trediobj['scale'] = e.data.scale ? e.data.scale : 1
            trediobj['turny'] = e.data.turny
            trediobj['turnx'] = e.data.turnx
            trediobj['turnz'] = e.data.turnz
            trediobj['pathorigin'] = e.data.pathorigin
            trediobj['color'] = e.data.color
            trediobj['show'] = e.data.show
            trediobj['hide'] = e.data.hide
            trediobj['tStart'] = defaulttStart;
            trediobj['tEnd'] = defaulttEnd;
            trediobj['fulltime'] = e.data.fulltime
            this.props.dispatch(setfocusobj('3d', trediobj, 'focus'));
            this.waitingAnswer();
            //object properties
            if (e.data.action !== 'free2d') {
              ReactDOM.render(<MenuSecond
                reducer={this.props.reducer}
                callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'));
            }
          } else if (e.data.category === 'text') {
            if ((typeof e.data.properties.vo === "undefined") || (typeof e.data.properties.vo === null)) {
              console.log("vo  non esiste  ");
              if (!e.data.properties.vo) {
                console.log("creo vo  ");
                e.data.properties.vo = {};
              }
            }
            if ((typeof e.data.properties.vo.showhotspot === "undefined") || (typeof e.data.properties.vo.showhotspot === null)) {
              console.log("showhotspot non eisste default true ");
              if (!e.data.properties.vo.showhotspot) {
                console.log("ho creato showhotspot");
                e.data.properties.vo.showhotspot = true;
              }
            }
            textobj['properties'] = e.data.properties;
            textobj['show'] = e.data.show;
            textobj['hide'] = e.data.hide;
            textobj['tStart'] = defaulttStart;
            textobj['tEnd'] = defaulttEnd;
            textobj['fulltime'] = e.data.fulltime;
            textobj['name'] = (e.data.name !== "" ? e.data.name : default_name);
            textobj['description'] = e.data.description;
            textobj['x'] = e.data.x;
            textobj['y'] = e.data.y;
            textobj['z'] = e.data.z;
            this.props.dispatch(setfocusobj('text', textobj, 'focus'));
            this.waitingAnswer();
            //text properties
            if (e.data.action !== 'free2d') {
              ReactDOM.render(<MenuThird
                action={e.data.action}
                reducer={this.props.reducer}
                callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
            }
          } else if (e.data.category === 'htmlpage') {
            if ((typeof e.data.properties.vo === "undefined") || (typeof e.data.properties.vo === null)) {
              console.log("vo  non esiste  ");
              if (!e.data.properties.vo) {
                console.log("creo vo  ");
                e.data.properties.vo = {};
              }
            }
            if ((typeof e.data.properties.vo.showhotspot === "undefined") || (typeof e.data.properties.vo.showhotspot === null)) {
              console.log("showhotspot non eisste default true ");
              if (!e.data.properties.vo.showhotspot) {
                console.log("ho creato showhotspot");
                e.data.properties.vo.showhotspot = true;
              }
            }
            htmlpageobj['properties'] = e.data.properties;
            htmlpageobj['show'] = e.data.show;
            htmlpageobj['hide'] = e.data.hide;
            htmlpageobj['tStart'] = defaulttStart;
            htmlpageobj['tEnd'] = defaulttEnd;
            htmlpageobj['fulltime'] = e.data.fulltime;
            htmlpageobj['name'] = (e.data.name !== "" ? e.data.name : default_name);
            htmlpageobj['description'] = e.data.description;
            htmlpageobj['x'] = e.data.x;
            htmlpageobj['y'] = e.data.y;
            htmlpageobj['z'] = e.data.z;
            this.props.dispatch(setfocusobj('htmlpage', htmlpageobj, 'focus'));
            this.waitingAnswer();
            //text properties
            if (e.data.action !== 'free2d') {
              ReactDOM.render(<MenuFourth
                action={e.data.action}
                reducer={this.props.reducer}
                callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
            }
          } else if (e.data.category === 'image') {
            if ((typeof e.data.properties.vo === "undefined") || (typeof e.data.properties.vo === null)) {
              console.log("vo  non esiste  ");
              if (!e.data.properties.vo) {
                console.log("creo vo  ");
                e.data.properties.vo = {};
              }
            }
            if ((typeof e.data.properties.vo.showhotspot === "undefined") || (typeof e.data.properties.vo.showhotspot === null)) {
              console.log("showhotspot non eisste default true ");
              if (!e.data.properties.vo.showhotspot) {
                console.log("ho creato showhotspot");
                e.data.properties.vo.showhotspot = true;
              }
            }
            imageobj['properties'] = e.data.properties;
            imageobj['show'] = e.data.show;
            imageobj['hide'] = e.data.hide;
            imageobj['tStart'] = defaulttStart;
            imageobj['tEnd'] = defaulttEnd;
            imageobj['fulltime'] = e.data.fulltime;
            imageobj['name'] = (e.data.name !== "" ? e.data.name : default_name);
            imageobj['description'] = e.data.description;
            imageobj['x'] = e.data.x;
            imageobj['y'] = e.data.y;
            imageobj['z'] = e.data.z;
            this.props.dispatch(setfocusobj('image', imageobj, 'focus'));
            this.waitingAnswer();
            //text properties
            if (e.data.action !== 'free2d') {
              ReactDOM.render(<MenuSixth
                action={e.data.action}
                reducer={this.props.reducer}
                callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
            }
          } else if (e.data.category === 'video2d') {

            if ((typeof e.data.properties.vo === "undefined") || (typeof e.data.properties.vo === null)) {
              console.log("vo  non esiste  ");
              if (!e.data.properties.vo) {
                console.log("creo vo  ");
                e.data.properties.vo = {};
              }
            }
            if ((typeof e.data.properties.vo.showhotspot === "undefined") || (typeof e.data.properties.vo.showhotspot === null)) {
              console.log("showhotspot non eisste default true ");
              if (!e.data.properties.vo.showhotspot) {
                console.log("ho creato showhotspot");
                e.data.properties.vo.showhotspot = true;
              }
            }
            video2dobj['properties'] = e.data.properties;
            video2dobj['description'] = e.data.description;
            video2dobj['name'] = (e.data.name !== "" ? e.data.name : default_name);
            video2dobj['data'] = e.data;
            video2dobj['show'] = e.data.show;
            video2dobj['hide'] = e.data.hide;
            video2dobj['tStart'] = defaulttStart;
            video2dobj['tEnd'] = defaulttEnd;
            video2dobj['sizew'] = e.data.sizew;
            video2dobj['sizeh'] = e.data.sizeh;
            video2dobj['fulltime'] = e.data.fulltime;
            video2dobj['x'] = e.data.x;
            video2dobj['y'] = e.data.y;
            video2dobj['z'] = e.data.z;
            this.props.dispatch(setfocusobj('video2d', video2dobj, 'focus'));
            this.waitingAnswer();
            //video properties
            if (e.data.action !== 'free2d') {
              if (video2dobj.properties.mentor == null) {
                console.log("caso in cui non esiste mentor");
                ReactDOM.render(<MenuFifth
                  action={e.data.action}
                  reducer={this.props.reducer}
                  callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
              } else {
                if (video2dobj.properties.mentor === true) {
                  console.log("this.props.store");
                  console.log(this.props.store);
                  ReactDOM.render(<MenuFifthMentor
                    action={e.data.action}
                    reducer={this.props.reducer}
                    dispatch={this.props.dispatch}
                    store={this.props.store}
                    parent={this}
                    callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
                } else {
                  console.log("caso in cui  mentor e false");
                  ReactDOM.render(<MenuFifth
                    action={e.data.action}
                    reducer={this.props.reducer}
                    callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
                }
              }
              // ReactDOM.render(<MenuFifth
              // 	reducer={this.props.reducer}
              // 	callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
            }
          } else if (e.data.category === 'audio') {

            console.log("e.data.category === 'audio'");

            if ((typeof e.data.properties.vo === "undefined") || (typeof e.data.properties.vo === null)) {
              console.log("vo  non esiste  ");
              if (!e.data.properties.vo) {
                console.log("creo vo  ");
                e.data.properties.vo = {};
              }
            }
            if ((typeof e.data.properties.vo.showhotspot === "undefined") || (typeof e.data.properties.vo.showhotspot === null)) {
              console.log("showhotspot non eisste default true ");
              if (!e.data.properties.vo.showhotspot) {
                console.log("ho creato showhotspot");
                e.data.properties.vo.showhotspot = true;
              }
            }

            audioobj['properties'] = e.data.properties;
            audioobj['show'] = e.data.show;
            audioobj['hide'] = e.data.hide;
            audioobj['tStart'] = defaulttStart;
            audioobj['tEnd'] = defaulttEnd;
            audioobj['fulltime'] = e.data.fulltime;
            audioobj['name'] = (e.data.name !== "" ? e.data.name : default_name);
            audioobj['description'] = e.data.description;
            audioobj['x'] = e.data.x;
            audioobj['y'] = e.data.y;
            audioobj['z'] = e.data.z;
            this.props.dispatch(setfocusobj('audio', audioobj, 'focus'));
            this.waitingAnswer();
            //text properties
            if (e.data.action !== 'free2d') {
              ReactDOM.render(<MenuSeventh
                action={e.data.action}
                reducer={this.props.reducer}
                callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
            }
          } else if (e.data.category === 'metadata') {
            metadataobj['properties'] = e.data.properties;
            metadataobj['show'] = e.data.show;
            metadataobj['hide'] = e.data.hide;
            metadataobj['tStart'] = defaulttStart;
            metadataobj['tEnd'] = defaulttEnd;
            metadataobj['fulltime'] = e.data.fulltime;
            metadataobj['name'] = (e.data.name !== "" ? e.data.name : default_name);
            metadataobj['description'] = e.data.description;
            this.props.dispatch(setfocusobj('metadata', metadataobj, 'focus'));
            this.waitingAnswer();
            //text properties
            if (e.data.action !== 'free2d') {
              ReactDOM.render(<MetadataMenu
                action={e.data.action}
                reducer={this.props.reducer}
                callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
            }
          } else if (e.data.category === 'textline') {
            if ((typeof e.data.properties.vo === "undefined") || (typeof e.data.properties.vo === null)) {
              console.log("vo  non esiste  ");
              if (!e.data.properties.vo) {
                console.log("creo vo  ");
                e.data.properties.vo = {};
              }
            }
            if ((typeof e.data.properties.vo.showhotspot === "undefined") || (typeof e.data.properties.vo.showhotspot === null)) {
              console.log("showhotspot non eisste default true ");
              if (!e.data.properties.vo.showhotspot) {
                console.log("ho creato showhotspot");
                e.data.properties.vo.showhotspot = true;
              }
            }
            textlineobj['properties'] = e.data.properties;
            textlineobj['show'] = e.data.show;
            textlineobj['hide'] = e.data.hide;
            textlineobj['tStart'] = defaulttStart;
            textlineobj['tEnd'] = defaulttEnd;
            textlineobj['fulltime'] = e.data.fulltime;
            textlineobj['name'] = (e.data.name !== "" ? e.data.name : default_name);
            textlineobj['description'] = e.data.description;
            this.props.dispatch(setfocusobj('textline', textlineobj, 'focus'));
            this.waitingAnswer();
            //text properties
            if (e.data.action !== 'free2d') {
              ReactDOM.render(<MenuEight
                action={e.data.action}
                reducer={this.props.reducer}
                callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
            }
          }
          //Open event menu
          if (e.data.action === 'event') {
            this.setState({ tabBarActiveIndex: 1, rightMenuDeactive: false });
            this.onTabChangeRight({}, { activeIndex: 1 });
          }
        }
        // console.log(e.data);
        ReactDOM.render(<HeaderRightMenu data={e.data.properties} category={e.data.category} defaultName={default_name} reducer={this.props.reducer}></HeaderRightMenu>, document.getElementById('menuVerticalRightHeader'));
        if (timelineref) {
          timelineref.setFocusObjsWithId(e.data.id); //objIdentifier = properties.id (NON è _id di mongo)
        }
        break
      case 'hotspotlist':
        console.log("ho richiesto i dati hot spot sto nel configuration.jsx")
        break
      case 'objectDeFocus':
        this.props.dispatch(setfocusobj("", {}, ""));
        this.waitingAnswer();
        var data = {};
        data.activeIndex = 0;
        this.onTabChangeLeft("", data);
        //this.setState({rightMenuDeactive:false});
        var data = {};
        data.activeIndex = 0;
        this.onTabChangeRight("", data);
        ReactDOM.render(<div></div>, document.getElementById('menuVerticalRight'));
        ReactDOM.render(<div></div>, document.getElementById('menuVerticalRightHeader'));
        if (timelineref) {
          timelineref.clearCurrentSelection();
        }
        break  
      default:
        break
    }
  }

  msgSend(e, data) {
    if (!e) {
      this.setState({ control: data }, this.postControl)
    } else {
      var obj = { type: data.type }
      this.setState({ control: obj }, this.postControl)
    }
  }

  postControl() {
    var iframe = document.getElementById('iframeObj')
    if (iframe) {
      var iframeContentWindow = iframe.contentWindow
      iframeContentWindow.postMessage(this.state.control, services.domain)
    }
  }

  renderIframe(url) {
    //ReactDOM.unmountComponentAtNode(document.getElementById('iframebox'))
    this.setState({ urlLoading: url });
  }

  handleCompass() {
    this.sendPostMessage({ 'type': 'handleCompass', "compassStatus": this.state.compassStatus, "compassPosition": this.state.compassPosition });
  }

  funcModalGraphClose(){
    
    this.setState({ModalOpenGraph: false})
  
}


  loadVideo() {
     console.log(this.state.identifier);
     console.log(this.props.reducer.resourceid);
    if (this.props.reducer.resourceid.length > 0) {
      this.setState({ loading: true });
      var servicePath = services.domain + services.video_learning_GET_AUTH.replace('[id]', this.state.identifier);
      servicePath = servicePath + '&config=controls';
      servicePath = servicePath + '&resType=' + this.props.reducer.resType; //IMAGE2 || IMAGE || VIDEO3 ||VIDEO2
      servicePath = servicePath + '&id=' + this.props.reducer.resourceid;
      servicePath = servicePath + '&appname=' + this.props.reducer.appname;
      servicePath = servicePath + '&domain=' + this.props.reducer.domain;
      servicePath = servicePath + '&userid=' + this.props.reducer.userid;
      servicePath = servicePath + '&local=' + true;
      servicePath = servicePath + '&cover=' + false;
      servicePath = servicePath + '&mediares=first&medialan='+this.state.medialanguage;
      let externalTypeToAnnotate=["IMAGECLOUD3","IMAGECLOUD2","VIDEOCLOUD2","VIDEOCLOUD3"];
      if (externalTypeToAnnotate.includes(this.props.reducer.resType.toUpperCase())){
        console.log("uri risorsa esterna da annotare annotator");
        console.log(this.props.reducer.uri);
        servicePath = servicePath + '&uriExternalRes='+this.props.reducer.uri; 
      }
      console.log(servicePath)
      this.setState({
        items: [],
        selected: servicePath,
        urlLoading: servicePath
      });
    } else {
      this.setState({ items: [], selected: '' });
    }
  }

  choiceCategory(event, data) {
    this.setState({ category: data.value })
  }

  mergeConfigPlay(event, data) {
    if (data.value.length > 0) {
      this.setState({ identifier: data.value }, this.loadVideo)
    } else {
      this.setState({ identifier: '', items: [], selected: '' })
    }
  }

  deleteObjectAll(event, data) {
    var self = this
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt
    Axios({
      method: 'delete',
      url: services.domain + services.delete_allconfig_DELETE_AUTH,
      data: {
        processId: data.identifier, //configId
        resourceName: this.state.videoIdentifier,
        appname: this.props.reducer.appname,
        domainAsset: this.props.reducer.domain,
      }
    })
      .then(function (response) {
        if (response.status === 204) {
          self.setState({ items: [], editModal: true, ModalOpenTrash: false })
        }
        self.setState({ ModalOpenTrash: false });
      })
      .catch(function (error) {
        console.log('Errore in funzione [Configuration.jsx/deleteObjectAll]: ', error);
        self.setState({ ModalOpenTrash: false });
      })
  }

  modifyName(event, data) {
    var self = this
    var data1 = {
      identifier: data.identifier, //configId
      resourceName: this.state.videoIdentifier,
      newName: data.newName,
      newConsumerName: data.newConsumerName
    };

    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt;
    const headers = { headers: { 'content-type': 'application/json' } };
    Axios.post(services.domain + services.postModifyName, data1, headers)
      .then(function (response) {
        if (response.status === 200) {
          console.log('Funzione chiamata modifyName 200' + JSON.stringify(response.data.message));
          self.communicationMethod.onModifiedNameItem(response.data.message, "");
        }
      })
      .catch(function (error) {
        console.log('Errore in funzione [Configuration.jsx/modifyName]: ', error);
        self.communicationMethod.onModifiedNameItem("", error);
      });
  }


  copyObject(event, data) {
    var self = this
    var data1 = {
      identifier: data.identifier, //configId
      resourceName: this.state.videoIdentifier,
      owner: this.props.reducer.userid,
      group:this.props.reducer.group
    };

    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt;
    const headers = { headers: { 'content-type': 'application/json' } };
    Axios.post(services.domain + services.postCopyConfig, data1, headers)
      .then(function (response) {
        if (response.status === 200) {
          console.log('Funzione chiamata copyConfig 200' + JSON.stringify(response.data.message));
          self.communicationMethod.onCopiedItem();
        }
      })
      .catch(function (error) {
        console.log('Errore in funzione [Configuration.jsx/copyObject]: ', error)
      });
  }

  copyObjectAnotherVideo(event, data) {
    var self = this
    console.log(data);
    var dataToSend = {
      configName: data.configurationNameOld,//configId
      newConfigName: data.configurationName,
      videoToCopy: data.videoName,
      videoToCopyDuration: data.videoDuration,
      resourceName: this.state.videoIdentifier,//vecchio video di provenienza
      owner: this.props.reducer.userid,
      group:this.props.reducer.group

    };

    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt;
    const headers = { headers: { 'content-type': 'application/json' } };
    Axios.post(services.domain + services.postCopyAnotherVideoConfig, dataToSend, headers)
      .then(function (response) {
        if (response.status === 200) {
          console.log('Funzione chiamata copyObjectAnotherVideo 200' + JSON.stringify(response.data.message));
          self.communicationMethod.onCopiedItem();
        }
      })
      .catch(function (error) {
        console.log('Errore in funzione [Configuration.jsx/copyObjectAnotherVideo]: ', error)
      });
  }



  saveTopic(event, data) {
    var self = this
    var data1 = {
      topic: data.topic,
      resourceName: this.state.videoIdentifier
    };

    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt;
    const headers = { headers: { 'content-type': 'application/json' } };
    Axios.post(services.domain + services.postSaveTopic, data1, headers)
      .then(function (response) {
        if (response.status === 200) {
          console.log('Funzione chiamata saveTopic 200' + JSON.stringify(response.data.message));
          //  self.communicationMethod.onCopiedItem();
        }
      })
      .catch(function (error) {
        console.log('Errore in funzione  [Configuration.jsx/saveTopic]: ', error)
      });
  }


  onTabChangeRight = (event, data) => {
    switch (data.activeIndex) {
      case 0://Props
        if (this.props.reducer.focusobj.type === 'shape') {
          ReactDOM.render(<MenuFirst reducer={this.props.reducer}
            dispatch={this.props.dispatch}
            store={this.props.store}
            callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
          this.setState({ tabBarActiveIndex: data.activeIndex });
        } else if (this.props.reducer.focusobj.type === 'text') {
          ReactDOM.render(<MenuThird reducer={this.props.reducer}
            dispatch={this.props.dispatch}
            store={this.props.store}
            callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
          this.setState({ tabBarActiveIndex: data.activeIndex });
        } else if (this.props.reducer.focusobj.type === 'textline') {
          ReactDOM.render(<MenuEight reducer={this.props.reducer}
            dispatch={this.props.dispatch}
            store={this.props.store}
            callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
          this.setState({ tabBarActiveIndex: data.activeIndex });
        } else if (this.props.reducer.focusobj.type === 'video2d') {
          //video properties
          // console.log(this.props.reducer.focusobj)
          if (this.props.reducer.focusobj.obj.properties.mentor == null) {
            console.log("caso in cui non esiste mentor");
            ReactDOM.render(<MenuFifth reducer={this.props.reducer}
              dispatch={this.props.dispatch}
              store={this.props.store}
              callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
            this.setState({ tabBarActiveIndex: data.activeIndex });
          } else {
            if (this.props.reducer.focusobj.obj.properties.mentor === true) {
              ReactDOM.render(<MenuFifthMentor reducer={this.props.reducer}
                dispatch={this.props.dispatch}
                store={this.props.store}
                parent={this}
                callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
              this.setState({ tabBarActiveIndex: data.activeIndex });
            } else {
              console.log("caso in cui  mentor e false");
              ReactDOM.render(<MenuFifth reducer={this.props.reducer}
                dispatch={this.props.dispatch}
                store={this.props.store}
                callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
              this.setState({ tabBarActiveIndex: data.activeIndex });
            }
          }
        } else if (this.props.reducer.focusobj.type === 'htmlpage') {
          ReactDOM.render(<MenuFourth reducer={this.props.reducer}
            dispatch={this.props.dispatch}
            store={this.props.store}
            callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
          this.setState({ tabBarActiveIndex: data.activeIndex });
        } else if (this.props.reducer.focusobj.type === 'image') {
          ReactDOM.render(<MenuSixth reducer={this.props.reducer}
            dispatch={this.props.dispatch}
            store={this.props.store}
            callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'))
          this.setState({ tabBarActiveIndex: data.activeIndex });
        } else if (this.props.reducer.focusobj.type === 'audio') {
          ReactDOM.render(<MenuSeventh reducer={this.props.reducer}
            dispatch={this.props.dispatch}
            store={this.props.store}
            callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'));
          this.setState({ tabBarActiveIndex: data.activeIndex });
        } else if (this.props.reducer.focusobj.type === 'metadata') {
          ReactDOM.render(<MetadataMenu reducer={this.props.reducer}
            dispatch={this.props.dispatch}
            store={this.props.store}
            callbackParent={this.menuCallback} />, document.getElementById('menuVerticalRight'));
          this.setState({ tabBarActiveIndex: data.activeIndex });
        }
        break
      case 1: //event
        if (this.props.reducer.focusobj.type === 'shape') {
          ReactDOM.render(<SMenuFirst reducer={this.props.reducer}
            dispatch={this.props.dispatch}
            store={this.props.store}
            parent={this}
          />, document.getElementById('menuVerticalRight'))
          this.setState({ tabBarActiveIndex: data.activeIndex });
        } else if (this.props.reducer.focusobj.type === 'image') {
          ReactDOM.render(<ImageAction reducer={this.props.reducer}
            dispatch={this.props.dispatch}
            store={this.props.store}
            parent={this}
            saveFunction={this.editSave} />, document.getElementById('menuVerticalRight'))
          this.setState({ tabBarActiveIndex: data.activeIndex });
        } else if (this.props.reducer.focusobj.type === 'htmlpage') {
          ReactDOM.render(<HtmlAction reducer={this.props.reducer}
            dispatch={this.props.dispatch}
            store={this.props.store}
            parent={this} />, document.getElementById('menuVerticalRight'))
          this.setState({ tabBarActiveIndex: data.activeIndex });
        } else if (this.props.reducer.focusobj.type === 'video2d') {
          if (this.props.reducer.focusobj.obj.properties.mentor) {
            if (this.props.reducer.focusobj.obj.properties.mentor) {
              ReactDOM.render(<div className="borderSchemaRightMentor"><p style={{ 'marginLeft': '10px' }} >No Events for Mentor</p></div>, document.getElementById('menuVerticalRight'))
              this.setState({ tabBarActiveIndex: data.activeIndex });
            } else {
              ReactDOM.render(<VideoAction reducer={this.props.reducer}
                dispatch={this.props.dispatch}
                store={this.props.store}
                parent={this} />, document.getElementById('menuVerticalRight'))
              this.setState({ tabBarActiveIndex: data.activeIndex });
            }
          } else {
            ReactDOM.render(<VideoAction reducer={this.props.reducer}
              dispatch={this.props.dispatch}
              store={this.props.store}
              parent={this} />, document.getElementById('menuVerticalRight'))
            this.setState({ tabBarActiveIndex: data.activeIndex });
          }
        } else if (this.props.reducer.focusobj.type === 'audio') {
          ReactDOM.render(<AudioAction reducer={this.props.reducer}
            dispatch={this.props.dispatch}
            store={this.props.store}
            parent={this} />, document.getElementById('menuVerticalRight'))
          this.setState({ tabBarActiveIndex: data.activeIndex });
        } else if (this.props.reducer.focusobj.type === 'text') {
          ReactDOM.render(<TextAction reducer={this.props.reducer}
            dispatch={this.props.dispatch}
            store={this.props.store}
            parent={this} />, document.getElementById('menuVerticalRight'))
          this.setState({ tabBarActiveIndex: data.activeIndex });
        } else if (this.props.reducer.focusobj.type === 'metadata') {
          ReactDOM.render(<MetadataAction reducer={this.props.reducer}
            dispatch={this.props.dispatch}
            store={this.props.store}
            parent={this} />, document.getElementById('menuVerticalRight'))
          this.setState({ tabBarActiveIndex: data.activeIndex });
        } else if (this.props.reducer.focusobj.type === 'textline') {
          ReactDOM.render(<TextLineAction reducer={this.props.reducer}
            dispatch={this.props.dispatch}
            store={this.props.store}
            parent={this} />, document.getElementById('menuVerticalRight'))
          this.setState({ tabBarActiveIndex: data.activeIndex });
        }
        break
      default:
        console.log('TAB Button Not linked')
        break
    }
  }
  onTabChangeLeft = (event, data) => {
    console.log('onTabChangeLeft: data.activeIndex: ', data.activeIndex);
    switch (data.activeIndex) {
      case 0://HotSpot
        ReactDOM.render(<CategoriesComponent reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('menuVerticalLeft'))
        this.setState({ tabBarActiveIndexSx: data.activeIndex });
        break;
      case 1: //Metadata
        ReactDOM.render(<MenuMetadata reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('menuVerticalLeft'))
        this.setState({ tabBarActiveIndexSx: data.activeIndex });
        break;
      default:
        console.log('[onTabChangeLeft]-TAB Button Not linked')
        break;
    }
  }


  onTabChangeCenter = (event, data) => {
    ReactDOM.unmountComponentAtNode(document.getElementById('menuCentralDown'));
    console.log('onTabChangeCenter: data.activeIndex: ', data.activeIndex);
    switch (data.activeIndex) {
      case 0: //Timeline
        if (this.state.items.length > 0) {
          this.timelineLoading();
          this.setState({ tabBarActiveIndexCx: data.activeIndex });
        }
        break;
      case 1://Jumps
        this.jumpsLoading();
        this.setState({ tabBarActiveIndexCx: data.activeIndex });
        break;
      case 2://Video Event
        this.videoEventLoading();
        this.setState({ tabBarActiveIndexCx: data.activeIndex });
        break;
      default:
        console.log('[onTabChangeCenter]-TAB Button Not linked')
        break;
    }
  }

  onChildChanged(newState) {
    if (!newState) {
      console.log('Catch funzione [Configuration.jsx/onChildChanged]newState_: ', newState)
      return
    }
    switch (newState.type) {
      case 'edit':
        var index = "";
        for (var i = 0; i < newState.configurations.length; i++) {
          if (newState.configurations[i].key === newState.configId) {
            index = i;
            break;
          }
        }
        if (index === "") { //NEW EMPTY CONFIG
        console.log("empty config")
        console.log(newState.configId)
          this.setState({ identifier: newState.configId, consumerName: newState.consumerName, uploaded: newState.uploaded, editModal: false }, this.loadVideo)
        } else {
          //controllo che il compass sia definito o meno per le vecchie configurazioni
          console.log("apro configurazione già esistente ")
          if ((newState.configurations[index].compass !== 'undefined') && (newState.configurations[index].compass)) {
            var topDX = false, topSX = false, bottomDX = false, bottomSX = false;
            if (newState.configurations[index].compass.compassStatus) {
              switch (newState.configurations[index].compass.compassPosition) {
                case "TopDX":
                  topDX = true; topSX = false; bottomDX = false; bottomSX = false;
                  break;
                case "TopSX":
                  topDX = false; topSX = true; bottomDX = false; bottomSX = false;
                  break;
                case "BottomDX":
                  topDX = false; topSX = false; bottomDX = true; bottomSX = false;
                  break;
                case "BottomSX":
                  topDX = false; topSX = false; bottomDX = false; bottomSX = true;
                  break;
                default:
                  break;
              }
            }
            this.setState({
              identifier: newState.configId, editModal: false, consumerName: newState.consumerName, visibility: newState.configurations[index].visibility,
              status: newState.configurations[index].status, compatibility: newState.configurations[index].compatibility,
              compassStatus: newState.configurations[index].compass.compassStatus, videoundertakeoptions: newState.configurations[index].videoundertakeoptions,
               switchoptions: newState.configurations[index].switchoptions,
               vrmodeoptions: newState.configurations[index].vrmodeoptions,
              compassPosition: newState.configurations[index].compass.compassPosition,
              topDX: topDX, topSX: topSX, bottomDX: bottomDX, bottomSX: bottomSX, uploaded: newState.uploaded
            }, this.loadVideo)

          } else {      //chiamata normale configurazioni pre compass
            this.setState({
              identifier: newState.configId, editModal: false, consumerName: newState.consumerName, visibility: newState.configurations[index].visibility, videoundertakeoptions: newState.configurations[index].videoundertakeoptions,
              status: newState.configurations[index].status, compatibility: newState.configurations[index].compatibility, uploaded: newState.uploaded
            }, this.loadVideo)
          }
          if ((newState.configurations[index].videoundertakeoptions !== 'undefined') && (newState.configurations[index].videoundertakeoptions)) {
            this.setState({ videoundertakeoptions: newState.configurations[index].videoundertakeoptions });
          }
          if ((newState.configurations[index].switchoptions !== 'undefined') && (newState.configurations[index].switchoptions)) {
            this.setState({ switchoptions: newState.configurations[index].switchoptions });
          }    
          if ((newState.configurations[index].vrmodeoptions !== 'undefined') && (newState.configurations[index].vrmodeoptions)) {
            this.setState({ vrmodeoptions: newState.configurations[index].vrmodeoptions }); 
          }
        }
        break
        case 'graph':
          // console.log("video array ")
          // console.log(newState.videoArray);
          // console.log(newState);
          //cerca il video giusto nellarray sovrastante
          console.log("newState graph ")
          console.log(newState)
          var index=(newState.videoArray.findIndex(element => element.name ===newState.videoId));
          //apertura manuale di learning aggiornato 
          this.props.history.push({pathname:'/Config',search:'id='+newState.videoId, state:{title:newState.configId,topic:this.state.videoArray[index].topic,uploaded:this.state.videoArray[index].uploaded}});
          this.props.dispatch(menuItem('configuration'));
          this.funcModalGraphClose();
          // console.log(newState.videoId)
          // console.log(newState.configId)
          // console.log(newState.configuration)
          

          
         // controllo che il compass sia definito o meno per le vecchie configurazioni
          if ((newState.configuration.compass !== 'undefined') && (newState.configuration.compass)) {
            var topDX = false, topSX = false, bottomDX = false, bottomSX = false;
            if (newState.configuration.compass.compassStatus) {
              switch (newState.configuration.compass.compassPosition) {
                case "TopDX":
                  topDX = true; topSX = false; bottomDX = false; bottomSX = false;
                  break;
                case "TopSX":
                  topDX = false; topSX = true; bottomDX = false; bottomSX = false;
                  break;
                case "BottomDX":
                  topDX = false; topSX = false; bottomDX = true; bottomSX = false;
                  break;
                case "BottomSX":
                  topDX = false; topSX = false; bottomDX = false; bottomSX = true;
                  break;
                default:
                  break;
              }
            }
            this.setState({videoIdentifier: newState.videoId,  videoFromGraph:newState.videoId,identifier:newState.configId,underType:newState.underType, title:newState.videoId,
              identifier: newState.configId, consumerName: newState.consumerName, visibility: newState.configuration.visibility,
              status: newState.configuration.status, compatibility: newState.configuration.compatibility,
              compassStatus: newState.configuration.compass.compassStatus, videoundertakeoptions: newState.configuration.videoundertakeoptions,
              switchoptions: newState.configuration.switchoptions,
              vrmodeoptions: newState.configuration.vrmodeoptions,
              compassPosition: newState.configuration.compass.compassPosition,
              topDX: topDX, topSX: topSX, bottomDX: bottomDX, bottomSX: bottomSX, uploaded: newState.uploaded
            }, this.loadVideo)

          } else {      //chiamata normale configurazioni pre compass
            this.setState({videoIdentifier: newState.videoId,videoFromGraph:newState.videoId,identifier:newState.configId,underType:newState.underType, title:newState.videoId,
              identifier: newState.configId, consumerName: newState.consumerName, visibility: newState.configuration.visibility, videoundertakeoptions: newState.configuration.videoundertakeoptions,
              switchoptions: newState.configuration.switchoptions,
              vrmodeoptions: newState.configuration.vrmodeoptions,
              status: newState.configuration.status, compatibility: newState.configuration.compatibility, uploaded:this.state.videoArray[index].uploaded
            }, this.loadVideo)
          }
          if ((newState.configuration.videoundertakeoptions !== 'undefined') && (newState.configuration.videoundertakeoptions)) {
            this.setState({ videoundertakeoptions: newState.configuration.videoundertakeoptions });
          }
          if ((newState.configuration.switchoptions !== 'undefined') && (newState.configuration.switchoptions)) {
            this.setState({ switchoptions: newState.configuration.switchoptions });
          }
          if ((newState.configurations[index].vrmodeoptions !== 'undefined') && (newState.configurations[index].vrmodeoptions)) {
            this.setState({ vrmodeoptions: newState.configurations[index].vrmodeoptions }); 
          }
          break
      case 'editeddelete':
        //shapeobj['action'] = 'edit'
        /*var obj=new Object();*/
        var obj = {}
        obj.identifier = newState.configId
        this.deleteObjectAll('', obj)
        break
      case 'obj':
        trediobj['pathorigin'] = newState.items[newState.index].pathorigin
        trediobj['action'] = 'obj'
        this.setState({ trediobj: trediobj }, this.msgSend(undefined, trediobj))
        break
      case 'objcustomization':
        trediobj['alpha'] = newState.alpha
        trediobj['turny'] = newState.turny
        trediobj['turnx'] = newState.turnx
        trediobj['turnz'] = newState.turnz
        trediobj['scale'] = newState.scale
        trediobj['action'] = 'objcustomization'
        this.setState({ trediobj: trediobj }, this.msgSend(undefined, trediobj))
        break
      case 'meta':
        this.msgSend(undefined, { type: newState.type, action: newState.action })
        break
      case 'copyconfig':
        var obj = {}
        obj.identifier = newState.configId;
        this.copyObject('', obj)
        break
      case 'copyconfiganothervideo':
        // console.log(newState)
        this.copyObjectAnotherVideo('', newState.data)
        break
      case 'modifyname':
        var obj = {}
        obj.identifier = newState.configId
        obj.newName = newState.newName
        obj.newConsumerName = newState.newConsumerName
        this.modifyName('', obj)
        break
      case 'topic':
        var obj = {}
        obj.topic = newState.selectedTopic
        this.saveTopic('', obj)
        break
      default:
        console.log('onChildChanged Not in choice', newState.type)
        break
    }
  }

  /* *  Menu callback  *  Unica per i diversi menu  */
  menuCallback(event) {
    this.setState({ activeItem: event });
    switch (event) {
      case 'time':
        //ReactDOM.unmountComponentAtNode(document.getElementById('timeshapeaccordion'))
        ReactDOM.render(<TimeComponent dispatch={this.props.dispatch}
          reducer={this.props.reducer}
          store={this.props.store}
          duration={this.state.duration}
          parent={this} />, document.getElementById('timeaccordion'))
        break
      case 'shapetext':
        //ReactDOM.unmountComponentAtNode(document.getElementById('textshapeaccordion'))
        ReactDOM.render(<ShapeText reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('textshapeaccordion'))
        this.setState({ macrocategory: 'shape' })
        break
      case 'shapecolor':
        //ReactDOM.unmountComponentAtNode(document.getElementById('colorshapeaccordion'))
        ReactDOM.render(<ShapeColor reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('colorshapeaccordion'))
        this.setState({ macrocategory: 'shape' })
        break
      case 'shapescale':
        //ReactDOM.unmountComponentAtNode(document.getElementById('scaleshapeaccordion'))
        ReactDOM.render(<ShapeScale reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('scaleshapeaccordion'))
        this.setState({ macrocategory: 'shape' })
        break
      case 'objcustomization':
        ReactDOM.render(<ObjCustomization userId={this.props.reducer.userid} jwt={this.props.reducer.jwt}
          callbackParent={this.onChildChanged} />, document.getElementById('menuVerticalLeft'))
        this.setState({ macrocategory: 'obj' })
        break
      case 'objfocuseddelete':
        this.msgSend(undefined, { type: 'delete' })
        break
      case 'text':
        //ReactDOM.unmountComponentAtNode(document.getElementById('textaccordion'))
        ReactDOM.render(<TextOnly reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('textaccordion'))
        this.setState({ macrocategory: 'text' })
        break
      case 'textcolor':
        //ReactDOM.unmountComponentAtNode(document.getElementById('textcoloraccordion'))
        ReactDOM.render(<TextColor reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('textcoloraccordion'))
        this.setState({ macrocategory: 'text' })
        break
      case 'textfont':
        //ReactDOM.unmountComponentAtNode(document.getElementById('textfontaccordion'))
        ReactDOM.render(<TextFont reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('textfontaccordion'))
        this.setState({ macrocategory: 'text' })
        break
      case 'textposition':
        //ReactDOM.unmountComponentAtNode(document.getElementById('textpositionaccordion'))
        ReactDOM.render(<TextPosition reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('textpositionaccordion'))
        this.setState({ macrocategory: 'text' })
        break
      case 'textviewoptions':
        ReactDOM.render(<TextViewOptions reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('textviewoptions'));
        this.setState({ macrocategory: 'text' });
        break
      case 'htmlpageinfo':
        //ReactDOM.unmountComponentAtNode(document.getElementById('textaccordion'))
        ReactDOM.render(<HtmlPageInfo reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('htmlpageinfo'))
        this.setState({ macrocategory: 'htmlpage' })
        break //HtmlPageAppearance
      case 'htmlpagelink':
        //ReactDOM.unmountComponentAtNode(document.getElementById('textaccordion'))
        ReactDOM.render(<HtmlPageLink reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('htmlpagelink'))
        this.setState({ macrocategory: 'htmlpage' })
        break //HtmlPageAppearance
      case 'htmlpageappearance':
        //ReactDOM.unmountComponentAtNode(document.getElementById('textaccordion'))
        ReactDOM.render(<HtmlPageAppearance reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('htmlpageappearance'))
        this.setState({ macrocategory: 'htmlpage' })
        break //HtmlPageAppearance
      case 'htmlpageposition':
        //ReactDOM.unmountComponentAtNode(document.getElementById('textpositionaccordion'))
        ReactDOM.render(<HtmlPagePosition reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('htmlpageposition'))
        this.setState({ macrocategory: 'htmlpage' })
        break
      case 'htmlpageviewoptions':
        //ReactDOM.unmountComponentAtNode(document.getElementById('textaccordion'))
        ReactDOM.render(<HtmlPageViewOptions reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('htmlpageviewoptions'));
        this.setState({ macrocategory: 'htmlpage' });
        break
      case 'imageinfo':
        //ReactDOM.unmountComponentAtNode(document.getElementById('textaccordion'))
        ReactDOM.render(<ImageInfo reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('imageinfo'))
        this.setState({ macrocategory: 'image' })
        break //HtmlPageAppearance
      case 'imageimport':
        //ReactDOM.unmountComponentAtNode(document.getElementById('textaccordion'))
        ReactDOM.render(<ImageImport reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('imageimport'))
        this.setState({ macrocategory: 'image' })
        break
      case 'imageappearance':
        //ReactDOM.unmountComponentAtNode(document.getElementById('textaccordion'))
        ReactDOM.render(<ImageAppearance reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('imageappearance'))
        this.setState({ macrocategory: 'image' })
        break
      case 'imageviewoptions':
        //ReactDOM.unmountComponentAtNode(document.getElementById('textaccordion'))
        ReactDOM.render(<ImageViewOptions reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('imageviewoptions'))
        this.setState({ macrocategory: 'image' })
        break
      case 'video2dtext':
        //ReactDOM.unmountComponentAtNode(document.getElementById('video2dtextaccordion'))
        ReactDOM.render(<Video2dText reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('video2dtextaccordion'))
        this.setState({ macrocategory: 'video2d' })
        break
      case 'video2dtextmentor':
        //ReactDOM.unmountComponentAtNode(document.getElementById('video2dtextaccordion'))
        ReactDOM.render(<MentorText reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('video2dtextaccordion'))
        this.setState({ macrocategory: 'video2d' })
        break
      case 'video2dappearance':
        //ReactDOM.unmountComponentAtNode(document.getElementById('scalevideo2daccordion'))
        ReactDOM.render(<Video2dAppearance reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('video2dappearance'))
        this.setState({ macrocategory: 'video2d' })
        break
      case 'video2dscale':
        //ReactDOM.unmountComponentAtNode(document.getElementById('scalevideo2daccordion'))
        ReactDOM.render(<Video2dScale reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('scalevideo2daccordion'))
        this.setState({ macrocategory: 'video2d' })
        break
      case 'video2doption':
        ReactDOM.render(<Video2dOptions dispatch={this.props.dispatch}
          reducer={this.props.reducer}
          store={this.props.store}
          parent={this} />, document.getElementById('optionvideo2daccordion'))
        break
      case 'video2dimport':
        ReactDOM.render(<Video2dImport reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('video2dimport'))
        this.setState({ macrocategory: 'video2d' })
        break
      case 'video2dimportmentor':
        var mentorVideoMock = [];
        if (this.props.reducer.group === "RTI") {
          mentorVideoMock = mentorVideoMockRTI;
        } else {
          mentorVideoMock = mentorVideoMockRBB;
        }
        ReactDOM.render(<Video2dImportMentor reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          videomentor={mentorVideoMock}
          parent={this} />, document.getElementById('video2dimport'))
        this.setState({ macrocategory: 'video2d' })
        break
      case 'video2dviewoptions':
        ReactDOM.render(<Video2dViewOptions reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('video2dviewoptions'));
        this.setState({ macrocategory: 'video2d' });
        break
      case 'shapetag':
        // ReactDOM.unmountComponentAtNode(document.getElementById('tagshapeaccordion'))
        ReactDOM.render(<ShapeTag userId={this.props.reducer.userid} jwt={this.props.reducer.jwt}
          store={this.props.store} dispatch={this.props.dispatch}
          callbackParent={this.onChildChanged}
          parent={this} />, document.getElementById('tagshapeaccordion'))
        this.setState({ macrocategory: 'shape' })
        break
      case 'htmlshapetag':
        // ReactDOM.unmountComponentAtNode(document.getElementById('htmltagshapeaccordion'))
        ReactDOM.render(<HtmlTag userId={this.props.reducer.userid} jwt={this.props.reducer.jwt}
          store={this.props.store} dispatch={this.props.dispatch}
          callbackParent={this.onChildChanged}
          parent={this} />, document.getElementById('htmltagshapeaccordion'))
        this.setState({ macrocategory: 'html' })
        break
      case 'textshapetag':
        // ReactDOM.unmountComponentAtNode(document.getElementById('texttagshapeaccordion'))
        ReactDOM.render(<TextTag userId={this.props.reducer.userid} jwt={this.props.reducer.jwt}
          store={this.props.store} dispatch={this.props.dispatch}
          callbackParent={this.onChildChanged}
          parent={this} />, document.getElementById('texttagshapeaccordion'))
        this.setState({ macrocategory: 'html' })
        break
      case 'video2dshapetag':
        // ReactDOM.unmountComponentAtNode(document.getElementById('video2dtagshapeaccordion'))
        ReactDOM.render(<Video2dTag userId={this.props.reducer.userid} jwt={this.props.reducer.jwt}
          store={this.props.store} dispatch={this.props.dispatch}
          callbackParent={this.onChildChanged}
          parent={this} />, document.getElementById('video2dtagshapeaccordion'))
        this.setState({ macrocategory: 'video2d' })
        break
      case 'audioshapetag':
        // ReactDOM.unmountComponentAtNode(document.getElementById('audiotagshapeaccordion'))
        ReactDOM.render(<AudioTag userId={this.props.reducer.userid} jwt={this.props.reducer.jwt}
          store={this.props.store} dispatch={this.props.dispatch}
          callbackParent={this.onChildChanged}
          parent={this} />, document.getElementById('audiotagshapeaccordion'))
        this.setState({ macrocategory: 'audio' })
        break

      case 'metadatashapetag':
        // ReactDOM.unmountComponentAtNode(document.getElementById('metadatatagshapeaccordion'))
        ReactDOM.render(<MetadataTag userId={this.props.reducer.userid} jwt={this.props.reducer.jwt}
          objectfocus={this.state.objectfocus} store={this.props.store} dispatch={this.props.dispatch}
          callbackParent={this.onChildChanged}
          parent={this} />, document.getElementById('metadatatagshapeaccordion'))
        this.setState({ macrocategory: 'metadata' })
        break
      case 'imageshapetag':
        // ReactDOM.unmountComponentAtNode(document.getElementById('imagetagshapeaccordion'))
        ReactDOM.render(<ImageTag userId={this.props.reducer.userid} jwt={this.props.reducer.jwt}
          store={this.props.store} dispatch={this.props.dispatch}
          callbackParent={this.onChildChanged}
          parent={this} />, document.getElementById('imagetagshapeaccordion'))
        this.setState({ macrocategory: 'image' })
        break
      case 'textlineshapetag':
        // ReactDOM.unmountComponentAtNode(document.getElementById('textlinetagshapeaccordion'))
        ReactDOM.render(<TextlineTag userId={this.props.reducer.userid} jwt={this.props.reducer.jwt}
          store={this.props.store} dispatch={this.props.dispatch}
          callbackParent={this.onChildChanged}
          parent={this} />, document.getElementById('textlinetagshapeaccordion'))
        this.setState({ macrocategory: 'textline' })
        break
      case 'audioinfo':
        ReactDOM.render(<AudioInfo reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('audioinfo'))
        this.setState({ macrocategory: 'audio' })
        break
      case 'audioimport':
        this.props.dispatch(launchModal('SETTING_MODAL', 'audio'))
        this.setState({ macrocategory: 'audio' })
        break
      case 'audioposition':
        console.log(this.props.reducer)
        ReactDOM.render(<AudioPosition reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('audioposition'))
        this.setState({ macrocategory: 'audio' })
        break
      case 'audiocover':
        console.log(this.props.reducer)
        ReactDOM.render(<AudioCover reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('audiocover'))
        this.setState({ macrocategory: 'audio' })
        break
      case 'htmlposition':
        console.log(this.props.reducer)
        ReactDOM.render(<HtmlPosition reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('htmlposition'))
        this.setState({ macrocategory: 'html' })
        break
      case 'video2dposition':
        console.log(this.props.reducer)
        ReactDOM.render(<Video2dPosition reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('video2dposition'))
        this.setState({ macrocategory: 'video2d' })
        break
      case 'imageposition':
        console.log(this.props.reducer)
        ReactDOM.render(<ImagePosition reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('imageposition'))
        this.setState({ macrocategory: 'image' })
        break
      case 'textpositionscene':
        console.log(this.props.reducer)
        ReactDOM.render(<TextPositionScene reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('textpositionscene'))
        this.setState({ macrocategory: 'text' })
        break
      case 'audioappearance':
        ReactDOM.render(<AudioAppearance reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('audioappearance'))
        this.setState({ macrocategory: 'audio' })
        break
      case 'audiooptions':
        ReactDOM.render(<AudioOptions dispatch={this.props.dispatch}
          reducer={this.props.reducer}
          store={this.props.store}
          parent={this} />, document.getElementById('audiooptions'))
        break
      case 'audioviewoptions':
        ReactDOM.render(<AudioViewOptions dispatch={this.props.dispatch}
          reducer={this.props.reducer}
          store={this.props.store}
          parent={this} />, document.getElementById('audioviewoptions'));
        break
      case 'metadatainfo':
        ReactDOM.render(<MetadataInfo reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('metadatainfo'))
        this.setState({ macrocategory: 'metadata' });
        break
      case 'metadataappearance':
        ReactDOM.render(<MetadataAppearance reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('metadataappearance'))
        this.setState({ macrocategory: 'metadata' });
        break
      case 'metadataviewoptions':
        ReactDOM.render(<MetadataViewOptions reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('metadataviewoptions'))
        this.setState({ macrocategory: 'metadata' });

        break
      case 'textlineinfo':
        ReactDOM.render(<TextlineInfo reducer={this.props.reducer}
          dispatch={this.props.dispatch}
          store={this.props.store}
          parent={this} />, document.getElementById('textlineinfo'))
        this.setState({ macrocategory: 'textline' })
        break
      case 'textlineviewoptions':
        ReactDOM.render(<TextlineViewOptions dispatch={this.props.dispatch}
          reducer={this.props.reducer}
          store={this.props.store}
          parent={this} />, document.getElementById('textlineviewoptions'));
        break
      case 'textlinecolor':
        ReactDOM.render(<TextlineColor dispatch={this.props.dispatch}
          reducer={this.props.reducer}
          store={this.props.store}
          parent={this} />, document.getElementById('textlinecoloraccordion'));
        this.setState({ macrocategory: 'textline' })
        break
      default:
        console.log('menuCallback Not in choice', event)
        break
    }
  }

  funcModalClose(event, data) {
    switch (data.modaltype) {
      case 'Identifier':
        this.setState({ msgIdentifierOpen: false });
        break;
      case 'Save':
        this.setState({ msgSavingOpen: false });
        break;
      case 'DeleteConf':
        this.setState({ ModalOpenTrash: false });
        break;
      case 'closeconfig':
        this.setState({ ModalOpenBackHome: false });
        break;
      case 'Closefullscreen':
        this.setState({ ModalOpenFullscreen: false });
        break;
      case 'deleteselected':
        this.setState({ msgDeleteHotspotOpen: false });
        break;
      default:
        break
    }
  }

  funcModalOpen(event, data) {
    console.log(data.modaltype)
    switch (data.modaltype) {
      case 'graphtab':
        this.setState({ ModalOpenGraph: true });
        break;
      case 'delete':
        this.setState({ ModalOpenTrash: true });
        break;
      case 'closeconfig':
        this.setState({ ModalOpenBackHome: true });
        break;
      case 'fullscreen':
        this.setState({ ModalOpenFullscreen: true });
        break;
      case 'deleteselected':
        this.setState({ msgDeleteHotspotOpen: true });
        break;
      default:
        break
    }
  }

  msgIdentifierOpen() {
    this.setState({ msgIdentifierOpen: true })
  }

  msgSavingOpen() {
    this.setState({ msgSavingOpen: true })
  }

  animationHandle(visible) {
    this.setState({ animVisible: visible })
  }

  msgFocusOptionsDelete() {
    this.setState({ msgDeleteHotspotOpen: false });
    this.msgSend(undefined, { type: 'delete' });
    ReactDOM.unmountComponentAtNode(document.getElementById('menuVerticalRight'));
  }

  msgDefocus() {
    this.msgSend(undefined, { type: 'cancelfocus' })
  }

  editSave() {
    /**27-09-2022 - pubblicazione */
    console.log("Il testo di quello che ti volevo trattatta")
    console.log(this.state.videoIdentifier);
    console.log(this.props.reducer.domain);
    
    var playfullscreenurl = services.domain + services.video_use_GET_AUTH.replace('[id]', this.state.identifier)
    playfullscreenurl = playfullscreenurl.replace('[resourceName]', this.state.videoIdentifier);
    playfullscreenurl = playfullscreenurl.replace('[resType]', this.props.reducer.resType);
    playfullscreenurl = playfullscreenurl.replace('[domainAsset]', this.props.reducer.domain);
    playfullscreenurl = playfullscreenurl.replace('[appname]', this.props.reducer.appname);
    if (this.state.compassStatus === true) {
      playfullscreenurl = playfullscreenurl + '&controls=true&postmsg=false&autoplay=true&compassStatus=' + this.state.compassStatus + "&compassPosition=" + this.state.compassPosition;
    } else {
      playfullscreenurl = playfullscreenurl + '&controls=true&postmsg=false&autoplay=true&compassStatus=' + this.state.compassStatus;
    }
    playfullscreenurl = playfullscreenurl + "&mediares=first&medialan=" + this.state.medialanguage;
    if (this.state.webcloudresid.length === 0 || !this.state.webcloudresid) {
      var self = this;
      var data1 = {
        domain: this.props.reducer.domain,
        appname: this.props.reducer.appname,
        type: "webcloud",
        dest: "resource",
        name: this.state.identifier,
        right: "CC0",
        linkright:"https://creativecommons.org/publicdomain/zero/1.0/" ,
        medialan: navigator.language.substring(0, 2) ? navigator.language.substring(0, 2) : (navigator.userLanguage.substring(0, 2) ? navigator.userLanguage.substring(0, 2) : "en"),
        mediares: "first",
        userId: this.props.reducer.userid,
        url: playfullscreenurl,
        uriCoverExternalRes: this.resourceUrl(this.state.videoIdentifier, true)
      };
      //Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt;
      /**
       * CREAZIONE NUOVA RISORSA WEB
       * */
      const headers = { headers: { 'content-type': 'application/json' } };
      Axios.post(services.ASSETBACKEND + services.resourcemanager_external_res_POST, data1, headers)
        .then(function (result) {
          if (result.status === 201) {
            //MOSTRARE POPUP DI CONFERMA ESITO
            //self.setState({webcloudresid:result.data.message._id, topublicicon: "eye"},()=>{self.autoSaving()});
            self.setState({ webcloudresid: result.data.message._id, topublicicon: "eye" }, () => { self.toIframe() });
          } else {
            self.setState({ webcloudresid: result.data.message._id, topublicicon: "eye slash" }, () => { self.toIframe() });
            //self.setState({webcloudresid:"", topublicicon: "eye slash"},()=>{self.autoSaving()});
          }
        })
        .catch(function (error) {
          self.setState({ webcloudresid: "", topublicicon: "eye slash" }, () => { self.toIframe() });
          //self.setState({webcloudresid:"", topublicicon: "eye slash"},self.autoSaving());
          //console.log('Errore in funzione  [Configuration.jsx/saveTopic]: ', error)
        });
    } else {
      this.toIframe();
    }
  }

  toIframe =()=>{
    let compass = {};
    compass.compassStatus = this.state.compassStatus;
    if ((compass.compassStatus) && (this.state.compassPosition === "")) { // posizione di default
      compass.compassPosition = "BottomSX";
    } else {
      compass.compassPosition = this.state.compassPosition;
    }
    //console.log(compass)
    var iframe = document.getElementById('iframeObj')
    if (iframe) {
      /*var iframeContentWindow = iframe.contentWindow;
      iframeContentWindow.postMessage({ 'type': 'videoeventloop', 'videoevent': this.state.videoundertakeoptions, 'owner': reduxStore.getState().reducer.userid }, '*')
      iframeContentWindow.postMessage({ 'type': 'switchbackground', 'switchevent': this.state.switchoptions, 'owner': reduxStore.getState().reducer.userid }, '*')
      iframeContentWindow.postMessage({ 'type': 'status', 'status': this.state.status, 'owner': reduxStore.getState().reducer.userid }, '*')
      iframeContentWindow.postMessage({ 'type': 'compatibility', 'compatibility': this.state.compatibility, 'owner': reduxStore.getState().reducer.userid }, '*')
      iframeContentWindow.postMessage({ 'type': 'compass', 'compass': compass, 'owner': reduxStore.getState().reducer.userid }, '*')
      iframeContentWindow.postMessage({ 'type': 'visibility', 'visibility': this.state.visibility, 'owner': reduxStore.getState().reducer.userid }, '*')
      iframeContentWindow.postMessage({ 'type': 'showasresource', 'webcloudresid': this.state.webcloudresid, 'owner': reduxStore.getState().reducer.userid}, '*')
      iframeContentWindow.postMessage({ 'type': 'save' }, '*')*/
      console.log("vrmode")
      console.log(this.state.vrmodeoptions)
      let iframeContentWindow = iframe.contentWindow;
      iframeContentWindow.postMessage({ 'type': 'save',
       'status': this.state.status, 
       'owner': reduxStore.getState().reducer.userid, 
       'compatibility': this.state.compatibility,
       'compass': compass,
       'visibility': this.state.visibility,
       'webcloudresid': this.state.webcloudresid,
       'switchevent': this.state.switchoptions,
       'vrmodeoptions': this.state.vrmodeoptions,
       'videoevent': this.state.videoundertakeoptions }, '*')
    }
  }
  

  autoSaving() {
    let compass = {};
    compass.compassStatus = this.state.compassStatus;
    if ((compass.compassStatus) && (this.state.compassPosition === "")) { // posizione di default
      compass.compassPosition = "BottomSX";
    } else {
      compass.compassPosition = this.state.compassPosition;
    }
    let iframe = document.getElementById('iframeObj')
    if (iframe) {
      let iframeContentWindow = iframe.contentWindow;
      iframeContentWindow.postMessage({ 'type': 'autosave',
       'status': this.state.status, 
       'owner': reduxStore.getState().reducer.userid, 
       'compatibility': this.state.compatibility,
       'compass': compass,
       'visibility': this.state.visibility,
       'webcloudresid': this.state.webcloudresid }, '*')
      /*iframeContentWindow.postMessage({ 'type': 'compatibility', 'compatibility': this.state.compatibility, 'owner': reduxStore.getState().reducer.userid }, '*')
      iframeContentWindow.postMessage({ 'type': 'compass', 'compass': compass, 'owner': reduxStore.getState().reducer.userid }, '*')
      iframeContentWindow.postMessage({ 'type': 'visibility', 'visibility': this.state.visibility, 'owner': reduxStore.getState().reducer.userid }, '*')
      iframeContentWindow.postMessage({ 'type': 'showasresource', 'webcloudresid': this.state.webcloudresid, 'owner': reduxStore.getState().reducer.userid}, '*')
      iframeContentWindow.postMessage({ 'type': 'autosave' }, '*')*/
    }
  }

  saveVisibilitySatus(checkoboxFlag) {
    console.log("saveVisibilitySatus pre if " + reduxStore.getState().reducer.userid);
    var iframe = document.getElementById('iframeObj')
    if (iframe) {
      console.log("saveVisibilitySatus in if " + reduxStore.getState().reducer.userid + "------" + checkoboxFlag);
      var iframeContentWindow = iframe.contentWindow;
      iframeContentWindow.postMessage({ 'type': 'visibility', 'visibility': checkoboxFlag, 'owner': reduxStore.getState().reducer.userid }, '*')
    }
  }

  handleChange(event, data) {
    //console.log("handleChange",JSON.stringify(data) );
    if (data.origin === 'status') {
      var obj = {};
      if (data.text === 'published') {
        obj['published'] = data.checked;
      }
      if (data.text === 'mentorized') {
        obj['mentorized'] = data.checked;
      }
      this.setState({ status: { ...this.state.status, ...obj } });
    }

    if (data.origin === 'compatibility') {
      var obj = {};
      if (data.text === 'web') {
        obj['web'] = data.checked;
      }
      if (data.text === 'ios') {
        obj['ios'] = data.checked;
      }
      if (data.text === 'android') {
        obj['android'] = data.checked;
      }
      if (data.text === 'hbbtv') {
        obj['hbbtv'] = data.checked;
      }
      if (data.text === 'vr') {
        obj['vr'] = data.checked;
      }

      this.setState({ compatibility: { ...this.state.compatibility, ...obj } });

    }
    if (data.origin === 'visibility') {
      this.setState({ visibility: data.value });
    }
    if (data.origin === 'compass') {

      if (!data.checked) {
        this.setState({
          compassPosition: "",
          compassStatus: data.checked,
          topDX: false,
          topSX: false,
          bottomDX: false,
          bottomSX: false
        }, this.handleCompass);
      } else {
        this.setState({ compassStatus: data.checked })
      }

    }
    if (data.origin === 'compassPosition') {

      switch (data.text) {
        case "TopSX":
          this.setState({
            compassPosition: "TopSX",
            topDX: !data.checked,
            topSX: data.checked,
            bottomDX: !data.checked,
            BottomSX: !data.checked
          }, this.handleCompass);
          break;
        case "TopDX":
          this.setState({
            compassPosition: "TopDX",
            topDX: data.checked,
            topSX: !data.checked,
            bottomDX: !data.checked,
            bottomSX: !data.checked
          }, this.handleCompass);
          break;
        case "BottomSX":
          this.setState({
            compassPosition: "BottomSX",
            topDX: !data.checked,
            topSX: !data.checked,
            bottomDX: !data.checked,
            bottomSX: data.checked
          }, this.handleCompass);
          break;
        case "BottomDX":
          this.setState({
            compassPosition: "BottomDX",
            topDX: !data.checked,
            topSX: !data.checked,
            bottomDX: data.checked,
            bottomSX: !data.checked
          }, this.handleCompass);
          break;
        default:
          this.setState({
            compassPosition: "",
            topDX: false,
            topSX: false,
            bottomDX: false,
            BottomSX: false
          }, this.handleCompass);
      }

    }
  }

  visibilityClick(event, data) { /*.Non più necessaria..*/ }

  setComponentTime(currentState) {
    if (currentState.reducer.focusobj.action.type === 'time') {
      if (currentState.reducer.focusobj.action.command === 'show') {
        this.sendPostMessage({ 'type': 'time', 'action': 'focusshow' })
      } else if (currentState.reducer.focusobj.action.command === 'hide') {
        this.sendPostMessage({ 'type': 'time', 'action': 'focushide' })
      } else if (currentState.reducer.focusobj.action.command === 'fulltime') {
        this.sendPostMessage({
          'type': 'time',
          'action': 'fulltime',
          'fulltime': currentState.reducer.focusobj.action.action
        })
      }
    }
  }

  sendPostMessage(postMessageContent) {
    const iframe = document.getElementById('iframeObj')
    if (iframe) {
      var iframeContentWindow = iframe.contentWindow
      iframeContentWindow.postMessage(postMessageContent, '*')
    }
  }

  timeLineCallback = (action, time) => {
    switch (action) {
      case "delete":
        this.msgSend(undefined, { type: 'delete', identifier: time })
        break;
        case "gotoedit":
          this.msgSend(undefined, {
            type: 'gotoedit',
            objidentifier: time.objIdentifier,
            time: time.tStart,
            fulltime: time.fulltime,
            category: time.category,
            objwhole: time,
            camerarotationx: time.camerarotationx,
            camerarotationy: time.camerarotationy,
            camerarotationz: time.camerarotationz
          });        
          break;
          case "gotoeditspecificmenu":
            this.msgSend(undefined, {
              type: 'gotoedit',
              objidentifier: time.objIdentifier,
              time: time.tStart,
              fulltime: time.fulltime,
              category: time.category,
              objwhole: time,
              camerarotationx: time.camerarotationx,
              camerarotationy: time.camerarotationy,
              camerarotationz: time.camerarotationz,
              callcategory:"menutime"
            });        
            break;
      default:
        console.log("timeLineCallback : Azione non ammessa ")
        break;
    }

  }

  editOnClose = (event, data) => {
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "closeedit"
      }
    }, "*");
    //this.props.history.push('/')
  }

  timelineLoading = () => {
    if (this.state.items.length >= 0 && document.getElementById('menuCentralDown') !== null) {
      timelineref = ReactDOM.render(<TimeLine reducer={this.props.reducer}
        dispatch={this.props.dispatch}
        store={this.props.store}
        items={this.state.items}
        callbackParent={this.timeLineCallback}
        parent={this} />, document.getElementById('menuCentralDown'));
      if (timelineref && timelineref.setConfigurationItems) {
        timelineref.setConfigurationItems(this.state.items);
      }
    }
  }

  timeLineTimeModifing = (idinput, tstart, tend, full) => {
    if (this.state.items.length > 0) {
      if (this.props.reducer.resType !=="IMAGE2" && this.props.reducer.resType !=="IMAGE3"){
      //update object focused in timeline
      var objIndex = this.state.items.findIndex(
        x => x.objIdentifier === idinput
      );
      if (objIndex !== -1 && objIndex < this.state.items.length) {
        var itemscopy = this.state.items.slice(); //copy array
        itemscopy[objIndex].tStart = tstart;
        itemscopy[objIndex].tEnd = tend;
        itemscopy[objIndex].fulltime = full;
        this.setState({ items: itemscopy }, this.timelineLoading());
      }
    }
    }
  }

  jumpsLoading = () => {
    if (this.state.items.length >= 0) {
      let jumpref = ReactDOM.render(<Jumps reducer={this.props.reducer}
        dispatch={this.props.dispatch}
        store={this.props.store}
        items={this.state.items}
        callbackParent={this.jumpsCallback} />, document.getElementById('menuCentralDown'));
      if (jumpref) {
        if (jumpref.setConfigurationItems) {
          jumpref.setConfigurationItems(this.state.items);
        }
      }
    }
  }

  jumpsCallback = (action, wholeobject) => {
    if (action === 'gotoedit') {
      this.msgSend(undefined, {
        type: 'gotoedit',
        objidentifier: wholeobject.objIdentifier,
        time: wholeobject.tStart,
        callcategory: 'event',
        fulltime: wholeobject.fulltime,
        category: wholeobject.category,
        objwhole: wholeobject,
        camerarotationx: wholeobject.camerarotationx,
        camerarotationy: wholeobject.camerarotationy,
        camerarotationz: wholeobject.camerarotationz
      })
    }
  }

  videoEventLoading = () => {
    console.log("videoEventLoading")
    console.log(this.state.vrmodeoptions);
    let videoeventref = ReactDOM.render(<VideoEvent reducer={this.props.reducer}
      dispatch={this.props.dispatch}
      store={this.props.store}
      eventoptions={this.state.videoundertakeoptions}
      switchoptions={this.state.switchoptions}
      vrmodeoptions={this.state.vrmodeoptions}
      callbackParent={this.videoEventLoadingCallBack} />, document.getElementById('menuCentralDown'));
    if (videoeventref) {
      if (videoeventref.setEventOptions) {
        videoeventref.setEventOptions(this.state.videoundertakeoptions,this.state.switchoptions,this.state.vrmodeoptions);
      }
    }
  }

  videoEventLoadingCallBack = (type, data) => {
    if (type === 'videoeventjump') {
      this.sendPostMessage({ 'type': 'videoeventjump', "data": data });
      this.setState({ videoundertakeoptions: data });
    } else if (type === 'videoeventstop') {
      this.sendPostMessage({ 'type': 'videoeventstop', "data": data });
      this.setState({ videoundertakeoptions: data });
    } else if (type === 'videoeventloop') {
      console.log("videoEventLoadingCallBack")
      this.sendPostMessage({ 'type': 'videoeventloop', "data": data });
      this.setState({ videoundertakeoptions: data });
    }else if (type === 'switchbackground') {
      this.sendPostMessage({ 'type': 'switchbackground', "data": data });
      this.setState({ switchoptions: data });
    }else if (type === 'vrmodeoptions') {
      console.log("videoEventLoadingCallBack di vrmode")
      this.sendPostMessage({ 'type': 'vrmode', "data": data });
      this.setState({ vrmodeoptions: data });
  }
    
  }

  /**
   * 
   * 26/08/2022
   */
  toPublic = (event, data) =>{
   // if(this.props.reducer.publicStatusExtRes !== "" && this.props.reducer.publicStatusExtRes !== undefined){
      var data1 = {
        domain: this.props.reducer.domain,
        appname:this.props.reducer.appname,
        id:this.state.webcloudresid,
        userId: this.props.reducer.userid,
        publicStatusExtRes:this.props.reducer.publicStatusExtRes.toString().toLowerCase() !== "false" ? false : true
      };
      let self = this;
      //const headers = { headers: { 'content-type': 'application/json','Access-Control-Allow-Origin':'*'} };
      const headers = { headers: { 'content-type': 'application/json' } };
      Axios.post(services.ASSETBACKEND + services.resourcemanager_VISIBILITY_AUTH, data1, headers)
        .then(function (result) {
          if (result.status === 200) {
            self.setState({topublicicon: result.data.message.publicStatusExtRes.toString().toLowerCase() !== "false" ? "eye slash" : "eye"}, ()=> {self.props.dispatch(actionLogin(
              self.props.reducer.jwt,
              self.props.reducer.userid,
              true,
              self.props.reducer.name,
              self.props.reducer.role,
              self.props.reducer.domain,
              self.props.reducer.fsname,
              self.props.reducer.resType,
              self.props.reducer.appname,
              self.props.reducer.uri,
              self.props.reducer.resourceid,
              self.props.reducer.domain,
              self.props.reducer.metadata ? self.props.reducer.metadata : null,
              self.props.reducer.itemName ?  self.props.reducer.itemName : "",
              result.data.message.publicStatusExtRes
      ))});
          }
        })
        .catch(function (error) {
          //self.setState({webcloudresid:"", topublicicon: "eye slash"},self.autoSaving());
          //console.log('Errore in funzione  [Configuration.jsx/saveTopic]: ', error)
        });
    //}
  }

  componentWillUnmount() {
    //unsubscribe();
    window.removeEventListener('message', this.msgHandler);
  }

  createPlayTab(event, data) {
    if (this.state.identifier.length > 0) {
      var playfullscreenurl = services.domain + services.video_use_GET_AUTH.replace('[id]', this.state.identifier)
      playfullscreenurl = playfullscreenurl.replace('[resourceName]', this.state.videoIdentifier);
      playfullscreenurl = playfullscreenurl.replace('[resType]', this.props.reducer.resType);
      playfullscreenurl = playfullscreenurl.replace('[domainAsset]', this.props.reducer.domain);
      playfullscreenurl = playfullscreenurl.replace('[appname]', this.props.reducer.appname);
      if (this.state.compassStatus === true) {
        playfullscreenurl = playfullscreenurl + '&controls=true&postmsg=false&autoplay=true&compassStatus=' + this.state.compassStatus + "&compassPosition=" + this.state.compassPosition;
      } else {
        playfullscreenurl = playfullscreenurl + '&controls=true&postmsg=false&autoplay=true&compassStatus=' + this.state.compassStatus;
      }
      playfullscreenurl = playfullscreenurl + "&mediares=first&medialan="+this.state.medialanguage;      
      window.open(playfullscreenurl, "_blank");
    }
    this.setState({ ModalOpenFullscreen: false });
  }

  waitingAnswer = () => {
    //unsubscribeConfig = this.props.store.subscribe(this.updateState);
    this.updateState();
  }


  // CODICE Visibility
  // <Popup wide trigger={ <Button size='small' name="visibility" circular basic icon as='a'
  //           floated='right' id="barTopButtonVideo" >
  //   <Icon name={this.state.visibility ? 'eye': 'eye slash'} color='grey' />
  //   </Button>} on='click'>
  //   {/*<Checkbox checked={this.state.visibility}
  //        onChange={this.handleChange}
  //       onClick={this.visibilityClick} label="Visibility Public/Private"/>*/}
  //   <Dropdown text='Visibility'>
  //     <Dropdown.Menu>
  //       <Dropdown.Item text='Public' onClick={this.handleChange}  value={true} origin='visibility'/>
  //       <Dropdown.Item text='Private' onClick={this.handleChange} value={false} origin='visibility'/>
  //     </Dropdown.Menu>
  //   </Dropdown>
  //   </Popup>


 getDuration=(duration)=>{
  let minutes = parseInt(duration / 60)
  let seconds = duration % 60
  if (parseInt(seconds) < 10) {
        seconds = '0' + parseInt(seconds)
  }
  let sec = parseFloat(seconds)
  if (minutes===0)
  return (sec.toFixed(0) >= 10 ? sec.toFixed(0) : ('0'+sec.toFixed(0))) + "s";
  else
  return minutes + ':' + (sec.toFixed(0) >= 10 ? sec.toFixed(0) : ('0'+sec.toFixed(0))) + "m";
 }

 getWeakResourcePublicStatus =()=>{
  //il servizio interroga il be dell'asset per recuperare i dati della risorsa web creata a partire dalla storia (configurazione)
  let self = this;
  Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt;
  Axios.get(services.ASSETBACKEND + services.resourcemanager_VISIBILITY_AUTH + "?domain="+this.props.reducer.domain + "&appname="+this.props.reducer.appname+"&id="+this.state.webcloudresid)
    .then(function (result) {
      if (result.status === 200) {
        self.setState({topublicicon: result.data.message.publicStatusExtRes.toString().toLowerCase() !== "false" ? "eye slash" : "eye"}, ()=> {self.props.dispatch(actionLogin(
          self.props.reducer.jwt,
          self.props.reducer.userid,
          true,
          self.props.reducer.name,
          self.props.reducer.role,
          self.props.reducer.domain,
          self.props.reducer.fsname,
          self.props.reducer.resType,
          self.props.reducer.appname,
          self.props.reducer.uri,
          self.props.reducer.resourceid,
          self.props.reducer.domain,
          self.props.reducer.metadata ? self.props.reducer.metadata : null,
          self.props.reducer.itemName ?  self.props.reducer.itemName : "",
          result.data.message.publicStatusExtRes
  ))});
      }
    })
    .catch(function (error) {
      console.log(error);
      //self.setState({webcloudresid:"", topublicicon: "eye slash"},self.autoSaving());
      //console.log('Errore in funzione  [Configuration.jsx/saveTopic]: ', error)
    });
 }

 resourceUrl = (id,cover) => {
  let ser =
  services.ASSETBACKEND +
  services.singleresourcemanager_GET_AUTH.replace(
    "{APPNAME}",
    this.props.reducer.appname
  );
  ser = ser.replace("{DOMAIN}", this.props.reducer.domain);
  ser = ser.replace("{RESOURCEID}", id);
  ser = ser.replace("{USERID}", this.props.reducer.userid);
  ser = ser.replace("{COVER}", cover);
  ser = ser.replace("{MEDIALAN}",this.state.medialanguage);// si deve aggiungere 
  ser = ser.replace("{MEDIARES}", "first");
  return ser;
};

  render() {
    return (
      <div>
          <div id="contentroot" style={{paddingTop:10}}>
            {/*<Grid celled  style={{height:'87vh'}}>*/}
            <Grid>
              <Grid.Row>
                <Grid.Column width={4} > {/*LEFT SECTION*/}
                  <Tab menu={{ secondary: true, pointing: true, attached: true, inverted: true, className: "wrapped" }} panes={this.state.panesLeft}
                    onTabChange={this.onTabChangeLeft} id='tested'
                    activeIndex={this.state.tabBarActiveIndexSx} className="borderSchemaLeft" />
                  <div id='menuVerticalLeft' className="borderSchemaLeft"></div>
                </Grid.Column>
                <Grid.Column width={9} className="borderSchemaCenter centerGrid" >  {/*CENTER SECTION*/}
                  <Grid.Row stretched className="iframerow">
                    <Segment id="barTopVideoFrame" basic float size="large" className="borderSchemaCenter">
                      <span style={{ marginLeft: '5px' }}></span>
                      <Popup trigger={
                        <span>
                          {this.props.reducer.resType==="IMAGE3" || this.props.reducer.resType==="IMAGE3CLOUD" || this.props.reducer.resType==="IMAGE2" || this.props.reducer.resType==="IMAGE2CLOUD" ?
                          <Icon name='image' size='small' color="grey" />:
                          <Icon name='video' size='small' color="grey" />}
                          <p className="dimensionfont">{this.state.identifier}</p>
                        </span>} on='hover'>
                        <p id='section-edit-card-link'>{this.state.identifier}</p>
                      </Popup>
                      <span style={{ marginLeft: '5px' }}></span>
                      <Popup trigger={
                        <span>
                          <Icon name='list alternate' size='small' color="grey" /><p className="dimensionfont">{this.props.reducer.itemname}</p>
                        </span>} on='hover'>
                        <p id='section-edit-card-link'>{this.props.reducer.itemname}</p>
                      </Popup>
                      <span style={{ marginLeft: '5px' }}></span>
                      <Popup trigger={
                        <span>
                          <Icon name='list alternate' size='small' color="grey" />
                          {
                         this.props.reducer.resType==="IMAGE3" || this.props.reducer.resType==="IMAGE3CLOUD" ?
                          <p className="dimensionfont">{"IMAGE360"}</p>: 
                          this.props.reducer.resType==="IMAGE2" || this.props.reducer.resType==="IMAGE2CLOUD" ? 
                          <p className="dimensionfont">{"IMAGE2D"}</p>: 
                           this.props.reducer.resType==="VIDEO2" || this.props.reducer.resType==="VIDEO2CLOUD" ? 
                           <p className="dimensionfont">{"VIDEO2D"}</p>: 
                          this.props.reducer.resType==="VIDEO3" || this.props.reducer.resType==="VIDEO3CLOUD" ? 
                          <p className="dimensionfont">{"VIDEO360"}</p>:  
                          <p id='section-edit-card-link'>Not value</p>
                        }                         
                        </span>} on='hover'>
                        
                        {
                         this.props.reducer.resType==="IMAGE3" || this.props.reducer.resType==="IMAGE3CLOUD" ?
                          <p id='section-edit-card-link'>IMAGE 360</p> : 
                          this.props.reducer.resType==="IMAGE2" || this.props.reducer.resType==="IMAGE2CLOUD" ? 
                           <p id='section-edit-card-link'>IMAGE 2D</p>  :
                           this.props.reducer.resType==="VIDEO2" || this.props.reducer.resType==="VIDEO2CLOUD" ? 
                          <p id='section-edit-card-link'>VIDEO 2D</p>  :
                          this.props.reducer.resType==="VIDEO3" || this.props.reducer.resType==="VIDEO3CLOUD" ? 
                          <p id='section-edit-card-link'>VIDEO 2D</p>  :
                          <p id='section-edit-card-link'>Not value</p>
                        }  
                        
                      </Popup>
                        {this.props.reducer.metadata ? this.props.reducer.metadata.streams ? this.props.reducer.metadata.streams.length > 0 ? 
                      (<Popup trigger={
                        <span>
                          <p className="dimensionfont">{"-"+this.props.reducer.metadata.streams[0].width + "x" + this.props.reducer.metadata.streams[0].height}</p>
                        </span>} on='hover'>
                        <p id='section-edit-card-link'>{this.props.reducer.metadata.streams[0].width + "x" + this.props.reducer.metadata.streams[0].height }</p>
                      </Popup>)
                      : "" : "" : ""}
                        
                      {this.props.reducer.resType==="IMAGE3" || this.props.reducer.resType==="IMAGE3CLOUD"|| this.props.reducer.resType==="IMAGE2" || this.props.reducer.resType==="IMAGE2CLOUD"|| this.props.reducer.resType==="VIDEO2CLOUD"|| this.props.reducer.resType==="VIDEO3CLOUD"?<span></span>:
                      this.props.reducer.metadata ? this.props.reducer.metadata.streams ? this.props.reducer.metadata.streams.length > 0 ? this.props.reducer.metadata.streams[0].duration> 0 ? 
                      (<Popup trigger={
                        <span>
                        <p className="dimensionfont">{"-"+this.getDuration(this.props.reducer.metadata.streams[0].duration)}</p>
                        </span>} on='hover'>
                        <p id='section-edit-card-link'>{this.getDuration(this.props.reducer.metadata.streams[0].duration)}</p>
                      </Popup>)
                        : "" : "" : "" :""
                      }

                      <Popup flowing hoverable position='bottom center' size='small' trigger={
                        <Button size='small' name="backhome" circular basic icon as='a'
                          floated='right' onClick={this.funcModalOpen} modaltype='closeconfig' id="barTopButtonVideo">
                          <Icon name='close' color='grey' />
                        </Button>}>
                        <p id='section-edit-card-link'><Icon name='close' size='large' />close current config</p>
                      </Popup>
                      <Confirm open={this.state.ModalOpenBackHome} onCancel={this.funcModalClose}
                        onConfirm={this.editOnClose} identifier={this.state.identifier} header='Close Configuration'
                        content={<div className="content">Are you sure you want to close <strong>{this.state.identifier} ?</strong><br />
                          All unsaved changes will be lost</div>} modaltype='closeconfig' />

                     
                   



                      <Popup flowing hoverable wide position='bottom center' size='small' trigger={
                        <Button size='small' modaltype="deleteselected" circular basic icon as='a'
                          floated='right' onClick={this.funcModalOpen} id="barTopButtonVideo">
                          <Icon name='trash alternate' color='grey' />
                        </Button>
                      } on='hover'>
                        <p id='section-edit-card-link'><Icon name='trash alternate' size='large' />Delete object selected</p>
                      </Popup>
                      <Confirm open={this.state.msgDeleteHotspotOpen} onCancel={this.funcModalClose}
                        onConfirm={this.msgFocusOptionsDelete} identifier={this.state.identifier} header='The object selected deleting'
                        content={"Are you sure to delete the object selected ?"} modaltype='deleteselected' />
             
                     
                      {/* INIZIO BUTTON GRAPH*/}
                        {/* <Popup flowing hoverable position='bottom center' size='small' trigger={
                          <Button size='small' modaltype="graphtab" circular basic icon as='a' actiontype='graphtab'
                            floated='right' onClick={this.funcModalOpen} id="barTopButtonGraph">
                            <Icon name='line graph' color='grey' />
                          </Button>
                        }>
                          <p id='section-edit-card-link'><Icon name='line graph' size='large' />Graph mode</p>
                        </Popup> */}

                        
                          {/* INIZIO preview*/}
                       <Popup flowing hoverable position='bottom center' size='small' trigger={
                          <Button size='small' name="playonTab" circular basic icon as='a' modaltype="fullscreen"
                            floated='right' onClick={this.funcModalOpen} id="barTopButtonVideo">
                            <Icon name='desktop' color='grey' />
                          </Button>
                        }>
                          <p id='section-edit-card-link'><Icon name='desktop' size='large' />Preview</p>
                        </Popup>
                        {/* <Popup flowing hoverable wide position='bottom center' size='small' trigger={
                        <Button size='small' name="publish" circular basic icon as='a'
                          floated='right' onClick={this.toPublic} id="barTopButtonVideo">
                          <Icon  name={this.state.topublicicon} color='grey' />
                        </Button>
                      } on='hover'>
                        <p id='section-edit-card-link'><Icon name='save' size='large' />Publish current story</p>
                      </Popup> */}
                      {/*this.state.resType !== "VIDEO3" ?
                        <Popup flowing hoverable position='bottom center' size='small' trigger={
                          <Button size='small' name="playonTab" circular basic icon as='a' modaltype="fullscreen"
                            floated='right' onClick={this.funcModalOpen} id="barTopButtonVideo">
                            <Icon name='desktop' color='grey' />
                          </Button>
                        }>
                          <p id='section-edit-card-link'><Icon name='desktop' size='large' />Switch on fullscreen</p>
                        </Popup>
                      : ""*/}
                      <Confirm id='ModalTemp' open={this.state.ModalOpenFullscreen} onCancel={this.funcModalClose}
                        onConfirm={this.createPlayTab} identifier={this.state.identifier} header='Switch on fullscreen'
                        content={<div className="content">Unsaved data will not be shown in the preview.<br />
                          Please save the current configuration if you want to see your latest changes</div>} modaltype='Closefullscreen' />
                      {/* FINE preview*/}
                        <Popup flowing hoverable wide position='bottom center' size='small' trigger={
                        <Button size='small' name="editsave" circular basic icon as='a'
                          floated='right' onClick={this.editSave} id="barTopButtonVideo">
                          <Icon name='save' color='grey' />
                        </Button>
                      } on='hover'>
                        <p id='section-edit-card-link'><Icon name='save' size='large' />Save current settings</p>
                      </Popup>
                       
                      {/* FINE BUTTON GRAPH*/}
                     
                      {/*<Popup pinned on='click' trigger={<Button size='small' className="tooltip" name="status" circular basic icon as='a'
                        floated='right' id="barTopButtonVideo" >
                        <Icon name="user doctor" color='grey' />
                        <span class="tooltiptext"> <Icon name="user doctor" color='black' /> Published settings</span>

                      </Button>} >
                        <p>publish/unpublish</p>
                        {/*<Radio checked={this.state.status.published} onChange={this.handleChange} onClick={this.visibilityClick} label="Published" text='published' origin='status' style={{'marginRight':'20px'}}/>
                <Radio checked={this.state.status.mentorized} onChange={this.handleChange} onClick={this.visibilityClick} label="Mentorized" text='mentorized' origin='status'/>*
                        </Segment><Checkbox checked={this.state.status.published} onChange={this.handleChange} onClick={this.visibilityClick} label="Published" text='published' origin='status' style={{ 'marginRight': '20px' }} />
                        <Checkbox checked={this.state.status.mentorized} onChange={this.handleChange}  onClick={this.visibilityClick} label="Mentorized" text='mentorized' origin='status' disabled />
                      </Popup>

                      <Popup pinned on='click' trigger={<Button size='small' className="tooltip" name="compass" circular basic icon as='a'
                        floated='right' id="barTopButtonVideo" >
                        <Icon name="compass" color='grey' />
                        <span class="tooltiptext"> <Icon name="compass" color='black' /> Compass settings</span>
                      </Button>} >
                        <p>Compass Enabled/Disabled</p>
                        <Checkbox toggle checked={this.state.compassStatus} onChange={this.handleChange} onClick={this.visibilityClick} origin='compass' style={{ 'marginRight': '20px' }} />
                        <div>
                          <br />
                          {this.state.compassStatus === true ?
                            <Form >
                              <p>Position</p>
                              <Form.Field>
                                <Radio checked={this.state.topSX} onChange={this.handleChange} label="TopLeft" text='TopSX' origin='compassPosition' style={{ 'marginRight': '20px' }} />
                              </Form.Field>
                              <Form.Field>
                                <Radio checked={this.state.topDX} onChange={this.handleChange} label="TopRight" text='TopDX' origin='compassPosition' style={{ 'marginRight': '20px' }} />
                              </Form.Field>
                              <Form.Field>
                                <Radio checked={this.state.bottomSX} onChange={this.handleChange} label="BottomLeft" text='BottomSX' origin='compassPosition' style={{ 'marginRight': '20px' }} />
                              </Form.Field>
                              <Form.Field>
                                <Radio checked={this.state.bottomDX} onChange={this.handleChange} label="BottomRight" text='BottomDX' origin='compassPosition' style={{ 'marginRight': '20px' }} />
                              </Form.Field>
                            </Form> : <span />}
                        </div>
                          </Popup>*/}
                    </Segment>
                    <Dimmer active={this.state.loading}> <Loader>Loading</Loader></Dimmer>
                    <div id="iframebox">
                      <iframe id="iframeObj" src={this.state.urlLoading} />
                    </div>
                    <Tab menu={{ secondary: true, pointing: true, attached: true, inverted: true, className: "wrapped" }} panes={this.props.reducer.resType ==="IMAGE2" || this.props.reducer.resType ==="IMAGECLOUD3"|| this.props.reducer.resType ==="IMAGECLOUD2"|| this.props.reducer.resType ==="IMAGE3" ? this.state.panesCenterDownImage: this.state.panesCenterDown}
                      onTabChange={this.onTabChangeCenter} activeIndex={this.state.tabBarActiveIndexCx} />
                    <div id='menuCentralDown'></div>
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column width={3}> {/*RIGHT SECTION*/}
                  <div id='menuVerticalRightHeader' className="borderSchemaRight"></div>
                  <Tab menu={{ secondary: true, pointing: true, attached: true, inverted: true, className: "wrapped" }} panes={this.state.panesRight}
                    onTabChange={this.onTabChangeRight}
                    activeIndex={this.state.tabBarActiveIndex} className="borderSchemaRight" />
                  <div id='menuVerticalRight' className="borderSchemaRight"></div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <Modal size='mini' open={this.state.msgSavingOpen} onClose={this.funcModalClose} id='ModalTemp'>
            {this.state.savingStatus ? <Modal.Header> Your configuration is stored correctly</Modal.Header> :
              <Modal.Header> Saving Error</Modal.Header>}
            <Modal.Content><p></p></Modal.Content>
            <Modal.Actions>
              <Button primary size='tiny' content='OK' onClick={this.funcModalClose} modaltype='Save'></Button>
            </Modal.Actions>
          </Modal>

          {/*
        * MODAL to choose a new configuration or edit an old configuration
        */}
          {this.props.reducer.resourceid ?
            <Modal open={this.state.editModal}
              size={'small'}
              closeIcon={true} onClose={this.editOnClose}>

              <Modal.Content scrolling>
                <Header as='h1'>
                  {/* <Image id='image-logo-modal' src='/assets/images/logo_H.png' avatar floated='left' alt="H360" /> */}
                  <Header.Subheader>Immersive Experience </Header.Subheader>
                </Header>
              </Modal.Content>
              <Edit userId={this.props.reducer.userid} jwt={this.props.reducer.jwt} reducer={this.props.reducer} videoId={this.props.reducer.resourceid} videoTopic="" uploaded="" resType={this.state.resType}
                callbackParent={this.onChildChanged} history={this.props.history} ref={communicationMethod => { this.communicationMethod = communicationMethod; }} />
            </Modal>
            : null}
            {/*
              * MOdal for player mode
            */}
        {this.props.reducer.resourceid ?
            
            <Modal open={this.state.playModal}
            size={'small'}
            dimmer={'blurring'} closeIcon={true} onClose={this.editOnClose}>
            <Modal.Content scrolling>
            <Header as='h4'>
            {/* src={this.props.reducer.group === "IDEHA" ? '/assets/images/logo_H.png' : '/assets/images/logo.png'} */} 
            {/* <Image id='image-logo-modal' src='/assets/images/logo_H.png' avatar floated='left' alt="H360" /> */}
            <Header.Subheader> Play Manager</Header.Subheader>
            </Header>
            </Modal.Content>
            <Play userId={this.props.reducer.userid} jwt={this.props.reducer.jwt} reducer={this.props.reducer} videoId={this.props.reducer.resourceid}
             videoname={this.state.videoname} history={this.props.history}  restype={this.state.resType}  />
            
            </Modal>
          : null}
          {/*
        * MODAL to view graph mode 
        */}
          <Modal open={this.state.ModalOpenGraph}
            size={'fullscreen'}
            closeIcon={true} onClose={this.funcModalGraphClose}
            style={{ "marginRight": "0px", "marginLeft": "30px" }} >

            <Modal.Content scrolling>
              <Header as='h1'>
                <Image id='image-logo-modal' src={this.props.reducer.group === "IDEHA" ? '/assets/images/logo_H.png' : '/assets/images/logo.png'} avatar floated='left' alt="H360" />
                <Header.Subheader floated='center' >Graph jump View</Header.Subheader>
              </Header>
            </Modal.Content>
            <GraphView callbackParent={this.onChildChanged} userId={this.props.reducer.userid} jwt={this.props.reducer.jwt} reducer={this.props.reducer} videoId={this.state.videoIdentifier} configid={this.state.identifier} videoArray={this.state.videoArray} ></GraphView>
          </Modal>
      </div>
    )
  }
}
export default connect(mapStateToProps)(Configuration)
