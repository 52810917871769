import React, { Component } from 'react';
import {Input, Button , Table ,Icon,Dropdown,Divider,Grid,Label} from 'semantic-ui-react';
import Axios from 'axios';
import * as services from '../../../../config/services.json';
import '../../../../css/config.css';
import { transitions, positions, Provider as AlertContainer } from 'react-alert'


import { setfocusobj} from '../../../../redux/actions/ObjectAction';




let unsubscribe
class DriveHotSpotModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
   	 	objList:[], dropdownMenu:[] , selectedHotspot:"",
      selectedHotspotList:(this.props.events ? (this.props.events.itemSelect ? this.props.events.itemSelect : []):[] )
    };
    this.updateState = this.updateState.bind(this);
    this.getData = this.getData.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.removeHotspot=this.removeHotspot.bind(this);
    this.onClickOkButton=this.onClickOkButton.bind(this);

  }
componentWillUnmount(){



  unsubscribe();
  // console.log("componentWillUnmount()");
}



  componentWillMount() {
    //authentication check

    this.getData();
  }


  handleChange(e,d){
    console.log("handle change ")
     console.log(e)
    console.log(d)

    // var =d.options.indexOf(d.value);
    var indexName = d.options.findIndex(x => x.value ===d.value);

    // console.log(d.options[indexName])



     var itemsCopy = this.state.selectedHotspotList.slice(); //copy array
    var indexFound=itemsCopy.indexOf(d.value);


    if (indexFound  ===-1){
    var helpObj={
      name:d.options[indexName].text,
      value:d.value
    }
    itemsCopy.push(helpObj);
    }else {
    // itemsCopy.splice(indexFound, 1); //remove element
    // console.log("hotspot gia esiste metti alert ")
    this.showAlertError("Hotspot already exists")

    }
    this.setState({selectedHotspotList:itemsCopy});
    // var itemsCopy = this.state.activeFalse.slice(); //copy array
    //   itemsCopy.splice(e.target.getAttribute("name"), 1, true); //remove element
    // index = a.findIndex(x => x.prop2 ==="yutu");
  }

  removeHotspot(e,d){
    console.log("removeHotspot ")

    // console.log(e)
    console.log(d)
    var itemsCopy = this.state.selectedHotspotList.slice(); //copy array
    console.log(itemsCopy)
    var indexName = itemsCopy.findIndex(x => x.value ===d.value);

    // var indexFound=itemsCopy.indexOf(d.content);
    if (indexName  !==-1){
      itemsCopy.splice(indexName, 1); //remove element    }else {
     this.setState({selectedHotspotList:itemsCopy});
    }


  }


  getData(){
    console.log("richiesta dati ")
    var updated = this.props.store.getState();
    unsubscribe = this.props.store.subscribe(this.updateState);
    this.props.store.dispatch(setfocusobj(updated.reducer.focusobj.type,updated.reducer.focusobj.obj,{'type':'hotspotlist','action':'getlist'}));
    this.props.parent.waitingAnswer();
    //unsubscribe();

    /* You have to wait response from the iframe
    unsubscribe();
    */
}


  updateState(){
      var updated = this.props.store.getState();
      console.log(updated.reducer.action)
      // if((updated.reducer.hotspotlist)&&(updated.reducer.action==="hotspotlistNotEmpty")){

	  if(updated.reducer.hotspotlist){
          console.log(updated)
          var list=updated.reducer.hotspotlist;
          var menu=[];
          for (var i=0; i< list.length;i++){
            if (list[i].category!=='shape'){
            var obj ={
              "key":i ,
              "value":list[i].objIdentifier ,
              "text": list[i].name + " | "+ list[i].category
            }
            menu.push(obj)
            }
          }
          // if (this.props.events.itemSelect.length>0){
          // this.setState({dropdownMenu:menu,selectedHotspotList:this.props.events.itemSelect});
          // }else{
          this.setState({dropdownMenu:menu});
          // }

          // this.props.parent.waitingAnswer();

		    // unsubscribe();
	  }
    }




  onClickOkButton (){
    if (this.state.selectedHotspotList.length>0){
      this.props.callbackFather('' ,'', '',this.state.selectedHotspotList,{});
    }else {
      this.showAlertError("No hotspot  selected")
      }
  }


  render () {
    var flag=false;
    return (
      <div>
        <p>Select hotspots  :</p>
          <Dropdown text='Select Hotspot' simple item  onChange={this.handleChange}options={this.state.dropdownMenu}     />
          <Divider />

            <Grid>
              <Grid.Row >
              <Grid.Column columns='15'>
              <p>Selected hotspots:</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row >
              <Grid.Column >
              {this.state.selectedHotspotList.map((item,index) =>
                <Label key= {index} style={{marginBottom:'3px'}} content={item.name} removeIcon='delete'  onRemove={this.removeHotspot} index={index} basic color='grey' value={item.value}/>
              )}
            </Grid.Column>
            </Grid.Row>
            </Grid>

		<Button onClick={this.onClickOkButton}>OK</Button>

      <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />

    </div>
  )
  }


  alertOptions = {
    offset: 14,
    position: 'bottom center',
    theme: 'dark',
    time: 5000,
    transition: 'scale'
  }

  showAlertError = (msg) => {
    this.msg.show(msg, {
      time: 6000,
      type: 'error',
    })
  }


}

export default DriveHotSpotModal;
