/**
* Created by michelefuschillo on 06/02/18.
*/
import React, { Component } from 'react';
import { Table, Button, Confirm, Icon, Checkbox, Popup, Modal, Image, Header, Dropdown, Grid, Label, Divider,Message } from 'semantic-ui-react';
import Axios from 'axios';
import * as services from '../../../../config/services.json';
import ModifyModal from './modifyNameModal.jsx'
import CopyConfigModal from './copyConfigModal.jsx'
import CreateModal from './createConfigModal.jsx'
import { transitions, positions, Provider as AlertContainer } from 'react-alert'
import CategoryAssign from './CategoryAssign.jsx'



import '../../../../css/config.css';


/*var topicMenu = [
  { "key": "1", "value": "music festival", "text": "music festival" },
  { "key": "2", "value": "gym-exercise", "text": "gym-exercise" },
  { "key": "3", "value": "social event", "text": "social event" },
  { "key": "4", "value": "tourism", "text": "tourism" },
  { "key": "5", "value": "social event-fontane", "text": "social event-fontane" },
  { "key": "6", "value": "social event-university", "text": "social event-university" },
  { "key": "8", "value": "lifestyle", "text": "lifestyle" },
  { "key": "7", "value": "other", "text": "other" }

];*/

class EditList extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      type: "edit",
      configurations: [],
      configId: "",
      consumerName: "",
      ModalOpenTrash: false,
      ModalOpenNewConfig: false,
      ModalOpenModify: false,
      ModalOpenCopy: false,
      itemToModify: null,
      itemToDel: null,
      consumerModify: "",
      videoId:this.props.videoId,      
      indexModify: "",
      selectedTopic: "",
      uploaded: "NO",
      indexDel: "",
      assignedCat: [],
      videoData: {},
      modalOpenNewCat: false,
      newItemKey: "",
      resType:"",
      loadConfigurationCheck:true,
      showMessage:false,
    });

    this.loadConfigurations = this.loadConfigurations.bind(this);
    this.getVideoIdFromList = this.getVideoIdFromList.bind(this);
    this.deleteConfig = this.deleteConfig.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.saveName = this.saveName.bind(this);
    this.funcModalOpen = this.funcModalOpen.bind(this);
    this.funcModalClose = this.funcModalClose.bind(this);
    this.onCloseModalModify = this.onCloseModalModify.bind(this);
    this.onModifyName = this.onModifyName.bind(this);
    this.onCloseModalNew = this.onCloseModalNew.bind(this);
    this.copyConfig = this.copyConfig.bind(this);
    //this.onChangeTopic = this.onChangeTopic.bind(this);
    this.getVideoCategories = this.getVideoCategories.bind(this);
    this.removeCat = this.removeCat.bind(this);
    this.onCloseModalNewCategory = this.onCloseModalNewCategory.bind(this);
    this.onOpenModalNewCategory = this.onOpenModalNewCategory.bind(this);
    this.onNewCategoryFinish = this.onNewCategoryFinish.bind(this);
    this.dropName = this.dropName.bind(this);
    this.onCloseModalCopy = this.onCloseModalCopy.bind(this);
    this.copyConfigAnotherVideo = this.copyConfigAnotherVideo.bind(this);



  }

  static getDerivedStateFromProps(props, state) {
    return { //return new state
      videoId: props.videoId,
      // ... other derived state properties
    };
  }
  componentDidUpdate(prevProps, prevState) { //call after state change (called every time)
    if (this.state.loadConfigurationCheck===true) {
      this.loadConfigurations();    
    }
  }
  /*componentWillReceiveProps(nextProps) {
    this.setState({ selectedTopic: this.props.videoTopic, uploaded: this.props.uploaded  , resType:this.props.resType});
  }*/
  
  componentDidMount() { 


  }


  /*GESTIONE APERTURA/CHIUSURA MODAL*/
  funcModalOpen(event, data) {
    if (data.actiontype === 'copy') {
      //this.setState({ModalOpenCopy: true,configId:data.value, type:"copyconfig"},this.callbackParent);        
      this.setState({ ModalOpenCopy: true, configId: data.value });
    } else if (data.actiontype === 'delete') {
      this.setState({ ModalOpenTrash: true, itemToDel: data.value, indexDel: data.index });
    } else if (data.actiontype === 'modify') {
      this.setState({ ModalOpenModify: true, itemToModify: data.value, consumerModify: data.oldCN, indexModify: data.index });
    } else if (data.actiontype === 'new') {
      this.setState({ ModalOpenNewConfig: true });
    }
  };


  funcModalClose() { this.setState({ ModalOpenTrash: false }); };


  callbackParent() {
    this.props.callbackParent(this.state);
  }

  getVideoIdFromList(event, data) {
      this.setState({ configId: data.value, consumerName: data.actualConsumerName, type: "edit" }, this.callbackParent);
  }

  onCopiedItem() {
    this.showAlert("Configuration Successfully copied");
    this.onCloseModalCopy();
    // this.loadConfigurations();
  }
  onModifiedNameItem(data, error) {
    // console.log("itemcopiato" + data + "--"+error);
    if (error === "") {
      this.onCloseModalModify();
      // this.loadConfigurations();
    } else {
      this.com.handleError(error);
    }
  }

  deleteConfig(event, data) {
    this.setState({ configId: this.state.itemToDel, type: "editeddelete", ModalOpenTrash: false }, this.callbackParent);
    var tempArray = this.removeElement(this.state.configurations, this.state.itemToDel);
    this.setState({ configurations: tempArray });
  }

  removeElement = (array, element) => {
    return array.filter(el => el.key !== element);
  }

  // axios.get('https://site.com/', {
  //   params: {
  //     id: self.state.videoId
  //   }
  // })
  loadConfigurations() {
    console.log("loadConfigurations")
    if (this.props.videoId) {
      var self = this;
      //  self.setState({ configurations: [] ,loadConfigurationCheck:true }); //Azzero configuration per bind Delete
      // console.log("owner prima della chiamata " + self.props.userId);
      console.log("self.state.videoId")
      console.log(self.state.videoId)
      var servicePath = services.retrieve_configurations_GET_AUTH.replace("[id]", self.state.videoId);
      // servicePath = servicePath.replace('[owner]', self.props.userId);
      // servicePath = servicePath.replace('[group]', self.props.reducer.group);      
      servicePath = servicePath.replace('[appname]',  self.props.reducer.appname);
      servicePath = servicePath.replace('[domainAsset]', self.props.reducer.domain);
      servicePath = servicePath.replace('[reqType]', "edit");
      console.log(servicePath)
      Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.jwt;
      Axios.get(services.domain + servicePath)
        .then(function (response) {          
          if (response.data.message.length > 0) {     
            let newobjArray=[];                   
            for (var key in response.data.message) {              
              var obj = response.data.message[key];
              var newobj = {
                key: obj._id, //configurazione
                visibility: obj.elements[0].visibility,
                status: obj.elements[0].status,
                consumerName: obj.elements[0].consumerName,
                compatibility: obj.elements[0].compatibility,
                compass: obj.elements[0].compass,
                videoundertakeoptions: obj.elements[0].videoundertakeoptions,
                switchoptions:obj.elements[0].switchoptions,
                vrmodeoptions:obj.elements[0].vrmodeoptions,
                owner: obj.elements[0].owner,
                elements: obj.elements //elementi configurazione (array)
              };
              newobjArray.push(newobj);
            }
            
            self.setState({ configurations:newobjArray , loadConfigurationCheck:false});
            // self.setState({ configurations: [...self.state.configurations, newobj] , loadConfigurationCheck:false});
          } else {
            self.setState({ configurations: [] , loadConfigurationCheck:false});
          }
        })
        .catch(function (error) {
          console.log("Errore in funzione [Configuration.jsx/loadConfigurations]: ", error);
        });
    }
  }

  onChangeName(event, data) {
    if (data.id === "configName") {
      this.setState({ configId: data.value });
    } else {
      this.setState({ consumerName: data.value });
    }
  }

  saveName(event, data) {
    if (this.state.configId.length > 0) {
      this.setState({ type: "edit" }, this.callbackParent);
    }
  }

  onCloseModalCopy() {
    this.setState({ ModalOpenCopy: false });
  }

  onCloseModalNew() {
    this.setState({ ModalOpenNewConfig: false });

  }
  onCloseModalModify() {
    this.setState({ ModalOpenModify: false });
  }

  onModifyName(newName, oldName, newConsumerName) {
    console.log("modifica il nome: " + oldName + "con :" + newName);
    this.setState({ configId: oldName, type: "modifyname", newName: newName, newConsumerName: newConsumerName }, this.callbackParent);


  }
  //copia configuraizione esistente
  // copyConfig(event,data){
  //   console.log("copyConfig");
  //   this.setState({configId:data.value, type:"copyconfig"},this.callbackParent);
  // }
  copyConfig(data) {
    // console.log("copyConfig");
    this.setState({ configId: data, type: "copyconfig" }, this.callbackParent);
  }
  copyConfigAnotherVideo(data) {
    // console.log(data);
    this.setState({ data: data, type: "copyconfiganothervideo" }, this.callbackParent);
  }
  // codice public
  // <Table.HeaderCell id='section-content-tableHeader' textAlign='left' > Public  </Table.HeaderCell>
  // <Table.Cell><Checkbox checked={item.visibility}  readOnly /></Table.Cell>
  /*onChangeTopic(event, data) {
    console.log("onChangeTopic");
    console.log(data);
    this.setState({ selectedTopic: data.value, type: "topic" }, this.callbackParent)
  }*/
  onCloseModalNewCategory(event, data) {
    this.setState({ modalOpenNewCat: false })
  }
  onOpenModalNewCategory(event, data) {
    this.setState({ modalOpenNewCat: true })
  }

  onNewCategoryFinish() {
    this.getVideoCategories();
    this.setState({ modalOpenNewCat: false })

  }

  getVideoCategories() {
    var self = this;

    var servicePath = services.getVideoCategories.replace("[resourceName]", self.state.videoId);
    servicePath = servicePath.replace('[group]', self.props.reducer.group);



    console.log(services.domain + servicePath);
    Axios.get(services.domain + servicePath)
      .then(function (response) {
        console.log(response.data.message);
        self.setState({ assignedCat: response.data.message.categories, videoData: response.data.message.video })

      })
      .catch(function (error) {
        console.log("Errore in funzione [Configuration.jsx/getVideoCategories]: ", error);
      });
  }
  removeCat(event, data) {
    var self = this;
    console.log("removeCat: \n");
    // console.log(data);
    var updatedCateg = this.state.assignedCat.slice();
    console.log(updatedCateg);
    var delIndex = updatedCateg.indexOf(data.content);
    updatedCateg.splice(delIndex, 1);
    self.setState({ assignedCat: updatedCateg });

    var param = {
      category: data.content,
      resourceName: this.state.videoData.name,
      group: this.state.videoData.group
    };
    const headers = { headers: { 'content-type': 'application/json' } };
    Axios.post(services.domain + services.deleteVideoFromCategory, param, headers)
      .then(function (response) {
        if (response.status === 200) {
          console.log('Funzione chiamata removeCat 200' + JSON.stringify(response.data.message));
          // self.setState({assignedCat:updatedCateg});
        }
      })
      .catch(function (error) {
        console.log('Errore in funzione [Configuration.jsx/removeCat]: ', error);
        // self.setState({assignedCat:updatedCateg});
      });


  }
  // <CreateModal callbackOnSave = {this.saveName} onchangecallback={this.onChangeName}></CreateModal>
  dropName = (data) => {
    var myLen = data.length;

    if (myLen >= 45) {
      var dots = myLen > 45 ? '...' : '';
      return data.substring(0, 45) + dots;
    }

    return data;


  }

  createUrlToIE=(data,identifierVideo)=>{
    var servicePath="";
    servicePath = services.video_use_GET_AUTH.replace('[id]', data.value);        
    servicePath = servicePath.replace('[resType]', this.props.reducer.resType);
    servicePath = servicePath.replace('[appname]',  this.props.reducer.appname);
    servicePath = servicePath.replace('[domainAsset]', this.props.reducer.domain);
    servicePath = servicePath.replace('[resourceName]', identifierVideo);
    servicePath = servicePath+ '&userid=' + this.props.userId;
    servicePath = servicePath + '&local=' + true;
    servicePath = servicePath + '&cover=' + false;
    servicePath = servicePath+'&controls=true&postmsg=false&autoplay=true';
    servicePath = servicePath+ "&mediares=first&medialan=it"
    return servicePath;
  }
  createPlayTab=(event,data)=>{  
    const identifierVideo = this.state.videoId;
    if (identifierVideo) {      
      window.open(services.domain + this.createUrlToIE(data,identifierVideo),"_blank");     
      } 
    }
 
  copyUrl=(event,data)=>{

    navigator.clipboard.writeText(services.domain +this.createUrlToIE(data,this.state.videoId));    
    this.setState({
      showMessage: true
    },()=>(window.setTimeout(() => {
      this.setState({
        showMessage: false
      });
    }, 2000))
    );
  }
  handleDismiss=()=>{
    this.setState({showMessage:false});
  }
  render() {
    const { ModalOpenTrash } = this.state;
    // console.log(this.state.videoData);
    var cat = this.state.assignedCat;
    const videoDataObject = this.state.videoData;
    return (
      <div>
        {/* <div style={{ marginLeft: '10px' }}>
          
          <Divider />
          <Grid>

            <Grid.Row >
              <Grid.Column columns='15'>
                <p>Associated Categories:</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row >
              <Grid.Column >
                {cat.map((item, index) =>
                  <Label style={{ marginBottom: '3px' }} content={item} removeIcon={item !== 'all' ? 'delete' : ""} onRemove={this.removeCat} index={index} basic color={item !== 'all' ? 'grey' : 'red'} />
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row >
              <Grid.Column columns='15'>
                <Button positive size='small' content='Add Category' onClick={this.onOpenModalNewCategory} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div> */}

        <Divider />
        <Modal open={this.state.modalOpenNewCat}
          size='large'
          dimmer={'blurring'} closeIcon={true} onClose={this.onCloseModalNewCategory} >

          <Modal.Content scrolling>
            <Header as='h4'>
              <Image id='image-logo-modal' src= '/assets/images/logo_H.png' avatar floated='left' alt="H360" />
              <Header.Subheader > Assign new Category to video <i style={{ fontWeight: 'bold' }}>{videoDataObject.name}</i>   </Header.Subheader>

            </Header>
            <CategoryAssign videoObj={videoDataObject} callbackParent={this.onNewCategoryFinish} actualCategory={this.state.assignedCat}></CategoryAssign>
          </Modal.Content>
        </Modal>

        <Table singleLine selectable color='grey' size='small' >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell id='section-content-tableHeader' textAlign='left' > Name   </Table.HeaderCell>
              {/* <Table.HeaderCell id='section-content-tableHeader' textAlign='left' > ConsumerName    </Table.HeaderCell> */}
              {/* <Table.HeaderCell id='section-content-tableHeader' textAlign='left' > Mentor  </Table.HeaderCell> */}
              <Table.HeaderCell id='section-content-tableHeader' textAlign='center'>Actions </Table.HeaderCell>
              <Table.HeaderCell id='section-content-tableHeader'>
                <Popup flowing hoverable position='bottom center' size='tiny'
                  trigger={<Button name="new" circular color='green' size='medium' icon='plus' position='right' onClick={this.funcModalOpen} actiontype='new' />}>
                  <p id='section-edit-card-link'>Create new Immersive Experience</p>
                </Popup>
                <Popup flowing hoverable position='bottom center' size='tiny'
                  trigger={<Button name="new" circular color='green' size='medium' icon='play' position='right' onClick={this.createPlayTab} actiontype='new' />}>
                  <p id='section-edit-card-link'>Play background</p>
                </Popup>
                <Modal open={this.state.ModalOpenNewConfig}
                  size='tiny'
                  dimmer={'blurring'} closeIcon={true} onClose={this.onCloseModalNew} >

                  <Modal.Content scrolling>
                    <Header as='h1'>
                      {/* <Image id='image-logo-modal' src= '/assets/images/logo_H.png' avatar floated='left' alt="H360" /> */}
                      <Header.Subheader> Create new Immersive Experience  </Header.Subheader>
                    <Divider/>
                    </Header>
                    <CreateModal callbackOnSave={this.saveName} onchangecallback={this.onChangeName}></CreateModal>



                  </Modal.Content>
                </Modal>

              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.configurations.sort((a, b) => a.key > b.key).map((item, indexCfg) =>
              <Table.Row key={indexCfg}>
                {/*
          <Table.Cell>{item.key}</Table.Cell>
          */
                }
                <Popup flowing hoverable wide position='bottom center' size='small' trigger={
                  <Table.Cell>{this.dropName(item.key)}</Table.Cell>
                } on='hover'>
                  <p id='section-edit-card-link'>{this.dropName(item.key)}</p>
                </Popup>
                {/* <Popup flowing hoverable wide position='bottom center' size='small' trigger={
                  <Table.Cell>{item.consumerName ? item.consumerName : "Not defined"}   </Table.Cell>
                } on='hover'>
                  <p id='section-edit-card-link'>{item.consumerName ? item.consumerName : "Not defined"}</p>
                </Popup> */}

                {/* <Table.Cell><Icon disabled name='x' color='red' /></Table.Cell> */}
                <Table.Cell textAlign='center'>
                  {/*Button edit  disabled={item.owner===this.props.reducer.userid?false:true} coreggi prima bug e poi inserisci*/}
                  <Popup flowing hoverable position='left center' size='tiny'
                    trigger={<Button index={indexCfg} basic circular icon="edit"  value={item.key} actualConsumerName={item.consumerName} onClick={this.getVideoIdFromList} />}>
                    <p id='section-edit-card-link'>Edit</p>
                  </Popup>
                  {/* Button modify
                  <Popup flowing hoverable position='top center' size='tiny'
                    trigger={<Button index={indexCfg} basic circular disabled icon="i cursor" onClick={this.funcModalOpen} actiontype='modify' oldCN={item.consumerName} value={item.key}></Button>}>
                    <p id='section-edit-card-link'>Modify name</p>
                  </Popup>
                  {/*Button Copy 
                  <Popup flowing hoverable position='top center' size='tiny'
                    trigger={<Button index={indexCfg} basic circular  disabled icon="copy" value={item.key} actiontype='copy' onClick={this.funcModalOpen}></Button>}>
                    <p id='section-edit-card-link'>Copy </p>
                  </Popup> */}
                  {/*Button Delete  disabled={item.owner===this.props.reducer.userid?false:true} correggi prima il bug e poi inserisci dinuovo */}  
                  <Popup flowing hoverable position='top center' size='tiny'
                    trigger={<Button index={indexCfg} basic circular icon='trash' operation='DelShapeInRes' 
                      onClick={this.funcModalOpen} actiontype='delete' value={item.key}></Button>}>
                    <p id='section-edit-card-link'>Delete </p>
                  </Popup>
                  <Popup flowing hoverable position='top center' size='tiny'
                    trigger={<Button index={indexCfg} basic circular icon='play' operation='DelShapeInRes'
                      onClick={this.createPlayTab}  value={item.key}></Button>}>
                    <p id='section-edit-card-link'>Play </p>
                  </Popup>               
                  <Popup flowing hoverable position='right center' size='tiny'
                    trigger={<Button index={indexCfg} basic circular icon='share alternate' operation='DelShapeInRes'
                    onClick={this.copyUrl}
                     value={item.key}></Button>}>
                    <p id='section-edit-card-link'>Share </p>
                  </Popup>
                </Table.Cell>
                <Table.Cell>  <p /></Table.Cell>
              </Table.Row>
            )}

          </Table.Body>
        </Table>
        {this.state.showMessage?
        <Message
          color='green'
          size='mini'
          onDismiss={this.handleDismiss}          
          content='Link Copied'
        />
        :null}

        {/*Modal modify */}
        <Modal open={this.state.ModalOpenModify}
          size='tiny'
          dimmer={'blurring'} closeIcon={true} onClose={this.onCloseModalModify} >
          <Modal.Content scrolling>
            <Header as='h4'>
              <Image id='image-logo-modal' src= '/assets/images/logo_H.png'  avatar floated='left' alt="H360" />
              <Header.Subheader> Change configuration name </Header.Subheader>
            </Header>
            <ModifyModal callbackOnModifyName={this.onModifyName} oldname={this.state.itemToModify} oldconsumerName={this.state.consumerModify} ref={com => { this.com = com; }}></ModifyModal>
          </Modal.Content>
        </Modal>
        {/*Modal copy */}
        <Modal open={this.state.ModalOpenCopy}
          size='small'
          dimmer={'blurring'} closeIcon={true} onClose={this.onCloseModalCopy} >
          <Modal.Content scrolling>
            <Header as='h4'>
              <Image id='image-logo-modal' src='/assets/images/logo_H.png' avatar floated='left' alt="H360" />
              <Header.Subheader> Copy configuration : "{this.state.configId}" </Header.Subheader>
            </Header>
            <CopyConfigModal callbackOnCopyConfig={this.copyConfig} callbackCopyConfigAnotherVideo={this.copyConfigAnotherVideo} videodata={videoDataObject} reducer={this.props.reducer} group={this.props.reducer.group} configId={this.state.configId}  resType={this.state.resType} ref={com => { this.com = com; }}></CopyConfigModal>
          </Modal.Content>
        </Modal>
        {/*Modal delete confirm */}
        <Confirm id='ModalTemp' open={ModalOpenTrash} onCancel={this.funcModalClose}
          onConfirm={this.deleteConfig} header='Delete Configuration' content="Are you sure you want to delete conf?" />
        {/*Error alert*/}
        <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
      </div>
    )
  }






  alertOptions = {
    offset: 14,
    position: 'bottom center',
    theme: 'dark',
    time: 5000,
    transition: 'scale'
  }

  showAlert = (msg) => {
    this.msg.show(msg, {
      time: 3000,
      type: 'success',
    })
  }

  showAlertError = (msg) => {
    this.msg.show(msg, {
      time: 6000,
      type: 'error',
    })
  }

}
export default EditList;
