/**
 * Created by obbiettivo on 10/09/18.
 */
import React, { Component } from 'react'
import '../../css/modal.css'


class PreviewModal extends Component {
  constructor (props) {
    super(props)
  }
  render () {
    return (
      <div style={{background: "linear-gradient(black, grey)"}}>
      {this.props.type==="video"?
      <video style={{marginBottom: "auto", height: 280}} class='preview-video-container' src={this.props.path}  controls crossorigin='anonymous'  controlsList='nodownload' type="video/mp4"></video>
       :
      <audio class='preview-media-container' src={this.props.path} style={{marginBottom:"auto", background: "linear-gradient(black, grey)"}} controls crossorigin='anonymous'  controlsList='nodownload'type="audio/mpeg"></audio>
     }
      </div>
    )
  }
}

export default PreviewModal
