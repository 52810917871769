import React, { Component } from 'react';
import {Icon,Button,Grid,Accordion,Divider,Dimmer,Loader,Label,Tab} from 'semantic-ui-react';
import Axios from 'axios';
import {connect} from 'react-redux';
import * as services from '../config/services.json';
import menuItem from '../redux/actions/menustate';
import ReactDOM from 'react-dom'
/*import ReactDOM from 'react-dom';*/
import '../css/player.css'

const mapStateToProps = (state) => ({
  reducer: state.reducer,
})

class Player extends Component {
  constructor (props) {
    super(props);
    this.state = {
      items: [],
      mentor: false,
      isOpen: true,
      video: [],
      selected: "",
      time: "",
      duration: "",
      configurationName:"",
      videoDisabled: true,
      title: "",
      loading: false,
      mute: false,
      iconmute: "mute",
      configurations: [],
      categoryColumnLabel: "HotSpots",
      activeIndex: 0,
      tabBarActiveIndex: 0,
      panesLeft: [
        {menuItem: {key: 'config', icon: 'edit', content: 'Configurations', disabled: false}},
        {menuItem: {key: 'time', icon: 'wait', content: 'Timeline', disabled: false}}
      ],
      itemIndexConfigurationsList:-1,
      itemIndexTimeline:-1,
    };

    this.msgHandler = this.msgHandler.bind(this);
    this.msgSend = this.msgSend.bind(this);
    this.choiceCategory=this.choiceCategory.bind(this);
    this.loadVideo=this.loadVideo.bind(this);
    this.loadConfigurations=this.loadConfigurations.bind(this);
    this.postControl = this.postControl.bind(this);
    this.goToItemTime = this.goToItemTime.bind(this);
  }

  componentWillMount() {
    //authentication check
    if(!this.props.reducer.logged){
      this.props.history.push("/login");
      this.props.dispatch(menuItem('login'));
    }else{
      this.received = false;
      window.addEventListener('message', this.msgHandler);
      this.setState({title:this.props.location.state.title})
      this.loadConfigurations(); //carica eventuali configurazioni gi? presenti
      this.loadVideo();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.msgHandler);
  }

  /*
   * POST MESSAGE MANAGEMENT
   */
  msgHandler(e) {
    switch (e.data.type) {
      case 'configitems':
        if (e.data.items.length > 0) {
          this.setState({items:e.data.items.sort(function(a,b){return parseFloat(a.tStart) - parseFloat(b.tStart)}).slice()});
        }else{
          this.setState({items: []});
        }
        break;
      case 'time':
        this.setState({time:e.data.current});
        break;
      case 'duration':
        /*var playCommand = {
           type:'play'
         } */
        this.setState({duration:e.data.duration, loading:false});
        break;
      case 'currentelements':
        var indexItems=(this.state.items.findIndex(element => element.key === e.data.item));
        if(e.data.action === 'show' && this.state.items[indexItems]){
            this.state.items[indexItems].color='orange';
        }else if(e.data.action === 'hide' && this.state.items[indexItems]){
          this.state.items[indexItems].color='grey';
        }
        this.onTabChangeLeft({}, {activeIndex:1})
        break;
      default:
        break;
    }
  }

  msgSend (e, data) {
    if (e) { //video control
      var obj = {type: data.name}
      //JSON.stringify(obj)
      this.setState({control: obj}, this.postControl);
      if (obj.type === 'unmute') {
        this.setState({mute: true, iconmute: 'mute'})
      }else if(obj.type === 'mute'){
        this.setState({mute: false, iconmute: 'unmute'})
      }else if (obj.type === 'stop') {}
    } else { //other commands
      this.setState({control: data}, this.postControl)
    }
  }

  handleConfigurationsList = (event, data)=> {
    this.setState({itemIndexConfigurationsList:data.index ,configurationName:data.item},(this.onTabChangeLeft({}, {activeIndex:0}),this.loadVideo()));

  }

  handleItemsList = (event, data)=> {
    this.setState({itemIndexTimeline:data.index },this.onTabChangeLeft({}, {activeIndex:1}));
  }

  loadVideo (event, data) {
    if(data){
      if(data.name === "master"){
        this.setState({configurationName:""});
        this.handleConfigurationsList({},{index:-1});
      }
    }
    //ID VIDEO FROM HOME CHOICE
    const params = new URLSearchParams(this.props.location.search)
    const identifierVideo = params.get('id')
    if (identifierVideo) {
      var servicePath = services.video_use_GET_AUTH.replace('[id]', this.state.configurationName);
      servicePath = servicePath.replace('[name]', identifierVideo);
      servicePath = servicePath+'&controls=true&postmsg=false';
      console.log(services.domain + servicePath);
      if((services.domain + servicePath) != this.state.selected){
        this.setState({
          items: [],
          selected: services.domain + servicePath
        });
      }
    } else {
      servicePath = services.domain+services.video_player_GET_AUTH.replace("[name]",identifierVideo);
      if(servicePath != this.state.selected){
        this.setState({items: [], selected: servicePath});
      }
    }
  }

  /**
   *  Loading to retrieve all video configurations
   * */
  loadConfigurations(){
    //ID VIDEO FROM HOME CHOICE
    const params = new URLSearchParams(this.props.location.search);
    const identifierVideo = params.get('id');
    var self=this;

    self.setState({configurations:[],loading: true}); //Azzero configuration per bind Delete
    var servicePath = services.retrieve_configurations_GET_AUTH.replace("[id]",identifierVideo);
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt;
    Axios.get(services.domain+servicePath)
      .then(function (response) {
        if(response.data.message.length >0) {
          for (var key in response.data.message) {
            var obj = response.data.message[key];
            var newobj = {
              key: obj._id, //configurazione
              elements: obj.elements, //elementi configurazione (array)
            };
            self.setState({configurations: [...self.state.configurations, newobj]});
          }
          self.setState({loading: false});
          self.onTabChangeLeft({}, {activeIndex:0});
        }else{
          self.setState({configurations:[],loading: false});
        }
      })
      .catch(function (error) {
        console.log("Errore in funzione [Configuration.jsx/loadConfigurations]: ",error);
      });
  }

  choiceCategory(event, data){
    this.setState({category:data.value});
  }

  /*GESTIONE VISUALIZZAZIONE INFORMAZIONI
   * START
   * */

  postControl () {
    var iframe = document.getElementById('iframeObjplayer')
    if (iframe) {
      var iframeContentWindow = iframe.contentWindow
      iframeContentWindow.postMessage(this.state.control, '*')
    }
  }
  goToItemTime(event, data){
    this.msgSend(undefined,{type: 'goto',time:data.name})
  }


  /*GESTIONE CARD PER INSERIMENTO NUOVO ELEMENTO
   * END
   * */
  onTabChangeLeft = (event, data) => {
    const Configurations=this.state.configurations;
    const Items=this.state.items;
    //ID VIDEO FROM HOME CHOICE
    const params = new URLSearchParams(this.props.location.search)
    const identifierVideo = params.get('id')
    switch (data.activeIndex) {
      case 0://configurations
          ReactDOM.render(<div className="tabbardiv">
            <Button  fluid basic color='grey' name='master' onClick={this.loadVideo}>
            <i id="master-labelplayer">{identifierVideo}</i>
            </Button>
            <Divider/>
            { Configurations.map((item,index) =>
              <Accordion fluid >
                <Accordion.Title  index={index} active={this.state.itemIndexConfigurationsList === index} onClick={this.handleConfigurationsList} item={item.key}>
                  <div className="tabbarsubdiv">{item.key}<Icon name='dropdown'/> </div>
                </Accordion.Title>
                <Accordion.Content index = {index} active={this.state.itemIndexConfigurationsList === index}>
                  <div className="tabbarsubdivcontent"><Icon name='tags'  size='small' />Elements: {item.elements.length}</div>
                  <Divider fitted />
                </Accordion.Content>
              </Accordion>
            )}</div>,document.getElementById('menuVerticalLeftPlayer'));
        this.setState({tabBarActiveIndex: data.activeIndex})
        break
      case 1: //timeline
      if (Items.length>0){
      ReactDOM.render(<div className="tabbardiv">
        { Items.map((item,index) =>
          <Accordion fluid>
            <Accordion.Title key={item.key} index={index} active={this.state.itemIndexTimeline === index} onClick={this.handleItemsList} id={item.key}>
                <Label as='a' basic color={item.fulltime === true ? 'orange': item.color}>
                  <Icon name='lightbulb outline' color={item.fulltime === true ? 'orange': item.color}/>  {item.name ? item.name : item.category}
                </Label>
                <Icon name='dropdown' color={item.color} />
            </Accordion.Title>
            <Accordion.Content index = {index} active={this.state.itemIndexTimeline === index}>
              <div id="hotspotdetail">
              { item.description ? <p>{item.description}</p> : ""}
              { item.link ? <p><Icon name='linkify' size='small' />Link: <a href={item.link} target="_blank">{item.link}</a></p> : ""}
                {item.fulltime === false ?    <Label  as='a' image basic name={item.tStart} color={item.color} onClick={this.goToItemTime}>{item.meta}
                </Label> : ''}
              </div>
              <Divider hidden />
            </Accordion.Content>
          </Accordion>
        )}
      </div>, document.getElementById('menuVerticalLeftPlayer'))
        this.setState({tabBarActiveIndex: data.activeIndex})
      }
        break
      default:
        console.log('TAB Button Not linked')
        break
    }
  }

  copyUrlFullscreenMode =()=>{
      var textArea = document.getElementById("textareaurl");
      textArea.select();
      document.execCommand("copy");
  }

  render(){
    return(
      <div>
        <Grid style={{margin:'1px 1px 1px 0px',padding: '0px 0px 0px 0px'}}>
          <Grid.Column width={4} style={{margin:'0px 0px 0px 0px',padding: '0px 0px 0px 0px', display:'block'}}>
            <Tab menu={{secondary: false, pointing: true, attached:true}} panes={this.state.panesLeft}
                 onTabChange={this.onTabChangeLeft}
                 activeIndex={this.state.tabBarActiveIndex}/>
            <div id='menuVerticalLeftPlayer'>
            </div>
          </Grid.Column>
          <Grid.Column width={12} style={{margin:'0px 0px 0px 0px',padding: '0px 0px 0px 0px', display:'block'}}>
            <Dimmer active={this.state.loading}>
              <Loader>Loading</Loader>
            </Dimmer>
            <div id="iframeboxplayer">
              <iframe id="iframeObjplayer" src={this.state.selected}/>
            </div>
          </Grid.Column>
          <Grid.Row>
            <Grid.Column width={4}>

            </Grid.Column>
            <Grid.Column width={12}>

            <Icon name='expand' size='large' color="grey"/>
                <a href={this.state.selected} target="_blank">Fullscreen Mode</a>
                <Button basic icon onClick={this.copyUrlFullscreenMode}> <Icon name='copy' size='large' color="grey"/> <textarea id="textareaurl"
                                                                                                       value={this.state.selected} style={{'color': 'rgba(0,0,0,0)', 'font-size': '0','background': 'rgba(0,0,0,0)','width': '0px','height': '0px'}}
                /></Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )}
}

// Use connect to put them together
export default connect(mapStateToProps)(Player);
