import React, { Component } from 'react'
import { Input, Modal, Button } from 'semantic-ui-react'
import { PhotoshopPicker } from 'react-color'

import { setfocusobj } from '../../../../redux/actions/ObjectAction'

class TextlineColor extends Component {
  constructor (props) {
    super(props)
    this.updateState = this.updateState.bind(this);
    var updated = this.props.store.getState();

    this.state = {
      previousState:updated,
      color: updated.reducer.focusobj.obj.properties.color,
      background: updated.reducer.focusobj.obj.properties.background,
      backgroundObj: updated.reducer.focusobj.obj.properties.backgroundObj,
      backgroundAlpha: updated.reducer.focusobj.obj.properties.backgroundAlpha
    }
    this.colorPickerHandler = this.colorPickerHandler.bind(this)
    this.colorChangePickerHandler = this.colorChangePickerHandler.bind(this)
    this.backgroundColorChangePickerHandler = this.backgroundColorChangePickerHandler.bind(this)
    this.openTextColorPicker = this.openTextColorPicker.bind(this)
    this.closeColorPicker = this.closeColorPicker.bind(this)
    this.openBackgroundColorPicker = this.openBackgroundColorPicker.bind(this)
    this.backgroundColorPickerHandler = this.backgroundColorPickerHandler.bind(this)
    this.backgroundAlphaPicker = this.backgroundAlphaPicker.bind(this)
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('textline', focusobj,{'type': 'color', 'action': 'change'}));
    unsubscribe();
  }

  /* color picker */
  colorPickerHandler (event) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.properties.color = this.state.tempTextColor;
    this.props.dispatch(setfocusobj('textline', focusobj, {'type': 'color', 'action': 'change'}));
    this.closeColorPicker();
    unsubscribe();
  }

  colorChangePickerHandler (color) {
    this.setState({tempTextColor: color.hex})
  }

  backgroundColorChangePickerHandler (color) {
    var rgbobj = color.rgb
    rgbobj.a = this.state.backgroundAlpha
    var rgbstr = 'rgba(' + rgbobj.r + ',' + rgbobj.g + ',' + rgbobj.b + ',' + this.state.backgroundAlpha + ')'
    this.setState({
      tempBackgroundColor: rgbstr,
      tempBackgroundColorObj: rgbobj
    })
  }

  backgroundColorPickerHandler (event) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.properties.background = this.state.tempBackgroundColor;
    focusobj.properties.backgroundObj = this.state.tempBackgroundColorObj;
    this.props.dispatch(setfocusobj('textline', focusobj, {'type': 'color', 'action': 'change'}));
    this.closeColorPicker();
    unsubscribe();
  }

  backgroundAlphaPicker (event, data) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.properties.backgroundAlpha = data.value;
    var rgbobj = this.props.reducer.focusobj.obj.properties.backgroundObj;
    rgbobj.a = data.value;
    focusobj.properties.backgroundObj = rgbobj;
    var alphaUpdated = 'rgba(' + rgbobj.r + ',' + rgbobj.g + ',' + rgbobj.b + ',' + data.value + ')';
    focusobj.properties.background = alphaUpdated;
    this.props.dispatch(setfocusobj('textline', focusobj, {'type': 'alpha', 'action': 'change'}));
    unsubscribe();
  }

  updateState () {
    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'textline') {
      this.setState({
        previousState:updated,
        color: updated.reducer.focusobj.obj.properties.color,
        tempTextColor: updated.reducer.focusobj.obj.properties.color,
        background: updated.reducer.focusobj.obj.properties.background,
        tempBackgroundColor: updated.reducer.focusobj.obj.properties.background,
        tempBackgroundColorObj: updated.reducer.focusobj.obj.properties.backgroundObj,
        backgroundAlpha: updated.reducer.focusobj.obj.properties.backgroundAlpha
      });
	  this.props.parent.waitingAnswer();
    }
  }

  openTextColorPicker (event, data) {
    this.setState({textColorModal: true, backgroundColorModal: false})
  };

  closeColorPicker (event, data) {
    this.setState({textColorModal: false, backgroundColorModal: false})
  };

  openBackgroundColorPicker (event, data) {
    this.setState({backgroundColorModal: true, textColorModal: false})
  };

  render () {
    return (
      <div>
        <div>
          <div style={{'color': '#000000', 'fontSize': 12}}>Text Color</div>
          <Button style={{backgroundColor: this.state.color, width: '90%', borderColor: 'black', border: 'solid 2px'}}
                  onClick={this.openTextColorPicker}></Button>
        </div>
        <div>
          <div style={{'color': '#000000', 'fontSize': 12}}>Background Color</div>
          <Button
            style={{backgroundColor: this.state.background, width: '90%', borderColor: 'black', border: 'solid 2px'}}
            onClick={this.openBackgroundColorPicker}></Button>
        </div>
        <div>
          <div style={{'color': '#000000', 'fontSize': 12}}>Background Alpha {this.state.backgroundAlpha} </div>
          <Input name='alpha' type='range' min={0} max={1} step={0.1} value={this.state.backgroundAlpha}
                 onChange={this.backgroundAlphaPicker}
                 style={{height: 'auto', width: '90%'}}/>
        </div>
        <Modal basic open={this.state.textColorModal} onClose={this.closeColorPicker}>
          <PhotoshopPicker header={'Text color'} color={this.state.tempTextColor}
                           onChange={this.colorChangePickerHandler} onAccept={this.colorPickerHandler}
                           onCancel={this.closeColorPicker}/>
        </Modal>

        <Modal basic open={this.state.backgroundColorModal} onClose={this.closeColorPicker}>
          <PhotoshopPicker header={'Background color'} color={this.state.tempBackgroundColor}
                           onChange={this.backgroundColorChangePickerHandler}
                           onAccept={this.backgroundColorPickerHandler} onCancel={this.closeColorPicker}/>
        </Modal>
      </div>
    )
  }
}

export default TextlineColor