/** * Created by michelefuschillo on 14/02/18. */
import React, { Component } from 'react';
import {Input,Table} from 'semantic-ui-react';
import '../../../../css/config.css';
import { setfocusobj} from '../../../../redux/actions/ObjectAction';

class TextPosition extends Component {
  constructor (props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    var updated = this.props.store.getState();
      this.state = {
        previousState:updated,
        width: updated.reducer.focusobj.obj.properties.width,
        height: updated.reducer.focusobj.obj.properties.height
    }
    this.inputHandler = this.inputHandler.bind(this);
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('text', focusobj,{'type':'width','action':'change'}));
    unsubscribe();
  }

  updateState(){
    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'text') {
      this.setState({
        previousState:updated,
        width:updated.reducer.focusobj.obj.properties.width,
        height:updated.reducer.focusobj.obj.properties.height});
		this.props.parent.waitingAnswer();
    }
  }

  inputHandler (event, data) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    switch (data.name){
      case 'width':
        focusobj.properties.width = data.value;
        this.props.dispatch(setfocusobj('text', focusobj,{'type':'width','action':'change'}));
        break;
      case 'height':
        focusobj.properties.height = data.value;
        this.props.dispatch(setfocusobj('text', focusobj,{'type':'height','action':'change'}));
        break;
      default:
        break;
    }
    unsubscribe();
  }

  render () {
	let reduxstate = this.props.store.getState();
    return (
    <Table collapsing id='section-config-tableTextPosition' basic='very' >
      <Table.Body>
      <Table.Row>
        <Table.Cell>Width</Table.Cell>
        <Table.Cell><Input name='width' type='range' min={10} max={reduxstate.reducer.focusobj.obj.properties.vo.ontop ? 600 : 1000} step={1} value={this.state.width} onChange={this.inputHandler}
                     style={{ height:'auto', width:'65px'}}/></Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Height</Table.Cell>
        <Table.Cell><Input name='height' type='range' min={10} max={reduxstate.reducer.focusobj.obj.properties.vo.ontop ? 600 : 1000} step={1} value={this.state.height} onChange={this.inputHandler}
                     style={{ height:'auto', width:'65px'}}/></Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
    )
  }
}
export default TextPosition;