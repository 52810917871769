/**
 * Created by michelefuschillo on 21/11/17.
 */
function actionLogout () {
  // Return action
  return {
    // Unique identifier
    type: 'LOGOUT'
  };
};
export default actionLogout;