/**
* Created by michelefuschillo on 29/01/18.
*/
import React, { Component } from 'react';
import {List, Image, Button} from 'semantic-ui-react';
import {setfocusobj} from '../../../../redux/actions/ObjectAction';
import '../../../../css/config.css';

let unsubscribe;
class ImageComp extends Component {
	constructor (props) {
		super(props);
		this.updateState = this.updateState.bind(this);
		this.objSelected=this.objSelected.bind(this);
	}
  
	updateState(){
		this.props.parent.waitingAnswer();
	}

	objSelected(event, data){
		unsubscribe = this.props.store.subscribe(this.updateState);
		this.props.dispatch(setfocusobj(
			'image',
			{type: 'image', action: '',name:"",description:"", properties: {coverwidth:0,coverheight:0, covername:"",coverpath:"",coveraction:""}},
			{'type':'image','command':'new'}));
			unsubscribe();
	}

		render () {
			return (
				<div>
				<List size="mini">
				<List.Item name='first'>
				<Button color="grey" circular inverted size='mini' compact onClick={this.objSelected}>
				<Image src='/assets/images/imageobj/imageobj.png' height="16" width="16" name='circle'/>
				</Button>
				</List.Item>
				</List>
				</div>
			)
		}
	}

	export default ImageComp;