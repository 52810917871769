/* Created by obbiettivo on 18/06/18. */
import React, { Component } from 'react';
import {Grid,Button, Input, Icon, Image}      from 'semantic-ui-react';
import {setfocusobj} from '../../../../redux/actions/ObjectAction';
import { transitions, positions, Provider as AlertContainer } from 'react-alert'
import '../../../../css/config.css';

let unsubscribe;

class ImagePosition extends Component {
  constructor (props) {
	  super(props);

	  this.updateState = this.updateState.bind(this);
      this.onChangePosition = this.onChangePosition.bind(this);    
      this.setPosition = this.setPosition.bind(this);

      

	  var updated = this.props.store.getState();

	  this.state = {
		  previousState:updated,
		  x:parseFloat(updated.reducer.focusobj.obj.x).toFixed(0),
          y:parseFloat(updated.reducer.focusobj.obj.y).toFixed(0),
          z:parseFloat(updated.reducer.focusobj.obj.z).toFixed(0)
	  };
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    // console.log(updated);
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('image', focusobj,{}));
    this.props.parent.waitingAnswer();
    unsubscribe();
  }

  updateState(){
	  var updated = this.props.store.getState() //NEW
	  if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'image') {
        // console.log(updated.reducer.focusobj)
		  this.setState({
			  previousState:updated,
              x:parseFloat(updated.reducer.focusobj.obj.x).toFixed(0),
              y:parseFloat(updated.reducer.focusobj.obj.y).toFixed(0),
              z:parseFloat(updated.reducer.focusobj.obj.z).toFixed(0)
		  })
		  this.props.parent.waitingAnswer();
	  }
  }

  onChangePosition(e,d){
     
      switch (d.name) {
          case 'xposition':
            this.setState({x:d.value});
              break;
              case 'yposition':
                this.setState({y:d.value});
                break;
                case 'zposition':
                    this.setState({z:d.value});
                    break;
          default:
              break;
      }

  }

  setPosition(e,d){
    if ((this.state.x<=795)&&(this.state.x>=-795)
        &&(this.state.y<=795)&&(this.state.y>=-795)&&
            (this.state.z<=795)&&(this.state.z>=-795)){
        	  unsubscribe = this.props.store.subscribe(this.updateState);
        	  var updated = this.props.store.getState();
        	  var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
                // console.log(focusobj);
                // console.log(parseFloat(this.state.x).toFixed(2))
                focusobj.x=parseFloat(this.state.x).toFixed(0);
                focusobj.y=parseFloat(this.state.y).toFixed(0);
                focusobj.z=parseFloat(this.state.z).toFixed(0);
                // console.log(focusobj)
                this.props.dispatch(setfocusobj('image', focusobj,{}));
                this.props.parent.waitingAnswer();
                unsubscribe();
    }else{
        this.showAlertError("Values between -795 and 795");
    }                
  }


  alertOptions = {
    offset: "100px",
    position: 'bottom center',
    theme: 'dark',
    time: 5000,
    transition: 'scale'
  }


  showAlertError = (msg) => {
    this.msg.show(msg, {
      time: 6000,
      type: 'error',
    })
  }

  render () {
    return (
        <Grid  divided>
           <Grid.Row>
           <Input style={{"marginLeft":"10px"}}
              name='xposition'
              size='small'
              label={{ basic: true, content: 'X' }}
              labelPosition='left'
              placeholder='x position'
              value={this.state.x}             
              type='number'
              onChange={this.onChangePosition}
            />
           </Grid.Row>
           <Grid.Row>
           <Input style={{"marginLeft":"10px"}}
              name='yposition'
              size='small'
              label={{ basic: true, content: 'Y' }}
              labelPosition='left'
              placeholder='y position'
              value={this.state.y}  
              type='number'           
              onChange={this.onChangePosition}
            />
           
           </Grid.Row>
           <Grid.Row>
           <Input style={{"marginLeft":"10px"}}
              name='zposition'
              size='small'
              label={{ basic: true, content: 'Z' }}
              labelPosition='left'
              placeholder='z position'
              value={this.state.z}          
              type='number'
              onChange={this.onChangePosition}
            />
           </Grid.Row>    
           <Grid.Row>     
               <Button style={{"marginLeft":"5px"}}
               basic  fluid circle
               icon="check circle"
               color="green"
               onClick={this.setPosition}>                           
                </Button>   
                <AlertContainer  ref={a => this.msg = a} {...this.alertOptions} /> 
           </Grid.Row>              
           </Grid>  
		 )
  }
}

export default ImagePosition;
