/**
* Created by michelefuschillo on 29/01/18.
*/
import React, { Component } from 'react';
import {Button, List, Image}         from 'semantic-ui-react';
import { setfocusobj} from '../../../../redux/actions/ObjectAction';

import '../../../../css/config.css';

let unsubscribe;
class ShapeComp extends Component {
	constructor (props) {
		super(props);
		this.shapePicker = this.shapePicker.bind(this);
		this.updateState = this.updateState.bind(this);
	}
  
	updateState(){
		this.props.parent.waitingAnswer();
	}

	shapePicker (event, data) {
		unsubscribe = this.props.store.subscribe(this.updateState);
		this.props.dispatch(setfocusobj(
			'shape',
			{type: 'shape', alpha: 1, scale: 1, action: '', name:'',description:'', link: '',color:''},
			{'type':'shape','command':'new','shape':data.name}));
			unsubscribe();
		}

		render () {
			return (
				<List horizontal size="mini">
				<List.Item name="shapecircle">
				<Button name="shapecircle" color="grey" circular inverted size='mini' compact onClick={this.shapePicker}>
				<Image src='/assets/images/shape/circle.png' height="16" width="16" name='circle'/>
				</Button>
				</List.Item>
				<List.Item name="shapebox">
				<Button name="shapebox" color="grey" circular inverted size='mini' compact onClick={this.shapePicker}>
				<Image src='/assets/images/shape/square.png' height="16" width="16" name = 'square'/>
				</Button>
				</List.Item>
				<List.Item name="shapetriangle">
				<Button name="shapetriangle" color="grey" circular inverted size='mini' compact onClick={this.shapePicker}>
				<Image src='/assets/images/shape/triangle.png' height="16" width="16" name='triangle'/>
				</Button>
				</List.Item>
				</List>
			)
		}
	}

	export default ShapeComp;