/* * Created by michelefuschillo on 31/01/18. */
import React, { Component } from 'react'
import {Accordion,Icon,Popup} from 'semantic-ui-react';
import '../../../css/config.css'
import * as desc from './descriptionmenu.json'

const ShowInfo=true;

class MenuFifth extends Component {
  constructor (props) {
    super(props)
    this.state = ({
      accordionActiveIndex: -1,
      accordionActiveIndexInternal: -1,
    })
    
    this.manageAccordion = this.manageAccordion.bind(this)
    this.callbackComponent = this.callbackComponent.bind(this)
  }
  
  componentWillMount () {
    this.setState({accordionActiveIndex: -1, accordionActiveIndexInternal: -1})
  }
  
  componentWillReceiveProps(props) {
    if (this.props.action==="menutime"){
      //accordionActiveIndex in questo caso assume il valore della posizione del menu time 
      //cambiare se cambi l'ordine del menù 
      this.setState({accordionActiveIndex:3,accordionActiveIndexInternal:-1});
      let i= document.getElementById('timeaccordion')    
      if (i){
        this.props.callbackParent("time")
      }
    }else{
      this.setState({accordionActiveIndex: -1, accordionActiveIndexInternal: -1});
    }
  }
  
  manageAccordion (event, data) {
    if (data.index === this.state.accordionActiveIndex) {
      this.setState({accordionActiveIndex: -1})
    } else {
      this.setState({accordionActiveIndex: data.index})
      this.props.callbackParent(data.name)
    }
  }
  
  componentDidMount() {
    
    if (this.props.action==="menutime"){
      //accordionActiveIndex in questo caso assume il valore della posizione del menu time 
      //cambiare se cambi l'ordine del menù 
      this.setState({accordionActiveIndex:3,accordionActiveIndexInternal:-1});
      let i= document.getElementById('timeaccordion')    
      if (i){
        this.props.callbackParent("time")
      }
    }
  }
  
  callbackComponent () {
    this.props.callbackParent(this.state);
  }
  
  render () {
    return (
      <div>
      <Accordion styled>
      <Accordion.Title name="video2dtext" className="accordionTitle"
      active={this.state.accordionActiveIndex === 0} index={0} onClick={this.manageAccordion}
      position='left'>
      <Icon name='dropdown' id='section-config-IconAccordion'/>
      
      <Icon color="grey" name='info circle'/>
      <i id='section-config-AccordionHotspot'>Info</i>
      
      
      {this.props.reducer.focusobj.obj.properties.vo ?( this.props.reducer.focusobj.obj.properties.vo.showhotspot === true ? <Icon color="grey" name='eye'/> : <Icon color="grey" name='eye slash'/>):''}
      { ShowInfo=== true ? 
        <Popup flowing hoverable position='bottom right' size='small' trigger={                      
          <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
        }>
        <p id='section-edit-card-link'>{desc.info.description}</p>
        </Popup>
        :
        <span></span>}
        </Accordion.Title>
        
        <Accordion.Content active={this.state.accordionActiveIndex === 0}>
        <div id='video2dtextaccordion'></div>
        </Accordion.Content>
        
        <Accordion.Title name="video2dposition" className="accordionTitle" active={this.state.accordionActiveIndex === 1} index={1} onClick={this.manageAccordion} position='left' >
        <Icon name='dropdown' id='section-config-IconAccordion'/>
        <Icon color="grey" name='globe'/><i id='section-config-AccordionHotspot'>Position</i>
        { ShowInfo=== true ? 
          <Popup flowing hoverable position='bottom right' size='small' trigger={                      
            <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
          }>
          <p id='section-edit-card-link'>{desc.position.description}</p>
          </Popup>
          :
          <span></span>}
          </Accordion.Title>
          <Accordion.Content active={this.state.accordionActiveIndex  === 1}>
          <div id='video2dposition'></div>
          </Accordion.Content>
          
          <Accordion.Title name="video2dimport" active={this.state.accordionActiveIndex === 2} index={2}
          onClick={this.manageAccordion} position='left'>
          <Icon name='dropdown' id='section-config-IconAccordion'/>
          
          <Icon color="grey" name='upload'/><i id='section-config-AccordionHotspot'>Load</i>
          { ShowInfo=== true ? 
            <Popup flowing hoverable position='bottom right' size='small' trigger={                      
              <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
            }>
            <p id='section-edit-card-link'>{desc.load.description}</p>
            </Popup>
            :
            <span></span>}
            </Accordion.Title>
            <Accordion.Content active={this.state.accordionActiveIndex === 2}>
            <div id='video2dimport'></div>
            </Accordion.Content>
            { this.props.reducer.resType !=="IMAGE2" && this.props.reducer.resType !=="IMAGE3"?
            <div>
            <Accordion.Title name="time" active={this.state.accordionActiveIndex === 3} index={3}
            onClick={this.manageAccordion} position='left'>
            <Icon name='dropdown' id='section-config-IconAccordion'/>
            
            <Icon color="grey" name='time'/><i id='section-config-AccordionHotspot'>Time</i>
            { ShowInfo=== true ? 
              <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
              }>
              <p id='section-edit-card-link'>{desc.time.description}</p>
              </Popup>
              :
              <span></span>}
              </Accordion.Title>
              <Accordion.Content active={this.state.accordionActiveIndex === 3}>
              <div id='timeaccordion'></div>
              </Accordion.Content>
              </div>
            :null} 
              <Accordion.Title name="video2dscale" active={this.state.accordionActiveIndex === 4} index={4}
              onClick={this.manageAccordion} position='left'>
              <Icon name='dropdown' id='section-config-IconAccordion'/>
              
              <Icon color="grey" name='expand arrows alternate'/><i id='section-config-AccordionHotspot'>Size</i>
              { ShowInfo=== true ? 
                <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                  <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                }>
                <p id='section-edit-card-link'>{desc.size.description}</p>
                </Popup>
                :
                <span></span>}
                </Accordion.Title>
                <Accordion.Content active={this.state.accordionActiveIndex === 4}>
                <div id='scalevideo2daccordion'></div>
                </Accordion.Content>
                
                <Accordion.Title name="video2dappearance" className="accordionTitle" active={this.state.accordionActiveIndex === 5} index={5} onClick={this.manageAccordion} position='left' >
                <Icon name='dropdown' id='section-config-IconAccordion'/>
                
                <Icon color="grey" name='picture'/><i id='section-config-AccordionHotspot'>Cover</i>
                { ShowInfo=== true ? 
                  <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                    <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                  }>
                  <p id='section-edit-card-link'>{desc.cover.description}</p>
                  </Popup>
                  :
                  <span></span>}
                  </Accordion.Title>
                  
                  <Accordion.Content active={this.state.accordionActiveIndex  === 5}>
                  <div id='video2dappearance'></div>
                  </Accordion.Content>
                  
                  <Accordion.Title name="video2doption" active={this.state.accordionActiveIndex === 6} index={6}
                  onClick={this.manageAccordion} position='left'>
                  <Icon name='dropdown' id='section-config-IconAccordion'/>
                  <Icon color="grey" name='play circle'/><i id='section-config-AccordionHotspot'>Play options</i>
                  { ShowInfo=== true ? 
                    <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                      <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                    }>
                    <p id='section-edit-card-link'>{desc.playoption.description}</p>
                    </Popup>
                    :
                    <span></span>}
                    
                    </Accordion.Title>
                    <Accordion.Content active={this.state.accordionActiveIndex === 6}>
                    <div id='optionvideo2daccordion'></div>
                    </Accordion.Content>
                    
                    
                    <Accordion.Title name="video2dviewoptions" className="accordionTitle" active={this.state.accordionActiveIndex === 7} index={7} onClick={this.manageAccordion} position='left' >
                    <Icon name='dropdown' id='section-config-IconAccordion'/>
                    <Icon color="grey" name='move'/><i id='section-config-AccordionHotspot'>View options</i>
                    { ShowInfo=== true ? 
                      <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                        <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                      }>
                      <p id='section-edit-card-link'>{desc.viewoptions.description}</p>
                      </Popup>
                      :
                      <span></span>}
                      </Accordion.Title>
                      
                      <Accordion.Content active={this.state.accordionActiveIndex  === 7}>
                      <div id='video2dviewoptions'></div>
                      </Accordion.Content>
                      
                      {/* <Accordion.Title name="video2dshapetag"  active={this.state.accordionActiveIndex  === 8} index={8} onClick={this.manageAccordion} position='left'>
                      <Icon name='dropdown' id='section-config-IconAccordion'/>
                      <Icon color="grey" name='tag'/><i id='section-config-AccordionHotspot'>Tags</i>
                      { ShowInfo=== true ? 
                        <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                          <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                        }>
                        <p id='section-edit-card-link'>{desc.tags.description}</p>
                        </Popup>
                        :
                        <span></span>}
                        </Accordion.Title>
                        <Accordion.Content active={this.state.accordionActiveIndex  === 8}>
                        <div id='video2dtagshapeaccordion'></div>
                        </Accordion.Content> */}
                        </Accordion>
                        </div>
                        )
                      }
                    }
                    
                    export default MenuFifth
                    