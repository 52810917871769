import React, { Component } from 'react';
import { Input, Icon, Button, Radio, Grid } from 'semantic-ui-react';
import Axios from 'axios';
import * as services from '../../../../config/services.json';
import menuItem from '../../../../redux/actions/menustate';
import actionLogout from '../../../../redux/actions/logout';
import '../../../../css/config.css';
import { launchModal } from '../../../../redux/actions/ModalAction'
import { getResourceDataForLanguage } from './../utils';

let unsubscribe;

class switchBackgroundResource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resource: "",            
            covername: "",
            audiolink: "",
            audioname: ""            
        };
    }

    componentWillReceiveProps(props) {

    }

    componentWillMount() {
        //authentication check
        var updated = this.props.store.getState();
        if (!updated.reducer.logged) {
            this.props.store.history.push("/login");
            this.props.store.dispatch(menuItem('login'));
        } else {
            this.props.store.dispatch(menuItem('home'));
        }
    }    

    onClickOkButton = (event, data) => {
        console.log(this.state.resource);
        if (this.props.modaltype==="vrmode"){
            var actionOptions = {
                righteyeresource:this.state.resource
            }
            this.props.callbackFather('', '', '', this.state.resource, actionOptions);
        }else{
            var actionOptions = {
                switchresource:this.state.resource
            }
            this.props.callbackFather('', '', '', this.state.resource, actionOptions);
        }
    }

    

    openUploadModal = (event, data) => {
        /*unsubscribe = this.props.store.subscribe(this.updatestate);
        this.props.store.dispatch(launchModal('SETTING_MODAL', 'AUDIO',"audiolink"));
        this.setState({macrocategory: 'audio'});*/
        /**
        * Modifica nuova modalità asset 03/02/2022
        */

        console.log(data.name.toLowerCase());
        this.setState({ macrocategory: 'image' });
        /**NUOVA MOADLITà ASSET 07-12-2021 */
        window.parent.postMessage({
            type: "CHILDMSG-ANNOTATOR", payload: {
                msg: "upload",
                typeres: data.name.toLowerCase()
            }
        }, "*");
        window.addEventListener('message', this.postMessageHandler);
    }

    openListModal = (event, data) => {
        let getResType="";
        if (this.props.modaltype==="vrmode"){
            getResType="imglist";
        }else {
            getResType="switchingresource"
        }
        //unsubscribe = this.props.store.subscribe(this.updateState);
        //this.props.dispatch(launchModal('SETTING_MODAL', 'AUDIO'));
        //this.setState({ macrocategory: 'audio' });
        //unsubscribe = this.props.store.subscribe(this.updateState);
        //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
        this.setState({ macrocategory: 'image' });
        /**NUOVA MOADLITà ASSET 15-12-2021 */
        window.parent.postMessage({
            type: "CHILDMSG-ANNOTATOR", payload: {
                msg: getResType
            }
        }, "*");
        window.addEventListener('message', this.postMessageHandler);
    }

    // openExternalModal = (event, data) => {
    //     //unsubscribe = this.props.store.subscribe(this.updateState);
    //     //this.props.dispatch(launchModal('SETTING_MODAL', 'AUDIO'));
    //     //this.setState({ macrocategory: 'audio' });
    //     //unsubscribe = this.props.store.subscribe(this.updateState);
    //     //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    //     this.setState({ macrocategory: 'audio' });
    //     /**NUOVA MOADLITà ASSET 15-12-2021 */

    //     window.parent.postMessage({
    //         type: "CHILDMSG-ANNOTATOR", payload: {
    //             msg: "external"
    //         }
    //     }, "*");
    //     window.addEventListener('message', this.postMessageHandler);
    // }


    /**NUOVA MOADLITà ASSET 03-02-2022 */
    postMessageHandler = (event) => {
        if (event.origin === services.ASSETDOMAIN) { //FROM ASSETLOGIN
            switch (event.data.type) {
                case "NEWRESOURCE":
                    console.log("newRes:")
                    console.log(event.data)
                    //old implementation use cover url with event.data.payload._id to store only direct link to resoruce
                    // this.setState({ audiolink: this.coverUrl(event.data.payload._id), audioname: event.data.payload.name });
                    //now store all resource data to check in play mode wich langauage choiced
                    
                        //gestisci risorsa estermna         
                        this.setState({ resource: event.data.payload});
                        window.removeEventListener('message', this.postMessageHandler);
                    
                    break;
                default: break;
            }
        }
    }

    coverUrl = (resoruce) => {    
        let ser =
          services.ASSETBACKEND + services.singleresourcemanager_GET_AUTH.replace(
            "{APPNAME}",
            this.props.reducer.appname
          );
        ser = ser.replace("{DOMAIN}", this.props.reducer.domain);
        ser = ser.replace("{RESOURCEID}", resoruce._id);
        ser = ser.replace("{USERID}", this.props.reducer.userid);
        ser = ser.replace("{COVER}", false);
        ser = ser.replace("{MEDIARES}","first");
        ser = ser.replace("{MEDIALAN}", getResourceDataForLanguage(resoruce));
        // ser = ser + "&t=" + new Date().getTime();
        return ser;
      };
      
    removeSelection = (e, d) => {
        this.setState({ resource: "" });
    }
    render() {
        return (
            <div>
                <Input name='inputlink' type='text' value={this.state.resource.name ? this.state.resource.name  : "Select resource to switch"} disabled={true}
                    style={{ height: 'auto', width: '50%' }} />
                <Button icon style={{ marginLeft: '5px' }} onClick={this.openUploadModal} name="external">
                    <Icon name='upload' />
                </Button>
                <Button icon onClick={this.openListModal}>
                    <Icon name='unordered list' />
                </Button>
                <Button icon onClick={this.removeSelection}>
                    <Icon name='delete' />
                </Button>
                <Button onClick={this.onClickOkButton} style={{ marginLeft: '5px' }}>OK</Button>
            </div>
        )
    }

}

export default switchBackgroundResource;
