import React, { Component } from 'react';
import {Button,  Divider,Input,Modal,Grid,Dropdown,Radio,Image} from 'semantic-ui-react';
import { transitions, positions, Provider as AlertContainer } from 'react-alert';
import Axios from 'axios';
import * as services from '../../../../config/services.json';

var dropDownOptions ={
  "isFetching": false,
  "multiple": false,
  "search": true,
  "searchQuery": "",
  "value": "",
  options:[]
};

class copyConfigModal extends Component {
  constructor (props) {
    super(props);
    this.state=({cover:"",
      name:"",
      search: true,
      searchQuery: null,
      videoSelectedName:"",
      videoDuration:"",
      dropDownOptions:dropDownOptions,selectedCategory:"",copyAction:"thisvideo" , videos:[] });
      this.handleMessage=this.handleMessage.bind(this);
      this.showAlert = this.showAlert.bind(this);
      this.loadVideo=this.loadVideo.bind(this);
      this.onChangeVideo=this.onChangeVideo.bind(this);
      this.onChangeAction=this.onChangeAction.bind(this);
      this.sendCopyCommand=this.sendCopyCommand.bind(this);
      this.onChangeName=this.onChangeName.bind(this);
    }
    componentWillMount(){
      console.log(this.props.resType)
      //  console.log(this.props.videodata)
       // var coverPath=services.domain + services.cover_video_path.replace("[name]",this.props.videodata.detail.cover)
       // this.setState({cover:coverPath})
    }
    alertOptions = {    offset: 14,    position: 'bottom center',    theme: 'dark',    time: 5000,    transition: 'scale'  };

    handleMessage(event,data){
      // console.log("nome nuovo "+ data.value)  ;
      this.setState({nameCat:data.value,selectedCategory:"" });
    }

    handleError(error){
      this.showAlert('Name is arleady taken ', 'error')
    }

    showAlert (message, result) {
      this.msg.show(message, { time: 5000, type: result })  }

    handleSearchChange = (e, {searchQuery }) => this.setState({searchQuery })

    onChangeVideo(event,data){
        if (data.value===""){
          this.setState({cover:"",videoSelectedName:"",videoDuration:""})
        }else {
          var i= data.options.findIndex(x => x.value === data.value);
          if (i===-1){
            console.log("cover not found");
          }else {
            var coverPath=services.domain + services.cover_video_path.replace("[name]",data.options[i].cover)
            this.setState({cover:coverPath,videoSelectedName:data.options[i].value, videoDuration:data.options[i].duration})
          }
        }
      }

      loadVideo(){
        var self = this;
        // console.log("loadvideo");
        //    this.setState({loading:true});
        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt;
        Axios.get(services.domain+services.resannotation_group_GET_AUTH.replace('[group]',self.props.group))
        .then(function (response) {
          if(response.status === 200){
            console.log(response);
            dropDownOptions.options=[];
            let alldata=[];
            if (self.props.resType==="IMAGE3"||self.props.resType==="VIDEO3"){
              alldata=alldata.concat(response.data.message.image3d);
              alldata=alldata.concat(response.data.message.video3d);
            }else if (self.props.resType==="IMAGE2"||self.props.resType==="VIDEO2"){
              alldata=alldata.concat(response.data.message.image2d);            
              alldata=alldata.concat(response.data.message.video2d);
            }
            // if (response.data.message.image3d.length>0){
            //   for(var i=0 ; i<response.data.message.image3d.length ; i++){
            //     if (response.data.message.image3d[i]._id!==self.props.videodata._id){
            //     var dropDownOptionsElem={};
            //     dropDownOptionsElem.key=i;
            //     dropDownOptionsElem.text=response.data.message.image3d[i].name;
            //     dropDownOptionsElem.value=response.data.message.image3d[i]._id;
            if (alldata.length>0){
              for(var i=0 ; i<alldata.length ; i++){
                if (alldata[i]._id!==self.props.videodata._id){
                var dropDownOptionsElem={};
                dropDownOptionsElem.key=i;
                dropDownOptionsElem.text=alldata[i].name;
                dropDownOptionsElem.value=alldata[i]._id;
                // dropDownOptionsElem.cover=response.data.message.image2d[i].detail.cover;
                // dropDownOptionsElem.duration=response.data.message.image2d[i].detail.duration;
                dropDownOptions.options.push(dropDownOptionsElem);
               }
              }
              // console.log(dropDownOptions)
              self.setState({dropDownOptions:dropDownOptions,videos:response.data.message});
            }else {
                console.log("Non ci sono video ");
            }
            //gestisci risposta
          }else if(response.status === 401){
                  console.log("Errore 401 token expired");
          }
        })
        .catch(function (error) {
          //    self.setState({loading:false});
          if(error === 401){ //token expired
            //errore messaggio
            console.log("Errore 401 token expired");
          }
          console.log("Errore in funzione [copyConfigModal.jsx]: ",error);
        });
      }
      onChangeAction(e,d){

        if (d.name==="originTvideo"){
          // var coverPath=services.domain + services.cover_video_path.replace("[name]",this.props.videodata.detail.cover)
          dropDownOptions.options=[];
          this.setState({copyAction:"thisvideo",cover:"",dropDownOptions:dropDownOptions,name:"",videoSelectedName:"",videoDuration:""})
        }else if (d.name==="originAvideo"){

          this.setState({copyAction:"another",cover:"",name:this.props.configId },this.loadVideo)
        }
      }

      sendCopyCommand(){
        if (this.state.copyAction==="thisvideo"){
          this.props.callbackOnCopyConfig(this.props.configId);

        }else {//case "another"
          if (this.state.name!==""){
            if (this.state.videoSelectedName!==""){
              // chiamata al nuovo copia
              var data = {
                configurationNameOld:this.props.configId,
                videoName:this.state.videoSelectedName,
                configurationName:this.state.name,
                videoDuration: this.state.videoDuration
              }
              this.props.callbackCopyConfigAnotherVideo(data);
            }else {
               this.showAlert('Empty Video ', 'error')
            }
          }else {
              this.showAlert('Empty name ', 'error')
          }
        }
      }

      onChangeName(e,d){
        this.setState({name:d.value});
      }

      render () {
        return(
          <div>
            <div >
              <Divider style={{marginTop:'35px'}}/>
              <Grid columns={2} divided>
                <Grid.Column >
                  <Radio name='originTvideo' label='On this background Resource ' onChange={this.onChangeAction}
                    checked={this.state.copyAction === 'thisvideo' ? true : false}/>
                    <Divider/>
                  {/* <p>Name : {this.props.videodata.name}</p> */}
                  {/* <p>Duration : {parseInt(this.props.videodata.detail.duration)}s</p> */}

                </Grid.Column>
                <Grid.Column >
                  <Radio name='originAvideo' label='Choose another background resource' onChange={this.onChangeAction}
                    checked={this.state.copyAction === 'another' ? true : false}/>
                  <Divider/>
                  <Dropdown  disabled={this.state.copyAction === 'another' ? false : true} placeholder='Select Video' clearable selection
                    onChange={this.onChangeVideo}
                    onSearchChange={this.handleSearchChange}
                    options={this.state.dropDownOptions.options}
                    search={this.state.dropDownOptions.search}>
                  </Dropdown>
                  <Divider/>
                  <p>Configuration Name :</p>
                  <Input placeholder={this.state.name} value= {this.state.name} onChange={this.onChangeName}/>
                  {/* <Divider/>
                  <p>Duration : {parseInt(this.state.videoDuration)}s</p>
                  <Divider/> */}
                  {/* <p>Cover:  </p>
                  <Image src={this.state.cover} size='tiny' /> */}
                </Grid.Column>
                <Grid.Row >
                  <Button   fluid positive size='small' content='Copy' onClick={this.sendCopyCommand} />
                </Grid.Row>
              </Grid>
            </div>
            <div>
              <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
            </div>
          </div>
        )
      }
    }
    export default copyConfigModal;
