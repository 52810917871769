/**
 * Created by obbiettivo on 04/09/18.
 */
import React, { Component } from 'react'
import {Button,Divider,Icon,Form,Dimmer,Loader,Header,TextArea} from 'semantic-ui-react';

import Axios from 'axios'
import Services from '../../config/services.json'
import { transitions, positions, Provider as AlertContainer } from 'react-alert'
import '../../css/modal.css'
import LabelsBox from '../../config/labels.json';
import {setfocusobj} from '../../redux/actions/ObjectAction';
import {launchModal} from '../../redux/actions/ModalAction';
import { format } from 'morgan';
import { setLinkEvent } from "../../redux/actions/LinkEvent";

//video/mp4
class UploadModal extends Component {
  constructor (props) {
    super(props)
    var updated = this.props.store.getState();
    this.state = {
      loading: false,
      file:undefined,
      subType: updated.reducer.subType,
      type:updated.reducer.type
    }
    this.saveResource = this.saveResource.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.fileChoiced = this.fileChoiced.bind(this);
  }
  
  componentDidUpdate() {
    console.log("uploadomadl")
	  if(this.state.subType === undefined){
		  var updated = this.props.store.getState();
	   	  this.setState({
		   subType: updated.reducer.subType,
	       type:updated.reducer.type})
	  }
  }

  alertOptions = {    offset: 14,    position: 'bottom center',    theme: 'dark',    time: 5000,    transition: 'scale'  };

  changeValue (event) {
    switch (event.target.id) {
      case 'name':
        this.setState({name: event.target.value})
        break
      case 'description':
        this.setState({description: event.target.value})
        break
      default:
        break
    }
  }

  fileChoiced(event, data) {
    this.setState({file: document.getElementById('file').files[0]})
  }

  /*SALVA RISORSA*/
  saveResource (event, data) {
    if(!this.state.loading){
      event.preventDefault();
      if (!this.props.userId) {
        return this.showAlert('You are not logged in', 'error')
      }

      if (!this.props.jwt) {
        return this.showAlert('User Expired try to Login again', 'error')
      }

      if (!this.state.name) {
        return this.showAlert('Missing Icon name', 'error')
      }

      if (!this.state.description) {
        return this.showAlert('Missing Icon Description', 'error')
      }
      this.setState({loading: true})
      var self = this;
      var formData = new FormData();
      formData.set('userId', this.props.userId);
      formData.set('name', this.state.name);
      formData.set('description', this.state.description);
      formData.set(
         "type",
         this.props.subType
           ? LabelsBox[this.props.subType].mimetype
           : LabelsBox[this.props.reducer.focusobj.type].mimetype
       );
      formData.set("group", this.props.reducer.group);
      formData.set(
        "dest",
        this.props.subType
          ? (LabelsBox[this.props.subType].dest ? LabelsBox[this.props.subType].dest: "")  
          : LabelsBox[this.props.reducer.focusobj.type].mimetype
      );
       formData.set(
         "degree",
         this.props.extra ? (this.props.extra === "video2d" || this.props.extra === "image2d"  ?  2 : 3) : 0
       );
      formData.set('resource', this.state.file);
      //formData.set('type', this.props.subType ? LabelsBox[this.props.subType].mimetype : LabelsBox[this.props.reducer.focusobj.type].mimetype);
      
      const headers = {headers: {'Authorization': 'Bearer ' + this.props.jwt, 'content-type': 'multipart/form-data'}}
      Axios.post(Services.domain + Services.upload_resource_POST_AUTH, formData, headers).then(
        function (response) {
          self.setState({loading: false})
          if (response.status === 201) {
            var updated =self.props.store.getState();            
            var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
            if (self.props.extra==="video2dcover"){
              focusobj.properties.coverpath = response.data.message.pathorigin;
              focusobj.properties.covername = response.data.message.name;
              self.props.dispatch(setfocusobj("video2d",focusobj,{'type':'path','action':'selected'}));
            } else if (self.props.extra === "audiocover") {
              focusobj.properties.cover = response.data.message.pathorigin;
              focusobj.properties.covername = response.data.message.name;
              self.props.dispatch(setfocusobj("audio", focusobj, { 'type': 'path', 'action': 'selected' }));
              
            } else if (self.props.extra === "audiolink") {
               var media={};
                media.path=response.data.message.pathorigin;
                media.name=response.data.message.name;                
                self.props.dispatch(setLinkEvent(media));              
            }else {
              if(self.props.reducer.focusobj.type === "video2d"){
                focusobj.properties.pathresource = response.data.message.pathorigin;
                focusobj.properties.nameresource = response.data.message.name;
                self.props.dispatch(setfocusobj("video2d",focusobj,{'type':'path','action':'selected'}));

              }else if(self.props.reducer.focusobj.type === "htmlpage"){
                focusobj.properties.coverpath = response.data.message.pathorigin;
                focusobj.properties.covername = response.data.message.name;
                self.props.dispatch(setfocusobj("htmlpage",focusobj,{'type':'htmlpage','action':'htmlpage'}));

              }else if(self.props.reducer.focusobj.type === "image"){
                focusobj.properties.coverpath = response.data.message.pathorigin;
                focusobj.properties.covername = response.data.message.name;
                self.props.dispatch(setfocusobj("image",focusobj,{'type':'image','action':'image'}));

              }else if(self.props.reducer.focusobj.type === "audio"){
                focusobj.properties.audioPath = response.data.message.pathorigin;
                focusobj.properties.name      = response.data.message.name;
                self.props.dispatch(setfocusobj("audio",focusobj,{'type':'audio','action':'audio'}));
              }
            }
              //self.showAlert('Upload Successfully', 'success')
             self.props.dispatch(launchModal("","",""));
             console.log(self.props.contexttoreq)
             if (self.props.contexttoreq==="homecontext"){               
              window.location.reload();
             }    
            return;

          }else{
            self.showAlert('Error During Upload', 'error')
          }
        }).catch(
          function (error) {
            self.setState({loading: false})
            self.showAlert('Error Input File', 'error')
          })
    }
  }

  showAlert (message, result) {
    
     this.msg.show(message, { time: 5000, type: result })  
  }

  render () {
    return (
      <div>
        {this.state.subType ? <div>
        <Header as='h3' inverted> {this.state.subType ? LabelsBox[this.state.subType].en.upload.title : LabelsBox[this.state.type].en.upload.title} <Header.Content>
          </Header.Content> </Header>
            <Dimmer active={this.state.loading}> <Loader>Loading</Loader> </Dimmer>
            <Form>
              <Header as='h4' inverted> <Icon name='text cursor' color='grey' size='mini'/> <Header.Content>
                {LabelsBox[this.state.subType].en.upload.name}</Header.Content> </Header>
              <TextArea autoHeight id="name" placeholder='Name...' onChange={this.changeValue} maxLength="15"/>
              <Header as='h4' inverted> <Icon name='text cursor' color='grey' size='mini'/> <Header.Content>
                {LabelsBox[this.state.subType].en.upload.description}</Header.Content> </Header>
              <TextArea autoHeight id="description" placeholder='Description...' size="tiny"
                        onChange={this.changeValue}/>
              <Divider hidden/>
              <Header as='h4' inverted> <Icon name='upload' color='grey' size='mini'/> <Header.Content>
                {LabelsBox[this.state.subType].en.upload.info}</Header.Content> </Header>
              <Button basic color='grey' icon='upload' content='Load' inverted fluid>
                <input
                  id="file"
                  type="file"
                  onChange={this.fileChoiced} accept={LabelsBox[this.state.subType].en.upload.file_info}/>
              </Button>
            </Form>
        <Divider hidden/>
            <Button inverted type='submit' floated='right' basic color='grey' icon='save' content='Save' onClick={this.saveResource}></Button>
        <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
        </div>: ''}
      </div>
    )
  }
}

export default UploadModal
