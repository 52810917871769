
export function setObjListAction (hotspotlist) {
  // Return action
  return {
    // Unique identifier

    type: 'LISTACTION',
    // Payload
    payload:{
      hotspotlist
    }
  };
};
