/**
 * Created by michelefuschillo on 29/01/18.
 */
import React, { Component } from 'react';
import {Table,Input} from 'semantic-ui-react';
import {setfocusobj} from '../../../../redux/actions/ObjectAction';

class ScaleComp extends Component {
  constructor (props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    this.shapeScaleHandler=this.shapeScaleHandler.bind(this);
    var updated = this.props.store.getState();
    this.state={
      previousState:updated,
      scale:updated.reducer.focusobj.obj.scale};
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('shape', focusobj,{'type':'scale','action':'change'}));
    unsubscribe();
  }

  shapeScaleHandler (event, data) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.scale = data.value;
    this.props.dispatch(setfocusobj('shape', focusobj,{'type':'scale','action':'change'}));
    unsubscribe();
  }

  updateState(){
    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'shape') {
      this.setState({
        previousState:updated,
        scale: updated.reducer.focusobj.obj.scale});
		this.props.parent.waitingAnswer();
    }
  }

  render () {
    return (
      <Table collapsing id='section-config-tableTextPosition' basic='very' >
        <Table.Body>
          <Table.Row>
            <Table.Cell><Input name='coverwidth' type='range' min={1} max={500} step={1} value={this.state.scale}
                               onChange={this.shapeScaleHandler}
                               style={{height: 'auto', width: '65px'}}/></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>  <Input type='number' min={10} max={300} step={1} value={this.state.scale} onChange={this.shapeScaleHandler} size='small'
                                 style={{height:'auto', width:'65px'}}/> </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    )
  }
}

export default ScaleComp;