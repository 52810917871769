import React, { Component } from 'react';
import {Button,  Divider,Input,Modal} from 'semantic-ui-react';
import { transitions, positions, Provider as AlertContainer } from 'react-alert'




class ModifyNameModal extends Component {
  constructor (props) {
    super(props);
    this.state=({name:"",consumerName:""});

    this.handleMessage=this.handleMessage.bind(this);
    this.save=this.save.bind(this);
    this.showAlert = this.showAlert.bind(this);

  }


  alertOptions = {    offset: 14,    position: 'bottom center',    theme: 'dark',    time: 5000,    transition: 'scale'  };


  componentWillMount(){
   console.log("modifica")

  }
  handleMessage(event,data){
    // console.log("nome nuovo "+ data.value)  ;
    if (data.id==="oldname"){
      // console.log("id nuovo "+ data.id)  ;

    this.setState({name:data.value});
  }else {
    // console.log("id nuovo else  "+ data.id)  ;

    this.setState({consumerName:data.value});
  }
  }
  save (){
     if ((this.state.name!=="")||(this.state.consumerName!=="")) {
      this.props.callbackOnModifyName(this.state.name,this.props.oldname, this.state.consumerName);
     }else {
      this.showAlert('Empty fields ', 'error')
    }
  }
  handleError(error){
    this.showAlert('Name is arleady taken ', 'error')
  }
  showAlert (message, result) {
    this.msg.show(message, { time: 5000, type: result })  }

    render () {
      return(
        <div>
          <div >
            <Divider  horizontal/>

            New Name:{' '} <br/>
            <Input focus placeholder={this.props.oldname} id="oldname" onChange={this.handleMessage} /><br/>
            New Consumer Name:{' '}<br/>
          <Input focus placeholder={this.props.oldconsumerName==="" ? "Consumer name ":this.props.oldconsumerName }  id="old_consumerName" onChange={this.handleMessage} /><br/>

            <Modal.Actions>
            <Button primary floated='right' size='small' onClick={this.save}> OK  </Button>
            </Modal.Actions>
          </div>
          <div>
            <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
          </div>
        </div>
      )
    }
  }
  export default ModifyNameModal;
