/**
 * Created by michelefuschillo on 31/01/18.
 */
import React, { Component } from 'react';
import {Icon,Accordion,Image}       from 'semantic-ui-react';

import ShapeComp from '../types/shapes/shapes';
import TextComp from '../types/text/textcomp';
import TextlineComp from '../types/textline/textlinecomp';
import Video2DComp from '../types/video2d/video2d';
import HtmlPageComp from '../types/htmlpage/htmlpagecomp';
import ImageComp from '../types/images/imagecomp';
import AudioComp from '../types/audio/audio';
import MentorAddonComp from '../types/mentoraddon/mentoraddon';



import '../../../css/config.css';

class CategoryList extends Component {
  constructor (props) {
    super(props);
    this.state=({accordionActiveIndex:-1,accordionActiveIndexInternal:-1})
    this.manageAccordion=this.manageAccordion.bind(this);
  }

  componentWillMount() {
    this.setState({accordionActiveIndex:-1,accordionActiveIndexInternal:-1});
  }

  manageAccordion(event, data){
    if(data.index === this.state.accordionActiveIndex){
      this.setState({accordionActiveIndex:-1});
    }else{
      this.setState({accordionActiveIndex:data.index});
    }
  }


  // aggiungi per lo shape 
  // <Accordion.Title name="shape" className="accordionTitle" active={this.state.accordionActiveIndex === 0} index={0} onClick={this.manageAccordion} position='left' >
  //   <Icon name='dropdown' id='section-config-IconAccordion'/>
  //   <Icon color="grey" name='object group'/><i id='section-config-AccordionHotspot'>Shape</i>
  // </Accordion.Title>
  // <Accordion.Content active={this.state.accordionActiveIndex  === 0}>
  //   <ShapeComp reducer={this.props.reducer}
  //              dispatch={this.props.dispatch}
  //              store={this.props.store}
  //              parent={this.props.parent}/>
  // </Accordion.Content>




  render () {
    return (
      <div>
        <div>
        {/* *** Ordine del pannello a SX ***

            Image
            2DVideo
            Audio
            Text
            TextLine
            HTMLPage
            Shape
        */}
          <Accordion styled>

          <Accordion.Title name="image"  active={this.state.accordionActiveIndex  === 4} index={4} onClick={this.manageAccordion} position='left'>
            <Icon name='dropdown' id='section-config-IconAccordion'/>
            <Icon color="grey" name='image'/><i id='section-config-AccordionHotspot'>Image</i>
          </Accordion.Title>
          <Accordion.Content active={this.state.accordionActiveIndex  === 4}>
            <ImageComp reducer={this.props.reducer}
                          dispatch={this.props.dispatch}
                          store={this.props.store}
                          parent={this.props.parent}/>
          </Accordion.Content>

          <Accordion.Title name="video"  active={this.state.accordionActiveIndex  === 2} index={2} onClick={this.manageAccordion} position='left'>
            <Icon name='dropdown' id='section-config-IconAccordion'/>
            <Icon color="grey" name='video'/><i id='section-config-AccordionHotspot'>2D Video</i>
          </Accordion.Title>
          <Accordion.Content active={this.state.accordionActiveIndex  === 2}>
            <Video2DComp reducer={this.props.reducer}
                         dispatch={this.props.dispatch}
                         store={this.props.store}
                         parent={this.props.parent} createdBy='User'/>
          </Accordion.Content>

          <Accordion.Title name="audio"  active={this.state.accordionActiveIndex  === 5} index={5} onClick={this.manageAccordion} position='left'>
            <Icon name='dropdown' id='section-config-IconAccordion'/>
            <Icon color="grey" name='music'/><i id='section-config-AccordionHotspot'>Audio</i>
          </Accordion.Title>
          <Accordion.Content active={this.state.accordionActiveIndex  === 5}>
            <AudioComp reducer={this.props.reducer}
                          dispatch={this.props.dispatch}
                          store={this.props.store}
                          parent={this.props.parent}/>
          </Accordion.Content>

          <Accordion.Title name="text"  active={this.state.accordionActiveIndex  === 1} index={1} onClick={this.manageAccordion} position='left'>
            <Icon name='dropdown' id='section-config-IconAccordion'/>
            <Icon color="grey" name='font'/><i id='section-config-AccordionHotspot'>Text</i>
          </Accordion.Title>
          <Accordion.Content active={this.state.accordionActiveIndex  === 1}>
            <TextComp reducer={this.props.reducer}
                      dispatch={this.props.dispatch}
                      store={this.props.store}
                      parent={this.props.parent}/>
            </Accordion.Content>

            <Accordion.Title name="textline"  active={this.state.accordionActiveIndex  === 6} index={6} onClick={this.manageAccordion} position='left'>
              <Icon name='dropdown' id='section-config-IconAccordion'/>
              <Icon color="grey" name='minus'/><i id='section-config-AccordionHotspot'>Text line</i>
            </Accordion.Title>
            <Accordion.Content active={this.state.accordionActiveIndex  === 6}>
              <TextlineComp reducer={this.props.reducer}
                        dispatch={this.props.dispatch}
                        store={this.props.store}
						            parent={this.props.parent}/>
              </Accordion.Content>

              {/* <Accordion.Title name="htmlpage"  active={this.state.accordionActiveIndex  === 3} index={3} onClick={this.manageAccordion} position='left'>
                <Icon name='dropdown' id='section-config-IconAccordion'/>
                <Icon color="grey" name='html5'/><i id='section-config-AccordionHotspot'>Html Page</i>
              </Accordion.Title>
              <Accordion.Content active={this.state.accordionActiveIndex  === 3}>
                <HtmlPageComp reducer={this.props.reducer}
                          dispatch={this.props.dispatch}
                          store={this.props.store}
  						            parent={this.props.parent}/>
              </Accordion.Content> */}

              {/* <Accordion.Title name="mentoraddon"   active={this.state.accordionActiveIndex  === 7} index={7} onClick={this.manageAccordion} position='left'>
              <Icon name='dropdown' id='section-config-IconAccordion'/>                            
                 <Image style={{'display':'inline'}}src='/assets/images/mentoraddon/mentorinside.png' height="16" width="16" name='circle'/>       
                 <i style={{'marginLeft':'5px'}}id='section-config-AccordionHotspot'>Mentor</i>
              </Accordion.Title>
              <Accordion.Content active={this.state.accordionActiveIndex  === 7}>
                <MentorAddonComp reducer={this.props.reducer}
                         dispatch={this.props.dispatch}
                         store={this.props.store}
                         parent={this.props.parent} createdBy='User'/>          
              </Accordion.Content> */}


          </Accordion>
        </div>
      </div>
    )
  }
}

export default CategoryList;
