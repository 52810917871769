/**
 * Created by obbiettivo on 10/09/18.
 */
import React, { Component } from 'react'
import {Button,Divider,Icon,Dimmer,Loader,Header,Image} from 'semantic-ui-react'

import Axios from 'axios'
import Services from '../../config/services.json'
import { transitions, positions, Provider as AlertContainer } from 'react-alert'
import '../../css/modal.css'
import LabelsBox from '../../config/labels.json';
import {launchModal} from '../../redux/actions/ModalAction';

class DeleteModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
    }
    this.showAlert = this.showAlert.bind(this);
    this.deleteResource = this.deleteResource.bind(this);
  }

  alertOptions = {    offset: 14,    position: 'bottom center',    theme: 'dark',    time: 5000,    transition: 'scale'  };

  deleteResource () {
    var self = this
    self.setState({loading: true});
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.jwt
    Axios({
      method: 'delete',
      url: Services.domain + Services.upload_resource_v2_DELETE_AUTH,
      data: {identifier: self.props.extra.id, public_id:self.props.extra.public_id}
    }).then(
      function (response) {
        if (response.status === 204) {
          self.showAlert('Delete Successfully', 'success')
          self.setState({loading: false});
        }else  if (response.status === 202) { //the resource has used in one or more configuration (the output is an array that contains the objects) 
          var listObjUsed= "";
          for(var i=0; i<response.data.message.lenght;i++){
            listObjUsed = listObjUsed + "config: " + response.message.data[i].processId + "video: " + response.message.data[i].resourceName + "name: " + response.message.data[i].resourceName + '<\br>'
          }
          self.showAlert('Delete is denied', 'The resource is used in one or more configurations: ' + '<\br>' + listObjUsed);
          self.setState({loading: false});
        }
      })
      .catch(function (error) {
        self.setState({loading: false});
        console.log('Errore in funzione [other.jsx/deleteResource]: ', error)
      })
  }

  showAlert (message, result) {
    this.msg.show(message, {
      time: 1000,
      type: result,
	  onOpen: () => {
	      //console.log('hey')
	  }, // callback that will be executed after this alert open
	  onClose: () => {
	      //console.log('closed')
		  this.props.dispatch(launchModal("","",""))
	  } // callback that will be executed after this alert is removed
    })
  }

  render () {
    return (
      <div>
        <Header as='h3' inverted>
          <Icon name='question circle' color='grey'/>
          <Header.Content> {LabelsBox[this.props.extra.type].en.delete.title}</Header.Content>
        </Header>
        <Divider />
          <Dimmer active={this.state.loading}> <Loader>Loading</Loader> </Dimmer>
            <Header as='h4' inverted>
              <Image id='image-logo-modal' src={this.props.reducer.group==="IDEHA" ? '/assets/images/logo_H.png':'/assets/images/logo.png'} avatar floated='left' alt="H360" /> 
              <Header.Subheader>{LabelsBox[this.props.extra.type].en.delete.message + " "}  <strong>{this.props.extra.itemname}</strong></Header.Subheader>
            </Header>
        <Divider hidden/>
        <Divider />
          <Button basic color='grey' icon='trash'  size='tiny' content='Yes' onClick={this.deleteResource} floated='right'></Button>
        <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
      </div>
    )
  }
}

export default DeleteModal
