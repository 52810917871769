import React from 'react';
import {configureStore} from './store/store';
import {Provider} from 'react-redux';
import Header from './Header';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import Home from './menuItems/home';
import Config from './menuItems/configuration/configuration';
import About from './menuItems/about';
import Player from './menuItems/player';
import Profile from './menuItems/profile';
import Login from './menuItems/login';
import Logout from './menuItems/logout';
import Signup from './menuItems/signup';
import Mentor from './menuItems/mentor';
import ModalContainer from './ModalContainer/ModalContainer';
import SettingContainer from './SettingContainer/SettingController';



import {launchModal} from './redux/actions/ModalAction';
import {loadsetting} from './redux/actions/SettingAction';
import { setfocusobj} from './redux/actions/ObjectAction';
import { setComponentInfo} from './redux/actions/ComponentAction';


import './semantic/semantic/semantic.min.css';



import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

configureStore(function (store, persistor) {
  /*
    *  Reset info
    * */
  store.dispatch(launchModal("","",""));
  store.dispatch(loadsetting("",{dom:''}));
  store.dispatch(setfocusobj("",{},""));
  store.dispatch(setComponentInfo(0));
  const Main = () => (
    <div>
      <Provider store={store}>
        <Router>
          <div>
            {/*<Header/>*/}
            <ModalContainer store={store}/>
            {/*<SettingContainer store={store}/>*/}
            <Switch>
              {/*<Route exact path='/' component={Home}/>*/}
              {/*<Route exact path='/Profile' component={Profile}/>*/}
              <Route exact path='/' render={(props) => <Config {...props} store={store} />}/>
              {/*<Route exact path='/About' component={About}/>
              <Route exact path='/Media' component={Player}/>
              <Route exact path='/Player' component={Player}/>*/}

              {/*<Route exact path='/Mentor' component={Mentor}/>*/}
              {/*<Route exact path='/Login' component={Login}/>*/}
              {/*<Route exact path='/Logout' component={Logout} />*/}
              {/* <Route exact path='/Signup' component={Signup} />*/}
            </Switch>
          </div>
        </Router>
      </Provider>
    </div>
  );
  ReactDOM.render(<Main/>, document.getElementById("content"));
  registerServiceWorker();
});
