import React, { Component } from 'react';
import {TextArea, List, Divider, Icon, Input} from 'semantic-ui-react';
import { setfocusobj} from '../../../../redux/actions/ObjectAction'
import * as services from '../../../../config/services.json';
import { getResourceDataForLanguage } from './../utils';

let unsubscribe;
class Textlineinfo extends Component {
  constructor (props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    var updated = this.props.store.getState();
      this.state = {
        previousState:updated,
        textline: updated.reducer.focusobj.obj.properties.text,
        name:updated.reducer.focusobj.obj.name,
        description:updated.reducer.focusobj.obj.description,
        resourceid:updated.reducer.focusobj.obj.properties.resourceid ,
        nameres:updated.reducer.focusobj.obj.properties.nameres,
        deleteButtonStatus:updated.reducer.focusobj.obj.properties.nameres ? false:true,
        textAreaStatus:updated.reducer.focusobj.obj.properties.resourceid ? true:false};
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('textline', focusobj,{'type':'textline','action':'textline'}));
    unsubscribe();
  }

  updateState(){
    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'textline') {
      this.setState({
        previousState:updated,
        textline:updated.reducer.focusobj.obj.properties.text,
        name:updated.reducer.focusobj.obj.name,
        description:updated.reducer.focusobj.obj.description,
        resourceid:updated.reducer.focusobj.obj.properties.resourceid ,
        nameres:updated.reducer.focusobj.obj.properties.nameres});
		this.props.parent.waitingAnswer();
    }
  }

  onChangeText =(event, data) =>{
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    switch(data.name){
      case 'textline':
        focusobj.properties.text = data.value;
        this.props.dispatch(setfocusobj('textline', focusobj,{'type':'textline','action':'textline'}));
        break;
      case 'textlinename':
        focusobj.name = data.value;
        this.props.dispatch(setfocusobj('textline', focusobj,{'type':'textline','action':'textline'}));
        break;
      case 'textlinedescription':
        focusobj.description = data.value;
        this.props.dispatch(setfocusobj('textline', focusobj,{'type':'textline','action':'textline'}));
        break;
      default:
        break;
    }
    unsubscribe();
  }
  openUploadModal =(event, data)=> {
	  //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'text' });
    
    /**NUOVA MOADLITà ASSET 07-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "upload",
        typeres: "text"
      }
    }, "*");

    window.addEventListener('message', this.postMessageHandler);
  }

  openListModal = (event, data) => {
    //this.props.dispatch(launchModal('SETTING_MODAL', 'IMAGE'));
    this.setState({ macrocategory: 'text' });
    /**NUOVA MOADLITà ASSET 07-12-2021 */
    window.parent.postMessage({
      type: "CHILDMSG-ANNOTATOR", payload: {
        msg: "textlist"
      }
    }, "*");
    window.addEventListener('message', this.postMessageHandler);
  }



  postMessageHandler = (event) => {
    if (event.origin === services.ASSETDOMAIN) { //FROM ASSETLOGIN
      switch (event.data.type) {
        case "NEWRESOURCE":
          console.log("Gestione new resource in text")
          console.log(event.data.payload);
          //testMock
          // let textcloudcontent={
          //   "it":"mock ita",
          //   "en":"mock en",
          //   "bg":"mock bg"
          // };
          // event.data.payload.textcloudcontent=textcloudcontent;
          // //fine mock rimuovere
          let data={};
          data.medialan=event.data.payload.textcloudcontent;
          unsubscribe = this.props.store.subscribe(this.updateState);
          var focusobj = Object.assign({}, this.props.reducer.focusobj.obj);
          console.log(event.data.payload.textcloudcontent[getResourceDataForLanguage(data)]);
          focusobj.properties.text = event.data.payload.textcloudcontent[getResourceDataForLanguage(data)];
          focusobj.properties.resourceid = event.data.payload._id;  
          focusobj.properties.nameres = event.data.payload.name;                  
          this.props.dispatch(setfocusobj('textline', focusobj,{'type':'textline','action':'textline'}));
          this.setState({
            resourceid:event.data.payload._id , 
            nameres:event.data.payload.name,
            deleteButtonStatus:event.data.payload.name ? false:true,
            textAreaStatus: event.data.payload._id? true:false});
          //prendi l'ogetto che arriva 
          //analizza la lingua da prendere secondo algoritmo base ma customizzato per le lingue 
          //setta il testo giusto 
          //utiliza il dispatch come sopra al posto di quello imamgini 
          // devi fare il controllo se il testo è preso da res manager non puoi permettere la modifica quindi se vuoi che 
          //il testo sia messo a mano devi fare la cancellazione del settaggio di un testo preso da res manager 
          //devi anche settare il testo preso dal res manager nella textinput e disabilitarlo se viene da res manager 
          // focusobj.properties.coverpath = this.coverUrl(event.data.payload);
          // focusobj.properties.covername = event.data.payload.name;
          // focusobj.properties.resourceid = event.data.payload._id;  //CREDO DI DOVER SALVARE ANCHE RESOURCE ID UNICO MODO PER POTER AVERE RIFERIMENTO ALLA RISORSA 
          // this.props.dispatch(setfocusobj("image", focusobj, { 'type': 'image', 'action': 'image' }));
          window.removeEventListener('message', this.postMessageHandler);
          //this.setState({ childmessage: event.data.payload.msg });
          break;
        default: break;
      }
    }
  }

  removeText =(event)=>{
    console.log("remove text");
    var focusobj = Object.assign({}, this.props.reducer.focusobj.obj);
    focusobj.properties.text = "";
    focusobj.properties.resourceid = "";  
    focusobj.properties.nameres = "";                  
    this.props.dispatch(setfocusobj('textline', focusobj,{'type':'textline','action':'textline'}));
    // textAreaStatus, resourceid, nameres:""
    this.setState({textAreaStatus:false, resourceid:"", nameres:"", textline:""});

  } 
  render () {
    return (
      <List className="componentOnIframeClass">
        <List.Item>
          <List.Content>
            <List.Header className="listitemheaderobj" ></List.Header>
            <List.Description className="listitemobj" >
              <TextArea name="textlinename" placeholder='Name...' onChange={this.onChangeText} value={this.state.name} autoHeight style={{ minHeight: 30,maxHeight: 30, width:'100%',}} maxLength="30" />
              <div>
              <TextArea name="textlinedescription" placeholder='Description...' onChange={this.onChangeText} value={this.state.description} autoHeight style={{ minHeight: 50, width:'100%',height:'auto'}} maxLength="200"/>
              </div>
            </List.Description>
          </List.Content>
        </List.Item>
        <Divider/>
        <List.Item>
          <List.Content>
            <List.Header className="listitemheaderobj" ></List.Header>
            <List.Description className="listitemobj" >
              <TextArea name="textline" placeholder='Scrollable text here...' onChange={this.onChangeText}  disabled={this.state.textAreaStatus} value={this.state.textline} autoHeight style={{ minHeight: 50, width:'100%',height:'auto'}} maxLength="200"/>
            </List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
        <Input size='mini' fluid disabled value={this.state.nameres}/>
        <Icon name='list' size="small" onClick={this.openListModal}/>
        <Icon name='upload' size="small" onClick={this.openUploadModal}/> 
        <Icon name='trash' size="small"   disabled={this.state.deleteButtonStatus} onClick={this.removeText}/> 
        </List.Item>
      </List>
    )
  }
}

export default Textlineinfo;