import Axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Dimmer, Divider, Dropdown, Header, Icon, Image, Input, Label, Loader, Modal, Popup, Segment, Grid, GridColumn, CardGroup } from 'semantic-ui-react';
import { launchModal } from ".././redux/actions/ModalAction";
import * as services from '../config/services.json';
import '../css/home.css';
import actionLogout from '../redux/actions/logout';
import menuItem from '../redux/actions/menustate';
import Play from './configuration/types/player/play'; //crea il player modal




//grafica parte audio
// <p id='titlemetadata'>Video</p>

// <p id='titlemetadata'>Audio</p>
//                       <Header.Subheader >Dar: {item.dar}</Header.Subheader>

// <Divider id='titlemetadata'/>
//     <Header.Subheader textAlign='left'>Codec: {item.audiocodec}</Header.Subheader>
//     <Header.Subheader textAlign='left'>Channel: {item.audiochannel}</Header.Subheader>
//
//     <Divider id='titlemetadata'/>

const mapStateToProps = (state) => ({
  reducer: state.reducer,
})

const uploadOptions = [
  {
    key: 'video',
    text: 'Video 360',
    value: 'video',
  },
  {
    key: 'image',
    text: 'Image 360',
    value: 'image',
  },
  {
    key: 'video',
    text: 'Video 2D',
    value: 'video2d',
  },
  {
    key: 'image2d',
    text: 'Image 2D',
    value: 'image2d',
  }
];

// active: [], activeFalse: []items itemsCopy,
class Home extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(), region: {},
      videoname: "",  loading: false, editModal: false, searchedindex: "", modalVideoDelete: false, videoNameToDelete: "", order: true, statusLocationVideo: "", typeResourceToUpload: 'video',
      video2dToAnnotate: [],
      video3dToAnnotate: [],
      image2dToAnnotate: [],
      image3dToAnnotate: [],
      resType: "",
      fsname: "",
      activeI3dFalse:[],
      activeI3d:[],
      activeI2dFalse:[],
      activeI2d:[],
      activeV3dFalse:[],
      activeV3d:[],
      activeV2dFalse:[],
      activeV2d:[],
      itemsCopyV2d:[],itemsCopyV3d:[], itemsCopyI2d:[], itemsCopyI3d:[],
      colorDefaultV3d:true,
      colorSecondV3d:false,
      colorDefaultV2d:true,
      colorSecondV2d:false,
      colorDefaultI3d:true,
      colorSecondI3d:false,
      colorDefaultI2d:true,
      colorSecondI2d:false    }
    this.cardAction = this.cardAction.bind(this);
    this.mentorAction = this.mentorAction.bind(this);
    this.handleShowI3d= this.handleShowI3d.bind(this);
    this.handleHideI3d = this.handleHideI3d.bind(this);
    this.handleShowI2d= this.handleShowI2d.bind(this);
    this.handleHideI2d = this.handleHideI2d.bind(this);
    this.handleShowV2d= this.handleShowV2d.bind(this);
    this.handleHideV2d = this.handleHideV2d.bind(this);
    this.handleShowV3d= this.handleShowV3d.bind(this);
    this.handleHideV3d = this.handleHideV3d.bind(this);
    this.filterInput = this.filterInput.bind(this);
    this.mediaActionDirect = this.mediaActionDirect.bind(this);
    this.onCloseModalPlay = this.onCloseModalPlay.bind(this);
    this.itemsSort = this.itemsSort.bind(this);
    this.itemsSortInverse = this.itemsSortInverse.bind(this);
  }
  
  componentDidMount() { }
  
  componentWillMount() {
    //authentication check
    if (!this.props.reducer.logged) {
      this.props.history.push("/login");
      this.props.dispatch(menuItem('login'));
    } else {
      this.props.dispatch(menuItem('home'));
      var self = this;
      // console.log(self.props.reducer.jwt)
      this.setState({ loading: true });
        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt;
        Axios.get(services.domain + services.resannotation_group_GET_AUTH.replace('[group]', self.props.reducer.group))
        .then(function (response2) {
          if (response2.status === 200) {
            console.log(response2.data.message)
            let copyObjV2d = response2.data.message.video2d.slice(); //copy array
            let copyObjV3d = response2.data.message.video3d.slice(); //copy array
            let copyObjI3d = response2.data.message.image3d.slice(); //copy array
            let copyObjI2d = response2.data.message.image2d.slice(); //copy array
            copyObjI2d.sort(function (a, b) {
              return a.name.localeCompare(b.name);
            }
            );
            copyObjV3d.sort(function (a, b) {
              return a.name.localeCompare(b.name);
            }
            );
            copyObjI3d.sort(function (a, b) {
              return a.name.localeCompare(b.name);
            }
            );
            copyObjV2d.sort(function (a, b) {
              return a.name.localeCompare(b.name);
            }
            );
          
            
                  self.setState({
                    loading: false, 
                    video2dToAnnotate: copyObjV2d,
                    video3dToAnnotate: copyObjV3d,
                    image2dToAnnotate: copyObjI2d,
                    image3dToAnnotate: copyObjI3d,
                    activeI3d: new Array(response2.data.message.image3d.length).fill(false), activeI3dFalse:new Array(response2.data.message.image3d.length).fill(false),
                    activeI2d: new Array(response2.data.message.image2d.length).fill(false), activeI2dFalse:new Array(response2.data.message.image2d.length).fill(false),
                    activeV3d: new Array(response2.data.message.video3d.length).fill(false), activeV3dFalse: new Array(response2.data.message.video3d.length).fill(false),
                    activeV2d: new Array(response2.data.message.video2d.length).fill(false), activeV2dFalse:   new Array(response2.data.message.video2d.length).fill(false),
                    temsCopyV2d:copyObjV2d, itemsCopyV3d:copyObjV3d, itemsCopyI2d:copyObjI2d, itemsCopyI3d:copyObjI3d
                  });                                          
          } else if (response2.status === 401) {
            self.setState({
              video2dToAnnotate:[],video3dToAnnotate: [],image2dToAnnotate: [],image3dToAnnotate: [], 
              itemsCopyV2d:[],itemsCopyV3d:[], itemsCopyI2d:[], itemsCopyI3d:[],loading: false
            });
            this.props.dispatch(actionLogout());
            if (typeof this.props.history !== "undefined") {
              this.props.history.push("/");
            }
            window.location.reload();
          }
        }).catch(function (error) {
          self.setState({ loading: false });
          console.log(error)
          // if (error.response.status === 401) { //token expired
          self.setState({
            video2dToAnnotate: [],video3dToAnnotate: [],image2dToAnnotate: [],image3dToAnnotate: [],
            itemsCopyV2d:[],itemsCopyV3d:[], itemsCopyI2d:[], itemsCopyI3d:[]
          });
          self.props.dispatch(actionLogout());
          if (typeof self.props.history !== "undefined") {
            self.props.history.push("/");
          }
          
        })
        //fine get resources       
    }
  }
  //-----
  handleShowI3d(e) {
    var itemsCopy = this.state.activeI3dFalse.slice(); //copy array
    itemsCopy.splice(e.target.getAttribute("name"), 1, true); //remove element
    this.setState({ activeI3d: itemsCopy, order: false });
  }
  
  handleHideI3d(e) {
    var itemsCopy = this.state.activeI3dFalse.slice(); //copy array
    itemsCopy.splice(e.target.getAttribute("name"), 1, false); //remove element
    this.setState({ activeI3d: itemsCopy, order: false });
  }
  handleShowI2d(e) {
    var itemsCopy = this.state.activeI2dFalse.slice(); //copy array
    itemsCopy.splice(e.target.getAttribute("name"), 1, true); //remove element
    this.setState({ activeI2d: itemsCopy, order: false });
  }
  
  handleHideI2d(e) {
    var itemsCopy = this.state.activeI2dFalse.slice(); //copy array
    itemsCopy.splice(e.target.getAttribute("name"), 1, false); //remove element
    this.setState({ activeI2d: itemsCopy, order: false });
  }
  handleShowV3d(e) {
    var itemsCopy = this.state.activeV3dFalse.slice(); //copy array
    itemsCopy.splice(e.target.getAttribute("name"), 1, true); //remove element
    this.setState({ activeV3d: itemsCopy, order: false });
  }
  
  handleHideV3d(e) {
    var itemsCopy = this.state.activeV3dFalse.slice(); //copy array
    itemsCopy.splice(e.target.getAttribute("name"), 1, false); //remove element
    this.setState({ activeV3d: itemsCopy, order: false });
  }
  handleShowV2d(e) {
    var itemsCopy = this.state.activeV2dFalse.slice(); //copy array
    itemsCopy.splice(e.target.getAttribute("name"), 1, true); //remove element
    this.setState({ activeV2d: itemsCopy, order: false });
  }
  
  handleHideV2d(e) {
    var itemsCopy = this.state.activeV2dFalse.slice(); //copy array
    itemsCopy.splice(e.target.getAttribute("name"), 1, false); //remove element
    this.setState({ activeV2d: itemsCopy, order: false });
  }
  //-----
  

  filterInput(event, data) {  //possibile bug quando non trovo nulla e si azzera l'array
  
  switch (data.type) {      
    case "VIDEO3":
    if (data.value.length > 0) {
      let filtered = this.state.itemsCopyV3d.filter(function (el, index, arr) {
        // Filtering here
        return ((el.name.toUpperCase()).indexOf(data.value.toUpperCase()) !== -1)
      }); 
      this.setState({ video3dToAnnotate: filtered });
    } else {
      let itemsCopy = this.state.itemsCopyV3d.slice(); //copy array
      this.setState({ video3dToAnnotate: itemsCopy });
    }
    break;
    case "VIDEO2":
    if (data.value.length > 0) {
      let filtered1 =this.state.itemsCopyV2d.filter(function (el, index, arr) {
        // Filtering here
        return ((el.name.toUpperCase()).indexOf(data.value.toUpperCase()) !== -1)
      }); 
      this.setState({ video2dToAnnotate: filtered1 });
    } else {
      let itemsCopy1 = this.state.itemsCopyV2d.slice(); //copy array
      this.setState({ video2dToAnnotate: itemsCopy1 });
    }
    break;
    case "IMAGE3":
    if (data.value.length > 0) {
      let filtered2 =this.state.itemsCopyI3d.filter(function (el, index, arr) {
        // Filtering here
        return ((el.name.toUpperCase()).indexOf(data.value.toUpperCase()) !== -1)
      }); 
      this.setState({ image3dToAnnotate: filtered2 });
    } else {
      let itemsCopy2 = this.state.itemsCopyI3d.slice(); //copy array
      this.setState({ image3dToAnnotate: itemsCopy2});
    }
    break;
    case "IMAGE2":
    if (data.value.length > 0) {
      let filtered3 =this.state.itemsCopyI2d.filter(function (el, index, arr) {
        // Filtering here
        return ((el.name.toUpperCase()).indexOf(data.value.toUpperCase()) !== -1)
      }); 
      this.setState({ image2dToAnnotate: filtered3 });
    } else {
      let itemsCopy3 = this.state.itemsCopyI2d.slice(); //copy array
      this.setState({ image2dToAnnotate: itemsCopy3});
    }
    break;
    default:
    break;
  }
  
  
}
/*
* Video delete operation
*/
videoAndConfigDelete =(event, data)=>{ 
  //delete operation must have these parameters: videoname and groupname
  console.log(data.restype);
  var deletedArray=[];
switch (data.restype) {
  case "VIDEO3":
    deletedArray=this.state.video3dToAnnotate.slice();
  break;
  case "VIDEO2":
    deletedArray=this.state.video2dToAnnotate.slice();
  break ;
  case "IMAGE3":
    deletedArray=this.state.image3dToAnnotate.slice();
  break;
  case"IMAGE2" :
  deletedArray=this.state.image2dToAnnotate.slice();
  break;

}
  if (data.name) {
    var self = this
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt
    Axios({
      method: 'delete',
      url: services.domain + services.video_group_GET_AUTH,
      data: {
        videoname: data.name, //resource name
        groupname: self.props.reducer.group
      }
    })
    .then(function (response) {
      if (response.status === 204) {
        if (deletedArray.length > 1) {
          for (var i = 0; i < deletedArray.length; i++) {
            if (deletedArray[i]._id === data.name) {
              deletedArray.splice(i, 1);

            switch (data.restype) {
                case "VIDEO3":
                  self.setState({ video3dToAnnotate: deletedArray, modalVideoDelete: false });
                  break;
                case "VIDEO2":
                  self.setState({ video2dToAnnotate: deletedArray, modalVideoDelete: false });
                  break ;
                case "IMAGE3":
                  self.setState({ image3dToAnnotate: deletedArray, modalVideoDelete: false });
                  break;
                case"IMAGE2" :
                self.setState({ image2dToAnnotate: deletedArray, modalVideoDelete: false });
                break;
              }              
          }
          }
        } else {
          switch (data.restype) {
            case "VIDEO3":
              self.setState({ video3dToAnnotate: [], modalVideoDelete: false });
              break;
            case "VIDEO2":
              self.setState({ video2dToAnnotate: [], modalVideoDelete: false });
              break ;
            case "IMAGE3":
              self.setState({ image3dToAnnotate: [], modalVideoDelete: false });
              break;
            case"IMAGE2" :
            self.setState({ image2dToAnnotate: [], modalVideoDelete: false });
            break;

          }
          
        }
      }
    })
    .catch(function (error) {
      console.log('Errore in funzione [Configuration.jsx/deleteObjectAll]: ', error);
      
    })
  }
  this.setState({ modalVideoDelete: false });
}

closeModalVideoDelete = () => {
  this.setState({ modalVideoDelete: false });
}


  
 


itemsSort(e) {
  switch (e.target.getAttribute("type")) {
    case "VIDEO3": 
    let ascend = this.state.video3dToAnnotate.slice();
    ascend.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    }
    );
    let cD = this.state.colorDefaultV3d;
    let cS = this.state.colorSecondV3d;
    this.setState({ video3dToAnnotate: ascend, colorSecondV3d: cD, colorDefaultV3d: cS })      
    break;
    case "VIDEO2":
    let ascend1 = this.state.video2dToAnnotate.slice();
    ascend1.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    }
    );
    let cD1 = this.state.colorDefaultV2d;
    let cS1 = this.state.colorSecondV2d;
    this.setState({ video2dToAnnotate: ascend1, colorSecondV2d: cD1, colorDefaultV2d: cS1})  
    break;
    case "IMAGE3":
    let ascend2 = this.state.image3dToAnnotate.slice();
    ascend2.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    }
    );
    let cD2 = this.state.colorDefaultI3d;
    let cS2 = this.state.colorSecondI3d;
    this.setState({ image3dToAnnotate: ascend2, colorSecondI3d: cD2, colorDefaultI3d: cS2 })  
    break;
    case "IMAGE2":
    let ascend3 = this.state.image2dToAnnotate.slice();
    ascend3.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    }
    );
    let cD3 = this.state.colorDefaultI2d;
    let cS3 = this.state.colorSecondI2d;
    this.setState({ image2dToAnnotate: ascend3, colorSecondI2d: cD3, colorDefaultI2d: cS3 })  
    break;
  
    default:
      console.log("Errore : resType è vuoto itemsSort() ")
    break;
  }
  
 
  
}

itemsSortInverse(e) {
  
  switch (e.target.getAttribute("type")) {
    case "VIDEO3":
    let descend = this.state.video3dToAnnotate.slice();
    
    descend.sort(function (a, b) {
      return b.name.localeCompare(a.name);
    }
    );
    let cD = this.state.colorDefaultV3d;
    let cS = this.state.colorSecondV3d;
    this.setState({ video3dToAnnotate: descend, colorSecondV3d: cD, colorDefaultV3d: cS })
    
    break;
    case "VIDEO2":
    let descend1 = this.state.video2dToAnnotate.slice();
    
    descend1.sort(function (a, b) {
      return b.name.localeCompare(a.name);
    }
    );
    let cD1 = this.state.colorDefaultV3d;
    let cS1= this.state.colorSecondV3d;
    this.setState({ video2dToAnnotate: descend1, colorSecondV2d: cD1, colorDefaultV2d: cS1 })
    
    break;
    case "IMAGE3":
    
    let descend2 = this.state.image3dToAnnotate.slice();
    
    descend2.sort(function (a, b) {
      return b.name.localeCompare(a.name);
    }
    );
    let cD2 = this.state.colorDefaultI3d;
    let cS2= this.state.colorSecondI3d;
    this.setState({ image3dToAnnotate: descend2, colorSecondI3d: cD2, colorDefaultI3d: cS2 })
    
    
    break;
    case "IMAGE2":
    let descend3 = this.state.image2dToAnnotate.slice();
    
    descend3.sort(function (a, b) {
      return b.name.localeCompare(a.name);
    }
    );
    let cD3 = this.state.colorDefaultI2d;
    let cS3= this.state.colorSecondI2d;
    this.setState({ image2dToAnnotate: descend3, colorSecondI2d: cD3, colorDefaultI2d: cS3 })
    break;
    
    default:
    break;
  }
  
  
  
  
  
  
  
}

cardAction(event, data) {
  console.log(data)
  if (data.index) {
    /*var servicePath = services.video_learning_GET_AUTH.replace("[id]",data.index);
    servicePath = servicePath.replace("[name]",data.value);*/
    switch (data.restype) {
      case "VIDEO3":
      this.props.history.push({
        pathname: '/Config',
        search: 'id=' + data.index,
        state: {
          title: this.state.video3dToAnnotate[parseInt(data.name, 10)].name,
          // topic: this.state.items[parseInt(data.name, 10)].topic,
          // uploaded: this.state.items[parseInt(data.name, 10)].uploaded,
          videoArray: this.state.video3dToAnnotate,
          resType: data.restype,
          fsname: data.fsname
        }
      });
      break;
      case "VIDEO2":
        this.props.history.push({
          pathname: '/Config',
          search: 'id=' + data.index,
          state: {
            title: this.state.video2dToAnnotate[parseInt(data.name, 10)].name,
            //topic: this.state.items[parseInt(data.name, 10)].topic,
            //uploaded: this.state.items[parseInt(data.name, 10)].uploaded,
            videoArray: this.state.video2dToAnnotate,
            resType: data.restype,
            fsname: data.fsname
          }
        });
      break;
      case "IMAGE3":
      this.props.history.push({
        pathname: '/Config',
        search: 'id=' + data.index,
        state: {
          title: this.state.image3dToAnnotate[parseInt(data.name, 10)].name,
          // topic: this.state.items[parseInt(data.name, 10)].topic, 
          // uploaded: this.state.items[parseInt(data.name, 10)].uploaded,
          videoArray: this.state.image3dToAnnotate,
          resType: data.restype,
          fsname: data.fsname
        }
      });
      break;
      case "IMAGE2":
      this.props.history.push({
        pathname: '/Config',
        search: 'id=' + data.index,
        state: {
          title: this.state.image2dToAnnotate[parseInt(data.name, 10)].name,
          // topic: this.state.items[parseInt(data.name, 10)].topic, 
          // uploaded: this.state.items[parseInt(data.name, 10)].uploaded,
          videoArray: this.state.image2dToAnnotate,
          resType: data.restype,
          fsname: data.fsname
        }
      });
      break;
      
      default:
      break;
    }
    
    this.props.dispatch(menuItem('configuration'));
    //this.props.history.push({pathname:'/Player',search:'id='+data.index, state:{title:this.state.items[parseInt(data.name)].description}});
    //this.props.dispatch(menuItem('about'));
  }
}
onCloseModalPlay() {
  this.setState({ editModal: false });
}
mediaActionDirect(event, data) {
  console.log(data.index)
  if (data.index) {
    console.log(data.uploaded);
    /*var servicePath = services.video_learning_GET_AUTH.replace("[id]",data.index);
    servicePath = servicePath.replace("[name]",data.value);*/
    
    this.setState({ editModal: true, searchedindex: data.index, videoname: data.video, statusLocationVideo: data.uploaded, resType: data.restype, fsname: data.fsname });
    
    
    
    // this.props.history.push({pathname:'/ConfigPlay',search:'id='+data.index, state:{title:this.state.items[parseInt(data.name, 10)].description}});
    // this.props.dispatch(menuItem('configuration'));
    //this.props.history.push({pathname:'/Player',search:'id='+data.index, state:{title:this.state.items[parseInt(data.name)].description}});
    //this.props.dispatch(menuItem('about'));
  }
}


mentorAction(event, data) {
  if (data.index) {
    this.props.history.push({ pathname: '/Mentor', search: 'id=' + data.index, state: { title: this.state.items[parseInt(data.name, 10)].name } });
    this.props.dispatch(menuItem('mentor'));
  }
}

addResourceToAnnotate = () => {
  this.props.dispatch(
    launchModal("UPLOAD_MODAL", "resourcetoannotate" + this.state.typeResourceToUpload, this.state.typeResourceToUpload, "homecontext")
    ); //launchModal (modalType, subType, extra)
  }
  
  selectTypeResourceToAnnotate = (event, data) => {
    this.setState({
      typeResourceToUpload: data.value
    });
  }

  
  
  render() {
    
    let v2d = this.state.video2dToAnnotate.slice();
    let v3d = this.state.video3dToAnnotate.slice();
    let i2d = this.state.image2dToAnnotate.slice();
    let i3d = this.state.image3dToAnnotate.slice();

    
    return (
      <div>
      <div id='section-home-header-search'>
      
      
      <Grid fluid style={{ margin: '0px 0px 0px 0px' }}>
      <Grid.Row verticalAlign="middle" columns="3">
      <GridColumn width="3">
      {/* cancella grid  */}
      </GridColumn>
      <GridColumn width="5">
      {/* CANCELLa grid */}
      </GridColumn>
      <GridColumn width="8" style={{ paddingLeft: '35%', margin: '0px 0px 0px 0px' }}>
      <Button icon onClick={this.addResourceToAnnotate} basic inverted>
      <Icon name="add"></Icon>
      </Button>
      <Dropdown
      placeholder='type to upload'
      selection
      compact
      options={uploadOptions}
      onChange={this.selectTypeResourceToAnnotate}
      defaultValue="video"
      />
      </GridColumn>
      </Grid.Row>
      </Grid>
      </div>
      <Divider />
      <Segment basic id='section-home' className="segment centered">
        <div>
        {/*
          * MOdal for player mode
        */}
        <Modal open={this.state.editModal}
        size={'small'}
        dimmer={'blurring'} closeIcon={true} onClose={this.onCloseModalPlay}>
        <Modal.Content scrolling>
        <Header as='h4'>
        {/* src={this.props.reducer.group === "IDEHA" ? '/assets/images/logo_H.png' : '/assets/images/logo.png'} */} 
        {/* <Image id='image-logo-modal' src='/assets/images/logo_H.png' avatar floated='left' alt="H360" /> */}
        <Header.Subheader> Play Manager</Header.Subheader>
        </Header>
        </Modal.Content>
        <Play userId={this.props.reducer.userid} jwt={this.props.reducer.jwt} videoId={this.state.searchedindex} uploaded={this.state.statusLocationVideo}
        callbackParent={this.onChildChanged} videoname={this.state.videoname} history={this.props.history} group={this.props.reducer.group} restype={this.state.resType} fsname={this.state.fsname} />
        </Modal>
        </div>
        <div>
        {/*
          * MODAL FOR VIDEO DELETE
        */}
        <Modal open={this.state.modalVideoDelete}
        size={'small'}
        dimmer={'blurring'} closeIcon={true} onClose={this.closeModalVideoDelete}>
        <Modal.Content scrolling>
        <Header as='h4'>
        <Image id='image-logo-modal' src='/assets/images/logo_H.png' avatar floated='left' alt="H360" />
        Video Delete
        <Header.Subheader>Please confirm deletion of the video and all its configurations</Header.Subheader>
        </Header>
        </Modal.Content>
        <Modal.Actions>
        <Button basic color='green' onClick={() => this.setState({ modalVideoDelete: false })} >
        <Icon name='remove' /> No
        </Button>
        <Button color='grey' onClick={this.videoAndConfigDelete} name={this.state.videoNameToDelete} restype={this.state.resType}>
        <Icon name='checkmark' /> Yes
        </Button>
        </Modal.Actions>
        </Modal>
        </div>
        
  {/* gestione immagini 2d  */}
      <Grid   fluid  style={{ margin: '0px 0px 0px 0px',width: '100%' }} padded > 
            <Grid.Row columns="3" color="black"  >
            <GridColumn width="5" floated='left'>
            <Header inverted color="grey" size='large'>2d Images</Header>
            </GridColumn>
            <GridColumn width="6" floated='right'>
            <Input inverted icon='search' placeholder='Search...' transparent onChange={this.filterInput} type="IMAGE2" />
            </GridColumn>
            <GridColumn width="5" floated='right'>
            <div className="ui buttons">
            <Button className="ui button sort" onClick={this.itemsSort} type="IMAGE2" style={{ backgroundColor: this.state.colorDefaultI2d ? "#c1c1c1" : "#e8e8e8" }} >a - z</Button>
            <div className="or" data-text=" < > "></div>
            <Button className="ui button sort"  type="IMAGE2"style={{ backgroundColor: this.state.colorSecondI2d ? "#c1c1c1" : "#e8e8e8" }} onClick={this.itemsSortInverse}>z - a</Button>
            </div>
            </GridColumn>              
            </Grid.Row>
            </Grid> 
        {i2d ?
          [(i2d.length > 0 ?
            <CardGroup>       
            {/* <div id="home_category_banner_category">	Immagini 360  
            <Input inverted icon='search' placeholder='Search...' transparent onChange={this.filterInput} />        
            <div className="ui buttons">
            <Button className="ui button sort" onClick={this.itemsSort} style={{ backgroundColor: this.state.colorDefault ? "#c1c1c1" : "#e8e8e8" }} >a - z</Button>
            <div className="or" data-text=" < > "></div>
            <Button className="ui button sort" style={{ backgroundColor: this.state.colorSecond ? "#c1c1c1" : "#e8e8e8" }} onClick={this.itemsSortInverse}>z - a</Button>
            </div>
          </div> */}
          {i2d.map((item1, index1) =>
            <Dimmer.Dimmable key={index1}  name={index1} 
            onMouseEnter={this.handleShowI2d} onMouseLeave={this.handleHideI2d}>
            <Card key={index1} id='section-card' name={index1} raised >
            <Dimmer active={this.state.activeI2d[index1]}>
            <Header as='h3' inverted textAlign='left'>
            <Popup flowing hoverable wide position='bottom center' size='small' trigger={
              <p id='section-card-label-dimmer'>{item1.name}</p>
            } on='hover'>
            <p id='section-edit-card-link'>{item1.name}</p>
            </Popup>
            <Divider />            
            <Header style={{"fontSize": "smaller"}} inverted textAlign='left'>Res: {(item1.metadati.streams[0]? item1.metadati.streams[0].width +"x"+item1.metadati.streams[0].height:"NOT DEFINED" )}</Header>
            <Header style={{"fontSize": "smaller"}} inverted textAlign='left'>Codec : {(item1.metadati.streams[0]? (item1.metadati.streams[0].codec_name? item1.metadati.streams[0].codec_name :"NOT DEFINED"):"NOT DEFINED" )}</Header>                  
            <Header style={{"fontSize": "smaller"}} inverted textAlign='left'>Size: {(item1.metadati.format? (item1.metadati.format.size? (item1.metadati.format.size/1000000).toFixed(0) > 1000 ? (item1.metadati.format.size/1000000000).toFixed(1) + 'GB' : (item1.metadati.format.size/1000000).toFixed(2) + "MB":"NOT DEFINED"):"NOT DEFINED")}</Header>  
            <Divider id='titlemetadata'/>
            </Header>                    
                  <Button animated='vertical' inverted color='grey' size='tiny' onClick={this.mediaActionDirect} video={item1.name} name={index1} index={item1._id} uploaded={item1.uploaded} restype={item1.type+item1.degree} fsname={item1.fsname}> 
            <Button.Content hidden >Play</Button.Content>
            <Button.Content visible >
            <Icon color='grey' name='film' />
            </Button.Content>
            </Button>
            <Button animated='vertical'  inverted color='grey' size='tiny' onClick={this.cardAction} name={index1} index={item1._id} restype={item1.type+item1.degree} fsname={item1.fsname}>
            <Button.Content hidden >Edit</Button.Content>
            <Button.Content visible >
            <Icon color='grey' name='edit' />
            </Button.Content>
            </Button>
            <Button animated='vertical'  inverted color='grey' size='tiny' onClick={() => this.setState({modalVideoDelete:true, videoNameToDelete:item1._id, resType:item1.type+item1.degree })} >
            <Button.Content hidden >Delete</Button.Content>
            <Button.Content visible >
            <Icon color='grey' name='delete' />
            </Button.Content>
            </Button>
            <Card.Content name={index1} extra>
            <Icon name='clock outline'  size='large' id='section-card-iconTime' />
            <Label name={index1} id='section-card-footer-dark'> {item1.uploadeddate}</Label>
            </Card.Content>
            </Dimmer>
            <Card.Header name={index1} textAlign='left' >
            <Popup flowing hoverable wide position='bottom center' size='small' trigger={
              <div>
              <Icon corner color='blue' name='photo' id='section-card-iconTitle'/>
              <Label  name={index1} id='section-card-label'> {item1.name} </Label>
              </div>
            } on='hover'>
            <p id='section-edit-card-link'>{item1.name}</p>
            </Popup>
            
            </Card.Header>
            <Card.Content name={index1}>
            {item1.covername ? (
                    <Image id='section-card-image' src={services.domain+"/public/uploads/"+this.props.reducer.group+"/resourcetoannotate/2d/image/"+item1.covername} size='massive' name={index1} />
                    ) : (
                <Image id='section-card-image' src="/assets/images/about_previous.jpg" size='massive' name={index1} />)
              }
              </Card.Content>
              <Card.Content name={index1} extra>
              <Image src="/assets/images/avatar.png" avatar  verticalAlign='middle' size='mini'  alt='' floated='left' id='section-card-avatar' name={index1} />
              <Icon name='clock outline' color='black' size='large' id='section-card-iconTime' />
              <Label name={index1} id='section-card-footer'> {item1.uploadeddate}</Label>
              </Card.Content>
              </Card>
              </Dimmer.Dimmable>						
              )}
              </CardGroup>          
                          :<Header inverted color="grey" fluid size='large'> -- </Header>)]           
                          :(<span></span>)}
              
{/* gestione immagini 3d */}
                <Grid   fluid  style={{ margin: '0px 0px 0px 0px',width: '100%' }} padded > 
                  <Grid.Row columns="3" color="black"  >
                  <GridColumn width="5" floated='left'>
                  <Header inverted color="grey" size='large'>360 Images</Header>
                  </GridColumn>
                  <GridColumn width="6" floated='right'>
                  <Input inverted icon='search' placeholder='Search...' transparent onChange={this.filterInput} type="IMAGE3" />
                  </GridColumn>
                  <GridColumn width="5" floated='right'>
                  <div className="ui buttons">
                  <Button className="ui button sort" onClick={this.itemsSort} type="IMAGE3" style={{ backgroundColor: this.state.colorDefaultI3d ? "#c1c1c1" : "#e8e8e8" }} >a - z</Button>
                  <div className="or" data-text=" < > "></div>
                  <Button className="ui button sort"  type="IMAGE3"style={{ backgroundColor: this.state.colorSecondI3d ? "#c1c1c1" : "#e8e8e8" }} onClick={this.itemsSortInverse}>z - a</Button>
                  </div>
                  </GridColumn>              
                  </Grid.Row>
                  </Grid>                 
              {i3d ?
                [(i3d.length > 0 ?
                  <CardGroup>                     
                  {/* <div id="home_category_banner_category">	Immagini 360           
                  <Input inverted icon='search' placeholder='Search...' transparent onChange={this.filterInput} />               
                  <div className="ui buttons">
                  <Button className="ui button sort" onClick={this.itemsSort} style={{ backgroundColor: this.state.colorDefault ? "#c1c1c1" : "#e8e8e8" }} >a - z</Button>
                  <div className="or" data-text=" < > "></div>
                  <Button className="ui button sort" style={{ backgroundColor: this.state.colorSecond ? "#c1c1c1" : "#e8e8e8" }} onClick={this.itemsSortInverse}>z - a</Button>
                  </div>                 
                </div> */}
                {i3d.map((item1, index1) =>
                  <Dimmer.Dimmable key={index1}  name={index1} 
                  onMouseEnter={this.handleShowI3d} onMouseLeave={this.handleHideI3d}>
                  <Card key={index1} id='section-card' name={index1} raised >
                  <Dimmer active={this.state.activeI3d[index1]}>
                  <Header as='h3' inverted textAlign='left'>
                  <Popup flowing hoverable wide position='bottom center' size='small' trigger={
                    <p id='section-card-label-dimmer'>{item1.name}</p>
                  } on='hover'>
                  <p id='section-edit-card-link'>{item1.name}</p>
                  </Popup>
                  <Divider />            
                  <Header style={{"fontSize": "smaller"}} inverted textAlign='left'>Res: {(item1.metadati.streams[0]? item1.metadati.streams[0].width +"x"+item1.metadati.streams[0].height:"NOT DEFINED" )}</Header>
                  <Header style={{"fontSize": "smaller"}} inverted textAlign='left'>Codec : {(item1.metadati.streams[0]? (item1.metadati.streams[0].codec_name? item1.metadati.streams[0].codec_name :"NOT DEFINED"):"NOT DEFINED" )}</Header>                  
                  <Header style={{"fontSize": "smaller"}} inverted textAlign='left'>Size: {(item1.metadati.format? (item1.metadati.format.size? (item1.metadati.format.size/1000000).toFixed(0) > 1000 ? (item1.metadati.format.size/1000000000).toFixed(1) + 'GB' : (item1.metadati.format.size/1000000).toFixed(0) + "MB":"NOT DEFINED"):"NOT DEFINED")}</Header>                  
                  <Divider id='titlemetadata'/>
                  </Header>                    
                        <Button animated='vertical' inverted color='grey' size='tiny' onClick={this.mediaActionDirect} video={item1.name} name={index1} index={item1._id} uploaded={item1.uploaded} restype={item1.type+item1.degree} fsname={item1.fsname}> 
                  <Button.Content hidden >Play</Button.Content>
                  <Button.Content visible >
                  <Icon color='grey' name='film' />
                  </Button.Content>
                  </Button>
                  <Button animated='vertical'  inverted color='grey' size='tiny' onClick={this.cardAction} name={index1} index={item1._id} restype={item1.type+item1.degree} fsname={item1.fsname}>
                  <Button.Content hidden >Edit</Button.Content>
                  <Button.Content visible >
                  <Icon color='grey' name='edit' />
                  </Button.Content>
                  </Button>
                  <Button animated='vertical'  inverted color='grey' size='tiny' onClick={() => this.setState({modalVideoDelete:true, videoNameToDelete:item1._id, resType:item1.type+item1.degree})}>
                  <Button.Content hidden >Delete</Button.Content>
                  <Button.Content visible >
                  <Icon color='grey' name='delete' />
                  </Button.Content>
                  </Button>
                  <Card.Content name={index1} extra>
                  <Icon name='clock outline'  size='large' id='section-card-iconTime' />
                  <Label name={index1} id='section-card-footer-dark'> {item1.uploadeddate}</Label>
                  </Card.Content>
                  </Dimmer>
                  <Card.Header name={index1} textAlign='left' >
                  <Popup flowing hoverable wide position='bottom center' size='small' trigger={
                    <div>
                    <Icon corner color='blue' name='photo' id='section-card-iconTitle'/>
                    <Label  name={index1} id='section-card-label'> {item1.name} </Label>
                    </div>
                  } on='hover'>
                  <p id='section-edit-card-link'>{item1.name}</p>
                  </Popup>
                  
                  </Card.Header>
                  <Card.Content name={index1}>
                  {item1.covername ? (
                    <Image id='section-card-image' src={services.domain+"/public/uploads/"+this.props.reducer.group+"/resourcetoannotate/3d/image/"+item1.covername} size='massive' name={index1} />
                    ) : (
                      <Image id='section-card-image' src="/assets/images/about_previous.jpg" size='massive' name={index1} />)
                    }
                    </Card.Content>
                    <Card.Content name={index1} extra>
                    <Image src="/assets/images/avatar.png" avatar  verticalAlign='middle' size='mini'  alt='' floated='left' id='section-card-avatar' name={index1} />
                    <Icon name='clock outline' color='black' size='large' id='section-card-iconTime' />
                    <Label name={index1} id='section-card-footer'> {item1.uploadeddate}</Label>
                    </Card.Content>
                    </Card>
                    </Dimmer.Dimmable>						
                    )}
                    </CardGroup>          
                          :<Header inverted color="grey" fluid size='large'>-- </Header>)]           
                          :(<span></span>)} 
{/* gestione video 2d  */}  {/* //attenzione devi controllare i type mandati per resType e fsname   */}
                        <Grid   fluid  style={{ margin: '0px 0px 0px 0px',width: '100%' }} padded > 
                        <Grid.Row columns="3" color="black"  >
                        <GridColumn width="5" floated='left'>
                        <Header inverted color="grey" size='large'>2d Videos</Header>
                        </GridColumn>
                        <GridColumn width="6" floated='right'>
                        <Input inverted icon='search' placeholder='Search...' transparent onChange={this.filterInput} type="VIDEO2" />
                        </GridColumn>
                        <GridColumn width="5" floated='right'>
                        <div className="ui buttons">
                        <Button className="ui button sort" onClick={this.itemsSort} type="VIDEO2" style={{ backgroundColor: this.state.colorDefaultV2d ? "#c1c1c1" : "#e8e8e8" }} >a - z</Button>
                        <div className="or" data-text=" < > "></div>
                        <Button className="ui button sort"  type="VIDEO2"style={{ backgroundColor: this.state.colorSecondV2d ? "#c1c1c1" : "#e8e8e8" }} onClick={this.itemsSortInverse}>z - a</Button>
                        </div>
                        </GridColumn>              
                        </Grid.Row>
                        </Grid>
                    {v2d ?
                      [(v2d.length > 0 ?
                        <CardGroup>
                        {/* <div id="home_category_banner_category">	Immagini 360
                        <Input inverted icon='search' placeholder='Search...' transparent onChange={this.filterInput} />
                        <div className="ui buttons">
                        <Button className="ui button sort" onClick={this.itemsSort} style={{ backgroundColor: this.state.colorDefault ? "#c1c1c1" : "#e8e8e8" }} >a - z</Button>
                        <div className="or" data-text=" < > "></div>
                        <Button className="ui button sort" style={{ backgroundColor: this.state.colorSecond ? "#c1c1c1" : "#e8e8e8" }} onClick={this.itemsSortInverse}>z - a</Button>
                        </div>
                      </div> */}
                      {v2d.map((item1, index1) =>
                        <Dimmer.Dimmable key={index1}  name={index1} 
                        onMouseEnter={this.handleShowV2d} onMouseLeave={this.handleHideV2d}>
                        <Card key={index1} id='section-card' name={index1} raised >
                        <Dimmer active={this.state.activeV2d[index1]}>
                        <Header as='h3' inverted textAlign='left'>
                        <Popup flowing hoverable wide position='bottom center' size='small' trigger={
                          <p id='section-card-label-dimmer'>{item1.name}</p>
                        } on='hover'>
                        <p id='section-edit-card-link'>{item1.name}</p>
                        </Popup>
                        <Divider />            
                        <Header style={{"fontSize": "smaller"}} inverted textAlign='left'>Res: {(item1.metadati.streams[0]? item1.metadati.streams[0].width +"x"+item1.metadati.streams[0].height:"NOT DEFINED" )}</Header>
                        <Header style={{"fontSize": "smaller"}} inverted textAlign='left'>Codec video: {(item1.metadati.streams[0]? (item1.metadati.streams[0].codec_name? item1.metadati.streams[0].codec_name :"NOT DEFINED"):"NOT DEFINED" )}</Header>
                        <Header style={{"fontSize": "smaller"}} inverted textAlign='left'>FPS: {(item1.metadati.format? (item1.metadati.format.bit_rate? item1.metadati.format.bit_rate:"NOT DEFINED"):"NOT DEFINED")} </Header>
                        <Header style={{"fontSize": "smaller"}} inverted textAlign='left'>Size: {(item1.metadati.format? (item1.metadati.format.size? (item1.metadati.format.size/1000000).toFixed(0) > 1000 ? (item1.metadati.format.size/1000000000).toFixed(1) + 'GB' : (item1.metadati.format.size/1000000).toFixed(0) + "MB":"NOT DEFINED"):"NOT DEFINED")}</Header>
                        <Divider id='titlemetadata'/>
                        </Header>                    
                              <Button animated='vertical' inverted color='grey' size='tiny' onClick={this.mediaActionDirect} video={item1.name} name={index1} index={item1._id} uploaded={item1.uploaded} restype={item1.type+item1.degree} fsname={item1.fsname}> 
                        <Button.Content hidden >Play</Button.Content>
                        <Button.Content visible >
                        <Icon color='grey' name='film' />
                        </Button.Content>
                        </Button>
                        <Button animated='vertical'  inverted color='grey' size='tiny' onClick={this.cardAction} name={index1} index={item1._id} restype={item1.type+item1.degree} fsname={item1.fsname}>
                        <Button.Content hidden >Edit</Button.Content>
                        <Button.Content visible >
                        <Icon color='grey' name='edit' />
                        </Button.Content>
                        </Button>
                        <Button animated='vertical'  inverted color='grey' size='tiny' onClick={() => this.setState({modalVideoDelete:true, videoNameToDelete:item1._id, resType:item1.type+item1.degree})}>
                        <Button.Content hidden >Delete</Button.Content>
                        <Button.Content visible >
                        <Icon color='grey' name='delete' />
                        </Button.Content>
                        </Button>
                        <Card.Content name={index1} extra>
                        <Icon name='clock outline'  size='large' id='section-card-iconTime' />
                        <Label name={index1} id='section-card-footer-dark'> {('0'+Math.floor(item1.metadati.format.duration/3600) % 24).slice(-2)+':'+('0'+Math.floor(item1.metadati.format.duration/60)%60).slice(-2)+':'+('0' + item1.metadati.format.duration % 60).slice(-2)} </Label>
                        <Label name={index1} id='section-card-footer-dark'> {item1.uploadeddate} </Label>
                        </Card.Content>
                        </Dimmer>
                        <Card.Header name={index1} textAlign='left' >
                        <Popup flowing hoverable wide position='bottom center' size='small' trigger={
                          <div>
                          <Icon corner color='blue' name='video camera' id='section-card-iconTitle'/>
                          <Label  name={index1} id='section-card-label'> {item1.name} </Label>
                          </div>
                        } on='hover'>
                        <p id='section-edit-card-link'>{item1.name}</p>
                        </Popup>
                        
                        </Card.Header>
                        <Card.Content name={index1}>
                        {item1.covername ? (
                          <Image id='section-card-image' src={services.domain+"/public/uploads/"+this.props.reducer.group+"/resourcetoannotate/2d/video/"+item1.covername} size='massive' name={index1} />
                          ) : (
                            <Image id='section-card-image' src="/assets/images/about_previous.jpg" size='massive' name={index1} />)
                          }
                          </Card.Content>
                          <Card.Content name={index1} extra>
                          <Image src="/assets/images/avatar.png" avatar  verticalAlign='middle' size='mini'  alt='' floated='left' id='section-card-avatar' name={index1} />
                          <Icon name='clock outline' color='black' size='large' id='section-card-iconTime' />
                          <Label name={index1} id='section-card-footer'> {('0'+Math.floor(item1.metadati.format.duration/3600) % 24).slice(-2)+':'+('0'+Math.floor(item1.metadati.format.duration/60)%60).slice(-2)+':'+('0' + item1.metadati.format.duration % 60).slice(-2)} </Label>
                          <Label name={index1} id='section-card-footer'> {item1.uploadeddate}</Label>
                          </Card.Content>
                          </Card>
                          </Dimmer.Dimmable>						
                          )}
                          </CardGroup>          
                          :<Header inverted color="grey" fluid size='large'>-- </Header>)]           
                          :(<span></span>)} 

{/* gestione video 3d  */}      {/* NON FUNZIONA PERCHè FA RIFERIMENTO AL VECCHIO MODO DI GESTIRE I VIDEO CON USE CHE NON PRENDE VIDEO DA RESOURCE*/}    
                          <Grid   fluid  style={{ margin: '0px 0px 0px 0px',width: '100%' }} padded > 
                          <Grid.Row columns="3" color="black"  >
                          <GridColumn width="5" floated='left'>
                          <Header inverted color="grey" size='large'>360 Videos</Header>
                          </GridColumn>
                          <GridColumn width="6" floated='right'>
                          <Input inverted icon='search' placeholder='Search...' transparent onChange={this.filterInput} type="VIDEO3" />
                          </GridColumn>
                          <GridColumn width="5" floated='right'>
                          <div className="ui buttons">
                          <Button className="ui button sort" onClick={this.itemsSort} type="VIDEO3" style={{ backgroundColor: this.state.colorDefaultV3d ? "#c1c1c1" : "#e8e8e8" }} >a - z</Button>
                          <div className="or" data-text=" < > "></div>
                          <Button className="ui button sort"  type="VIDEO3"style={{ backgroundColor: this.state.colorSecondV3d ? "#c1c1c1" : "#e8e8e8" }} onClick={this.itemsSortInverse}>z - a</Button>
                          </div>
                          </GridColumn>              
                          </Grid.Row>
                          </Grid>  
                          {v3d ?
                            [(v3d.length > 0 ?
                              <CardGroup>
                              {/* <div id="home_category_banner_category">	Immagini 360                	 
                              <Input inverted icon='search' placeholder='Search...' transparent onChange={this.filterInput} />
                              <div className="ui buttons">
                              <Button className="ui button sort" onClick={this.itemsSort} style={{ backgroundColor: this.state.colorDefault ? "#c1c1c1" : "#e8e8e8" }} >a - z</Button>
                              <div className="or" data-text=" < > "></div>
                              <Button className="ui button sort" style={{ backgroundColor: this.state.colorSecond ? "#c1c1c1" : "#e8e8e8" }} onClick={this.itemsSortInverse}>z - a</Button>
                              </div>                
                            </div> */}
                            {v3d.map((item1, index1) =>
                              <Dimmer.Dimmable key={index1}  name={index1} 
                              onMouseEnter={this.handleShowV3d} onMouseLeave={this.handleHideV3d}>
                              <Card key={index1} id='section-card' name={index1} raised >
                              <Dimmer active={this.state.activeV3d[index1]}>
                              <Header as='h3' inverted textAlign='left'>
                              <Popup flowing hoverable wide position='bottom center' size='small' trigger={
                                <p id='section-card-label-dimmer'>{item1.name}</p>
                              } on='hover'>
                              <p id='section-edit-card-link'>{item1.name}</p>
                              </Popup>
                              <Divider />            
                              <Header style={{"fontSize": "smaller"}} inverted textAlign='left'>Res: {(item1.metadati.streams[0]? item1.metadati.streams[0].width +"x"+item1.metadati.streams[0].height:"NOT DEFINED" )}</Header>
                              <Header style={{"fontSize": "smaller"}} inverted textAlign='left'>Codec video: {(item1.metadati.streams[0]? (item1.metadati.streams[0].codec_name? item1.metadati.streams[0].codec_name :"NOT DEFINED"):"NOT DEFINED" )}</Header>
                              <Header style={{"fontSize": "smaller"}} inverted textAlign='left'>FPS: {(item1.metadati.format? (item1.metadati.format.bit_rate? item1.metadati.format.bit_rate:"NOT DEFINED"):"NOT DEFINED")} </Header>
                              <Header style={{"fontSize": "smaller"}} inverted textAlign='left'>Size: {(item1.metadati.format? (item1.metadati.format.size? (item1.metadati.format.size/1000000).toFixed(0) > 1000 ? (item1.metadati.format.size/1000000000).toFixed(1) + 'GB' : (item1.metadati.format.size/1000000).toFixed(0) + "MB":"NOT DEFINED"):"NOT DEFINED")}</Header>
                              <Divider id='titlemetadata'/>
                              </Header>                    
                                    <Button animated='vertical' inverted color='grey' size='tiny' onClick={this.mediaActionDirect} video={item1.name} name={index1} index={item1._id} uploaded={item1.uploaded} restype={item1.type+item1.degree} fsname={item1.fsname}> 
                              <Button.Content hidden >Play</Button.Content>
                              <Button.Content visible >
                              <Icon color='grey' name='film' />
                              </Button.Content>
                              </Button>
                              <Button animated='vertical'  inverted color='grey' size='tiny' onClick={this.cardAction} name={index1} index={item1._id} restype={item1.type+item1.degree} fsname={item1.fsname}>
                              <Button.Content hidden >Edit</Button.Content>
                              <Button.Content visible >
                              <Icon color='grey' name='edit' />
                              </Button.Content>
                              </Button>
                              <Button animated='vertical'  inverted color='grey' size='tiny' onClick={() => this.setState({modalVideoDelete:true, videoNameToDelete:item1._id, resType:item1.type+item1.degree})}>
                              <Button.Content hidden >Delete</Button.Content>
                              <Button.Content visible >
                              <Icon color='grey' name='delete' />
                              </Button.Content>
                              </Button>
                              <Card.Content name={index1} extra>
                              <Icon name='clock outline'  size='large' id='section-card-iconTime' />
                              <Label name={index1} id='section-card-footer-dark'> {('0'+Math.floor(item1.metadati.format.duration/3600) % 24).slice(-2)+':'+('0'+Math.floor(item1.metadati.format.duration/60)%60).slice(-2)+':'+('0' + item1.metadati.format.duration % 60).slice(-2)} </Label>
                              <Label name={index1} id='section-card-footer-dark'> {item1.uploadeddate} </Label>
                              </Card.Content>
                              </Dimmer>
                              <Card.Header name={index1} textAlign='left' >
                              <Popup flowing hoverable wide position='bottom center' size='small' trigger={
                                <div>
                                <Icon corner color='blue' name='video camera' id='section-card-iconTitle'/>
                                <Label  name={index1} id='section-card-label'> {item1.name} </Label>
                                </div>
                              } on='hover'>
                              <p id='section-edit-card-link'>{item1.name}</p>
                              </Popup>
                              
                              </Card.Header>
                              <Card.Content name={index1}>
                              {item1.covername ? (
                              <Image id='section-card-image' src={services.domain+"/public/uploads/"+this.props.reducer.group+"/resourcetoannotate/3d/video/"+item1.covername} size='massive' name={index1} />
                                  ) : (
                                  <Image id='section-card-image' src="/assets/images/about_previous.jpg" size='massive' name={index1} />)
                                }
                                </Card.Content>
                                <Card.Content name={index1} extra>
                                <Image src="/assets/images/avatar.png" avatar  verticalAlign='middle' size='mini'  alt='' floated='left' id='section-card-avatar' name={index1} />
                                <Icon name='clock outline' color='black' size='large' id='section-card-iconTime' />
                                <Label name={index1} id='section-card-footer'> {('0'+Math.floor(item1.metadati.format.duration/3600) % 24).slice(-2)+':'+('0'+Math.floor(item1.metadati.format.duration/60)%60).slice(-2)+':'+('0' + item1.metadati.format.duration % 60).slice(-2)} </Label>
                                <Label name={index1} id='section-card-footer'> {item1.uploadeddate} </Label>
                                </Card.Content>
                                </Card>
                                </Dimmer.Dimmable>						
                                )}
                                </CardGroup>          
                                :<Header inverted color="grey" fluid size='large'>-- </Header>)]           
                                :(<span></span>)} 
                                </Segment>
                                </div>
                                )
                                
                              }
                            }
                            
                            
                            export default connect(mapStateToProps)(Home);
