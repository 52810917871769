/**
 * Created by michelefuschillo on 01/02/18.
 */
import React, { Component } from 'react'
import { Radio, List, Image, Divider, Icon, Button, Input,Checkbox,Label,Dropdown } from 'semantic-ui-react'
import { setfocusobj } from '../../../../redux/actions/ObjectAction'
import { launchModal } from '../../../../redux/actions/ModalAction'


const optionsOnblink = [
  { key: 1, text: 'fast',   value: 'fast'   },
  { key: 2, text: 'medium', value: 'medium' },
  { key: 3, text: 'slow',   value: 'slow'   }
];

let unsubscribe;
class HtmlAppearance extends Component {
  constructor (props) {
    super(props)
    this.updateState = this.updateState.bind(this)
    this.handleBlink = this.handleBlink.bind(this)
    this.optionBlinkHandler = this.optionBlinkHandler.bind(this)



    var updated = this.props.store.getState()
    if (this.props.reducer.focusobj.obj.properties) {
      this.state = {
        previousState: updated,
        cover: updated.reducer.focusobj.obj.properties.cover,
        coverwidth: updated.reducer.focusobj.obj.properties.coverwidth,
        coverheight: updated.reducer.focusobj.obj.properties.coverheight,
        covertype: updated.reducer.focusobj.obj.properties.covertype,
        covername: updated.reducer.focusobj.obj.properties.covername,
        coverpath: updated.reducer.focusobj.obj.properties.coverpath,
        onblink:updated.reducer.focusobj.obj.properties.vo.onblink,
        onblinkduration:updated.reducer.focusobj.obj.properties.vo.onblinkduration ? updated.reducer.focusobj.obj.properties.vo.onblinkduration : 'medium',
        coveraction: updated.reducer.focusobj.obj.properties.coveraction
      }
    }
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('htmlpage', focusobj,{}));
    unsubscribe();
  }

  updateState () {
    var updated = this.props.store.getState()
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'htmlpage') {
      this.setState({
        previousState: updated,
        cover: this.props.reducer.focusobj.obj.properties.cover,
        coverwidth: updated.reducer.focusobj.obj.properties.coverwidth,
        coverheight: updated.reducer.focusobj.obj.properties.coverheight,
        covertype: updated.reducer.focusobj.obj.properties.covertype,
        covername: updated.reducer.focusobj.obj.properties.covername,
        coverpath: updated.reducer.focusobj.obj.properties.coverpath,
        onblink:updated.reducer.focusobj.obj.properties.vo.onblink,
        onblinkduration:updated.reducer.focusobj.obj.properties.vo.onblinkduration ? updated.reducer.focusobj.obj.properties.vo.onblinkduration : 'medium',
        coveraction: updated.reducer.focusobj.obj.properties.coveraction

      });
	  this.props.parent.waitingAnswer();
    }else{
      unsubscribe(); //the state update will be made into another component (asynchronous mode)
    }
  }

  onChangeMark = (event, data) => {
	  unsubscribe = this.props.store.subscribe(this.updateState);
	  var updated = this.props.store.getState();
	  var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    if (data.name != 'none') {
      focusobj.properties.cover = true
    }
    else {
      focusobj.properties.cover = false
      focusobj.properties.coverpath = ''
      focusobj.properties.covername = ''
      focusobj.properties.coveraction = ''
    }
    focusobj.properties.covertype = data.name
    if (data.name === 'marker') {
      focusobj.properties.coverpath = ''
      focusobj.properties.covername = ''
    }
    //focusobj.properties.cover = data.name; //marker / cover
    this.props.dispatch(setfocusobj('htmlpage', focusobj, {}));
    unsubscribe()
  }

  onChangeAction = (event, data) => {
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.properties.coveraction = data.name
    //focusobj.properties.cover = data.name; //marker / cover
    this.props.dispatch(setfocusobj('htmlpage', focusobj, {}));
    unsubscribe();
  }

  inputHandler = (event, data) => {

    var value=0;
    if(data.value>600){
      value=600;
    }else {
      value=data.value;
    }
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.properties.coverwidth = value;
      focusobj.properties.coverheight = value;
      this.props.dispatch(setfocusobj('htmlpage', focusobj, {}));
    // switch (data.name) {
    //   case 'coverwidth':
		//   focusobj.properties.coverwidth = data.value;
		//   this.props.dispatch(setfocusobj('htmlpage', focusobj, {}));
    //     break
    //   case 'coverheight':
		//   focusobj.properties.coverheight = data.value;
		//   this.props.dispatch(setfocusobj('htmlpage', focusobj, {}));
    //     break
    //   default:
    //     break
    // }
    unsubscribe();
  }

  openUploadModal = (event, data) => {
    unsubscribe = this.props.store.subscribe(this.updateState)
	  this.props.dispatch(launchModal('SETTING_MODAL', 'HTMLPAGE'));
	  this.setState({macrocategory: 'htmlpage'});
  }

  cancelCoverSelected = () => {
	  unsubscribe = this.props.store.subscribe(this.updateState);
	  var updated = this.props.store.getState();
	  var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
	  focusobj.properties.coverpath = '';
	  focusobj.properties.covername = '';
	  this.props.dispatch(setfocusobj('htmlpage', focusobj, {}));
	  this.setState({covername: ''});
  }


  // <List.Item>
  //   <List.Header> image width </List.Header>
  //   <Input name='coverwidth' type='range' min={1} max={500} step={1} value={this.state.coverwidth}
  //          onChange={this.inputHandler}
  //          style={{height: 'auto', width: '65px'}}/>
  // </List.Item>
  // <List.Item>
  //   <List.Header> image height </List.Header>
  //   <Input name='coverheight' type='range' min={1} max={500} step={1} value={this.state.coverheight}
  //          onChange={this.inputHandler}
  //          style={{height: 'auto', width: '65px'}}/>
  // </List.Item>


  handleBlink(event,data){
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    if(data.checked){
        focusobj.properties.vo.none   = false;
        focusobj.properties.vo.fov    = false;
        focusobj.properties.vo.ontop  = false;
        focusobj.properties.vo.onblinkduration = focusobj.properties.vo.onblinkduration ? focusobj.properties.vo.onblinkduration : 'medium';
      }
      focusobj.properties.vo.onblink = data.checked;
      this.props.dispatch(setfocusobj('htmlpage', focusobj,{}));
      unsubscribe();

  }

  optionBlinkHandler(event,data){
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.properties.vo.onblinkduration = data.value;
        focusobj.properties.vo.onblink = true;
        this.props.dispatch(setfocusobj('htmlpage', focusobj,{}));
        unsubscribe();

  }

  render () {
    return (
      <List className="componentOnIframeClass">
        <List.Item>
          <List.Content>
            <List.Header></List.Header>
            <List.Description>
              <Radio name='none' label='None' onChange={this.onChangeMark}
                     checked={this.state.covertype === 'none' ? true : false}/>
            </List.Description>
          </List.Content>
        </List.Item>
			  {/*<List.Item>
          <List.Content>
            <List.Header> <Image src='/assets/images/htmlpage/marker.png' height="16" width="16" name='circle'/>
            </List.Header>
            <List.Description>
              <Radio name='marker' label='Marker' onChange={this.onChangeMark}
                     checked={this.state.covertype === 'marker' ? true : false}/>
            </List.Description>
          </List.Content>
			  </List.Item>*/}
        <List.Item>
          <List.Content>            
            <List.Description>
              <Radio name="cover" label='Cover' onChange={this.onChangeMark}
                     checked={this.state.covertype === 'cover' ? true : false}/>
            </List.Description>
          </List.Content>
        </List.Item>
        {this.state.covertype === 'marker' || this.state.covertype === 'cover' ? <List.Item>
          <List.Content>
            <List.Description>
              <List >
                {this.state.covertype === 'cover' ? <List.Item>
                  <List.Content>
                    <List.Description>
                      <List>
                        <List.Item>
                          <Input size='mini' fluid disabled
                                 value={this.state.covername} placeholder='Add cover...'
                                 action={!this.state.covername ? {
                                     icon: 'upload',
                                     circular: true,
                                     onClick: this.openUploadModal,
                                     name: 'HTMLPAGE'
                                   }
                                   : {icon: 'delete', circular: true, onClick: this.cancelCoverSelected, name: 'HTMLPAGE'}}/>
                        </List.Item>
                      </List>
                    </List.Description>
                  </List.Content>
                </List.Item> : ''}
                <List.Item>
                  <List.Header> Image size </List.Header>
                  <Input type="range" name='coverwidth'  value={this.state.coverwidth}  min={50}   max={600}  step={1} onChange={this.inputHandler}
                    style={{height: 'auto', width: '10vw'}}/>
                </List.Item>
                <List.Item>

                    <Input type='number' min={50}   max={600} step={1} value={this.state.coverwidth} onChange={this.inputHandler} size='tiny'
                                           style={{height:'auto', width:'7vw'}}/><span>width</span>
                </List.Item>
                <List.Item>
                          <Checkbox className="check" label='blinking' name='blink' checked={this.state.onblink} onChange={this.handleBlink}/>
                        </List.Item>
                        {this.state.onblink ? <div>
                        <List.Item inline >
                          <Label basic color='black' pointing='right'>Speed</Label>
                          <Dropdown compact name='blink' onChange={this.optionBlinkHandler} options={optionsOnblink}
                                    selection value={this.state.onblinkduration} />
                        </List.Item>

                        <Divider hidden/>
                        </div> : <span/>}





              </List>
              <List.Item>
                <List.Header> </List.Header>
                <Radio name='embedded' label='Embedded' onChange={this.onChangeAction}
                       checked={this.state.coveraction === 'embedded' ? true : false}/>
              </List.Item>
              <List.Item>
                <List.Header> </List.Header>
                <Radio name='newwindow' label='New window' onChange={this.onChangeAction}
                       checked={this.state.coveraction === 'newwindow' ? true : false}/>
              </List.Item>
              <List.Item>
                <List.Header> </List.Header>
                <Radio name='fullscreen' label='Full screen' onChange={this.onChangeAction}
                       checked={this.state.coveraction === 'fullscreen' ? true : false}/>
              </List.Item>
            </List.Description>
          </List.Content>
        </List.Item> : ''}

      </List>
    )
  }
}

export default HtmlAppearance
