/**
 * Created by michelefuschillo on 31/01/18.
 */
import React, { Component,  } from 'react';
import {Icon,Accordion}       from 'semantic-ui-react';

import '../../../css/config.css';

class MenuFirst extends Component {
  constructor (props) {
    super(props);
    this.state=({accordionActiveIndex:-1,accordionActiveIndexInternal:-1})
    this.manageAccordion=this.manageAccordion.bind(this);
  }

  componentWillMount() {
    this.setState({accordionActiveIndex:-1,accordionActiveIndexInternal:-1});
  }

  componentWillReceiveProps(props) {
    this.setState({accordionActiveIndex: -1, accordionActiveIndexInternal: -1});
  }

  manageAccordion(event, data){
    if(data.index === this.state.accordionActiveIndex){
      this.setState({accordionActiveIndex:-1});
    }else{
      this.setState({accordionActiveIndex:data.index});
      this.props.callbackParent(data.name);
    }
  }

  /*
   *  SHAPE
   * */
  render () {
    return (
      <div>
      <Accordion styled>
        <Accordion.Title name="shapetext" className="accordionTitle" active={this.state.accordionActiveIndex === 0} index={0} onClick={this.manageAccordion} position='left' >
          <Icon name='dropdown' id='section-config-IconAccordion'/>
          <Icon color="grey" name='info circle'/>
            <i id='section-config-AccordionHotspot'>Info</i>


	  {this.props.reducer.focusobj.obj.properties.vo ?( this.props.reducer.focusobj.obj.properties.vo.showhotspot === true ? <Icon color="grey" name='eye'/> : <Icon color="grey" name='eye slash'/>):''}
        </Accordion.Title>

        <Accordion.Content active={this.state.accordionActiveIndex  === 0}> <div id='textshapeaccordion'></div></Accordion.Content>

        {/* <Accordion.Title name="time"  active={this.state.accordionActiveIndex  === 1} index={1} onClick={this.manageAccordion} position='left'>
          <Icon name='dropdown' id='section-config-IconAccordion'/>
          <Icon color="grey" name='time'/><i id='section-config-AccordionHotspot'>Time</i>
        </Accordion.Title>
        <Accordion.Content active={this.state.accordionActiveIndex  === 1}> <div id='timeaccordion'></div></Accordion.Content> */}

        <Accordion.Title name="shapecolor"  active={this.state.accordionActiveIndex  === 2} index={2} onClick={this.manageAccordion} position='left'>
          <Icon name='dropdown' id='section-config-IconAccordion'/>
          <Icon color="grey" name='tint'/><i id='section-config-AccordionHotspot'>Color</i>
        </Accordion.Title>
        <Accordion.Content active={this.state.accordionActiveIndex  === 2}> <div id='colorshapeaccordion'></div></Accordion.Content>

        <Accordion.Title name="shapescale"  active={this.state.accordionActiveIndex  === 3} index={3} onClick={this.manageAccordion} position='left'>
          <Icon name='dropdown' id='section-config-IconAccordion'/>
          <Icon color="grey" name='expand arrows alternate'/><i id='section-config-AccordionHotspot'>Size</i>
        </Accordion.Title>
        <Accordion.Content active={this.state.accordionActiveIndex  === 3}> <div id='scaleshapeaccordion'></div></Accordion.Content>

{/* 
        <Accordion.Title name="shapetag"  active={this.state.accordionActiveIndex  === 4} index={4} onClick={this.manageAccordion} position='left'>
          <Icon name='dropdown' id='section-config-IconAccordion'/>
          <Icon color="grey" name='tag'/><i id='section-config-AccordionHotspot'>Tags</i>
        </Accordion.Title>
        <Accordion.Content active={this.state.accordionActiveIndex  === 4}><div id='tagshapeaccordion'></div></Accordion.Content> */}
      </Accordion>
      </div>
    )
  }
}

export default MenuFirst;
