/**
 * Created by michelefuschillo on 01/02/18.
 */
import React, { Component } from 'react'
import { Table, Input } from 'semantic-ui-react'
import { setfocusobj } from '../../../../redux/actions/ObjectAction'

class ImageAppearance extends Component {
  constructor (props) {
    super(props)
    this.updateState = this.updateState.bind(this)
    var updated = this.props.store.getState();
    if (this.props.reducer.focusobj.obj.properties) {
      
      this.state = {
        previousState:updated,
        coverwidth: updated.reducer.focusobj.obj.properties.coverwidth,
        coverheight: updated.reducer.focusobj.obj.properties.coverheight,
        coverpath:updated.reducer.focusobj.obj.properties.coverpath
      }
    }
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('image', focusobj,{}));
    unsubscribe();
  }

  updateState () {
    var updated = this.props.store.getState();
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'image') {
      this.setState({
        previousState:updated,
        coverwidth: updated.reducer.focusobj.obj.properties.coverwidth,
        coverheight: updated.reducer.focusobj.obj.properties.coverheight,
        coverpath:updated.reducer.focusobj.obj.properties.coverpath
      });
	  this.props.parent.waitingAnswer();
    }
  }

  inputHandler = (event, data) => {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    let img = document.createElement('img');
    img.src = this.state.coverpath; // (*) 
    var self = this;
    img.onload=function(){
      var aspectRatio = (img.width/img.height);
      focusobj.properties.coverwidth = data.value;
      var newheight = ( data.value / aspectRatio);
      focusobj.properties.coverheight = newheight;
      self.props.dispatch(setfocusobj('image', focusobj, {}));
      unsubscribe();
    }

    /*switch (data.name) {
      case 'coverwidth':
		  //focusobj.properties.coverwidth = data.value;
         focusobj.properties.coverwidth = Math.round(data.value*ratio);
		  this.props.dispatch(setfocusobj('image', focusobj, {}));
        break
      case 'coverheight':
		  //focusobj.properties.coverheight = data.value;
      focusobj.properties.coverheight= Math.round(data.value*ratio);  
		  this.props.dispatch(setfocusobj('image', focusobj, {}));
        break
      default:
        break
    }*/
  }

  render () {
    return (
      <Table collapsing id='section-config-tableTextPosition' basic='very' >
        <Table.Body>
          {/*<Table.Row>
            <Table.Cell>Width</Table.Cell>
            <Table.Cell><Input name='coverwidth' type='range' min={1} max={500} step={1} value={this.state.coverwidth}
                               onChange={this.inputHandler}
                               style={{height: 'auto', width: '10vw'}}/></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Height</Table.Cell>
            <Table.Cell>  <Input name='coverheight' type='range' min={1} max={500} step={1} value={this.state.coverheight}
                                 onChange={this.inputHandler}
                                 style={{height: 'auto', width: '10vw'}}/></Table.Cell>
          </Table.Row>*/}
          <Table.Row><Table.Cell>
            <Input type="range" name='coverwidth'  value={this.state.coverwidth}  min={50}   max={700}  step={1} onChange={this.inputHandler} style={{height: 'auto', width: '10vw'}}/>
          {/*<Input type="range" name='coverheight' value={this.state.coverheight} min={this.state.minHeight}  max={this.state.maxHeight} step={1} onChange={this.inputHandler} style={{height: 'auto', width: '10vw'}} />*/}
          </Table.Cell></Table.Row>
          <Table.Row><Table.Cell>
          <Input type='number' min={50}   max={700} step={1} value={this.state.coverwidth} onChange={this.inputHandler} size='small'
                                 style={{height:'auto', width:'4vw'}}/><span>width</span>
          </Table.Cell></Table.Row>
          <Table.Row><Table.Cell>
          </Table.Cell></Table.Row>
        </Table.Body>
      </Table>
    )
  }
}
export default ImageAppearance