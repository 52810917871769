/**
* Created by michelefuschillo on 31/01/18.
*/
import React, { Component } from 'react';
import {Accordion,Icon,Popup} from 'semantic-ui-react';

import '../../../css/config.css';

import * as desc from './descriptionmenu.json'

const ShowInfo=true;

class MetadataMenu extends Component {
  constructor (props) {
    super(props);
    this.state=({accordionActiveIndex:-1,accordionActiveIndexInternal:-1})
    this.manageAccordion=this.manageAccordion.bind(this);
    this.handleMenuVertical = this.handleMenuVertical.bind(this);
  }
  
  componentWillMount() {
    
    this.setState({accordionActiveIndex:-1,accordionActiveIndexInternal:-1});
  }
  
  componentWillReceiveProps(props) {if (this.props.action==="menutime"){
    //accordionActiveIndex in questo caso assume il valore della posizione del menu time 
    //cambiare se cambi l'ordine del menù 
    this.setState({accordionActiveIndex:1,accordionActiveIndexInternal:-1});
    let i= document.getElementById('timeaccordion')
    console.log(i);
    if (i){
      this.props.callbackParent("time")
    }
  }else{
    this.setState({accordionActiveIndex: -1, accordionActiveIndexInternal: -1});
  }
}


componentDidMount() {
  
  if (this.props.action==="menutime"){
    //accordionActiveIndex in questo caso assume il valore della posizione del menu time 
    //cambiare se cambi l'ordine del menù 
    this.setState({accordionActiveIndex:1,accordionActiveIndexInternal:-1});
    let i= document.getElementById('timeaccordion')
    console.log(i);
    if (i){
      this.props.callbackParent("time")
    }
  }
}
manageAccordion(event, data){
  if(data.index === this.state.accordionActiveIndex){
    this.setState({accordionActiveIndex:-1});
  }else{
    this.setState({accordionActiveIndex:data.index});
    this.props.callbackParent(data.name);
  }
}
/*
*  image obj
* */
render () {
  return (
    <div>
    <Accordion styled>
    <Accordion.Title name="metadatainfo" className="accordionTitle" active={this.state.accordionActiveIndex === 0} index={0} onClick={this.manageAccordion} position='left' >
    <Icon name='dropdown' id='section-config-IconAccordion'/>
    <Icon color="grey" name='info circle'/>
    <i id='section-config-AccordionHotspot'>Info</i>
    {this.props.reducer.focusobj.obj.properties.vo.showhotspot ? <Icon color="grey" name='eye slash'/> : ''}
    
    { ShowInfo=== true ? 
      <Popup flowing hoverable position='bottom right' size='small' trigger={                      
        <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
      }>
      <p id='section-edit-card-link'>{desc.info.description}</p>
      </Popup>
      :
      <span></span>}
      </Accordion.Title>
      
      <Accordion.Content active={this.state.accordionActiveIndex  === 0}>
      <div id='metadatainfo'></div>
      </Accordion.Content>
      
      <Accordion.Title name="time" active={this.state.accordionActiveIndex === 1} index={1}
      onClick={this.manageAccordion} position='left'>
      <Icon name='dropdown' id='section-config-IconAccordion'/>
      <Icon color="grey" name='time'/><i id='section-config-AccordionHotspot'>Time</i>
      
      { ShowInfo=== true ? 
        <Popup flowing hoverable position='bottom right' size='small' trigger={                      
          <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
        }>
        <p id='section-edit-card-link'>{desc.time.description}</p>
        </Popup>
        :
        <span></span>}
        </Accordion.Title>
        <Accordion.Content active={this.state.accordionActiveIndex === 1}>
        <div id='timeaccordion'></div>
        </Accordion.Content>
        
        <Accordion.Title name="metadataappearance" className="accordionTitle" active={this.state.accordionActiveIndex === 2} index={2} onClick={this.manageAccordion} position='left' >
        <Icon name='dropdown' id='section-config-IconAccordion'/>
        <Icon color="grey" name='expand arrows alternate'/><i id='section-config-AccordionHotspot'>Size</i>
        
        { ShowInfo=== true ? 
          <Popup flowing hoverable position='bottom right' size='small' trigger={                      
            <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
          }>
          <p id='section-edit-card-link'>{desc.size.description}</p>
          </Popup>
          :
          <span></span>}
          </Accordion.Title>
          
          <Accordion.Content active={this.state.accordionActiveIndex  === 2}>
          <div id='metadataappearance'></div>
          </Accordion.Content>
          
          {/*<Accordion.Title name="metadataviewoptions" className="accordionTitle" active={this.state.accordionActiveIndex === 3} index={3} onClick={this.manageAccordion} position='left' >
          <Icon color="grey" name='move'/><i id='section-config-AccordionHotspot'>View options</i>
          <Icon name='dropdown' id='section-config-IconAccordion'/>
          </Accordion.Title>
          
          <Accordion.Content active={this.state.accordionActiveIndex  === 3}>
          <div id='metadataviewoptions'></div>
        </Accordion.Content>*/}
        
        {/* <Accordion.Title name="metadatashapetag"  active={this.state.accordionActiveIndex  === 3} index={3} onClick={this.manageAccordion} position='left'>
        <Icon name='dropdown' id='section-config-IconAccordion'/>
        <Icon color="grey" name='tag'/><i id='section-config-AccordionHotspot'>Tags</i>
        
        { ShowInfo=== true ? 
          <Popup flowing hoverable position='bottom right' size='small' trigger={                      
            <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
          }>
          <p id='section-edit-card-link'>{desc.tags.description}</p>
          </Popup>
          :
          <span></span>}
          </Accordion.Title>
          <Accordion.Content active={this.state.accordionActiveIndex  === 3}><div id='metadatatagshapeaccordion'></div></Accordion.Content> */}
          </Accordion>
          </div>
          )
        }
        
        handleMenuVertical(event, data){
          this.setState({activeItem:data.name});
          this.props.callbackParent(data.name);
        }
      }
      
      export default MetadataMenu;
      