import React, { Component } from 'react';
import {Button,Segment,Input,Image,Dropdown,Icon,Header,Step} from 'semantic-ui-react'
import {connect} from 'react-redux';
import actionLogout from '../redux/actions/logout';
import Axios from 'axios';
import * as data from '../config/services.json';
import menuItem from '../redux/actions/menustate';
import { transitions, positions, Provider as AlertContainer } from 'react-alert'

import {changeName} from '../redux/actions/auth';

import '../css/profile.css';


const mapStateToProps = (state) => ({
  reducer: state.reducer,
})

const roles = [
  { key: 'admin', text: 'Admin', value: 'admin' },
  { key: 'normal', text: 'Normal', value: 'normal' },
  { key: 'restricted', text: 'Restricted', value: 'restricted'},
]

class Profile extends Component {
  constructor (props) {
    super(props);
    this.state = {name:"", email:"",role:"",file:"", avatar:""};
    this.logout=this.logout.bind(this);
    this.loadInfo=this.loadInfo.bind(this);
    this.fileChoiced = this.fileChoiced.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    this.saveAvatar = this.saveAvatar.bind(this);
    this.showAlert=this.showAlert.bind(this);
    this.showAlertError=this.showAlertError.bind(this);
    this.changeValue=this.changeValue.bind(this);
    //this.loadPhoto=this.loadPhoto.bind(this);
  }

  componentWillMount() {
    //authentication check
    if(!this.props.reducer.logged){
      this.props.history.push("/login");
      this.props.dispatch(menuItem('login'));
      this.setState({avatar:'/assets/images/avatar.png'})
    }else{
      this.loadInfo();
      this.setState({avatar:data.domain + data.retrieve_photo_GET+this.props.reducer.userid})
    }
  }

  render(){
   return(
    <div>
      <Segment basic id='section-profile'>
        <div className="ui raised segment">
          <Header as='h4' color='black'> <Icon name='image' color='black' size='mini' /> <Header.Content> Avatar *</Header.Content> </Header>
          <Image  src={this.props.reducer.logged ? this.state.avatar : '/assets/images/avatar.png'  } size="tiny" avatar />
          {/*<Input id="file" size='mini' type="file" placeholder='Avatar choice' onChange={this.fileChoiced}/>*/}

          <Step.Group size='mini' id='section-profile-step'>
            <Step>
              <div>
                <label htmlFor="file" className="ui icon button" style={{background: 'transparent'}}>
                  <Icon name='upload' color='black' size='big'/></label>
                  <Input type="file" id="file" style={{display: 'none'}} size='mini' placeholder='Avatar choice' onChange={this.fileChoiced} />
              </div>

              <Step.Content>
              <Step.Title>Choose Image</Step.Title>
              <Step.Description>Choose your .png avatar file</Step.Description>
              </Step.Content>
            </Step>

            <Step onClick={this.saveAvatar}>
              <Icon name='save' color='black'/>
              <Step.Content>
              <Step.Title>Save Avatar</Step.Title>
              <Step.Description>Save in order to upgrade avatar</Step.Description>
              </Step.Content>
              </Step>
            </Step.Group>
          </div>
          {/* <Button circular color='yellow' floated='right'  onClick={this.saveAvatar} icon='save'> 
          <Image verticalAlign='middle' src={this.props.reducer.logged? this.state.avatar:'/assets/images/avatar.png'} className="visible content" id='section-profile-avatar' centered avatar bordered={true} size="medium"/><span>Save</span>
          </Button>*/}
              <div className="ui raised segment">
                <Header as='h4' color='black'> <Icon name='newspaper' color='black' size='mini'/> <Header.Content> Name *</Header.Content> </Header>
                <Input icon='id card' iconPosition='left' size='small' id="name" className="Name" placeholder={this.state.name} onChange={this.changeValue} fluid />
              </div>
              <div className="ui raised segment">
                <Header as='h4' color='black'> <Icon name='privacy' color='black' size='mini'/> <Header.Content> Role *</Header.Content> </Header>
                <Dropdown id="role" placeholder='Role' fluid selection options={roles} defaultValue="admin" disabled/>
            </div>
              <div className="ui raised segment">
                <Header as='h4' color='black'> <Icon name='mail outline' color='black' size='mini' /> <Header.Content> Email *</Header.Content> </Header>
                <Input icon='mail' iconPosition='left' size='small' placeholder={this.state.email} onChange={this.changeValue} disabled fluid/>
              </div>
      <div>
      <Button className='ui right animated' color='grey' inverted >
        <Button.Content visible >Save</Button.Content>
        <Button.Content hidden onClick={this.saveProfile} >Go... </Button.Content>
      </Button>
 
      <Button className='ui right floated animated' color='black' inverted >
        <Button.Content visible >Logout</Button.Content>
        <Button.Content hidden onClick={this.logout} >Bye... </Button.Content>
      </Button>
      </div>
        <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
    </Segment>
    </div>
  )}

  changeValue(event){
     console.log(event.target);
     switch(event.target.id){
       case 'name':
         this.setState({name:event.target.value})
         break;
       default:
         break;
     }
  }

  logout(event, data){
    this.props.dispatch(actionLogout());
    window.location.reload();
    this.props.history.push("/");
  }

  loadInfo(){
    var self=this;
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt;
    Axios.get(data.domain + data.user_info_GET+self.props.reducer.userid)
      .then(function (response) {
        if(response.data.message.length > 0) {
          self.setState(
            {id: response.data.message[0]._id,
              email: response.data.message[0].email,
              name: response.data.message[0].name,
              avatar: data.domain + data.retrieve_photo_GET+self.props.reducer.userid,
              role:response.data.message[0].role})
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  /*loadPhoto(){
    var self=this;
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt;
    Axios.get(data.domain + data.retrieve_photo_GET_AUTH+self.props.reducer.userid)
      .then(function (response) {
        if(response) {
          self.setState({avatar: "data:image/jpeg;base64,"+response.data})
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }*/


  fileChoiced(event, data)
  {
    this.setState({file:document.getElementById("file").files[0]});
  }

  //
  saveProfile(){
    console.log("state.name",this.state.name);
    if(this.state.name && this.props.reducer.userid){ //multipartform per upload immagine
      console.log("state.name",this.state.name);
      var self=this;
      const parameters = {
        userId:this.props.reducer.userid,
        name:this.state.name
      };
      Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt;
      Axios.post(data.domain + data.update_profile_POST_AUTH,parameters)
        .then(function (response) {
          if(response.status === 200){
            //self.showAlert();
            self.props.dispatch(changeName(response.data.message['name']));
            window.location.reload();
            return;
          }
          self.showAlertError();
        })
        .catch(function (error) {
          self.showAlertError();
        });
      return;
    }
  }

  saveAvatar(){
    if(this.state.file && this.props.reducer.userid){ //multipartform per upload immagine
      var self=this;
      var formData = new FormData();
      formData.set('userId',this.props.reducer.userid);
      formData.set('avatar',this.state.file);

      Axios.defaults.headers.common['Authorization'] = 'Bearer ' + self.props.reducer.jwt;
      const headers = {headers:{ 'content-type': 'multipart/form-data' }};
      Axios.post(data.domain + data.upload_photo_POST_AUTH,formData, headers)
        .then(function (response) {
          if(response.status === 200){
            //self.showAlert();
            window.location.reload();
            return;
          }
          self.showAlertError();
        })
        .catch(function (error) {
          self.showAlertError();
        });
      return;
    }
  }


  alertOptions = {
    offset: 14,
    position: 'bottom center',
    theme: 'dark',
    time: 5000,
    transition: 'scale'
  }

  showAlert(){
    this.msg.show('Your profile is updated successfully', {
      time: 7000,
      type: 'success',
    })
  }

  showAlertError(){
    this.msg.show('Update failed', {
      time: 6000,
      type: 'error',
    })
  }
}

export default connect(mapStateToProps)(Profile);