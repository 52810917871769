/**
    data : (obbligatorio) array medialan 
    explicitLanguage: (opzionale) ricerca la lingua richiesta se undefined viene cercata la lingua del browser
 */
    export function getResourceDataForLanguage(data,explicitLanguage){
        // console.log("lingua del browser selezionato : ")
        // console.log(navigator.language.substring(0,2))  
        let lan=explicitLanguage ?explicitLanguage : navigator.language.substring(0,2) || navigator.userLanguage.substring(0,2) ;
        const lanList = Object.keys(data.medialan);
        // print all keys
        // console.log(lanList);
        if (lanList.includes(lan)){
          return lan ;
        }else if (lanList.includes("en")){
          return "en";
        }else if (lanList.includes("it")){
          return "it";
        }else {
          return lanList[0];
        }
      }



export function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf('?') !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2');
  }
  else {
    return uri + separator + key + "=" + value;
  }
}

