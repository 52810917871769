import React, { Component } from 'react';
import {List, Image, Button} from 'semantic-ui-react';
import {setfocusobj} from '../../../../redux/actions/ObjectAction';
import '../../../../css/config.css';

let unsubscribe;
class TextlineComp extends Component {
	constructor (props) {
		super(props);
		this.objSelected=this.objSelected.bind(this);
		this.updateState = this.updateState.bind(this);
	}
  
	updateState(){
		this.props.parent.waitingAnswer();
	}

	objSelected(event, data){
		unsubscribe = this.props.store.subscribe(this.updateState);
		this.props.dispatch(setfocusobj(
			'textline',
			{type: 'textline', action: '', name:'', description:'', properties: {
				text: 'Scrollable text here',
				color: '#ffffff',
				backgroundAlpha: '0.50',
				background: 'rgba(0, 0, 0, 0.50)',
				backgroundObj: {r: 120, g: 120, b: 120, a: 0.50},
				iframe: false,
				link: '',
				vo: {ontop: true,
					ontopscroll: 'textline',
					onscrollver: 'bottom',
					onscrolloptionstxt:'fix',onscrolliteration:'loop',
					onscrolldirection:'left',onscrollduration:'medium'}
				}},
				{'type':'textline','command':'new','textline':data.name}));
				unsubscribe();
			}


			render () {
				return (
					<div>
					<List size="mini">
					<List.Item>
					<Button color="grey" circular inverted size='mini' compact onClick={this.objSelected}>
					<Image src='/assets/images/text/textscroll.png' height="20" width="20" name='textscroll'/>
					</Button>
					</List.Item>
					</List>
					</div>
				)
			}
		}

		export default TextlineComp;