/**
 * Created by michelefuschillo on 09/09/18.
 */
import React, { Component } from 'react';
import {connect}      from 'react-redux';
import GenericSetting from './component/GenericSetting';
import ReactDOM      from 'react-dom';

const mapStateToProps = (state) => {
  return {
    settingType: state.reducer.settingType,
    jwt: state.reducer.jwt,
    userId: state.reducer.userid,
    settingExtra:state.reducer.settingExtra,
	group: state.reducer.group  ,
  extra :state.reducer.group
  };
};

const SettingController = (props) => {
  
  if (!props.settingType || !document.getElementById(props.settingExtra.dom)) {
    return null;
  }
  return  ReactDOM.render(
    <GenericSetting 
	 jwt={props.jwt}
	 userId={props.userId}
	 extra={props.extra}
	 group={props.group} 
	 dispatch={props.dispatch}
	 settingType={props.settingType} 
	 reducer={props.reducer}
	 modalfrom={props.settingExtra.modalfrom}/>,document.getElementById(props.settingExtra.dom));
}	
export default connect(mapStateToProps)(SettingController);
