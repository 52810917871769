/**
* Created by michelefuschillo on 31/01/18.
*/
import React, { Component } from 'react';
import {Accordion,Icon,Popup} from 'semantic-ui-react';
import '../../../css/config.css';
import * as desc from './descriptionmenu.json'

const ShowInfo=true;

class MenuSixth extends Component {
  constructor (props) {
    super(props);
    this.state=({accordionActiveIndex:-1,accordionActiveIndexInternal:-1, infoname:"Info"})
    this.manageAccordion=this.manageAccordion.bind(this);
    this.handleMenuVertical = this.handleMenuVertical.bind(this);
  }
  
  componentWillMount() {
    this.setState({accordionActiveIndex:-1,accordionActiveIndexInternal:-1});
  }
  
  
  componentWillReceiveProps(props) {
    // console.log(this.props.actualObj.name);
    // console.log(this.props.actualObj.properties.vo.showhotspot);
    if (this.props.action==="menutime"){
      //accordionActiveIndex in questo caso assume il valore della posizione del menu time 
      //cambiare se cambi l'ordine del menù 
      this.setState({accordionActiveIndex:3,accordionActiveIndexInternal:-1});
      let i= document.getElementById('timeaccordion')
      if (i){
        this.props.callbackParent("time")
      }
    }else{
      this.setState({accordionActiveIndex: -1, accordionActiveIndexInternal: -1    });
    }
    
    // ,
    // infoName:this.props.actualObj.name!=="" ? this.props.actualObj.name : "Info",
    // showhotspot: this.props.actualObj.properties.vo.showhotspot
    
  }
  
  
  componentDidMount() {
    
    if (this.props.action==="menutime"){
      //accordionActiveIndex in questo caso assume il valore della posizione del menu time 
      //cambiare se cambi l'ordine del menù 
      this.setState({accordionActiveIndex:3,accordionActiveIndexInternal:-1});
      let i= document.getElementById('timeaccordion')
      if (i){
        this.props.callbackParent("time")
      }
    }
  }
  manageAccordion(event, data){
    if(data.index === this.state.accordionActiveIndex){
      this.setState({accordionActiveIndex:-1});
    }else{
      this.setState({accordionActiveIndex:data.index});
      this.props.callbackParent(data.name);
    }
  }
  /*
  *  image obj
  * */
  render () {
    return (
      <div>
      <Accordion styled>
      <Accordion.Title name="imageinfo" className="accordionTitle" active={this.state.accordionActiveIndex === 0} index={0} onClick={this.manageAccordion} position='left' >
      <Icon name='dropdown' id='section-config-IconAccordion'/>
      <Icon color="grey" name='info circle'/>
      <i id='section-config-AccordionHotspot'>Info</i>
      
      
      {this.props.reducer.focusobj.obj.properties.vo ?( this.props.reducer.focusobj.obj.properties.vo.showhotspot === true ? <Icon color="grey" name='eye'/> : <Icon color="grey" name='eye slash'/>):''}
      { ShowInfo=== true ? 
        <Popup flowing hoverable position='bottom right' size='small' trigger={                      
          <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
        }>
        <p id='section-edit-card-link'>{desc.info.description}</p>
        </Popup>
        :
        <span></span>}
        </Accordion.Title>
        
        <Accordion.Content active={this.state.accordionActiveIndex  === 0}>
        <div id='imageinfo'></div>
        </Accordion.Content>
        
        <Accordion.Title name="imageposition" className="accordionTitle" active={this.state.accordionActiveIndex === 1} index={1} onClick={this.manageAccordion} position='left' >
        <Icon name='dropdown' id='section-config-IconAccordion'/>
        <Icon color="grey" name='globe'/><i id='section-config-AccordionHotspot'>Position</i>
        { ShowInfo=== true ? 
          <Popup flowing hoverable position='bottom right' size='small' trigger={                      
            <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
          }>
          <p id='section-edit-card-link'>{desc.position.description}</p>
          </Popup>
          :
          <span></span>}
          </Accordion.Title>
          <Accordion.Content active={this.state.accordionActiveIndex  === 1}>
          <div id='imageposition'></div>
          </Accordion.Content>
          <Accordion.Title name="imageimport" active={this.state.accordionActiveIndex === 2} index={2}
          onClick={this.manageAccordion} position='left'>
          <Icon name='dropdown' id='section-config-IconAccordion'/>
          <Icon color="grey" name='upload'/><i id='section-config-AccordionHotspot'>Load</i>
          { ShowInfo=== true ? 
            <Popup flowing hoverable position='bottom right' size='small' trigger={                      
              <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
            }>
            <p id='section-edit-card-link'>{desc.load.description}</p>
            </Popup>
            :
            <span></span>}
            </Accordion.Title>
            <Accordion.Content active={this.state.accordionActiveIndex === 2}>
            <div id='imageimport'></div>
            </Accordion.Content>

            { this.props.reducer.resType !=="IMAGE2" && this.props.reducer.resType !=="IMAGE3"?
            <div>
            <Accordion.Title name="time" active={this.state.accordionActiveIndex === 3} index={3}
            onClick={this.manageAccordion} position='left'>
            <Icon name='dropdown' id='section-config-IconAccordion'/>
            <Icon color="grey" name='time'/><i id='section-config-AccordionHotspot'>Time</i>
            { ShowInfo=== true ? 
              <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
              }>
              <p id='section-edit-card-link'>{desc.time.description}</p>
              </Popup>
              :
              <span></span>}
              </Accordion.Title>
              <Accordion.Content active={this.state.accordionActiveIndex === 3}>
              <div id='timeaccordion'></div>
              
              
              </Accordion.Content>
              </div>
            :null}             
              <Accordion.Title name="imageappearance" className="accordionTitle" active={this.state.accordionActiveIndex === 4} index={4} onClick={this.manageAccordion} position='left' >
              <Icon name='dropdown' id='section-config-IconAccordion'/>
              <Icon color="grey" name='expand arrows alternate'/><i id='section-config-AccordionHotspot'>Size</i>
              { ShowInfo=== true ? 
                <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                  <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                }>
                <p id='section-edit-card-link'>{desc.size.description}</p>
                </Popup>
                :
                <span></span>}
                </Accordion.Title>
                
                <Accordion.Content active={this.state.accordionActiveIndex  === 4}>
                <div id='imageappearance'></div>
                </Accordion.Content>
                
                <Accordion.Title name="imageviewoptions" className="accordionTitle" active={this.state.accordionActiveIndex === 5} index={5} onClick={this.manageAccordion} position='left' >
                <Icon name='dropdown' id='section-config-IconAccordion'/>
                <Icon color="grey" name='move'/><i id='section-config-AccordionHotspot'>View options</i>
                { ShowInfo=== true ? 
                  <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                    <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                  }>
                  <p id='section-edit-card-link'>{desc.viewoptions.description}</p>
                  </Popup>
                  :
                  <span></span>}
                  </Accordion.Title>
                  
                  <Accordion.Content active={this.state.accordionActiveIndex  === 5}>
                  <div id='imageviewoptions'></div>
                  </Accordion.Content>
                  
                  {/* <Accordion.Title name="imageshapetag"  active={this.state.accordionActiveIndex  === 6} index={6} onClick={this.manageAccordion} position='left'>
                  <Icon name='dropdown' id='section-config-IconAccordion'/>
                  <Icon color="grey" name='tag'/><i id='section-config-AccordionHotspot'>Tags</i>
                  { ShowInfo=== true ? 
                    <Popup flowing hoverable position='bottom right' size='small' trigger={                      
                      <Icon name='question circle' style={{"float": "right","display": "block" , "margin-top":"5px"}} size='small' color='grey'/>                        
                    }>
                    <p id='section-edit-card-link'>{desc.tags.description}</p>
                    </Popup>
                    :
                    <span></span>}
                    </Accordion.Title>
                    <Accordion.Content active={this.state.accordionActiveIndex  === 6}><div id='imagetagshapeaccordion' object="audio" ></div></Accordion.Content> */}
                    </Accordion>
                    </div>
                    )
                  }
                  
                  handleMenuVertical(event, data){
                    this.setState({activeItem:data.name});
                    this.props.callbackParent(data.name);
                  }
                }
                
                export default MenuSixth;
                