/**
 * Created by michelefuschillo on 29/01/18.
 */
import React, { Component } from 'react'
import { List, Input ,Modal,Image,Header,Table,Button} from 'semantic-ui-react'
import { setfocusobj } from '../../../../redux/actions/ObjectAction'
import { launchModal } from '../../../../redux/actions/ModalAction'
import '../../../../css/modal.css'
/*
* let will not be added as properties on the global window object like those defined with var
 */
let unsubscribe;
class Video2dImportMentor extends Component {
  constructor (props) {
    super(props)
    var updated = this.props.store.getState();
    this.state = {
      previousState:updated,
      nameresource: updated.reducer.focusobj.obj.properties ? updated.reducer.focusobj.obj.properties.nameresource : '',
      pathresource: updated.reducer.focusobj.obj.properties ? updated.reducer.focusobj.obj.properties.pathresource : '',
      videomentor:this.props.videomentor,
      mentorsnippet:updated.reducer.focusobj.obj.properties.mentorsnippet,
      uploadMentorModal:false
    }
    this.updateState = this.updateState.bind(this);
    this.cancelSelected = this.cancelSelected.bind(this);
    this.openToChoice = this.openToChoice.bind(this);
    this.closeModal=this.closeModal.bind(this);
    this.settingSelceted=this.settingSelceted.bind(this);
  }

  openToChoice (event, data) {
      unsubscribe = this.props.store.subscribe(this.updateState);//sposta nella funzione di selezione 
      this.setState({uploadMentorModal:true})
	//   this.props.dispatch(launchModal('SETTING_MODAL', 'video'));
    //   this.setState({macrocategory: 'video2d'});
    
  }

  closeModal(e,data){
    this.setState({uploadMentorModal:false});
  }

  componentWillReceiveProps(props) {
    var unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    this.props.dispatch(setfocusobj('video2d', focusobj, {'type': 'video2d', 'action': 'change'}));
    unsubscribe();
  }

  updateState () {
    var updated = this.props.store.getState() //NEW
    if ((this.state.previousState !== updated) && updated.reducer.focusobj.type === 'video2d') {
      this.setState({
        previousState:updated,
        nameresource: updated.reducer.focusobj.obj.properties.nameresource,
        pathresource: updated.reducer.focusobj.obj.properties.pathresource,
        videomentor:this.props.videomentor,
        mentorsnippet: updated.reducer.focusobj.obj.properties.mentorsnippet
      });
	  this.props.parent.waitingAnswer();
    }else{
        unsubscribe(); //the state update will be made into another component (asynchronous mode)
    }
  }

   settingSelceted(e,d){
       console.log(d);
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.properties.nameresource = d.selected.namementor;
    focusobj.properties.pathresource = d.selected.path;
    this.props.dispatch(setfocusobj('video2d', focusobj, {'type': 'video2d', 'action': 'change'}));
    this.setState({name:d.selected.namementor, uploadMentorModal:false });
   } 

  cancelSelected () {
    unsubscribe = this.props.store.subscribe(this.updateState);
    var updated = this.props.store.getState();
    var focusobj = Object.assign({}, updated.reducer.focusobj.obj);
    focusobj.properties.nameresource = '';
    focusobj.properties.pathresource = '';
    this.props.dispatch(setfocusobj('video2d', focusobj, {'type': 'video2d', 'action': 'change'}));
    this.setState({name: ''});
  }

  render () {
    //   console.log("this.state.videomentor");
    //   console.log(this.state.videomentor);
      var j=[];
      if (this.state.videomentor){          
       var x = Object.values(this.state.videomentor);
       for(var i=0;i<x.length;i++){
        if (x[i].mentorsnippet===this.state.mentorsnippet){
            j.push(x[i]);
            
        }
     }
    }
    return (
      <List className="componentOnIframeClass">
        <List.Item>
          <List.Content>
            <List.Description>
              <List>
                <List.Item>
                  <Input size='mini' fluid disabled
                         value={this.state.nameresource} placeholder='Add Video...ginett'
                         action={!this.state.nameresource ? 
                          { icon: 'upload', circular: true, onClick: this.openToChoice,  name: 'video2dimport'}
                          : 
                          {icon: 'delete', circular: true, onClick: this.cancelSelected, name: 'video2dimport'}}/>
                </List.Item>
                <List.Item>
                <Modal open={this.state.uploadMentorModal} size={'large'} closeIcon={true} onClose={this.closeModal}>
                    <Modal.Content scrolling>
                    <Header as='h1'>
                        <Image id='image-logo-modal' src={this.props.store.getState().reducer.group==="IDEHA" ? '/assets/images/logo_H.png':'/assets/images/logo.png'} avatar floated='left' alt="H360"/>
                        <Header.Subheader>Choose the Mentor Video </Header.Subheader>
                    </Header>
                    {/* <JumpModal  store={this.props.store}  callbackFather={this.childFunction} ></JumpModal> */}
                   {j.length>0 ?<div>
                    <Table singleLine selectable color='grey' compact size='small' striped id='section-content-mentorheader'stackable >
                        <Table.Header>
                        <Table.Row>
                            {/* <Table.HeaderCell id='section-content-tableHeader' width={1}></Table.HeaderCell> */}
                            <Table.HeaderCell id='section-content-tableHeader' width={4}>Name </Table.HeaderCell>
                            <Table.HeaderCell id='section-content-tableHeader' width={6}>Preview </Table.HeaderCell>
                            <Table.HeaderCell id='section-content-tableHeader' width={4}>Actions </Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                    </Table>
                    
                    
                    <Table singleLine selectable compact size='small' striped id='section-content-mentor' stackable >
                    <Table.Body>
                    {j.map((item,index) =>
                     <Table.Row key={index}>
                           <Table.Cell singleLine width={4}>{item.namementor}</Table.Cell>
                           <Table.Cell singleLine width={6}>
                                <video class='preview-mentor-container'  
                                        src={item.path}  
                                        
                                        controls 
                                        crossorigin='anonymous'  
                                        controlsList='nodownload' 
                                        type="video/mp4">
                                </video>
                                </Table.Cell>
                           <Table.Cell singleLine width={4}>
                           <Button  
                            icon='check' 
                            basic
                            title='selected' 
                            index={index}      
                            selected={item}      
                            onClick={this.settingSelceted}             
                           >                               
                           </Button>
                           </Table.Cell>
                           </Table.Row>
                        )} 
                      </Table.Body>
                      </Table>
                      </div>
                      : <span>Sorry! There are no videos </span>}

                    </Modal.Content>
                    </Modal>
                </List.Item>
              </List>
            </List.Description>
          </List.Content>
        </List.Item>
        
      </List>
    )
  }
}

export default Video2dImportMentor